import React, { useEffect } from "react";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import { addonProductInfoSelector } from "../../../ReduxModules/Selectors/ProductSelectors";
import AddonProductPage from "../../FormPages/BonusDeals/AddonProduct";
import { pages } from "../../../Utils/CustomerFormHelper";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import FKLoader from "../FKLoader/FKLoader";
import { useParams } from "react-router-dom";
import {
  goToNextStep,
  removeAndGoToNextStep,
  removeSteps
} from "../../../ReduxModules/Actions/PageStepActions/PageStepActions";
import { GET_ADDON_PRODUCT_INFO_SUCCESS } from "../../../ReduxModules/Actions/ProductActions/ProductActionTypes";
import store from "../../../ReduxModules/Store/Store";
import { getCampaignInfo } from "../../../Utils/AxiosFunctions";

function AddonProduct({
  addonProduct,
  isLoading,
  error,
  onNext,
  onBack,
  addonProductId,
  onConfirm
}) {
  const campaigns = store
    .getState()
    .saleState.form.recommendedProducts?.filter((o) => o.id == addonProductId)[0]?.campaigns;
  const campaignId = campaigns?.length > 0 ? campaigns[0] : null;
  let campaign = store
    .getState()
    .productState.products.filter((o) => o.id == addonProductId)[0]
    ?.availableCampaigns?.filter((o) => o.campaignId == campaignId)[0];
  if (campaign?.campaignName) {
    getCampaignInfo(campaign.campaignName).then((res) => {
      //TODO: Putte dette i en dispatcher eller noe i stedet for å kalle den her
      campaign.campaignText = res;
    });
  }

  const params = useParams();
  useEffect(() => {
    if (!addonProduct || (addonProduct && addonProduct.isLoadingProductInfo))
      productActionDispatchers.getAddonProductInfo(addonProductId);

    if (addonProduct.type !== GET_ADDON_PRODUCT_INFO_SUCCESS) {
      pageStepActionDispatchers.removeAndGoToNextStep(window.location.pathname, params);
    }
  }, [addonProductId]);

  const handleConfirm = (selected) => {
    saleActionDispatchers.selectAddonProduct(addonProduct.productId, selected);
    if (onConfirm) {
      onConfirm();
    } else {
      handleNext();
    }
  };

  function handleNext() {
    if (onNext) {
      onNext();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
    }
  }

  function handleBack() {
    if (onBack) {
      onBack();
    } else {
      pageStepActionDispatchers.goToPreviousStep(window.location.pathname, params);
    }
  }
  return (
    <FormPageContainer onBack={handleBack}>
      {addonProduct && !addonProduct.isLoadingProductInfo ? (
        <AddonProductPage
          onConfirm={handleConfirm}
          addonProduct={addonProduct}
          campaign={campaign}
        />
      ) : (
        <FKLoader text={"Laster tilleggsprodukt"} />
      )}
    </FormPageContainer>
  );
}

export default connect(addonProductInfoSelector)(AddonProduct);
