import { bindActionCreators } from "redux";
import store from "../../Store/Store";
import { submitTobbForm, setTobbId } from "./TobbActions";

const actions = {
  submitTobbForm,
  setTobbId
};

const tobbActionDispatchers = bindActionCreators(actions, store.dispatch);

export default tobbActionDispatchers;
