import * as update from "immutability-helper";
import {
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_INFO_ERROR,
  GET_CUSTOMER_INFO_SUCCESS,
  GET_ELHUB_DATA,
  GET_ELHUB_DATA_ERROR,
  GET_ELHUB_DATA_SUCCESS,
  RELOAD_APP_AND_UPDATE_CACHE,
  SET_STATE,
  SUBMIT_FORM,
  SUBMIT_FORM_ERROR,
  SUBMIT_FORM_SUCCESS,
  UPDATE_FORM,
  GET_ADDONPRODUCT,
  GET_ADDONPRODUCT_SUCCESS,
  GET_ADDONPRODUCT_ERROR,
  PARSE_QUERY_STRING_SUCCESS,
  PARSE_QUERY_STRING_ERROR,
  INITIALIZE_DELIVERYPOINT_DATA,
  INITIALIZE_DELIVERYPOINT_DATA_SUCCESS,
  INITIALIZE_DELIVERYPOINT_DATA_ERROR,
  GET_PRODUCT_INFO,
  GET_PRODUCT_INFO_SUCCESS,
  GET_PRODUCT_INFO_ERROR,
  GET_BANK_ID_CUSTOMER_INFO_SUCCESS,
  UPDATE_TRUMF_ID,
  SET_FORM_FROM_SMS_MOVE_IN_INFO,
  SET_FORM_FROM_INTERNAL_MOVE_IN_INFO,
  SET_PRODUCT_HUB_INFO,
  SET_HAS_CHANGED_PRODUCT,
  SELECT_ADDON_PRODUCT,
  SET_E_INVOICE_REFERENCE,
  START_FAMILY_MEMBER_STEPS,
  REMOVE_FAMILY_MEMBER,
  UPDATE_FAMILY_MEMBER,
  SUBMIT_MOBILE_FORM,
  SUBMIT_MOBILE_FORM_SUCCESS,
  SUBMIT_MOBILE_FORM_ERROR,
  SUBMIT_BEDRIFT_FORM,
  SUBMIT_BEDRIFT_FORM_SUCCESS,
  SUBMIT_BEDRIFT_FORM_ERROR,
  GET_MIN_SIDE_LOGIN_SUCCESS,
  SET_TOBB_ID
} from "../Actions/SaleActions/SaleActionTypes";
import * as _ from "lodash";
import { convertToEncryptedQueryString } from "../../Utils/UrlParser";
import { updateServiceWorker } from "../../Utils/ServiceWorkerHelper";
import config from "../../config";
import { getTrumfProductId } from "../../Utils/AdditionalProductHelper";
import {
  ADD_SUMMARY_MOBILE,
  ADD_SUMMARY_POWER,
  GET_EXISTING_CUSTOMER_DATA_BY_SSN_SUCCESS
} from "../Actions/CustomerActions/CustomerActionTypes";
import { SET_INVOICE_SELECTED_INDEX } from "../Actions/InvoiceActions/InvoiceActionTypes";
import { selectedInvoiceOptionToDistributionMethod } from "../../Utils/HelperFunctions";
import {
  COMPLETE_FAMILY_MEMBER,
  SET_MOBILE_SALE_STEPS,
  SET_BEDRIFT_STEPS
} from "../Actions/PageStepActions/PageStepActionTypes";
import { SALE_CONTEXT } from "../Sagas/SaleSaga";

const initialState = {
  form: {
    phone: "",
    firstName: "",
    lastName: "",
    born: "",
    personNumber: "",
    streetName: "",
    houseNo: "",
    zipCode: "",
    city: "",
    email: "",
    avtaletype: null,
    avtaleName: null,
    address: "",
    producthubInfo: {},
    responseOrders: null,
    onlyFirstName: "",
    ssn: "",
    termsAndConditionsConsent: false,
    subscriptionConsent: null,
    brandId: config.theme === "FK" ? 1 : 2, // FK/TK
    coid: null,
    trumfId: null,
    referralId: null,
    recommendedProducts: [],
    trumf: true, // todo: something
    ebNumber: "",
    extraProducts: [],
    additionalSales: [],
    extraProductPriceElement: null,
    moveOutId: 0, //from flyttefrontend redirect
    store: null,
    storeId: null,
    eInvoiceReference: null,
    chosenDistributionMethod: null,
    users: [{ id: 1 }], // mobil,
    tobbId: null,
    // Bedrift:
    companyName: "",
    orgNumber: "",
    invoiceEmail: "",
    ehfOrgNumber: "",
    invoiceAddress: "",
    invoiceZipCode: "",
    campaign: null,
    optionalMainProducts: [] //
  },
  hasChangedProduct: false,
  isPartOfInternalMoveFlow: false,
  isPartOfSmsMoveFlow: false,
  shouldRedirectBackToFlytting: false,
  initialized: false,
  isDeliveryPointDataInitialized: false,
  isInitializingDeliveryPointData: false,
  creditData: null,
  isLoading: false,
  error: null,
  criticalError: null,
  isSubmitting: false,
  submitSuccessful: false,
  filteredExtraProducts: [],
  productInfo: null,
  isLoadingProductInfo: false,
  productInfoError: null,
  bonusCampaigns: [],
  userCreated: false,
  summaryPower: [],
  summaryMobile: []
};

const saleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MOBILE_SALE_STEPS:
      return update(state, {
        form: {
          $set: {
            ...state.form,
            saleContext: SALE_CONTEXT.mobile
          }
        }
      });
    case SET_BEDRIFT_STEPS:
      return update(state, {
        form: {
          $set: {
            ...state.form,
            saleContext: SALE_CONTEXT.bedrift
          }
        }
      });
    case RELOAD_APP_AND_UPDATE_CACHE:
      updateServiceWorker(
        action.registration,
        window.location.origin +
          window.location.pathname +
          convertToEncryptedQueryString(state.form)
      );
      return state;
    case UPDATE_FORM:
      const invoiceDataChanged =
        action.form.ssn === state.form.ssn ||
        action.form.address === state.form.address ||
        action.form.zipCode === state.form.zipCode;
      const recommendedProducts = !_.isEmpty(action.form.recommendedProducts)
        ? !_.isArray(action.form.recommendedProducts)
          ? [action.form.recommendedProducts]
          : action.form.recommendedProducts
        : state.form.recommendedProducts;
      return update(state, {
        form: {
          $set: {
            ...state.form,
            ...action.form,
            isInvoiceDeliveryPointsLoaded:
              invoiceDataChanged || _.isEmpty(state.deliveryPoints)
                ? false
                : state.isInvoiceDeliveryPointsLoaded,
            recommendedProducts
          }
        }
      });

    case SELECT_ADDON_PRODUCT:
      return update(state, {
        form: {
          $set: {
            ...state.form,
            extraProducts: state.form.extraProducts
              .filter((ep) => parseInt(ep.id) !== parseInt(action.productId))
              .concat(
                action.selected
                  ? [
                      state.form.recommendedProducts.find(
                        (rp) => parseInt(rp.id) === parseInt(action.productId)
                      )
                    ]
                  : []
              )
          }
        }
      });

    case UPDATE_TRUMF_ID:
      return update(state, {
        form: {
          $set: {
            ...state.form,
            trumfId: action.trumfId,
            extraProducts: state.form.extraProducts
              .filter((ep) => ep.id !== getTrumfProductId())
              .concat([state.form.recommendedProducts.find((rp) => rp.id === getTrumfProductId())])
          }
        }
      });

    case SET_TOBB_ID:
      return update(state, {
        form: {
          $set: {
            ...state.form,
            tobbId: action.tobbId
          }
        }
      });

    case SET_PRODUCT_HUB_INFO:
      return update(state, {
        form: {
          $set: {
            ...state.form,
            producthubInfo: action.producthubInfo
          }
        }
      });
    case SET_FORM_FROM_SMS_MOVE_IN_INFO:
      return update(state, {
        initialized: { $set: true },
        isPartOfSmsMoveFlow: { $set: true },
        form: {
          $set: {
            ...state.form,
            phone: action.moveIn.inMoverPhoneNr,
            firstName: action.moveIn.inMoverFirstName,
            lastName: action.moveIn.inMoverLastName,
            moveOutId: action.moveIn.moveOutId,
            initialized: true,
            criticalError: null
          }
        }
      });
    case SET_FORM_FROM_INTERNAL_MOVE_IN_INFO:
      return update(state, {
        initialized: { $set: true },
        isPartOfInternalMoveFlow: { $set: true },
        shouldRedirectBackToFlytting: {
          $set: action.moveIn.shouldRedirectBackToFlytting === "true"
        },
        form: {
          $set: {
            ...state.form,
            phone: action.moveIn.inMoverPhoneNr,
            firstName: action.moveIn.inMoverFirstName,
            lastName: action.moveIn.inMoverLastName,
            ssn: action.moveIn.ssn,
            producthubInfo: action.moveIn.producthubInfo,
            recommendedProducts: action.moveIn.productHubAddOnIds,
            bonusCampaigns: action.moveIn.bonusCampaigns,
            initialized: true,
            criticalError: null
          }
        }
      });
    case SET_HAS_CHANGED_PRODUCT:
      return update(state, {
        hasChangedProduct: { $set: action.hasChangedProduct }
      });

    case SET_E_INVOICE_REFERENCE:
      return update(state, {
        form: {
          $set: {
            ...state.form,
            eInvoiceReference: action.payload
          }
        }
      });

    case SET_INVOICE_SELECTED_INDEX:
      return update(state, {
        form: {
          $set: {
            ...state.form,
            chosenDistributionMethod: selectedInvoiceOptionToDistributionMethod(action.payload)
          }
        }
      });

    case START_FAMILY_MEMBER_STEPS:
      if (!action.newUserId) {
        return state;
      }
      const users = [...state.form.users];
      users.push({ id: action.newUserId, extraProducts: [] });
      return update(state, {
        form: {
          $set: { ...state.form, users }
        }
      });

    case COMPLETE_FAMILY_MEMBER:
      return update(state, {
        form: {
          users: {
            $set: state.form.users.map((f) => {
              return f.id === action.userId ? { ...f, completed: true } : f;
            })
          }
        }
      });
    case REMOVE_FAMILY_MEMBER:
      return update(state, {
        form: {
          $set: {
            ...state.form,
            users: state.form.users.filter((f) => f.id !== action.id)
          }
        }
      });

    case UPDATE_FAMILY_MEMBER:
      return update(state, {
        form: {
          $set: {
            ...state.form,
            users: state.form.users.map((f) => {
              if (f.id === parseInt(action.id)) {
                return { ...f, ...action.user };
              }
              return f;
            })
          }
        }
      });
    // ASYNC CALLS
    case PARSE_QUERY_STRING_SUCCESS:
      return update(state, {
        initialized: { $set: true },
        bonusCampaigns: { $set: action.bonusCampaigns },
        form: {
          $set: {
            ...state.form,
            ...action.formData
          }
        }
      });

    case PARSE_QUERY_STRING_ERROR:
      return update(state, {
        initialized: { $set: true },
        criticalError: { $set: action.error }
      });

    case SUBMIT_MOBILE_FORM:
    case SUBMIT_FORM:
      return update(state, {
        isSubmitting: { $set: true }
      });

    case SUBMIT_MOBILE_FORM_SUCCESS:
    case SUBMIT_FORM_SUCCESS:
      return update(state, {
        isSubmitting: { $set: false },
        submitSuccessful: { $set: true },
        form: { $set: { ...state.form, submitOrderResponse: action.response } }
      });

    case SUBMIT_MOBILE_FORM_ERROR:
    case SUBMIT_FORM_ERROR:
      return update(state, {
        isSubmitting: { $set: false },
        error: { $set: action.error }
      });

    case SUBMIT_BEDRIFT_FORM:
      return update(state, {
        isSubmitting: { $set: true }
      });

    case SUBMIT_BEDRIFT_FORM_SUCCESS:
      return update(state, {
        isSubmitting: { $set: false },
        submitSuccessful: { $set: true },
        form: { $set: { ...state.form, submitOrderResponse: action.response } }
      });

    case SUBMIT_BEDRIFT_FORM_ERROR:
      return update(state, {
        isSubmitting: { $set: false },
        error: { $set: action.error }
      });

    case INITIALIZE_DELIVERYPOINT_DATA:
      return update(state, {
        isInitializingDeliveryPointData: { $set: true },
        isDeliveryPointDataInitialized: { $set: true }
      });

    case INITIALIZE_DELIVERYPOINT_DATA_SUCCESS:
      return update(state, {
        isInitializingDeliveryPointData: { $set: false }
      });

    case INITIALIZE_DELIVERYPOINT_DATA_ERROR:
      return update(state, {
        isInitializingDeliveryPointData: { $set: false }
      });

    case GET_CUSTOMER_INFO:
    case GET_ELHUB_DATA:
      return update(state, {
        isLoading: { $set: true }
      });

    case GET_CUSTOMER_INFO_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        form: {
          $set: {
            ...state.form,
            ...(!action.bankId
              ? action.bisnodeData
              : {
                  city: action.bisnodeData.city,
                  zipCode: action.bisnodeData.zipCode
                }),
            address: action.bisnodeData
              ? `${action.bisnodeData.streetName} ${action.bisnodeData.houseNo}${action.bisnodeData.entrance || ""}`
              : state.form.address
          }
        },
        creditData: { $set: action.creditData }
      });

    case GET_CUSTOMER_INFO_ERROR:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: action.error }
      });

    case GET_ELHUB_DATA_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        elhubData: { $set: action.data },
        newDeliveryPoint: {
          $set:
            action.data && action.data.length === 1
              ? { ...state.newDeliveryPoint, ...action.data[0] }
              : state.newDeliveryPoint
        }
      });

    case GET_ELHUB_DATA_ERROR:
      let deliveryPoints = state.deliveryPoints;
      if (_.isEmpty(deliveryPoints)) {
        deliveryPoints = [{}];
      }
      return update(state, {
        isLoading: { $set: false },
        error: { $set: action.error }
      });

    case GET_ADDONPRODUCT:
      return update(state, {
        isLoading: { $set: true }
      });

    case GET_ADDONPRODUCT_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        filteredAddonProducts: { $set: action.data }
      });

    case GET_ADDONPRODUCT_ERROR:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: action.error }
      });

    case GET_PRODUCT_INFO:
      return update(state, {
        isLoadingProductInfo: { $set: true }
      });

    case GET_PRODUCT_INFO_SUCCESS:
      return update(state, {
        isLoadingProductInfo: { $set: false },
        productInfo: { $set: action.productInfo }
      });

    case GET_PRODUCT_INFO_ERROR:
      return update(state, {
        isLoadingProductInfo: { $set: false },
        productInfoError: { $set: action.error }
      });

    case GET_BANK_ID_CUSTOMER_INFO_SUCCESS:
      return update(state, {
        form: { $set: { ...state.form, ...action.customerInfo } }
      });
    case ADD_SUMMARY_MOBILE:
      return {
        ...state,
        summaryMobile: [...state.summaryMobile, action.summaryMobile]
      };
    case ADD_SUMMARY_POWER:
      return {
        ...state,
        summaryPower: [...state.summaryPower, action.summaryPower]
      };
    case GET_MIN_SIDE_LOGIN_SUCCESS:
      return update(state, {
        isLoading: { $set: true },
        form: { $set: { ...state.form, ...action.payload.customerInfo } }
      });
    case GET_EXISTING_CUSTOMER_DATA_BY_SSN_SUCCESS:
      const { invoiceInfo, isActiveCustomer, email } = action;
      if (invoiceInfo && isActiveCustomer) {
        return update(state, {
          form: {
            $set: {
              ...state.form,
              address:
                `${invoiceInfo.streetName} ${invoiceInfo.streetNumber || ""}` +
                (invoiceInfo.streetLetter || ""),
              zipCode: invoiceInfo.zip,
              city: invoiceInfo.city,
              email: email || state.form.email,
              isActiveCustomer
            }
          }
        });
      }
      return state;

    case SET_STATE:
      return { ...state, ...action.state.saleState, initialized: true };
    default:
      return state;
  }
};

export default saleReducer;
