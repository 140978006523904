import update from "immutability-helper";
import {
  GET_SMS_ACCEPT_CUSTOMER_INFO,
  GET_SMS_ACCEPT_CUSTOMER_INFO_SUCCESS,
  GET_SMS_ACCEPT_CUSTOMER_INFO_ERROR,
  SMS_ACCEPT_SUBMIT_CONSENT,
  UPDATE_HASHED_CUSTOMER_ID,
  SUBMIT_SMS_ACCEPT_FORM_SUCCESS,
  SUBMIT_SMS_ACCEPT_FORM,
  SUBMIT_SMS_ACCEPT_FORM_ERROR,
  ADD_ACCEPTED_ORDER,
  REMOVE_ORDER_ACCEPT,
  GET_PRICE_SUMMARY,
  GET_PRICE_SUMMARY_SUCCESS,
  GET_PRICE_SUMMARY_ERROR
} from "../Actions/SmsAcceptActions/SmsAcceptActionTypes";
import { GET_EXTRAPRODUCT_PRICE_INFO_SUCCESS } from "../Actions/ProductActions/ProductActionTypes";

const initialState = {
  customerInfo: null,
  initialized: false,
  isLoadingCustomerInfo: false,
  loadCustomerInfoError: null,
  subscriptionConsent: null,
  hashedCustomerId: null,
  isSubmitting: false,
  submitSuccessful: false,
  submitError: null,
  acceptedOrders: [],
  priceSummary: {},
  priceSummaryError: {},
  isLoadingPriceSummary: false
};

const smsAcceptReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_HASHED_CUSTOMER_ID:
      return update(state, {
        hashedCustomerId: { $set: action.hashedCustomerId }
      });

    case GET_SMS_ACCEPT_CUSTOMER_INFO:
      return update(state, {
        isLoadingCustomerInfo: { $set: true },
        initialized: { $set: true }
      });

    case GET_SMS_ACCEPT_CUSTOMER_INFO_SUCCESS:
      return update(state, {
        isLoadingCustomerInfo: { $set: false },
        customerInfo: { $set: action.customerInfo }
      });

    case GET_SMS_ACCEPT_CUSTOMER_INFO_ERROR:
      return update(state, {
        isLoadingCustomerInfo: { $set: false },
        loadCustomerInfoError: { $set: action.error }
      });

    case SMS_ACCEPT_SUBMIT_CONSENT:
      return update(state, {
        subscriptionConsent: { $set: action.value }
      });

    case SUBMIT_SMS_ACCEPT_FORM:
      return update(state, {
        isSubmitting: { $set: true }
      });

    case SUBMIT_SMS_ACCEPT_FORM_SUCCESS:
      return update(state, {
        isSubmitting: { $set: false },
        submitSuccessful: { $set: true }
      });

    case SUBMIT_SMS_ACCEPT_FORM_ERROR:
      return update(state, {
        isSubmitting: { $set: false },
        submitSuccessful: { $set: false },
        submitError: { $set: action.error }
      });
    case ADD_ACCEPTED_ORDER:
      return update(state, {
        acceptedOrders: { $set: [...state.acceptedOrders, action.id] }
      });
    case REMOVE_ORDER_ACCEPT:
      return update(state, {
        acceptedOrders: { $set: state.acceptedOrders.filter((x) => x !== action.id) }
      });
    case GET_PRICE_SUMMARY_SUCCESS:
      return update(state, {
        isLoadingPriceSummary: { $set: false },
        priceSummary: {
          [action.id]: { $set: [action.priceSummary] } // Update the priceSummary for the specific order
        }
      });

    case GET_PRICE_SUMMARY_ERROR:
      return update(state, {
        isLoadingPriceSummary: { $set: false },
        priceSummaryErrors: {
          [action.id]: { $set: action.error } // Store the error for the specific order
        }
      });

    case GET_PRICE_SUMMARY:
      return update(state, {
        isLoadingPriceSummary: { $set: true }
      });

    default:
      return state;
  }
};

export default smsAcceptReducer;
