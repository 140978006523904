import React, { useEffect, useState } from "react";
import styles from "./OrderInfo.module.scss";
import ProductInfo from "../ProductInfo/ProductInfo";
import { IsFKTheme } from "../../../Utils/Themes";
import { MobileServicePrices } from "../../../Utils/Prices";
import { Collapse, FormControlLabel, FormGroup, Grid } from "@mui/material";
import smsAcceptActionDispatcher from "../../../ReduxModules/Actions/SmsAcceptActions/SmsAcceptActionDispatcher";
import { PhoneIphone } from "@mui/icons-material";
import CheckBox from "../../CheckBox/CheckBox";
import PriceSummarySmsAccept from "../PriceSummary/PriceSummarySmsAccept";

function MobileOrderInfo({ orderInfo, customer, salesTypeId, globalCheck, update, confirmed }) {
  const { mobileSaleData } = orderInfo;
  const [checked, setChecked] = useState(globalCheck);

  useEffect(() => {
    if (!confirmed) {
      if (checked) {
        smsAcceptActionDispatcher.addOrderAccept(orderInfo.id);
      } else smsAcceptActionDispatcher.removeOrderAccept(orderInfo.id);
    }
  }, [checked]);

  useEffect(() => {
    if (!confirmed) {
      setChecked(globalCheck);
    }
  }, [globalCheck]);

  const handleSetChecked = () => {
    if (!confirmed) setChecked(!checked);
  };
  const birthday = () => {
    const date = new Date(mobileSaleData.birthday);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}.${month}.${year}`;
  };

  useEffect(() => {
    if (!confirmed && update) {
      setChecked(true);
    }
  }, [update]);
  return (
    <div className={`whiteBox ${styles.container} ${IsFKTheme && styles.containerFK}`}>
      {checked ? (
        <div>
          <Grid container onClick={handleSetChecked}>
            <Grid item xs={2}>
              <div className={styles.container}>
                <PhoneIphone
                  className={`${IsFKTheme && styles.icon}`}
                  fontSize={"large"}
                ></PhoneIphone>
              </div>
            </Grid>
            <Grid item xs={7} className={styles.containerActiveFK}>
              <div className={styles.infoSection}>
                {mobileSaleData.mobileNumber ? (
                  <div>{mobileSaleData.mobileNumber}</div>
                ) : (
                  <div className={styles.boxTitle}>
                    {mobileSaleData.isOwner
                      ? `${customer.firstName} ${customer.lastName}`
                      : `${mobileSaleData.firstName} ${mobileSaleData.lastName}`}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={styles.floatRigth}>
                <CheckBox checked={checked} onClick={handleSetChecked}></CheckBox>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          <div className={styles.boxTitle}>Mobilabonnement</div>
          <div className={styles.infoSection}>
            <div className={styles.grayText}>Bruker</div>
            <div>
              {mobileSaleData.isOwner
                ? `${customer.firstName} ${customer.lastName}`
                : `${mobileSaleData.firstName} ${mobileSaleData.lastName}`}
            </div>
          </div>
          {mobileSaleData.birthday && (
            <div className={styles.infoSection}>
              <div className={styles.grayText}>Fødselsdato</div>
              <div>{birthday()}</div>
            </div>
          )}
          <div className={styles.infoSection}>
            <div className={styles.grayText}>Mobilnummer</div>
            <div>{mobileSaleData.mobileNumber || `Nytt nummer`}</div>
            {!mobileSaleData.mobileNumber && (
              <div style={{ fontSize: "14px" }}>{`${MobileServicePrices.NewNumber} kr`}</div>
            )}
          </div>
        </div>
      )}

      <Collapse in={!checked} timeout={"auto"}>
        <div className={styles.boxTitle}>Produkt</div>
        <ProductInfo productId={orderInfo.productHubId} order={orderInfo} />

        <PriceSummarySmsAccept
          order={orderInfo}
          power={false}
          addon={orderInfo.extraProducts !== null && orderInfo.extraProducts.length > 0}
          campaign={!!orderInfo.campaign}
        />
        <Grid container onClick={handleSetChecked} mt={2}>
          <Grid item xs={12}>
            {confirmed ? (
              <p className={styles.clickMe}>Akseptert</p>
            ) : (
              <FormGroup row className={styles.orderCheckbox}>
                <FormControlLabel
                  labelPlacement={"top"}
                  style={{ textAlign: "left" }}
                  control={
                    <button className={`btnPrimary ${styles.orderAcceptButton}`}>Velg</button>
                  }
                />
              </FormGroup>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </div>
  );
}

export default MobileOrderInfo;
