import React, { useRef } from "react";
import styles from "./PowerSmartDetails.module.scss";
import clsx from "clsx";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import Icon from "../../Icon/Icon";
import { FormControlLabel } from "@mui/material";
import { getSmartPowerProductId } from "../../../Utils/PowerSmartUtils";

function PowerSmartDetails({
  productId,
  termsUrl,
  cancellationFormUrl,
  title,
  description,
  features,
  ctaText
}) {
  const { SMART_PRODUCT_POWER_ID, SMART_PRODUCT_POWER_PLUS_ID, PULSE_METER_ID } =
    getSmartPowerProductId();

  const pulsOptOutRef = useRef();
  return (
    <>
      <h1 className={clsx(styles.header, "fallIn-")}>{title}</h1>
      <p
        className={clsx(styles.detailsIntro, "slide")}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <div className={clsx(styles.featureContainer, "slide")}>
        <div dangerouslySetInnerHTML={{ __html: features }}></div>
        <div className={styles.infoBox}>
          <Icon icon="WarningCircle" className={styles.infoBoxIcon} />
          <span className={styles.infoBoxText}>
            Fjordkraft-appen kan lastes ned fra App Store (for iPhone), eller fra Google Play
            (telefoner med Android-system), og språket i appen er norsk.
          </span>
        </div>
        {productId === SMART_PRODUCT_POWER_PLUS_ID && (
          <>
            <FormControlLabel
              className={styles.checkbox}
              control={<input type="checkbox" ref={pulsOptOutRef} />}
              label={"Jeg har allerede Fjordkraft Pulsmåler"}
            />
          </>
        )}

        <button
          className={clsx("btnPrimary", styles.cta)}
          onClick={() => {
            saleActionDispatchers.selectAddonProduct(
              PULSE_METER_ID,
              productId === SMART_PRODUCT_POWER_PLUS_ID ? !pulsOptOutRef.current?.checked : false
            );
            saleActionDispatchers.selectAddonProduct(productId, true);
            saleActionDispatchers.selectAddonProduct(
              productId === SMART_PRODUCT_POWER_PLUS_ID
                ? SMART_PRODUCT_POWER_ID
                : SMART_PRODUCT_POWER_PLUS_ID,
              false
            );
            pageStepActionDispatchers.goToNextStep("/Stromsmart");
          }}
          dangerouslySetInnerHTML={{ __html: ctaText }}
        ></button>
        <span className={styles.terms}>
          <a href={termsUrl} rel="noopener noreferrer" target="_blank">
            Vilkår,
          </a>{" "}
          <a href={cancellationFormUrl} rel="noopener noreferrer" target="_blank">
            angreskjema og skjema for opplysninger om angrerett
          </a>
        </span>
      </div>
    </>
  );
}

export { PowerSmartDetails };
