import {
  ADD_AND_GO_TO_NEXT_STEP,
  ADD_STEP,
  ADD_STEPS,
  COMPLETE_FAMILY_MEMBER,
  GO_TO_FIRST_STEP,
  GO_TO_NEXT_STEP,
  GO_TO_PREVIOUS_STEP,
  REMOVE_AND_GO_TO_NEXT_STEP,
  REMOVE_STEPS,
  SET_BEDRIFT_STEPS,
  SET_CONTINUE_AGREEMENT_STEPS,
  SET_CUSTOMER_FORM_STEPS,
  SET_FOCUS_DELAY,
  SET_MOBILE_SALE_STEPS,
  SET_MOBILE_USER_STEPS
} from "./PageStepActionTypes";
import store from "../../Store/Store";
import { pages } from "../../../Utils/CustomerFormHelper";
import { history } from "../../../App";
import { generatePath, matchPath } from "react-router-dom";
import { startDeliveryPointSteps } from "../SaleActions/SaleActions";
import * as _ from "lodash";
import { getAgeFromBirthDate, getAgeFromSsn } from "../../../Utils/HelperFunctions";
import { SALE_CONTEXT } from "../../Sagas/SaleSaga";

export const setCustomerFormSteps = (recommendedProducts, bonuses) => {
  return {
    type: SET_CUSTOMER_FORM_STEPS,
    recommendedProducts,
    bonuses
  };
};

export const setMobileSteps = (recommendedProducts, bonuses) => {
  return {
    type: SET_MOBILE_SALE_STEPS,
    recommendedProducts,
    bonuses
  };
};

export const setBedriftSteps = (recommendedProducts, bonuses) => {
  return {
    type: SET_BEDRIFT_STEPS,
    recommendedProducts,
    bonuses
  };
};

export const setContinueAgreementSteps = (recommendedProducts, bonuses) => {
  // TODO: do u need recc and bonus?
  return {
    type: SET_CONTINUE_AGREEMENT_STEPS,
    recommendedProducts,
    bonuses
  };
};

export const setMobileUserSteps = (userId) => {
  const recommendedProducts = store.getState().saleState.form.recommendedProducts;
  const { users, ssn } = store.getState().saleState.form;
  const user = users.find((f) => f.id === parseInt(userId));
  const initialized = users.some((u) => u.completed);
  const age = user && (user.isOwner ? getAgeFromSsn(ssn) : getAgeFromBirthDate(user.birthDate));
  let skipProductStep = false;
  if (!initialized && user) {
    const product = store.getState().productState.products.find((p) => p.id === user.avtaletype);
    if (!(product.tags.includes("Ung") && age >= 30)) skipProductStep = true;
  }
  return {
    type: SET_MOBILE_USER_STEPS,
    user,
    recommendedProducts,
    users,
    skipProductStep
  };
};
export const removeAndGoToNextStep = (stepToBeRemoved, params = null) => {
  const steps = store.getState().pageStepState.steps;
  let index =
    (params && steps.findIndex((s) => s.includes(generatePath(stepToBeRemoved, params)))) ||
    steps.findIndex((s) => s === stepToBeRemoved) ||
    steps.findIndex((s) => s.includes(stepToBeRemoved));

  if (index > steps.length) {
    throw Error("Error: Attempted to go to next step which would be out of bounds.");
  }
  history.replace(steps[index]);
  history.push(steps[index + 1]);
  return {
    type: REMOVE_AND_GO_TO_NEXT_STEP,
    stepsToBeRemoved: [stepToBeRemoved],
    params
  };
};

export const removeSteps = (stepsToBeRemoved, params = null) => {
  return {
    type: REMOVE_STEPS,
    stepsToBeRemoved,
    params
  };
};

export const goToFirstStep = (replace = false) => {
  const steps = store.getState().pageStepState.steps;
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  if (replace) {
    history.replace(steps[0]);
  } else {
    history.push(steps[0]);
  }
  return {
    type: GO_TO_FIRST_STEP
  }
};

export const goToDeliveryPointPage = (currentPage) => {
  const { initialized, initializing, deliveryPoints } =
    store.getState().deliveryPointState.deliveryPoints;
  if (
    (!_.isEmpty(deliveryPoints) && deliveryPoints.some((dp) => dp.elhubData || dp.complete)) ||
    (!initialized && !initializing)
  ) {
    history.push(pages.deliveryPoints);
    return {
      type: GO_TO_NEXT_STEP,
      currentPage
    };
  } else {
    const saleContext = store.getState().saleState.form.saleContext;
    return startDeliveryPointSteps(saleContext === SALE_CONTEXT.bedrift ? null : 1);
  }
};

function getStepsFromCurrentPage(currentPage) {
  return matchPath(currentPage, { path: pages.mobile + pages.addUser })
    ? store.getState().pageStepState.userSteps
    : matchPath(currentPage, { path: pages.deliveryPointV2 }) ||
        matchPath(currentPage, { path: pages.bedrift + pages.deliveryPointV2 })
      ? store.getState().pageStepState.deliveryPointSteps
      : store.getState().pageStepState.steps;
}
export const goToNextStep = (currentPage, params = null) => {
  const steps = getStepsFromCurrentPage(currentPage);

  if (
    matchPath(currentPage, pages.mobile + pages.addUser) &&
    matchPath(currentPage, steps[steps.length - 1])
  ) {
    history.push(pages.mobile + pages.summary);
    return {
      type: GO_TO_NEXT_STEP,
      currentPage
    };
  }
  let index =
    steps.findIndex((s) => matchPath(currentPage, { path: s, exact: true })) ||
    steps.findIndex((s) => matchPath(currentPage, { path: s })); // todo: Make it always exact path
  index++;
  if (index > steps.length) {
    throw Error("Error: Attempted to go to next step which would be out of bounds.");
  }
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  let nextStep = generatePath(steps[index], params);
  if (matchPath(nextStep, { path: pages.deliveryPoints, exact: true })) {
    return goToDeliveryPointPage();
  }
  history.push(nextStep);
  return {
    type: GO_TO_NEXT_STEP,
    currentPage
  };
};

export const completeUser = (userId) => {
  history.push(pages.mobile + pages.summary);
  return {
    type: COMPLETE_FAMILY_MEMBER,
    userId
  };
};

export const goToPreviousStep = (currentPage, params) => {
  const steps = getStepsFromCurrentPage(currentPage);
  let index =
    steps.findIndex((s) =>
      matchPath(currentPage, {
        path: s,
        exact: true
      })
    ) ||
    steps.findIndex(
      (s) => steps.findIndex((s) => s === currentPage) || matchPath(currentPage, { path: s })
    ); // todo: Make it always exact path
  index--;
  if (index < 0) {
    console.log(
      "Error: Attempted to go to previous step which would be out of bounds of available steps."
    );
    history.push(steps[0]);
    return {
      type: GO_TO_PREVIOUS_STEP,
      currentPage
    };
  }
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  const previousStep = generatePath(steps[index], params);
  history.push(previousStep);
  return {
    type: GO_TO_PREVIOUS_STEP,
    currentPage
  };
};

export const addAndGoToNextStep = (currentPage, nextPage, params) => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  const nextStep = generatePath(nextPage, params);
  history.push(nextStep);
  return {
    type: ADD_AND_GO_TO_NEXT_STEP,
    currentPage,
    nextPage
  };
};

export const addStep = (currentPage, nextPage) => {
  return {
    type: ADD_STEP,
    currentPage,
    nextPage
  };
};

export const addSteps = (currentPage, pages) => {
  return {
    type: ADD_STEPS,
    currentPage,
    pages
  };
};

export const setFocusDelay = (delay) => {
  return {
    type: SET_FOCUS_DELAY,
    delay
  };
};
