import React from "react";
import { connect } from "react-redux";
import { productSelector } from "../../ReduxModules/Selectors/SaleSelectors";

function SecretProductNameAndId({ name, id }) {
  return (
    <div id={"SecretNameAndId"} style={{ display: "none" }}>
      <div id={"SecretName"}>{name}</div>
      <div id={"SecretId"}>{id}</div>
    </div>
  );
}

export default connect(productSelector)(SecretProductNameAndId);
