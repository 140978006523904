import React from "react";
import ProductRadioGroup from "./ProductRadioGroup";
import { useParams } from "react-router-dom";
import ProductCheckGroup from "./ProductCheckGroup";

function ProductGroupContainer({ match, radioGroup, onConfirm, groupId }) {
  const { id, userId } = useParams();
  return radioGroup ? (
    <ProductRadioGroup groupId={groupId || id} userId={userId} onConfirm={onConfirm} radioGroup />
  ) : (
    <ProductCheckGroup groupId={groupId || id} userId={userId} onConfirm={onConfirm} />
  );
}

export default ProductGroupContainer;
