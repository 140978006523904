import React, { useState, useEffect } from "react";
import styles from "./SimpleInput.module.scss";
import { getPostArea } from "../../Utils/AxiosFunctions";

function SimpleInputPostCode({
  name,
  value,
  onChange,
  placeholder,
  disabled,
  city,
  autoComplete,
  inputRef,
  onKeyDown,
  onPaste,
  onBlur
}) {
  const [postArea, setPostArea] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const area = await getPostArea(value);
      setPostArea(area);
      onChange({ target: { name: "city", value: area } });
    };
    value.length === 4 && fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (e) => {
    if (!e.target.value.match("^[0-9]{0,4}$")) {
      return;
    }
    if (name) {
      onChange(e);
    } else {
      onChange(e.target.value);
    }
  };
  return (
    <div className={styles.inputValueBox}>
      <input
        className={styles.inputValue + " " + styles.postcode}
        name={name}
        value={value || ""}
        type={"number"}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        autoComplete={autoComplete}
        ref={inputRef}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        onBlur={onBlur}
      />
      <div className={styles.postArea}>{postArea || "Poststed"}</div>
    </div>
  );
}

export default SimpleInputPostCode;
