import { createStore, applyMiddleware, combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import root from "./Root";
import saleReducer from "../Reducers/SaleReducer";
import deliveryPointReducer from "../Reducers/DeliveryPointReducer";
import trumfReducer from "../Reducers/TrumfReducer";
import bankIdReducer from "../Reducers/BankIdReducer";
import smsAcceptReducer from "../Reducers/SmsAcceptReducer";
//import { composeWithDevTools as composeWithDevToolsDevOnly } from "redux-devtools-extension/developmentOnly";
import { composeWithDevTools } from "redux-devtools-extension";
import productReducer from "../Reducers/ProductReducer";
import customerReducer from "../Reducers/CustomerReducer";
import invoiceReducer from "../Reducers/InvoiceReducer";
import pageStepReducer from "../Reducers/PageStepReducer";
import { config } from "react-transition-group";
import sessionReducer from "../Reducers/SessionReducer";
import tobbReducer from "../Reducers/TobbReducer";

const sagaMiddleware = createSagaMiddleware();

const reducers = combineReducers({
  saleState: saleReducer,
  deliveryPointState: deliveryPointReducer,
  trumfState: trumfReducer,
  bankIdState: bankIdReducer,
  smsAcceptState: smsAcceptReducer,
  productState: productReducer,
  customerState: customerReducer,
  invoiceState: invoiceReducer,
  pageStepState: pageStepReducer,
  sessionState: sessionReducer,
  tobbState: tobbReducer
});
let tempStore;
if (config.env !== "PROD") {
  tempStore = createStore(reducers, composeWithDevTools(applyMiddleware(sagaMiddleware)));
} else {
  tempStore = createStore(reducers, composeWithDevTools(applyMiddleware(sagaMiddleware)));
}
const store = tempStore;
sagaMiddleware.run(root);

export default store;
