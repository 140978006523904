import { call, put, takeLatest } from "redux-saga/effects";
import * as AxiosFunction from "../../Utils/AxiosFunctions";
import { getProducts } from "../../Utils/AxiosFunctions";
import {
  GET_ADDONPRODUCT,
  GET_BANK_ID_TOKEN_RESPONSE,
  GET_CUSTOMER_INFO,
  GET_ELHUB_DATA,
  INITIALIZE_DELIVERYPOINT_DATA,
  PARSE_QUERY_STRING,
  SUBMIT_BEDRIFT_FORM,
  SUBMIT_FORM,
  SUBMIT_MOBILE_FORM
} from "../Actions/SaleActions/SaleActionTypes";
import {
  getAddonProductErrorAction,
  getAddonProductSuccessAction,
  getBankIdCustomerInfoErrorAction,
  getBankIdCustomerInfoSuccessAction,
  getCustomerInfoErrorAction,
  getCustomerInfoSuccessAction,
  getElhubDataErrorAction,
  getElhubDataSuccessAction,
  initializeDeliveryPointDataErrorAction,
  initializeDeliveryPointDataSuccessAction,
  parseQueryStringErrorAction,
  parseQueryStringSuccessAction,
  setStateAction,
  submitFormErrorAction,
  submitFormSuccessAction,
  submitMobileFormErrorAction,
  submitMobileFormSuccessAction
} from "../Actions/SaleActions/SaleActions";
import * as _ from "lodash";
import config from "../../config";
import {
  CHOSEN_DISTRIBUTION_METHOD,
  getPriceAreaTags,
  getWebStoreId
} from "../../Utils/HelperFunctions";
import {
  setBedriftSteps,
  setContinueAgreementSteps,
  setCustomerFormSteps,
  setMobileSteps
} from "../Actions/PageStepActions/PageStepActions";
import { IsFKTheme } from "../../Utils/Themes";
import jwt_decode from "jwt-decode";
import { productCategories } from "../../Utils/CustomerFormHelper";
import { getProductsError, getProductsSuccess } from "../Actions/ProductActions/ProductActions";
import { InvoicePrices } from "../../Utils/Prices";
import IframeHelper from "../../components/CustomerForm/Iframe/IframeHelper";

function* getBisnodeData(action) {
  try {
    const bisnodeData = yield call(AxiosFunction.getBisnodeInfo, action.phone);
    yield put(getCustomerInfoSuccessAction(bisnodeData, action.bankId));
  } catch (e) {
    yield put(getCustomerInfoErrorAction(e));
  }
}

export function* watchGetBisnodeData() {
  yield takeLatest(GET_CUSTOMER_INFO, getBisnodeData);
}

function* initializeDeliveryPointData(action) {
  try {
    const { ssn, address, zipCode, city, isBedrift } = action;
    if (isBedrift) {
      let res = yield call(AxiosFunction.getBedriftSiteList, ssn, null, null, null, null);
      yield put(
        initializeDeliveryPointDataSuccessAction(null, null, null, null, null, isBedrift, res)
      );
      // TODO: Now what...
    } else if (address) {
      let res = yield call(AxiosFunction.getElectricMeterInfo, zipCode, address, ssn, null);
      let elhubData;
      if (_.isArray(res) && res.length >= 1) {
        elhubData = res;
      }
      const mpid =
        res !== null
          ? res
              .filter(
                (data) =>
                  data?.meteringPointIdentification !== null &&
                  data?.meteringPointIdentification !== undefined
              )
              .map((data) => data.meteringPointIdentification)
              .find(Boolean) // Using find(Boolean) to get the first non-null/undefined value or undefined if not found
          : null;

      const priceArea =
        mpid !== null
          ? yield call(AxiosFunction.getPriceAreaName, mpid) //nye versjonen hvor vi sjekker elhub for priceArea ;)
          : yield call(AxiosFunction.getPriceArea, zipCode); //gamle versjonen hvor vi sjekker VÅR database. Men den inneholder ikke alle :(

      yield put(
        initializeDeliveryPointDataSuccessAction(elhubData, address, zipCode, city, priceArea)
      );
    } else {
      yield put(initializeDeliveryPointDataSuccessAction(null, address, zipCode, city));
    }
  } catch (e) {
    yield put(
      initializeDeliveryPointDataErrorAction(e, action.address, action.zipCode, action.city)
    );
  }
}

export function* watchInitializeDeliveryPointDataAction() {
  yield takeLatest(INITIALIZE_DELIVERYPOINT_DATA, initializeDeliveryPointData);
}

function* getElhubData(action) {
  try {
    const { ssn, address, zipCode, meterIdentification, id, priceArea, isBedrift } = action;
    let elhubData;
    let priceAreaNumber;

    if (address) {
      let res = yield call(
        AxiosFunction.getElectricMeterInfo,
        zipCode,
        address,
        ssn,
        meterIdentification
      );
      if (_.isArray(res) && res.length >= 1) {
        elhubData = res;
      }
    } else if (zipCode && meterIdentification) {
      let res = yield call(AxiosFunction.getEMInfoWithZipAndMeterNr, zipCode, meterIdentification);
      if (_.isArray(res) && res.length >= 1) {
        elhubData = res;
      }
      if (!_.isEmpty(elhubData)) {
        let isMoving = _.isEmpty(
          yield call(AxiosFunction.getEMInfoWithSsnAndMeterNr, ssn, meterIdentification)
        );
        for (let e of elhubData) {
          e.isMoving = isMoving;
        }
      }
    }

    if (!isBedrift && !priceArea) {
      if (elhubData && elhubData[0].meteringPointIdentification) {
        priceAreaNumber = yield call(
          AxiosFunction.getPriceAreaName,
          elhubData[0].meteringPointIdentification
        );
      }
      if (!priceAreaNumber) {
        // Backup if we still don't have price area number
        priceAreaNumber = yield call(AxiosFunction.getPriceArea, zipCode);
      }
    }

    yield put(getElhubDataSuccessAction(elhubData, id, priceAreaNumber, isBedrift));
  } catch (e) {
    yield put(getElhubDataErrorAction(e, action.id));
  }
}

export function* watchGetElhubData() {
  yield takeLatest(GET_ELHUB_DATA, getElhubData);
}

function pushDataLayerBedrift(form, responseOrders, extraProducts) {
  const products = [];
  products.push({
    name: form.producthubInfo?.name,
    id: form.producthubInfo?.id,
    price: 100,
    category: "Bedrift Strøm",
    quantity: 1
  });
  if (extraProducts) {
    for (let ep of extraProducts) {
      let existingExtraProduct = products.find((p) => p.id === ep.id);
      if (existingExtraProduct) {
        existingExtraProduct.quantity++;
      } else {
        products.push({
          name: ep.name,
          id: ep.id,
          price: 100, //ep.recPrice || 0,
          category: "Bedrift Strøm tillegsprodukt",
          quantity: 1
        });
      }
    }
  }

  const responseOrder = responseOrders[0];
  const splitContactPerson = splitLastOccurrence(responseOrder.powerSaleData?.contactPerson, " ");
  window.dataLayer.push({
    event: "purchase",
    email: responseOrder.customer?.contactInfo?.email,
    phone: responseOrder.customer?.contactInfo?.phone,
    street: responseOrder.powerSaleData?.address,
    firstName: splitContactPerson[0],
    lastName: splitContactPerson[1],
    country: "Norway",
    postCode: responseOrder.powerSaleData?.zipCode,
    ecommerce: {
      purchase: {
        actionField: {
          id: responseOrder.id, // orderId
          affiliation: "B2B",
          revenue: 100, // total init price + total recprice + invoicePrice
          tax: 0,
          shipping: 0
        },
        products
      }
    }
  });
}

function splitLastOccurrence(str, substring) {
  if (!str) return [null, null];
  const lastIndex = str.lastIndexOf(substring);
  if (lastIndex === -1) return [str, null];
  const before = str.slice(0, lastIndex);
  const after = str.slice(lastIndex + 1);
  return [before, after];
}

function pushDataLayerPower(form, responseOrders, extraProducts) {
  const products = [];
  products.push({
    name: form.producthubInfo.name,
    id: form.producthubInfo.id,
    price: 100, //form.producthubInfo.recPrice || 0,
    category: "Strøm",
    quantity: 1
  });
  for (let ep of extraProducts) {
    let existingExtraProduct = products.find((p) => p.id === ep.id);
    if (existingExtraProduct) {
      existingExtraProduct.quantity++;
    } else {
      products.push({
        name: ep.name,
        id: ep.id,
        price: 100,
        category: "Strøm tillegsprodukt",
        quantity: 1
      });
    }
  }
  const invoicePrice =
    form.chosenDistributionMethod === CHOSEN_DISTRIBUTION_METHOD.paper ? InvoicePrices.Paper : 0;
  const revenue =
    products.reduce((totalRevenue, product) => totalRevenue + product.price * product.quantity, 0) +
    invoicePrice;
  const responseOrder = responseOrders[0];
  window.dataLayer.push({
    event: "purchase",
    email: responseOrder.customer.contactInfo.email,
    phone: responseOrder.customer.contactInfo.phone,
    firstName: responseOrder.customer.firstName,
    lastName: responseOrder.customer.lastName,
    street: responseOrder.powerSaleData.address,
    country: "Norway",
    postCode: responseOrder.customer.invoiceInfo.invoiceZipCode,
    ecommerce: {
      purchase: {
        actionField: {
          id: responseOrder.id,
          affiliation: "Power",
          revenue, // total init price + total recprice + invoicePrice
          invoicePrice:
            form.chosenDistributionMethod === CHOSEN_DISTRIBUTION_METHOD.paper
              ? InvoicePrices.Paper
              : 0,
          tax: 0,
          shipping: 0
        },
        products
      }
    }
  });
}

function pushDataLayerMobile(form, responseOrders, productList) {
  const products = [];
  let revenue = 0;
  for (let u of form.users) {
    let product = productList.find((p) => p.id === u.product.id);
    let existingProduct = products.find((p) => p.id === u.product.id);
    if (existingProduct) {
      existingProduct.quantity++;
      revenue += existingProduct.price;
      revenue += existingProduct.initPrice;
    } else {
      products.push({
        name: product.name,
        id: product.id,
        price: product.data.priceIncVat || 0,
        initPrice: (product.data.initialPriceIncVat || 0) + u.createNewNumber ? 99 : 0,
        category: "Mobilabonnement",
        quantity: 1
      });
      revenue += product.data.priceIncVat || 0;
      revenue += (product.data.initialPriceIncVat || 0) + u.createNewNumber ? 99 : 0;
    }
    for (let ep of u.extraProducts) {
      let existingExtraProduct = products.find((p) => p.id === parseInt(ep.id));
      let product = productList.find((p) => p.id === parseInt(ep.id));
      if (existingExtraProduct) {
        existingExtraProduct.quantity++;
        revenue += existingExtraProduct.price;
        revenue += existingExtraProduct.initPrice;
      } else {
        products.push({
          name: product.name,
          id: parseInt(product.id),
          price: product.data.monthlyFeeIncVat || 0,
          initPrice: product.data.initialFeeIncVat || 0,
          category: "Mobil tilleggstjeneste",
          quantity: 1
        });
        revenue += product.data.monthlyFeeIncVat || 0;
        revenue += product.data.initialFeeIncVat || 0;
      }
    }
  }
  const invoicePrice =
    form.chosenDistributionMethod === CHOSEN_DISTRIBUTION_METHOD.paper
      ? InvoicePrices.MobilePaper
      : 0;
  const responseOrder = responseOrders[0];

  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      purchase: {
        actionField: {
          id: responseOrder.id, // orderId
          affiliation: "Mobile",
          revenue, // total init price + total recprice + invoicePrice
          invoicePrice: invoicePrice,
          tax: 0,
          shipping: 0
        },
        products
      }
    }
  });
}

function* submitBedriftForm(action) {
  const deliveryPoints = action.deliveryPoints;
  const form = action.form;

  let sale = [];

  for (var i = 0; i < deliveryPoints.length; i++) {
    sale.push({
      deliveryPoint: {
        address: deliveryPoints[i].address,
        zipCode: deliveryPoints[i].zipCode,
        city: deliveryPoints[i].city,
        meteringPointIdentification: deliveryPoints[i].elhubData?.meteringPointIdentification, //målepunktId
        meterIdentification: deliveryPoints[i].elhubData
          ? deliveryPoints[i].elhubData?.meterIdentification
          : deliveryPoints[i].meterIdentification //målernummer
      },
      productId: form.avtaletype,
      startUpDate: deliveryPoints[i].moveInDate,
      // extraProducts: extraProducts,
      // campaign: state.powerOrders[i].productCampaign ? {
      //     productHubId: state.powerOrders[i].productCampaign.id,
      //     campaignName: state.powerOrders[i].productCampaign.name
      // } : null,
      // accountId: state.powerOrders[i].invoice.accountId,
      // iSCUCustomerId: state.powerOrders[i].invoice.refId,
      // bonusCampaigns: state.powerOrders[i].bonusCampaigns,
      // rightOfWithDrawal: state.powerOrders[i].startDate.name === '14 dagers oppstart' ? true :
      //     (momentDate.isSame(moment(state.powerOrders[0].startDate.date), 'day') ||
      //         moment(state.powerOrders[0].startDate.date) > momentDate) ? true : false,
      // priceElementList: clone,
      // minimumTermDays: state.powerOrders[i].minimumTermDays !== null ? state.powerOrders[i].minimumTermDays : null,
      // invoiceLabel: state.powerOrders[i].invoiceLabel,
      invoiceDetailsCore: {
        address: form.invoiceAddress,
        zipCode: form.invoiceZipCode,
        email: form.invoiceEmail.trim(),
        distributionMethod: form.chosenDistributionMethod,
        //     postBoxAddress: (state.postBoxAddress !== null && state.postBoxAddress.length > 0) ? state.postBoxAddress : null,
        //     co: (state.coAddress !== null && state.coAddress.length > 0) ? state.coAddress : null,
        //     att: (state.attAddress !== null && state.attAddress.length > 0) ? state.attAddress : null,
        eInvoiceReference: form.chosenDistributionMethod === 4 ? form.ehfOrgNumber : null
      },
      contactPerson: form.firstName + " " + form.lastName
      // iRiskDealId: state.powerOrders[i].iRiskDealId,
      // iRiskGroupId: state.powerOrders[i].iRiskGroupId,
      // fixedPriceCore: state.powerOrders[i].fixedPrice != null ? state.powerOrders[i].fixedPrice : null
    });
  }

  let salesModuleSales = [];

  salesModuleSales = {
    // commissionCampaign: selectedCommissionCampaign === 0 ? null : selectedCommissionCampaign,
    ssn: form.orgNumber,
    firstName: null,
    lastName: null,
    fullName: form.companyName,
    organizationNumber: form.orgNumber,
    phone: form.phone,
    email: form.email.trim(),
    address: form.address,
    zipCode: form.zipCode,
    city: null,
    brandId: form.brandId,
    seller: null,
    store:
      form.brandId === 1
        ? { IdentityServerId: 5, Name: "fjordkraft.no" }
        : { IdentityServerId: 309, Name: "trondelagkraft.no" },
    sale: sale,
    // mdmId: state.mdmId,
    accepted: true,
    chosenDistributionMethod: form.chosenDistributionMethod,
    // creditCheckResult: state.creditCheck ? state.creditCheck.creditStatus === 1 ? 1 : null : null,
    salesTypeId: 2,
    contractMail: false,
    overrideCredit: false,
    // postBoxAddress: (state.postBoxAddress !== null && state.postBoxAddress.length > 0) ? state.postBoxAddress : null,
    // co: (state.coAddress !== null && state.coAddress.length > 0) ? state.coAddress : null,
    // att: (state.attAddress !== null && state.attAddress.length > 0) ? state.attAddress : null,
    // overrideAccept: chosenConfirm === 'signature' ? true : state.overrideAccept,
    digitalSignature: null
  };

  try {
    const response = yield call(AxiosFunction.postBedriftOrder, salesModuleSales);
    try {
      pushDataLayerBedrift(action.form, response, action.products);
      if (window.self !== window.top) {
        IframeHelper(response);
      }
    } catch (e) {
      console.log(`Failed to push to dataLayer. Error: ${e.message}`);
      yield call(
        AxiosFunction.reportError,
        "Failed to push to dataLayer (business)",
        "Failed to push to dataLayer",
        e
      );
    }
    yield put(submitMobileFormSuccessAction(response));
  } catch (e) {
    yield put(submitMobileFormErrorAction(e));
  }
}

export function* watchSubmitBedriftForm() {
  yield takeLatest(SUBMIT_BEDRIFT_FORM, submitBedriftForm);
}

function* submitMobileForm(action) {
  try {
    const response = yield call(AxiosFunction.postMobileOrder, action.form);
    try {
      pushDataLayerMobile(action.form, response, action.products);
      if (window.self !== window.top) {
        IframeHelper(response);
      }
    } catch (e) {
      console.log(`Failed to push to dataLayer. Error: ${e.message}`);
      yield call(
        AxiosFunction.reportError,
        "Failed to push to dataLayer (mobile)",
        "Failed to push to dataLayer",
        e
      );
    }
    yield put(submitMobileFormSuccessAction(response));
  } catch (e) {
    yield put(submitMobileFormErrorAction(e));
  }
}

export function* watchSubmitMobileForm() {
  yield takeLatest(SUBMIT_MOBILE_FORM, submitMobileForm);
}
function* submitForm(action) {
  try {
    const response = yield call(AxiosFunction.postOrder, action.form);

    try {
      const fetchedProducts = [];
      for (const ep of action.form.extraProducts) {
        const productInfo = action.products.find((p) => p.id === parseInt(ep.id));
        fetchedProducts.push(productInfo);
      }
      pushDataLayerPower(action.form, response, fetchedProducts);
      if (window.self !== window.top) {
        IframeHelper(response);
      }
    } catch (e) {
      console.log(`Failed to push to dataLayer. Error: ${e.message}`);
      yield call(
        AxiosFunction.reportError,
        "Failed to push to dataLayer (power)",
        "Failed to push to dataLayer",
        e
      );
    }

    yield put(submitFormSuccessAction(response));
  } catch (e) {
    yield put(submitFormErrorAction(e));
  }
}

export function* watchSubmitForm() {
  yield takeLatest(SUBMIT_FORM, submitForm);
}

function* getAddonProduct(action) {
  try {
    const { brand, customerType } = action;
    let addonProducts;
    addonProducts = yield call(AxiosFunction.getAddonProducts, brand, customerType);
    yield put(getAddonProductSuccessAction(addonProducts));
  } catch (e) {
    yield put(getAddonProductErrorAction(e));
  }
}

export function* watchGetAddonProduct() {
  yield takeLatest(GET_ADDONPRODUCT, getAddonProduct);
}

function* getState(queryString) {
  // todo: fix this
  if (!_.isEmpty(queryString)) {
    let state;
    try {
      state = JSON.parse(window.sessionStorage.getItem("state"));
    } catch (e) {
      console.error(`Failed to parse state in session storage. Error: ${e.message}`);
    }
    if (state && state.saleState && state.saleState.initialized) {
      yield put(setStateAction(state));
    }
    const sessionS = window.sessionStorage.getItem("smQueryString");
    if (sessionS) {
      try {
        queryString = JSON.parse(sessionS);
      } catch (e) {
        console.error(`Failed to parse queryString in session storage. Error: ${e.message}`);
      }
    }
  } else {
    window.sessionStorage.setItem("smQueryString", JSON.stringify(queryString));
  }
}

function* parseQueryString(action) {
  // TODO: splitte deg opp i flere funksjoner ? hindre å gjøre mye endringer her pga continueExistingAgreement
  try {
    let { queryString, isMobile, isBedrift } = action;
    getState(queryString);
    let avtaletype = queryString && queryString.avtaletype;
    let result;
    let isWeb;
    let continueExistingAgreement = queryString && queryString.continueExistingAgreement === "true";
    let optionalMainProducts = [];

    if (continueExistingAgreement) {
      let optionalProduct1 = queryString?.optionalProduct1;
      let optionalProduct2 = queryString?.optionalProduct2;
      optionalMainProducts.push(optionalProduct1, optionalProduct2);
    }

    if (avtaletype) {
      isWeb = yield call(AxiosFunction.CheckIfStoreIsWeb, avtaletype);
      if (isWeb || config.env === "dev" || config.env === "test") {
        result = yield call(AxiosFunction.GetProductHubProductWithBonuses, avtaletype);
      } else {
        throw new Error("Error: Missing avtaleType");
      }
    } else if (continueExistingAgreement) {
      // just continue
      //yield put(setContinueAgreementSteps(null, null))
    } else {
      if (config.env === "dev") {
        if (isMobile) {
          avtaletype = "256";
          result = yield call(AxiosFunction.GetProductHubProductWithBonuses, avtaletype);
        } else {
          avtaletype = IsFKTheme ? config.defaultProductId : "10";
          result = yield call(AxiosFunction.GetProductHubProductWithBonuses, avtaletype);
        }
      } else {
        throw new Error("Error: Missing avtaleType");
      }
    }
    let guid =
      result != null
        ? yield call(AxiosFunction.GetTermsAndConditionsGuidById, result.termsAndConditionsId)
        : yield call(AxiosFunction.GetTermsAndConditionsGuidById, 241); // 241 strøm til spotpris terms ID  // TODO: bedre måte å håndtere deg på
    let producthubInfo = { ...result, priceAreaTags: getPriceAreaTags(result), guid };
    let recommendedProducts = (queryString && queryString.recommendedProducts) || [];
    let priceElement = (queryString && queryString.priceElement) || null;
    if (!_.isEmpty(recommendedProducts)) {
      if (_.isString(recommendedProducts)) {
        recommendedProducts = recommendedProducts.split(",");

        if (priceElement) {
          // Selle's sktine triks
          recommendedProducts = recommendedProducts.map((rp) => {
            return { id: rp, productHubId: rp, priceElement, optional: true };
          });
        } else {
          recommendedProducts = recommendedProducts.map((rp) => {
            return { id: rp, productHubId: rp, optional: true }; // Backwardcompatible with old urlencoded links.
          });
        }
      }
    }
    try {
      // todo: Fix categories
      const products = yield call(
        getProducts,
        IsFKTheme ? "FKAS" : "TKAS",
        isBedrift ? "B" : "P",
        isMobile
          ? [productCategories.serviceOption, productCategories.productOption]
          : [productCategories.addon],
        [getWebStoreId()]
      );
      recommendedProducts = recommendedProducts.map((rp) => {
        return { ...rp, tags: products.find((p) => p.id === parseInt(rp.id)).tags };
      });
      yield put(getProductsSuccess(products));
    } catch (e) {
      yield put(getProductsError(e));
      throw new Error("Failed to get products when parsing query strings");
    }
    let avtaleName = result?.name;
    let address =
      queryString && queryString.streetName
        ? queryString.streetName + " " + (queryString.houseNo ? queryString.houseNo : "")
        : "";
    let newForm = {
      ...queryString,
      avtaletype,
      avtaleName,
      producthubInfo,
      recommendedProducts,
      extraProducts: recommendedProducts.filter((rp) => !rp.optional),
      optionalMainProducts: optionalMainProducts
    };
    if (address) {
      newForm.address = address;
    }
    if (queryString?.campaign) {
      if (isMobile) {
        let campaignProduct = yield call(
          AxiosFunction.GetProductHubProductById,
          queryString.campaign
        );
        let text = yield call(AxiosFunction.getProductInformation, queryString.campaign);
        newForm.campaign = {
          productHubId: campaignProduct.id,
          campaignName: campaignProduct.name,
          text: text
        };
      } else {
        let campaignProduct = yield call(AxiosFunction.GetCampaignByMomentId, queryString.campaign);
        newForm.campaign = {
          productHubId: campaignProduct.campaignId,
          campaignName: campaignProduct.name
        };
      }
    }
    if (isMobile) {
      yield put(setMobileSteps(recommendedProducts));
    } else if (isBedrift) {
      yield put(setBedriftSteps(recommendedProducts));
    } else if (continueExistingAgreement) {
      yield put(setContinueAgreementSteps(recommendedProducts, result?.bonusCampaigns));
    } else {
      yield put(setCustomerFormSteps(recommendedProducts, result.bonusCampaigns));
    }
    yield put(
      parseQueryStringSuccessAction({
        ...{
          formData: newForm,
          bonusCampaigns: result?.bonusCampaigns
        },
        ...queryString
      })
    );
  } catch (e) {
    console.log(`Failed to parse query string. Error: ${e.message}`);
    yield put(parseQueryStringErrorAction(e));
  }
}

export function* watchParseQueryString() {
  yield takeLatest(PARSE_QUERY_STRING, parseQueryString);
}

function* getBankIdTokenResponse(action) {
  try {
    const tokenResponse = yield call(AxiosFunction.getBankIdToken, action.code);

    if (action.error) {
      yield call(
        AxiosFunction.reportError,
        "BankId failed",
        "User unexpetedly failed to login to bankid",
        `error:${action.error}\nsubtype:${action.subtype}`
      );
    }

    const idTokenJson = jwt_decode(tokenResponse.idToken);

    let opt = idTokenJson["opt"];

    let info = opt.split("&").reduce(function (option, current) {
      let [key, value] = current.split("=");
      option[key] = value;
      return option;
    }, {});

    const customerInfo = {
      firstName: info.firstName,
      lastName: info.lastName,
      ssn: idTokenJson.pid,
      phone: info["BIDMOB_MSN"]
    };

    yield put(getBankIdCustomerInfoSuccessAction(customerInfo));
  } catch (e) {
    yield put(getBankIdCustomerInfoErrorAction(e));
  }
}

export function* watchGetBankIdTokenResponse() {
  yield takeLatest(GET_BANK_ID_TOKEN_RESPONSE, getBankIdTokenResponse);
}

export const SALE_CONTEXT = {
  powerSale: "POWERSALE",
  mobile: "MOBILE",
  bedrift: "BEDRIFT"
};
