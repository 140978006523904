import CalendarSelector from "../../../../../Calendar/CalendarSelector";
import styles from "../DeliveryPointMovingDate.module.scss";
import React, { useState } from "react";
import saleActionDispatchers from "../../../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import FormPageContainer from "../../../../FormPageContainer/FormPageContainer";
import {
  deliveryPointSelector,
  deliveryPointV2Selector
} from "../../../../../../ReduxModules/Selectors/SaleSelectors";
import { history } from "../../../../../../App";
import { pages } from "../../../../../../Utils/CustomerFormHelper";
import { addDays } from "../../../../../../Utils/HelperFunctions";
import ConfirmDateModal from "./ConfirmDateModal";
import { IsFKTheme } from "../../../../../../Utils/Themes";
import PowerMeter from "../../../../../../assets/Images/FK/CalendarFramed.svg";
import pageStepActionDispatchers from "../../../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { useParams } from "react-router-dom";

function DeliveryPointCalendar(props) {
  const { deliveryPoint, deliveryPointId, onBack } = props;
  const { moveInDate } = deliveryPoint;
  const [showConfirmDateModal, setShowConfirmDateModal] = useState(null); // todo: refactor so it makes more sense when reading code. showConfirmDateModal actually stores the date that user has to confirm
  const params = useParams();

  const handleMovingDateClick = (moveInDate) => {
    if (moveInDate < addDays(new Date(), 14)) {
      setShowConfirmDateModal(moveInDate);
    } else {
      handleMovingDateChange(moveInDate);
    }
  };
  const handleMovingDateChange = (moveInDate, rightOfWithdrawal = true) => {
    if (moveInDate) {
      moveInDate.setHours(moveInDate.getHours() + 2);
    }
    saleActionDispatchers.updateDeliveryPointAction(parseInt(deliveryPointId), {
      moveInDate,
      rightOfWithdrawal
    });
    setShowConfirmDateModal(null);
  };

  const handleConfirm = () => {
    saleActionDispatchers.updateDeliveryPointAction(parseInt(deliveryPointId), {
      complete: true,
      selected: true
    });
    history.push(pages.deliveryPoints);
  };

  const handleBack = () => {
    if (deliveryPoint.elhubData?.isMoving) {
      history.push(pages.deliveryPoints);
    } else {
      pageStepActionDispatchers.goToPreviousStep(window.location.pathname, params);
    }
  };
  return (
    <FormPageContainer onBack={handleBack} currentStep={pages.deliveryPoints}>
      {showConfirmDateModal && (
        <ConfirmDateModal
          show={showConfirmDateModal}
          onConfirm={() => handleMovingDateChange(showConfirmDateModal, false)}
          onCancel={() => setShowConfirmDateModal(null)}
          onClose={() => setShowConfirmDateModal(null)}
        />
      )}
      <h2 className={`${styles.header} fallIn-`}>Når skal strømavtalen gjelde fra?</h2>
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img
            className={"frameImage formPage-background fade-"}
            src={PowerMeter}
            alt="PowerMeter"
          />
        </div>
      )}
      <div className={"slide"}>
        <CalendarSelector onChange={handleMovingDateClick} value={moveInDate} />
        <br></br>
        <button
          disabled={!moveInDate}
          className={`btnPrimary fadeDownButton ${styles.button}`}
          onClick={handleConfirm}
        >
          Bekreft
        </button>
      </div>
    </FormPageContainer>
  );
}

export default connect(deliveryPointV2Selector)(DeliveryPointCalendar);
