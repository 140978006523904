import { bindActionCreators } from "redux";
import store from "../../Store/Store";
import {
  getCustomerByHashId,
  updateOrderMeterNumber,
  submitMeterNumberUpdate,
  getExistingCustomerDataBySsn,
  getInvoiceInfo
} from "./CustomerActions";

const actions = {
  getCustomerByHashId,
  updateOrderMeterNumber,
  submitMeterNumberUpdate,
  getExistingCustomerDataBySsn,
  getInvoiceInfo
};

const customerActionDispatchers = bindActionCreators(actions, store.dispatch);

export default customerActionDispatchers;
