import React, { useEffect } from "react";
import { IsFKTheme } from "../../Utils/Themes";
window.preloadedImages = [];

const imageFolder = "../../assets/Images";
// todo: Handle image loader in pipeline maybe?
function Preloader() {
  useEffect(() => {
    const imagesFK = [
      "../../assets/Images/BankIdLogo.svg",
      "../../assets/Images/FK/PowerWithBg.svg",
      "../../assets/Images/FK/HouseAndPowerMeter.svg",
      "../../assets/Images/FK/Strømmåler.svg",
      "../../assets/Images/FK/Hus_power.svg",
      "../../assets/Images/FK/House.svg",
      "../../assets/Images/FK/FKMascot.svg",
      "../../assets/Images/FK/Waterfall.svg",
      "../../assets/Images/FK/HouseWithBackground.svg",
      "../../assets/Icons/CheckCircleGreen.svg",
      "../../assets/Icons/UnCheckCircleGreen.svg",
      "../../assets/Images/FK/MascotBook.svg",
      "../../assets/Images/FK/RabatterOgTilbud.svg",
      "../../assets/Images/FK/HouseWelcome.svg",
      "../../assets/Images/FK/Trumf.svg",
      "../../assets/Images/FK/TrumfWithBg.svg",
      "../../assets/Images/FK/Phone.svg",
      "../../assets/Images/FK/Spotpris.svg",
      "../../assets/Images/FK/SpotprisWhite.svg",
      "../../assets/Images/Bedrift/Finnmaler.svg",
      "../../assets/Images/Bedrift/Identifisering.svg",
      "../../assets/Images/Bedrift/Oppstart.svg",
      "../../assets/Images/Bedrift/Oppsummering.svg"
    ];
    const imagesFKMobile = [
      "../../assets/Images/FK/Mobile/MascotBlush",
      "../../assets/Images/FK/Mobile/MascotStarEyes",
      "../../assets/Images/FK/Mobile/MobileMascotBlush",
      "../../assets/Images/FK/Mobile/MobileMascotStarEyes",
      "../../assets/Images/FK/Mobile/MobileMascotGasp",
      "../../assets/Images/FK/Mobile/MobileMascotGlasses"
    ];
    const imagesTK = [
      "../../assets/Images/BankIdLogo.svg",
      "../../assets/Images/TK/EllipseDarkTeal.svg",
      "../../assets/Images/TK/EllipseDarkTealDesktop.svg",
      "../../assets/Icons/LoadingIcon.svg",
      "../../assets/Images/TK/WhereIsMyMeterNumber.png",
      "../../assets/Icons/CheckedTK.svg",
      "../../assets/Icons/UncheckedTK.svg",
      "../../assets/Images/TK/TilbudTK.png"
    ];
    let preloadedImages = [];
    // forces browser to load images.
    if (IsFKTheme) {
      preloadedImages = imagesFK.map((img) => {
        return (new Image().src = img);
      });

      // todo: only preload if mobile form.
      preloadedImages.concat(
        imagesFKMobile.map((img) => {
          return (new Image().src = img);
        })
      );
    } else {
      preloadedImages = imagesTK.map((img) => {
        return (new Image().src = img);
      });
    }
    window.preloadedImages = preloadedImages;
  }, []);
  return <div />;
}

export default Preloader;
