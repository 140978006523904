import React, { useEffect, useLayoutEffect, useState } from "react";
import { Switch } from "react-router-dom";
import saleActionDispatchers from "../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { history } from "../../App";
import Preloader from "../Preloader/Preloader";
import {
  pages,
  isNextBedriftStep,
  pageStepAnimationDuration
} from "../../Utils/CustomerFormHelper";
import { connect } from "react-redux";
import ErrorPage from "../ErrorPage/ErrorPage";
import FormPageContainer from "./FormPageContainer/FormPageContainer";
import Loader from "./FKLoader/Loader";
import Receipt from "./Receipt/Receipt";
import SecretProductNameAndId from "./SecretProductNameAndId";
import BedriftContactPerson from "./Bedrift/BedriftContactPerson";
import BedriftIdentification from "./Bedrift/BedriftIdentification";
import pageStepActionDispatchers from "../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { StepsSelector } from "../../ReduxModules/Selectors/PageStepSelectors";
import { legalOwnerSelector } from "../../ReduxModules/Selectors/SaleSelectors";
import { getQueryStrings } from "../../Utils/UrlParser";
import productActionDispatchers from "../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import TransitionRoute from "../TransitionRoute/TransitionRoute";
import DeliveryPoints from "./DeliveryPoints/DeliveryPointsV2/DeliveryPoints";
import AddDeliveryPoint from "./DeliveryPoints/DeliveryPointsV2/AddDeliveryPoint";
import BedriftCalendar from "./Bedrift/BedriftCalendar";
import BedriftConfirmOrder from "./Bedrift/BedriftConfirmOrder";
import BedriftMovingDate from "./Bedrift/BedriftMovingDate";
import BedriftInvoiceMenu from "./Bedrift/BedriftInvoiceMenu";

function BedriftCustomerForm({
  location,
  initialized,
  submitSuccessful,
  criticalError,
  onlyLegalOwner,
  completedUser,
  recommendedProducts,
  avtaletype
}) {
  const [prevLocation, setPrevLocation] = useState(null);
  const [next, setNext] = useState(true); // used for tracking next/back animations
  const keyPath = location.pathname;

  useEffect(() => {
    const queryString = getQueryStrings(location.search);
    fetchData(queryString);
    pageStepActionDispatchers.setFocusDelay(1000);
    if (process.env.NODE_ENV !== "development") {
      onbeforeunload = (e) =>
        `Endringene dine vil bli slettet. Er du sikker på at du vil forlate siden?`;
      return () => {
        onbeforeunload = null;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    setNext(isNextBedriftStep(prevLocation?.pathname, keyPath, recommendedProducts));
    setPrevLocation(location);
  }, [location]);

  async function fetchData(queryString) {
    if (!initialized) {
      saleActionDispatchers.parseQueryStringAction(queryString, false, true);
    }
  }

  useEffect(() => {
    for (let r of recommendedProducts) productActionDispatchers.getAddonProductInfo(r.id);
  }, [recommendedProducts]);

  function startUserSteps() {
    if (!completedUser) {
      saleActionDispatchers.updateUser(1, {
        isOwner: onlyLegalOwner,
        avtaletype: parseInt(avtaletype)
      });
      return () => saleActionDispatchers.startUserSteps(1);
    }
    return null;
  }

  if (submitSuccessful && location.pathname !== pages.bedrift + pages.receipt) {
    history.replace(pages.bedrift + pages.receipt);
  }

  return (
    <>
      <Preloader />
      <SecretProductNameAndId />
      {!initialized ? (
        <FormPageContainer hideFooter className={"loaderContainer"}>
          <div className={"loader slide"}>
            <Loader text={"Vent mens vi gjør ting klart for deg..."} />
          </div>
        </FormPageContainer>
      ) : (
        <>
          {criticalError ? (
            <ErrorPage />
          ) : (
            <TransitionGroup className={next ? "formPage-next-" : "formPage-back-"}>
              <CSSTransition
                key={keyPath}
                timeout={pageStepAnimationDuration}
                classNames={"formPage-"}
                unmountOnExit
                mountOnEnter
              >
                <Switch location={location}>
                  <TransitionRoute exact path={pages.bedrift + pages.receipt} component={Receipt} />
                  <TransitionRoute exact path={pages.bedrift} component={BedriftIdentification} />
                  <TransitionRoute
                    exact
                    path={pages.bedrift + pages.contactPerson}
                    component={BedriftContactPerson}
                  />
                  <TransitionRoute
                    exact
                    path={pages.bedrift + pages.deliveryPoints}
                    component={DeliveryPoints}
                  />
                  <TransitionRoute
                    path={pages.bedrift + pages.deliveryPointV2}
                    component={AddDeliveryPoint}
                  />
                  <TransitionRoute
                    path={pages.bedrift + pages.confirmOrder}
                    component={BedriftConfirmOrder}
                  />
                  <TransitionRoute
                    path={pages.bedrift + pages.bedriftMovingDate}
                    component={BedriftMovingDate}
                  />
                  <TransitionRoute
                    path={pages.bedrift + pages.invoice}
                    component={BedriftInvoiceMenu}
                  />
                  <TransitionRoute
                    path={pages.bedrift + pages.bedriftCalendar}
                    component={BedriftCalendar}
                  />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        </>
      )}
    </>
  );
}

export default connect(legalOwnerSelector)(connect(StepsSelector)(BedriftCustomerForm));
