import React, { useEffect } from "react";
import styles from "../Trumf.module.scss";
import { IsFKTheme } from "../../../Utils/Themes";
import TrumfWithBg from "../../../assets/Images/FK/TrumfWithBg.svg";
import FormPageContainer from "../../CustomerForm/FormPageContainer/FormPageContainer";

function AddTrumfProductSuccess({ momentId, mdmId, redirectUri }) {
  useEffect(() => {
    if (redirectUri) {
      redirect();
    }
  }, []);
  async function redirect() {
    setTimeout(
      () => (window.location.href = `${decodeURI(redirectUri)}?kundeId=${momentId}&status=ok`),
      500
    );
  }
  return (
    <FormPageContainer hideFooter={true}>
      <h1>Trumf er nå registrert!</h1>
      {IsFKTheme && (
        <div className={styles.imgContainer}>
          <img src={TrumfWithBg} alt="trumf" />
        </div>
      )}
    </FormPageContainer>
  );
}

export default AddTrumfProductSuccess;
