import React, { useEffect, useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { mainProductCampaignDropdownSelector } from "../ReduxModules/Selectors/ProductSelectors";

function ProductCampaignDropdownSelect({
  onChange,
  product,
  options,
  isLoading,
  error,
  productCategories,
  isMobile
}) {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    if (isMobile) {
      setCampaigns(options);
    } else {
      setCampaigns(
        product?.availableCampaigns?.map((p) => ({ label: p.campaignName, value: p.campaignId }))
      );
    }
  }, [product, options, isMobile]);

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    if (isMobile) {
      onChange(selectedOption?.value.id);
    } else {
      onChange(selectedOption?.value);
    }
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <div style={{ marginTop: "5px", color: "white" }}>{`Velg kampanje til ${product?.name}`}</div>
      <Select
        onChange={handleChange}
        name="mobileCampaign"
        isLoading={isLoading}
        options={campaigns}
        isClearable
        placeholder={"Velg kampanje..."}
      />
      {error && <div>{error}</div>}
    </div>
  );
}

export default connect(mainProductCampaignDropdownSelector)(ProductCampaignDropdownSelect);
