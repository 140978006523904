import React, { useState } from "react";
import styles from "./BirthDate.module.scss";
import SimpleInput from "../../SimpleInput/SimpleInput";
import Laptop from "../../../assets/Images/FK/Laptop.svg";
import { IsFKTheme } from "../../../Utils/Themes";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import { formAndUserSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import MobileMascotSmile from "../../../assets/Images/FK/Mobil/MobileMascotSmile.svg";
import { pages, pageStepAnimationDuration } from "../../../Utils/CustomerFormHelper";
import { useParams } from "react-router-dom";
import { getAgeFromBirthDate, getAgeFromSsn } from "../../../Utils/HelperFunctions";

const birthDateRegex = /^\d\d.\d\d.\d\d\d\d$/g;

function BirthDate({ onConfirm, onBack, form, isMobile, userId, user }) {
  const params = useParams();
  const [validationError, setValidationError] = useState(null);
  const { birthDate } = userId ? user : form;
  const validInput = birthDate && birthDate.match(birthDateRegex); // todo: Maybe useMemo?
  const handleChange = (e) => {
    let value = e.target.value.replaceAll(".", "");
    if (value.length > 2) {
      value = value.substring(0, 2) + "." + value.substring(2, value.length);
    }
    if (value.length > 5) {
      value = value.substring(0, 5) + "." + value.substring(5, value.length);
    }
    // Prevent inputting invalid birthdate, allow removing when longer than limit just in case someone manages to break the limit :P
    if (value.length > 10 && value.length >= birthDate.length) return;

    if (userId) {
      saleActionDispatchers.updateUser(userId, { [e.target.name]: value });
    } else {
      saleActionDispatchers.updateFormAction({ [e.target.name]: value });
    }
  };

  const validateInput = () => {
    if (!validInput) {
      setValidationError("*Vennligst skriv inn en gyldig dato (DD.MM.ÅÅÅÅ).");
    }
  };
  const handleConfirm = () => {
    if (!validInput) {
      setValidationError("*Vennligst skriv inn en gyldig dato (DD.MM.ÅÅÅÅ).");
      return;
    }
    const initialized = form.users && form.users.some((f) => f.completed);
    if (!initialized) {
      const age = user.isOwner ? getAgeFromSsn(form.ssn) : getAgeFromBirthDate(user.birthDate);
      if (user.product.tags.includes("Ung") && age > 30) {
        pageStepActionDispatchers.addStep(
          window.location.pathname,
          pages.mobile + pages.addUser + pages.selectProduct
        );
      } else {
        pageStepActionDispatchers.removeSteps([pages.mobile + pages.addUser + pages.selectProduct]);
      }
    }
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
    }
  };

  if (validationError && validInput) {
    setValidationError(null);
  }

  return (
    <FormPageContainer
      className={`${styles.container} ${IsFKTheme && styles.containerFK}`}
      onBack={onBack}
      currentStep={userId ? pages.mobile + pages.summary : null}
    >
      <header className={`${styles.header} fallIn-`}>
        <h1>
          {userId
            ? `Hva er fødselsdatoen til ${user.firstName} ${user.lastName}`
            : "Hva er din fødselsdato?"}
        </h1>
      </header>
      {IsFKTheme && isMobile ? (
        <div className={"frameImageContainer"}>
          <img
            className={"frameImage formPage-background fade-"}
            src={MobileMascotSmile}
            alt={"mobileMascot"}
          />
        </div>
      ) : (
        <div className={styles.imgContainer}>
          <div className={styles.houseContainer}>
            <img className={styles.laptop} src={Laptop} alt={"laptop"} />
          </div>
        </div>
      )}
      <div className={"slide"}>
        <SimpleInput
          className={styles.field}
          type={"text"}
          name={"birthDate"}
          value={birthDate}
          label={"Fødselsdato"}
          onChange={handleChange}
          placeholder={"DD.MM.YYYY"}
          showWhiteBox
          autofocus
          inputMode={"numeric"}
          focusDelay={pageStepAnimationDuration}
          validationErrorMessage={validationError}
          onBlur={validateInput}
        />
        <button
          className={"btnPrimary " + styles.btn}
          disabled={!birthDate}
          onClick={handleConfirm}
        >
          Bekreft
        </button>
      </div>
    </FormPageContainer>
  );
}

export default connect(formAndUserSelector)(BirthDate);
