import React, { useEffect, useState } from "react";
import styles from "./Name.module.scss";
import SimpleInput from "../../SimpleInput/SimpleInput";
import FkMascot from "../../../assets/Images/FK/FKMascotFramed.svg";
import { IsFKTheme } from "../../../Utils/Themes";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import { formAndUserSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { sanitizeProps } from "../../../Utils/HelperFunctions";
import Loader from "../FKLoader/Loader";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import MobileMascotBlush from "../../../assets/Images/FK/Mobil/MobileMascotBlush.svg";
import { CSSTransition } from "react-transition-group";
import { pages, pageStepAnimationDuration } from "../../../Utils/CustomerFormHelper";
import { useParams } from "react-router-dom";

function Name({ onConfirm, onBack, form, isLoading, isMobile, userId, user }) {

  const [displayFirstName, setDisplayFirstName] = useState("");
  const [displayLastName, setDisplayLastName] = useState("");

  const params = useParams();
  const handleChange = (e) => {
    e.target.name === "firstName" ? setDisplayFirstName(e.target.value) : setDisplayLastName(e.target.value)
  };

  const handleConfirm = () => {
    if (userId) {
      saleActionDispatchers.updateUser(userId, sanitizeProps({ ...user, firstName: displayFirstName, lastName: displayLastName }));
    } else {
      saleActionDispatchers.updateFormAction(sanitizeProps({...form, firstName: displayFirstName, lastName: displayLastName}));
    }

    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
    }
  };

  const { firstName, lastName } = userId ? user : form;
  const validInput = displayFirstName && displayLastName && displayFirstName.trim().length > 0 && displayLastName.trim().length > 0;

  useEffect(() => {
    if (firstName && lastName) {
      setDisplayFirstName(firstName);
      setDisplayLastName(lastName);
    }
  }, [firstName, lastName])

  return (
    <>
      <CSSTransition
        in={!!isLoading}
        timeout={pageStepAnimationDuration}
        classNames={"formPage-"}
        mountOnEnter
        unmountOnExit
      >
        <FormPageContainer hideFooter className={"loaderContainer"}>
          <div className={"loader slide"}>
            <Loader text={"Henter kontaktinfo..."} />
          </div>
        </FormPageContainer>
      </CSSTransition>
      <CSSTransition
        in={!isLoading}
        timeout={pageStepAnimationDuration}
        classNames={"formPage-"}
        mountOnEnter
        unmountOnExit
      >
        <FormPageContainer
          className={`${styles.container} ${IsFKTheme && styles.containerFK}`}
          onBack={onBack}
          currentStep={userId ? pages.mobile + pages.summary : null}
        >
          {isMobile ? (
            <div>
              <h1 className={"fallIn-"}>
                {userId
                  ? "Hvem skal bruke abonnementet?"
                  : "Vi trenger litt mer informasjon om deg"}
              </h1>
              <div className={"frameImageContainer"}>
                <img
                  className={"frameImage formPage-background fade-"}
                  src={MobileMascotBlush}
                  alt={"mobileMascot"}
                />
              </div>
            </div>
          ) : (
            <div>
              <header className={styles.header}>
                <h1 className={"fallIn-"}>Navn</h1>
              </header>
              {IsFKTheme && (
                <div className={"frameImageContainer"}>
                  <img
                    className={"frameImage formPage-background fade-"}
                    src={FkMascot}
                    alt="mascot"
                  />
                </div>
              )}
            </div>
          )}
          <div className={"slide"}>
            <SimpleInput
              className={styles.field}
              type={"text"}
              name={"firstName"}
              value={displayFirstName}
              label={"Fornavn"}
              onChange={handleChange}
              placeholder={userId ? "Fornavn" : "Ditt fornavn"}
              showWhiteBox
              autofocus
              focusDelay={pageStepAnimationDuration}
            />
            <SimpleInput
              className={styles.field}
              type={"text"}
              name={"lastName"}
              value={displayLastName}
              label={"Etternavn"}
              onChange={handleChange}
              placeholder={userId ? "Etternavn" : "Ditt etternavn"}
              showWhiteBox
            />
            <button
              className={"btnPrimary " + styles.btn}
              disabled={!validInput}
              onClick={handleConfirm}
            >
              Bekreft
            </button>
          </div>
        </FormPageContainer>
      </CSSTransition>
    </>
  );
}

export default connect(formAndUserSelector)(Name);
