import * as _ from "lodash";
import { SALE_CONTEXT } from "../Sagas/SaleSaga";
import { parseInt } from "lodash";

export const stateSelector = (state) => {
  return { state };
};

export const formSelector = ({ saleState, deliveryPointState, customerState }) => {
  const { form, isLoading, error, creditData } = saleState;
  const { deliveryPoints } = deliveryPointState;
  const { isActiveCustomer } = customerState;
  return {
    form,
    deliveryPoints,
    isLoading,
    error,
    isCreditInfoBlocked:
      creditData &&
      creditData.response === 2 &&
      creditData.reason === "Personen er sperret for kredittopplysninger.",
    selectedAddonProducts: form.extraProducts,
    isActiveCustomer,
    isMobile: form.saleContext === SALE_CONTEXT.mobile,
    isBedrift: form.saleContext === SALE_CONTEXT.bedrift
  };
};

export const summarySelector = ({ saleState, deliveryPointState, customerState, productState }) => {
  const { form, isSubmitting, submitSuccessful, bonusCampaigns } = saleState;
  const { deliveryPoints } = deliveryPointState;
  const { isActiveCustomer } = customerState;
  const { products } = productState;
  return {
    form: {
      ...form,
      product: productState.products.find((p) => p.id === parseInt(form.avtaletype)),
      users: form.users.map((fm) => {
        return {
          ...fm,
          product: productState.products.find((p) => p.id === parseInt(fm.avtaletype)),
          extraProducts: !_.isEmpty(fm.extraProducts)
            ? fm.extraProducts.map((ep) => {
                return {
                  ...ep,
                  product: productState.products.find((p) => p.id === parseInt(ep.id))
                };
              })
            : []
        };
      })
    },
    deliveryPoints: deliveryPoints.filter((dp) => dp.selected),
    isLoading: isSubmitting,
    selectedAddonProducts: form.extraProducts,
    submitSuccessful,
    bonusCampaigns,
    isActiveCustomer,
    isMobile: form.saleContext === SALE_CONTEXT.mobile,
    isBedrift: form.saleContext === SALE_CONTEXT.bedrift,
    products
  };
};

export const bedriftSummarySelector = ({ saleState, deliveryPointState, invoiceState }) => {
  const { form, isSubmitting, submitSuccessful } = saleState;
  const { deliveryPoints } = deliveryPointState;
  const { selectedInvoiceOption } = invoiceState;
  return { form, deliveryPoints, isloading: isSubmitting, submitSuccessful, selectedInvoiceOption };
};

export const receiptSelector = ({ saleState }) => {
  return {
    form: saleState.form,
    isLoading: saleState.isSubmitting,
    submitSuccessful: saleState.submitSuccessful,
    error: saleState.error
  };
};
export const identificationSelector = ({ saleState, bankIdState }) => {
  const name = saleState.form.producthubInfo?.name || "";
  const id = saleState.form.producthubInfo?.id || "";
  const { bankIdInfo } = bankIdState;
  const bankIdName =
    bankIdState.bankIdInfo.firstName && `${bankIdInfo.firstName} ${bankIdInfo.lastName}`;
  return { name, id, bankIdName, saleContext: saleState.form.saleContext };
};
export const productSelector = ({ saleState }) => {
  const name = saleState.form.producthubInfo?.name || "";
  const id = saleState.form.producthubInfo?.id || "";
  return { name, id };
};

export const customerFormSelector = ({ saleState }) => {
  return {
    initialized: saleState.initialized,
    submitSuccessful: saleState.submitSuccessful,
    criticalError: saleState.criticalError,
    recommendedProducts: saleState.form.recommendedProducts
  };
};

export const trumfSelector = ({ saleState }) => {
  return { trumfId: saleState.form.trumfId };
};

export const deliveryPointsSelector = ({ saleState, deliveryPointState, customerState }) => {
  const { ssn, address, zipCode, city, saleContext } = saleState.form;
  const { isLoadingExistingCustomerData } = customerState;
  const priceAreaTags =
    saleState.form.producthubInfo && saleState.form.producthubInfo.priceAreaTags;
  const { initialized, initializing, deliveryPoints } = deliveryPointState;
  const isBedrift = saleContext === SALE_CONTEXT.bedrift;
  return {
    initialized,
    initializing: initializing || isLoadingExistingCustomerData,
    deliveryPoints,
    ssn,
    address,
    zipCode,
    city,
    isLoading: saleState.isLoading,
    priceAreaTags,
    isBedrift
  };
};

export const deliveryPointSelector = ({ saleState, deliveryPointState }) => {
  const { isLoading, elhubData, currentId, newDeliveryPoint, deliveryPoints } = deliveryPointState;
  const priceAreaTags =
    saleState.form.producthubInfo && saleState.form.producthubInfo.priceAreaTags;
  const deliveryPoint = _.isNumber(currentId)
    ? deliveryPoints.find((dp) => dp.id === currentId)
    : newDeliveryPoint;
  return {
    isLoading,
    elhubData,
    deliveryPoint,
    ssn: saleState.form.ssn,
    deliveryPoints,
    priceAreaTags
  };
};

export const deliveryPointV2Selector = (
  { saleState, deliveryPointState, pageStepState },
  { deliveryPointId }
) => {
  const { isLoading, elhubData, deliveryPoints } = deliveryPointState;
  const priceAreaTags =
    saleState.form.producthubInfo && saleState.form.producthubInfo.priceAreaTags;
  const deliveryPoint =
    !_.isEmpty(deliveryPoints) && deliveryPoints.find((dp) => dp.id === parseInt(deliveryPointId));
  return {
    isLoading,
    elhubData,
    deliveryPoint,
    ssn: saleState.form.ssn,
    deliveryPoints,
    priceAreaTags,
    deliveryPointSteps: pageStepState.deliveryPointSteps,
    isBedrift: saleState.form.saleContext === SALE_CONTEXT.bedrift
  };
};

export const isBedriftSelector = ({ saleState }) => {
  return { isBedrift: saleState.form.saleContext === SALE_CONTEXT.bedrift };
};

export const productInfoSelector = ({ saleState }) => {
  const { productInfo, form, isLoadingProductInfo, productInfoError } = saleState;
  const isBedrift = form.saleContext === SALE_CONTEXT.bedrift;
  return {
    loading: isLoadingProductInfo,
    productName: form.producthubInfo?.name ?? "",
    productId: form.producthubInfo?.id ?? "",
    productInfo,
    error: productInfoError,
    isBedrift
  };
};

export const bankIdSelector = ({ bankIdState, saleState }) => {
  const { isLoading, isBankIdComplete, error, bankIdInfo } = bankIdState;
  return {
    isLoading,
    isBankIdComplete,
    error,
    phone: bankIdInfo.phone,
    ssn: bankIdInfo.ssn,
    saleContext: saleState.form.saleContext
  };
};

export const userSelector = ({ saleState, pageStepState, productState }, { userId }) => {
  const { form } = saleState;
  const user = form.users.find((f) => f.id === parseInt(userId));
  return {
    user: user && { ...user, product: productState.products.find((p) => p.id === user.avtaletype) },
    lastUserStep: pageStepState.userSteps[pageStepState.userSteps.length - 1],
    firstUserStep: pageStepState.userSteps[0]
  };
};

export const formAndUserSelector = (state, props) => {
  return {
    ...userSelector(state, props),
    ...formSelector(state)
  };
};

export const powerSmartStepSelector = (state) => {
  return {
    ...formSelector(state)
  };
};

export const summaryAndUserAndFormSelector = (state, props) => {
  return { ...userSelector(state, props), ...summarySelector(state), ...formSelector(state) };
};

export const legalOwnerSelector = ({ saleState, pageStepState }) => {
  const { form } = saleState;
  return {
    criticalError: saleState.criticalError,
    avtaletype: form.avtaletype,
    onlyLegalOwner: form.onlyLegalOwner,
    completedUser: form.users && form.users.some((f) => f.completed),
    recommendedProducts: form.recommendedProducts,
    initialized: saleState.initialized,
    submitSuccessful: saleState.submitSuccessful
  };
};
