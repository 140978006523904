import React from "react";
import Icon from "../Icon/Icon";
import { IsFKTheme } from "../../Utils/Themes";
import styles from "./CheckBoxV2.module.scss";

function CheckBoxV2({ checked, children, className, onClick }) {
  return (
    <div className={`${IsFKTheme && styles.containerFK} ${styles.container}`}>
      <button
        className={`whiteBox ${styles.button} ${className} ${checked && styles.containerChecked}`}
        onClick={onClick}
      >
        {children}
        <Icon icon={checked ? "checkBoxChecked" : "checkBox"} className={styles.icon} />
      </button>
    </div>
  );
}

export default CheckBoxV2;
