import React from "react";
import loadingIcon from "../../assets/Icons/LoadingIcon.svg";
import styles from "./LoadingSpinnerTK.module.scss";

function LoadingSpinnerTK({ text }) {
  return (
    <div className={styles.loadingIcon}>
      <img className={styles.loadingElement} src={loadingIcon} alt="tklogo" />
      <h2>{text}</h2>
    </div>
  );
}

export default LoadingSpinnerTK;
