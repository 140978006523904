import React, { useRef, useEffect, useState } from "react";
import styles from "./SimpleInput.module.scss";
import SimpleInputPostCode from "./SimpleInputPostCode";
import Icon from "../Icon/Icon";
import {IsFKTheme, IsGETheme} from "../../Utils/Themes";
import { useLocation } from "react-router-dom";

// note: SimpleInput is not actually a simple component. But is to make styling, restrictions and validation simple by picking a type when you need a input field.
function SimpleInput({
  name,
  label,
  value,
  type,
  onChange,
  placeholder = "",
  showErase,
  className,
  disabled,
  city,
  autoComplete,
  showWhiteBox,
  autofocus,
  focusDelay,
  validationErrorMessage,
  onBlur,
  inputMode
}) {
  const inputRef = useRef();
  const [focusTimer, setFocusTimer] = useState(null);
  const [initLocation, setInitLocation] = useState(null);
  const location = useLocation();
  useEffect(() => {
    if (autofocus) {
      focusDelay ? focusInputWithDelay() : focusInput();
    }
    setInitLocation(location);
    return () => {
      if (focusTimer) {
        clearTimeout(focusTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autofocus) {
      focusDelay ? focusInputWithDelay() : focusInput();
    }
  }, [autofocus]);

  const getInput = () => {
    switch (type) {
      case "postcode":
        return (
          <SimpleInputPostCode
            name={name}
            value={value || ""}
            type={type}
            onChange={handleChange}
            placeholder={placeholder}
            disabled={disabled}
            city={city}
            autoComplete={autoComplete}
            inputRef={inputRef}
            onBlur={onBlur}
          />
        );
      default:
        return (
          <input
            ref={inputRef}
            className={styles.inputValue}
            name={name}
            value={value || ""}
            type={getType()}
            onChange={handleChange}
            inputMode={inputMode}
            placeholder={placeholder}
            disabled={disabled}
            autoComplete={autoComplete}
            onBlur={onBlur}
          />
        );
    }
  };
  const focusInput = () => {
    inputRef.current.focus();
  };

  const focusInputWithDelay = () => {
    setFocusTimer(setTimeout(() => inputRef.current && inputRef.current.focus(), focusDelay));
  };

  const getType = () => {
    switch (type) {
      case "phone":
      case "ssn":
      case "meterNumber":
      case "personNumber":
      case "tobbId":
      case "postcode":
        return "tel";
      case "name":
        return "text";
      default:
        return type;
    }
  };

  const handleChange = (e) => {
    let inputValue = e.target.value;
    if (type === "ssn") {
      inputValue = removeWhitespace(inputValue);
      if (!inputValue.match("^[0-9]{0,11}$")) return;
    } else if (type === "name") {
      if (!inputValue.match("^[A-Za-z\u00C0-\u1FFF\u2800-\uFFFD\\s-]*$")) return;
    } else if (type === "phone") {
      inputValue = removeWhitespace(inputValue);
      if (inputValue.substring(0, 3) === "+47") {
        inputValue = inputValue.substring(3, inputValue.length);
      }
      if (!inputValue.match("^[0-9]{0,8}$")) return;
    } else if (type === "meterNumber") {
      inputValue = removeWhitespace(inputValue);
      if (!inputValue.match("^[0-9]{0,18}$")) return;
    } else if (type === "tobbId") {
      if (!inputValue.match("^[0-9]{0,9}$")) return;
    } else if (type === "personNumber") {
      inputValue = removeWhitespace(inputValue);
      if (!inputValue.match("^[0-9]{0,5}$")) return;
    }
    e.target.value = inputValue; // todo: Figure out wtf this does
    name ? onChange(e) : onChange(inputValue);
  };

  const removeWhitespace = (string) => {
    if (string.length === 0) return string;
    return string.split(" ").join("");
  };
  return (
    <div
      className={`${className} ${type === "time" && styles.textCenter} ${showWhiteBox && "whiteBox"} ${validationErrorMessage && styles.validationBox}`}
      onClick={focusInput}
      disabled={disabled}
    >
      <div className={styles.inputName}>{label}</div>
      <div className={styles.inputValueBox}>
        {getInput()}
        {showErase && !disabled && (
          <div onClick={() => handleChange({ target: { name, value: "" } })}>
            <Icon className={styles.eraseBtn} icon={IsGETheme ? "geClear" : IsFKTheme ? "clear" : "tkClear"} />
          </div>
        )}
        {validationErrorMessage && (
          <Icon className={styles.validationWarningIcon} icon={"WarningCircle"} />
        )}
      </div>
      {validationErrorMessage && (
        <div className={styles.validationWarningText}>{validationErrorMessage}</div>
      )}
    </div>
  );
}

export default SimpleInput;
