import styles from "./TooOldDialog.module.scss";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";

function TooOldDialog({ open, handleClose }) {
  return (
    <Dialog open={open} PaperProps={{ className: styles.dialogBox }} onClose={handleClose}>
      <DialogTitle>Kan ikke velge produkt</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Brukeren må være under 30 år for å velge dette produktet
        </DialogContentText>
      </DialogContent>
      <div className={styles.dialogButtons}>
        <button className={`btnSecondary ${styles.button}`} onClick={handleClose}>
          Ok
        </button>
      </div>
    </Dialog>
  );
}

export default TooOldDialog;
