import React from "react";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import WhiteButtonList from "../../ButtonList/WhiteButtonList";
import { IsFKTheme } from "../../../Utils/Themes";
import MobileMascotBlush from "../../../assets/Images/FK/Mobil/MobileMascotBlush.svg";
import { connect } from "react-redux";
import { formSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { findAddtionalMobilePages, pages, userInfoSteps } from "../../../Utils/CustomerFormHelper";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { useParams } from "react-router-dom";

function ChooseMobileSubscriptionReceiver({ onConfirm, onBack, userId, form, user }) {
  // todo: Fix this component to work as add familymember
  const params = useParams();
  function selectOwner(isOwner) {
    if (userId) {
      saleActionDispatchers.updateUser(userId, { isOwner });
      if (isOwner) {
        saleActionDispatchers.updateUser(userId, {
          firstName: null,
          lastName: null,
          birthDate: null,
          email: null
        });
      }
      pageStepActionDispatchers.setMobileUserSteps(userId);
    } else {
      const mobileLegalOwnerSteps = [
        pages.mobile + pages.mobileNumber,
        pages.mobile + pages.customerEmail
      ];
      const mobileSelfSubscriptionSteps = [pages.mobile + pages.customerEmail];
      pageStepActionDispatchers.removeSteps(
        mobileLegalOwnerSteps.concat(mobileSelfSubscriptionSteps)
      );
      saleActionDispatchers.updateFormAction({ onlyLegalOwner: isOwner });
      if (isOwner) {
        pageStepActionDispatchers.addSteps(window.location.pathname, mobileSelfSubscriptionSteps);
      } else {
        pageStepActionDispatchers.addSteps(window.location.pathname, mobileLegalOwnerSteps);
      }
    }
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
    }
  }

  return (
    <FormPageContainer onBack={onBack} currentStep={userId ? pages.mobile + pages.summary : null}>
      <h1 className={"fallIn-"}>Er det du som skal bruke abonnementet?</h1>
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img
            className={"frameImage formPage-background fade-"}
            src={MobileMascotBlush}
            alt={"mobileMascot"}
          />
        </div>
      )}
      <div className={"slide"}>
        <WhiteButtonList
          buttons={[
            {
              content: <div className={"title"}>Ja, det er det</div>,
              onClick: () => selectOwner(true)
            },
            {
              content: <div className={"title"}>Nei, legg til en annen bruker</div>,
              onClick: () => selectOwner(false)
            }
          ]}
        />
      </div>
    </FormPageContainer>
  );
}

export default connect(formSelector)(ChooseMobileSubscriptionReceiver);
