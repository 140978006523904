import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { summarySelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import styles from "./Summary.module.scss";
import { IsFKTheme } from "../../../Utils/Themes";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import MobileMascotStarEyes from "../../../assets/Images/FK/Mobil/MobileMascotStarEyes.svg";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import * as _ from "lodash";
import Icon from "../../Icon/Icon";
import { Tooltip } from "@mui/material";
import { pages } from "../../../Utils/CustomerFormHelper";
import ConfirmModal from "../ConfirmModal/ConfirmModal";

function MobileSummary({ form, onBack, onConfirm, setShowProductInfoModal }) {
  const [openDialog, setOpenDialog] = useState(false);
  useEffect(() => {
    if (!_.isEmpty(form.users)) {
      if (form.users.some((f) => f.completed)) {
        pageStepActionDispatchers.removeSteps([pages.mobile + pages.mobileSubscriptionReceiver]);
      }
      for (let f of form.users) {
        if (!f.completed) {
          saleActionDispatchers.removeUser(f.id);
        }
      }
    }
  }, []);
  const handleChangeUser = (userId) => {
    saleActionDispatchers.startUserSteps(userId);
  };

  const handleRemoveUser = (userId) => {
    saleActionDispatchers.removeUser(userId);
    setOpenDialog(false);
  };

  const handleAddNewUser = () => {
    saleActionDispatchers.startUserSteps();
  };

  function handleConfirm() {
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname);
    }
  }

  return (
    <div>
      {openDialog && (
        <ConfirmModal
          open={openDialog}
          handleConfirm={() => handleRemoveUser(openDialog)}
          handleClose={() => setOpenDialog(false)}
          header={`Fjerne abonnement?`}
          content={`Er du sikker på at du vil fjerne abonnementet fra bestillingen?`}
          confirmText={"Fjern"}
        />
      )}
      <h1 className={"fallIn-"}>{"Her er bestillingen din så langt"}</h1>
      <div className={"frameImageContainer"}>
        <img
          className={"frameImage formPage-background fade-"}
          src={MobileMascotStarEyes}
          alt={"mobileMascot"}
        />
      </div>
      <div className={"slide"}>
        <div className={`whiteBox ${styles.summary} ${IsFKTheme && styles.containerFK}`}>
          <div className={styles.grayText}>{"Eier"}</div>
          <div>
            <div className={styles.blackText}>
              {form.firstName} {form.lastName}
            </div>
            <div className={styles.grayText}>{form.phone}</div>
            <div className={styles.grayText}>{form.email}</div>
          </div>
        </div>
        {!_.isEmpty(form.users) &&
          form.users
            .filter((f) => f.completed)
            .map((f, i) => {
              return (
                <div
                  key={i}
                  className={`whiteBox ${styles.summary} ${styles.subSummary} ${IsFKTheme && styles.containerFK}`}
                >
                  {form.users.filter((f) => f.completed).length > 1 && (
                    <Tooltip title={"Fjern"} arrow>
                      <div className={styles.smallCloseButton}>
                        <Icon
                          icon={IsFKTheme ? "clear" : "tkClear"}
                          onClick={() => setOpenDialog(f.id)}
                          type="button"
                        />
                      </div>
                    </Tooltip>
                  )}
                  <div className={styles.grayText}>{f.isOwner ? "Du har valgt" : "Ny bruker"}</div>
                  <button
                    className={styles.productName}
                    onClick={() => setShowProductInfoModal(f.product.id)}
                  >
                    {f.product.name}
                  </button>
                  <div>
                    <div className={styles.blackText}>
                      {f.isOwner ? form.firstName : f.firstName}{" "}
                      {f.isOwner ? form.lastName : f.lastName}
                    </div>
                    <div className={styles.grayText}>
                      {f.createNewNumber ? "Nytt nummer" : `Eksisterende nummer`}
                    </div>
                    <div className={styles.grayText}>{f.phone}</div>
                  </div>
                  <button
                    className={`${styles.changeButton}`}
                    onClick={() => handleChangeUser(f.id)}
                  >
                    Endre
                  </button>
                </div>
              );
            })}
        <button className={`${styles.addButton} btnBorder`} onClick={handleAddNewUser}>
          + Bestill abonnement til flere
        </button>
        <button className={`btnPrimary ${styles.mobileButton}`} onClick={() => handleConfirm()}>
          Fortsett bestilling
        </button>
      </div>
    </div>
  );
}

export default connect(summarySelector)(MobileSummary);
