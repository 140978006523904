import styles from "./ConfirmModal.module.scss";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";

function ConfirmModal({ open, handleClose, handleConfirm, confirmText, content, header }) {
  return (
    <Dialog open={open} PaperProps={{ className: styles.dialogBox }} onClose={handleClose}>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <div className={styles.dialogButtons}>
        <button className={`btnUnderline ${styles.dialogButtonNo}`} onClick={handleClose} autoFocus>
          Nei
        </button>
        <button className={`btnSecondary ${styles.dialogButtonYes}`} onClick={handleConfirm}>
          {confirmText || "Ja"}
        </button>
      </div>
    </Dialog>
  );
}

export default ConfirmModal;
