import { Dialog, DialogContent } from "@mui/material";
import styles from "./BedriftProductInfoDialog.module.scss";
import React, { useEffect } from "react";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import { bedriftProductInfoSelector } from "../../../ReduxModules/Selectors/ProductSelectors";
import store from "../../../ReduxModules/Store/Store.js";
import { connect } from "react-redux";

const BedriftProductInfoDialog = ({ open, handleClose, form }) => {
  const state = store.getState();
  let ingress = state.productState.products.find(
    (product) => product.id === form.avtaletype
  )?.ingress;

  useEffect(() => {
    form && productActionDispatchers.getProductInfoBedrift(form.avtaletype, null);
  }, []);

  return (
    <Dialog open={open} PaperProps={{ className: styles.dialogBox }} onClose={handleClose}>
      <DialogContent className={styles.mainContent}>
        <h4>{form.avtaleName}</h4>

        <div className={styles.item} dangerouslySetInnerHTML={{ __html: ingress }} />

        <div className={styles.item}>
          <div className={styles.grayText}>
            Påslaget inkluderer lovpålagte kostnader for elsertifikat. Det innebærer at du som
            forburker er med på å finansiere kraft produsert fra fornybare energikilder
          </div>
        </div>
      </DialogContent>
      <div className={styles.dialogButtons}>
        <button className={`btnUnderline ${styles.dialogButtonNo}`} onClick={handleClose} autoFocus>
          Lukk
        </button>
      </div>
    </Dialog>
  );
};

export default connect(bedriftProductInfoSelector)(BedriftProductInfoDialog);
