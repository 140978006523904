import React, { useEffect } from "react";
import { connect } from "react-redux";
import { stateSelector } from "../../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../../FormPageContainer/FormPageContainer";
import Loader from "../../FKLoader/Loader";
import config from "../../../../config";
import { IsFKTheme } from "../../../../Utils/Themes";
import { uuidv4 } from "../../../../Utils/HelperFunctions";

function BankIdLogin({ state }) {
  useEffect(() => {
    const nonce = uuidv4();
    const stateKey = uuidv4();
    const url = encodeURI(window.location.origin + "/bankid");
    window.sessionStorage.setItem("nonce", encodeURI(nonce));
    window.sessionStorage.setItem("stateKey", encodeURI(stateKey));
    window.sessionStorage.setItem("state", JSON.stringify(state));
    onbeforeunload = null;
    window.location.href = `${config.bankIdUrl}?client_id=${IsFKTheme ? "fjordkraft" : "trondelagkraft"}&response_type=code&scope=openid&prompt=login&redirect_uri=${url}&state=${stateKey}&nonce=${nonce}`;
  }, []);
  return (
    <FormPageContainer hideFooter>
      <Loader text={"Sender deg til bankid.."} />
    </FormPageContainer>
  );
}

export default connect(stateSelector)(BankIdLogin);
