import React from "react";
import { Alert, AlertTitle } from "@mui/material";

const validateFirstPage = (form, isMobile, isBedrift) => {
  if (form.ssn.length !== 11 && !isBedrift) {
    return (
      <Alert severity="error">
        <AlertTitle>Feil i Personnummer</AlertTitle>Personnummer må være 11 karakterer
      </Alert>
    );
  } else if (!isMobile && form.phone.length !== 8) {
    return (
      <Alert severity="error">
        <AlertTitle>Feil i telefonnummer</AlertTitle>Telefonnummer må være 8 karakterer
      </Alert>
    );
  } else {
    return null;
  }
};

export default validateFirstPage;
