import {
  SET_INVOICE_SELECTED_INDEX,
  SET_REFERENCE,
  SET_IS_LOADING_REFERENCE,
  SET_REFERENCE_FOUND
} from "../Actions/InvoiceActions/InvoiceActionTypes";

export const INVOICE_SELECTION_OPTIONS = {
  unSelected: -1,
  eInvoice: 0,
  email: 1,
  emailAndSms: 2,
  paper: 3,
  EHF: 4,
  electronic: 5
};

const initialState = {
  selectedInvoiceOption: INVOICE_SELECTION_OPTIONS.unSelected,
  isLoadingEInvoiceReference: false,
  eInvoiceReference: null,
  eInvoiceReferenceFound: false
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE_SELECTED_INDEX:
      return {
        ...state,
        selectedInvoiceOption: action.payload
      };
    case SET_IS_LOADING_REFERENCE:
      return {
        ...state,
        isLoadingEInvoiceReference: action.payload
      };
    case SET_REFERENCE:
      return {
        ...state,
        eInvoiceReference: action.payload
      };
    case SET_REFERENCE_FOUND:
      return {
        ...state,
        eInvoiceReferenceFound: action.payload
      };
    default:
      return state;
  }
};

export default invoiceReducer;
