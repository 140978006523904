import { formAndUserSelector } from "../../ReduxModules/Selectors/SaleSelectors";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import pageStepActionDispatchers from "../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import FormPageContainer from "../CustomerForm/FormPageContainer/FormPageContainer";
import { Box } from "@mui/material";
import styles from "./TobbStyle.scss";
import saleActionDispatchers from "../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { sanitizeProps } from "../../Utils/HelperFunctions";
import SimpleInput from "../SimpleInput/SimpleInput";
import { pageStepAnimationDuration } from "../../Utils/CustomerFormHelper";
import { IsFKTheme } from "../../Utils/Themes";
import WhiteButtonList from "../ButtonList/WhiteButtonList";
import * as AxiosFunction from "../../Utils/AxiosFunctions";
import tobbActionDispatchers from "../../ReduxModules/Actions/TobbActions/TobbActionDispatcher";
import CircularProgress from "@mui/material/CircularProgress";

function Tobb({ onNext, onBack, userId, onConfirm, form, params, user, onSkip }) {
  let tobbMedlemsnummer = userId ? user.tobbId : form.tobbId;
  const [buttonPressed, setButtonPressed] = useState(false);
  const [isValidNumber, setIsValidNumber] = useState(true);
  const [isCheckingNumber, setIsCheckingNumber] = useState(false);
  const [showNextPageButton, setShowNextPageButton] = useState(false);

  useEffect(() => {
    if (isValidNumber && buttonPressed) {
      setShowNextPageButton(true);
    }
  }, [isValidNumber, buttonPressed]);

  async function verifyTobbId(tobbId) {
    setIsCheckingNumber(true);
    let result = await AxiosFunction.verifyMembershipNumber(tobbId).then((response) => {
      setIsCheckingNumber(false);
      return response;
    });
    return result;
  }
  const handleConfirm = async () => {
    let verificationResult = await verifyTobbId(tobbMedlemsnummer);
    if (verificationResult) {
      setIsValidNumber(true);
      setButtonPressed(true);
      tobbActionDispatchers.setTobbId(tobbMedlemsnummer);
      saleActionDispatchers.updateFormAction(sanitizeProps(form));
    } else {
      setIsValidNumber(false);
    }
  };

  const handleSkip = () => {
    if (!userId) {
      saleActionDispatchers.updateFormAction({ ...form, tobbId: null });
    }
    pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
  };

  const handleChange = (e) => {
    if (userId) {
      saleActionDispatchers.updateUser(userId, { [e.target.name]: e.target.value });
    } else {
      saleActionDispatchers.updateFormAction({ [e.target.name]: e.target.value });
    }
  };
  const validInput = tobbMedlemsnummer && tobbMedlemsnummer.replace(/\s+/g, "").length === 9;

  return (
    <FormPageContainer onBack={onBack} currentStep={null}>
      <div className={`${IsFKTheme && styles.containerFK}`}>
        <header className={`fallIn- ${styles.header}`}>
          <h1>Hva er ditt TOBB kortnummer? </h1>
          <h6>Du vil få månedlig TOBB-bonus så lenge du er kunde. </h6>
        </header>
      </div>
      <div className={"slide"}>
        <SimpleInput
          className={styles.powerMeterInput}
          label={"Kortnummer"}
          value={tobbMedlemsnummer}
          type={"tobbId"}
          name={"tobbId"}
          onChange={handleChange}
          placeholder={"9 siffer"}
          showWhiteBox
          autofocus
          showErase={tobbMedlemsnummer !== ""}
          focusDelay={pageStepAnimationDuration}
          disabled={isCheckingNumber || (buttonPressed && isValidNumber)}
        />
        {buttonPressed
          ? isValidNumber !== true
          : !buttonPressed && (
              <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                <div className={styles.centeredButtonContainer}>
                  <WhiteButtonList
                    buttons={[
                      {
                        content: <div className={"title"}>Registrer kortnummer senere</div>,
                        onClick: handleSkip
                      }
                    ]}
                  />
                </div>
              </Box>
            )}
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <div className={styles.centeredButtonContainer}>
            {buttonPressed
              ? isValidNumber !== true
              : !buttonPressed && (
                  <button
                    className={`btnPrimary ${styles.submitButton}`}
                    disabled={!validInput || isCheckingNumber || (buttonPressed && isValidNumber)}
                    onClick={handleConfirm}
                  >
                    {isCheckingNumber ? (
                      <div className="d-flex align-items-center flex-column">
                        Søker
                        <CircularProgress
                          sx={{ color: "#FF5500FF" }}
                          thickness={4}
                          disableShrink
                          variant="indeterminate"
                          color="inherit"
                          size={20}
                        />
                      </div>
                    ) : (
                      "Bekreft"
                    )}
                  </button>
                )}
          </div>
        </Box>
        {isValidNumber === true && buttonPressed && (
          <>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Typography
                className={`fallIn- ${styles.header}`}
                style={{ textAlign: "center", fontSize: 25 }}
              >
                Ditt kortnummer er registrert.
              </Typography>
            </Box>
            {showNextPageButton && (
              <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                <div className={styles.centeredButtonContainer}>
                  <button
                    className={`btnPrimary ${styles.nextPageButton}`}
                    onClick={() =>
                      pageStepActionDispatchers.goToNextStep(window.location.pathname, params)
                    }
                  >
                    Gå til neste side
                  </button>
                </div>
              </Box>
            )}
          </>
        )}
        {!isValidNumber && (
          <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
            <Typography
              className={`fallIn- ${styles.header}`}
              style={{ textAlign: "center", fontSize: 20 }}
            >
              Kortnummeret er ikke gyldig. Prøv igjen.
            </Typography>
          </Box>
        )}
      </div>
    </FormPageContainer>
  );
}

export default connect(formAndUserSelector)(Tobb);
