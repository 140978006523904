import { connect, useDispatch, useSelector } from "react-redux";
import { formAndUserSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import styles from "./PriceSummaryCSS.module.scss";
import { IsFKTheme } from "../../../Utils/Themes";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import React, { useCallback, useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { sanitizeProps } from "../../../Utils/HelperFunctions";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { useParams } from "react-router-dom";
import store from "../../../ReduxModules/Store/Store";
import { Box, Grid, Paper, Popover } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import moment from "moment";
import termLinkStyles from "../Terms/TermLinks.module.scss";
import termStyles from "../Terms/Terms.module.scss";
import config from "../../../config";
import { makeStyles } from "@mui/styles";
import { powerRightOfWithdrawalLink } from "../../../Utils/ExternalLinks";

import { summaryAndUserAndFormSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import {
  getProductHubProductText,
  GetProductHubProductById,
  GetTermsAndConditionsGuidById
} from "../../../Utils/AxiosFunctions";
import { InvoicePrices } from "../../../Utils/Prices";
import { getSmartPowerProductId, getSmartPowerProductList } from "../../../Utils/PowerSmartUtils";
import { PortableAgreementText } from "../Agreement/PortableAgreementText";
import { getAllConsentForms } from "../../../Utils/sanityClient";

function PriceSummary({
  onConfirm,
  onBack,
  userId,
  user,
  form,
  deliveryPoints,
  bonusCampaigns,
  isActiveCustomer,
  isMobile
}) {
  const classes = useStyles();
  const [callToAction, setCallToAction] = useState("");
  const params = useParams();
  const [extraProductPriceInfoList, setExtraProductPriceInfoList] = useState([]);
  const [totalMonthlySum, setTotalMonthlySum] = useState(0);
  const [totalPaslag, setTotalPaslag] = useState(0);
  const [list, setList] = useState([]);
  const [acceptedPowerTerms, setAcceptedPowerTerms] = useState(false);
  const [acceptedStromSmartTerms, setAcceptedStromSmartTerms] = useState(false);
  const [requirePowerSmartTerms, setRequirePowerSmartTerms] = useState(false);
  const [stromSmartTermsGuid, setStromSmartTermsGuid] = useState(null);
  const SMART_POWER_PRODUCTS = getSmartPowerProductList("string");
  const SMART_POWER_PRODUCTS_IDS = getSmartPowerProductList("number");
  const [openStromSmartTermsMessageDialog, setOpenStromSmartTermsMessageDialog] = useState(false);
  const [openPulsTermsMessageDialog, setOpenPulsTermsMessageDialog] = useState(false);
  const [fetchedConsentForms, setFetchedConsentForms] = useState([]);
  const [pulsCancellationForm, setPulsCancellationForm] = useState(null);
  const [stromSmartCancellationForm, setStromSmartCancellationForm] = useState(null);

  const { SMART_PRODUCT_POWER_ID, SMART_PRODUCT_POWER_PLUS_ID, PULSE_METER_ID } =
    getSmartPowerProductId();
  const stromSmartTarget = useRef(null);
  const pulsTarget = useRef(null);
  const pulsIds = [PULSE_METER_ID];

  const baseUrl =
    config.env === "prod"
      ? "https://avtale.fjordkraft.no/api/term/"
      : "https://test-avtale.fjordkraft.no/api/term/";
  const link = baseUrl + form.producthubInfo.guid;
  const powerTarget = useRef(null);
  const [openPowerTermsMessageDialog, setOpenPowerTermsMessageDialog] = useState(false);

  const extraProductPriceInfos = useSelector((state) => state.productState.extraProductPriceInfo);
  const productPrices = useSelector((state) => state.productState.priceInfo);
  const selectedDeliveryPoints = useSelector((state) =>
    state.deliveryPointState.deliveryPoints.filter((deliveryPoint) => deliveryPoint.selected)
  );
  const forms = useSelector((state) => state.saleState.form);
  const eP = useSelector((state) => state.productState.products);
  const productName = forms.avtaleName ?? forms.producthubInfo?.name;
  const productId = forms.avtaletype ?? forms.producthubInfo.id;
  const campaignText = useSelector((state) => state.productState.campaignText);
  const productHubInfo = forms.producthubInfo;
  let no4 =
    productHubInfo.tags !== null &&
    productHubInfo.tags !== undefined &&
    productHubInfo.tags.some((item) => item.includes("NO4"));
  let tags = no4 ? true : false;

  const addresses = selectedDeliveryPoints.map((deliveryPoint) => {
    if (deliveryPoint?.moveInDate === null || deliveryPoint?.moveInDate === undefined) {
      const today = new Date();
      const futureDate = new Date();
      futureDate.setDate(today.getDate() + 14);
      return (deliveryPoint.moveInDate = futureDate);
    }
    if (deliveryPoint.elhubData !== null && deliveryPoint.elhubData !== undefined) {
      var streetCode = deliveryPoint.elhubData.addressV1?.streetCode;
      var buildingNumber = deliveryPoint.elhubData.addressV1?.buildingNumber;
      var buildingLetter = deliveryPoint.elhubData.addressV1?.buildingLetter;
      var elhubAdresse =
        deliveryPoint.elhubData.addressV1?.streetName +
        (buildingNumber !== null ? buildingNumber : "") +
        (buildingLetter !== null ? buildingLetter : "") +
        " " +
        (streetCode !== null ? streetCode : "");
      return {
        address: elhubAdresse,
        city: deliveryPoint?.elhubData?.addressV1?.city,
        zipCode: deliveryPoint?.zipCode,
        meteringPointId:
          deliveryPoint.elhubData !== null && deliveryPoint.elhubData !== undefined
            ? deliveryPoint.elhubData.meteringPointIdentification
            : null,
        startUp: moment(deliveryPoint.moveInDate).format("DD.MM.YYYY")
      };
    }
    return {
      address: deliveryPoint.address,
      city: deliveryPoint.city,
      zipCode: deliveryPoint.zipCode,
      meteringPointId:
        deliveryPoint.elhubData !== null && deliveryPoint.elhubData !== undefined
          ? deliveryPoint.elhubData.meteringPointIdentification
          : null,
      startUp: moment(deliveryPoint.moveInDate).format("DD.MM.YYYY")
    };
  });

  const extraProductIds =
    forms.extraProducts !== null && forms.extraProducts !== undefined
      ? forms.extraProducts
          .filter((extraProduct) =>
            eP.some((product) => parseInt(product.id) === parseInt(extraProduct.id))
          )
          .map((extraProduct) => extraProduct.id)
      : [];

  const hasAcceptedStromSmartInsurance = extraProductIds.some(
    (epID) => SMART_PRODUCT_POWER_PLUS_ID === Number.parseInt(epID)
  );
  const hasAcceptedPulsInsurance = extraProductIds.some((extraProductId) =>
    pulsIds.includes(Number.parseInt(extraProductId))
  );
  const currentStromSmartAddonProduct = useSelector((state) =>
    state.productState.products.find((element) => SMART_POWER_PRODUCTS_IDS.includes(element.id))
  );
  const currentPulsAddonProduct = useSelector((state) =>
    state.productState.products.find((element) => pulsIds.includes(element.id))
  );

  useEffect(() => {
    getAllConsentForms()
      .then((consentForms) => setFetchedConsentForms(consentForms))
      .catch(console.error);
  }, []);

  useEffect(() => {
    const fetchPriceInfo = async () => {
      await productActionDispatchers.getPriceInfo(productId);
      const callToActionResult = await getProductHubProductText(productId, 6, 7);
      setCallToAction(callToActionResult);
      setPulsCancellationForm((await GetProductHubProductById(pulsIds[0])).cancellationForm?.url);
      setStromSmartCancellationForm(
        (await GetProductHubProductById(SMART_POWER_PRODUCTS[0])).cancellationForm?.url
      );
    };

    fetchPriceInfo().catch(console.error);
  }, [productId]);

  useEffect(() => {
    const fetchExtraProductPriceInfo = async () => {
      try {
        if (
          extraProductIds.length > 0 &&
          (!extraProductPriceInfoList || extraProductPriceInfoList.length === 0)
        ) {
          const extraProductPriceInfo =
            productActionDispatchers.getExtraProductPriceInfo(extraProductIds);
          setExtraProductPriceInfoList(extraProductPriceInfo);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchExtraProductPriceInfo();
  }, [extraProductIds]);

  useEffect(() => {
    const fetchStromSmartTerms = async () => {
      if (extraProductIds.some((epId) => epId === SMART_PRODUCT_POWER_ID.toString())) {
        setStromSmartTermsGuid(
          await GetTermsAndConditionsGuidById(currentStromSmartAddonProduct?.termsAndConditionsId)
        );
      }
    };
    fetchStromSmartTerms();
    setRequirePowerSmartTerms(extraProductIds.some((id) => id == SMART_PRODUCT_POWER_PLUS_ID));
  }, [extraProductIds]);

  useEffect(() => {
    const fetchCampaignText = async () => {
      try {
        productActionDispatchers.getCampaignText(
          store.getState().saleState?.form?.campaign?.campaignName,
          isMobile
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchCampaignText();
  }, [store.getState().saleState?.form?.campaign]);

  const handleMonthlys = () => {
    let totalM =
      productPrices?.value && productPrices?.value.length > 0 ? handlePrices(true, false) : 0;
    let totalP =
      productPrices?.value && productPrices?.value.length > 0 ? handlePrices(false, false) : 0;
    setTotalMonthlySum(totalM);
    setTotalPaslag(parseFloat(totalP));
  };
  const handleExtraProducts = () => {
    let tempList = [];
    let epList = store.getState().productState.extraProductPriceInfo;

    if (epList.length === 0) {
      return tempList; // Return empty list if there are no extra products
    }

    epList.length > 0 &&
      epList.forEach((extraProduct, index) => {
        let totalPrice = 0;
        let denomination = "";
        if (extraProduct.id === 6 || extraProduct.id === 23) {
          return;
        }

        let priceElements = extraProduct.priceElements;

        if (extraProduct && priceElements.length > 0) {
          let price;
          let factor;
          if (priceElements.length > 1) {
            let price1 = priceElements[0].pricing.price;
            let price2 = priceElements[1].pricing.price;
            let factor = priceElements[1].factor;
            totalPrice = (price1 + price2) * factor;
            denomination = priceElements[1].denomination;
          } else {
            if (extraProduct.id === 21) {
              price = parseInt(forms.extraProductPriceElement);
            } else {
              price = priceElements[0].pricing.price;
            }
            factor = priceElements[0].factor;
            totalPrice = price * factor;
            denomination = priceElements[0].denomination;
          }
          if (denomination === "kr/dag") {
            // Add to total monthly sum
            let monthlyPrice = totalPrice * 30;
            setTotalMonthlySum((prevSum) => prevSum + monthlyPrice);
          } else if (denomination === "øre/kWh" && extraProduct.id !== 8) {
            totalPrice = parseFloat(totalPrice * 1.25).toFixed(2);
            setTotalPaslag((prevPaslag) => prevPaslag + parseFloat(totalPrice));
          }

          let extraProductIds = forms.extraProducts.map((extraProduct) => extraProduct.id);
          let matchingCampaignIds = [];

          forms.extraProducts.forEach((extraProduct) => {
            if (extraProduct.campaigns) {
              extraProduct.campaigns.forEach((campaignId) => {
                if (campaignId !== null && extraProductIds.includes(extraProduct.id)) {
                  matchingCampaignIds.push({
                    pId: parseInt(extraProduct.id),
                    cId: parseInt(campaignId)
                  });
                }
              });
            }
          });
          const campaignTexts = [];
          eP.forEach((product) => {
            if (matchingCampaignIds.map((p) => p.pId === parseInt(product.id)).includes(true)) {
              if (product.availableCampaigns) {
                let addedTextForProduct = false;
                product.availableCampaigns.forEach((campaign) => {
                  if (
                    matchingCampaignIds.map((p) => p.cId).includes(campaign.campaignId) &&
                    !addedTextForProduct
                  ) {
                    campaignTexts.push({
                      text: campaign.campaignName,
                      name: product.name
                    });
                    addedTextForProduct = true;
                  }
                });
              }
            }
          });

          let listen = {
            id: extraProduct.id,
            extraProduct: {
              price: totalPrice,
              name: extraProduct.name,
              denomination: denomination,
              campaignText:
                campaignTexts !== null && campaignTexts.length > 0
                  ? campaignTexts
                      .filter((campaignText) => campaignText.name === extraProduct.name)
                      .map((campaignText) => campaignText.text)
                  : null
            }
          };
          tempList = tempList.concat(listen);
        }
        if (extraProduct.id === 8) {
          totalPrice = -1;
          denomination = "øre/kWh";
          setTotalPaslag((prevPaslag) => (prevPaslag + parseFloat(totalPrice)).toFixed(2));

          let listen = {
            id: extraProduct.id,
            extraProduct: {
              price: totalPrice,
              name: extraProduct.name,
              denomination: denomination,
              campaignText: null
            }
          };
          tempList = tempList.concat(listen);
        }

        if (extraProduct.id == 7) {
          tempList.splice(index, 1, {
            id: extraProduct.id,
            extraProduct: {
              ...tempList[index].extraProduct,
              price: 0
            }
          });
        }
        if (extraProduct.id == 31 || extraProduct.id == 32) {
          setTotalMonthlySum(
            (prevSum) => prevSum + extraProduct.priceElements[0].pricing.price * 1.25
          );
          tempList.splice(index, 1, {
            id: extraProduct.id,
            extraProduct: {
              ...tempList[index].extraProduct,
              price: parseFloat(extraProduct.priceElements[0].pricing.price * 1.25).toFixed(0)
            }
          });
        }
      });
    return tempList;
  };

  useEffect(() => {
    handleMonthlys();
    const tempList = handleExtraProducts();
    setList(tempList);
  }, [extraProductPriceInfos, productPrices]);

  const dispatch = useDispatch();
  const addToSummary = useCallback((addresses, list, totalMonthlySum, totalPaslag) => {
    const product = store.getState().productState.priceInfo;
    addresses.map((address) => {
      const order = {
        address: {
          adresse: address.address,
          city: address.city,
          zip: address.zipCode
        },
        startUp: address.startUp,
        priceMonthly: handlePrices(true, false),
        pricePaslag: handlePrices(false, false),
        fixed: product.value && product.value[0]?.priceType === "FIXED",
        extraProducts:
          list !== null && list.length > 0
            ? list.map((item) => ({
                name: item.extraProduct.name,
                price: item.extraProduct.price,
                denomination: item.extraProduct.denomination,
                campaignText: item.extraProduct.campaignText
              }))
            : null,
        totalMonthly: totalMonthlySum,
        totalPaslag: totalPaslag
      };
      dispatch({ type: "ADD_SUMMARY_POWER", summaryPower: order });
    });
  }, []);

  const handleConfirm = () => {
    if (!isMobile) {
      const deliveryPointsList = deliveryPoints
        .filter((dp) => dp.selected === true)
        .map((dp) => ({ ...dp, ...dp.elhubData }));
      const bonusCampaignDtos =
        bonusCampaigns?.map((bc) => ({ id: bc.id, bonusTypeId: bc.bonusTypeId })) ?? null;

      saleActionDispatchers.submitFormAction({
        ...form,
        deliveryPoints: deliveryPointsList,
        bonusCampaigns: bonusCampaignDtos
      });
    }
    addToSummary(addresses, list, totalMonthlySum, totalPaslag);
    if (!userId) {
      saleActionDispatchers.updateFormAction(sanitizeProps(form));
    }
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
    }
  };

  const handlePrices = (monthly, deno) => {
    const product = store.getState().productState?.priceInfo;
    let price =
      product !== undefined &&
      product !== null &&
      product?.value?.find((price) => {
        if (monthly) {
          return price.name.includes("Månedlig");
        } else {
          return price.name.includes("Påslag strøm");
        }
      });

    if (price) {
      if (deno) {
        return (
          (tags ? price?.vatExclusivePrice : price.vatInclusivePrice) + " " + price.denomination
        );
      } else {
        return tags ? price?.vatExclusivePrice : price.vatInclusivePrice;
      }
    } else {
      return 0;
    }
  };

  return (
    <FormPageContainer
      className={`${styles.container} ${IsFKTheme && styles.containerFK}`}
      onBack={onBack}
      currentStep={null}
    >
      <Box className={"slide"}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between"
          }}
        >
          {addresses.map((address) => {
            return (
              <Grid item xs={12} key={address.address} sx={{ mb: 3 }}>
                <Paper
                  elevation={8}
                  className={"whiteBox"}
                  sx={{
                    ml: -5,
                    mr: -5,
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sx={{ textAlign: "center", mb: 1 }}>
                      <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                        {address.address} {address.zipCode} {address.city}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} sx={{ mb: 1 }}>
                      <Typography variant={"string"} sx={{ position: "left" }}>
                        Oppstartsdato:
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant={"string"}>{address.startUp}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={"string"} sx={{ textDecoration: "underline" }}>
                        {productName}:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant={"string"} sx={{ position: "left" }}>
                        Månedspris:
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant={"string"}>{handlePrices(true, true)}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant={"string"} sx={{ position: "left" }}>
                        Påslag:
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ mb: 1 }}>
                      <Typography variant={"string"}>{handlePrices(false, true)}</Typography>
                    </Grid>
                    {store.getState().productState.extraProductPriceInfo !== null &&
                      store.getState().productState.extraProductPriceInfo.length > 0 &&
                      list !== null &&
                      list.length > 0 && (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="string" sx={{ textDecoration: "underline" }}>
                              Valgte tilleggstjenester
                            </Typography>
                          </Grid>
                          {list !== null &&
                            list.length > 0 &&
                            list.map((item) => (
                              <Grid container key={item.id}>
                                <Grid item xs={8}>
                                  <Typography variant="string">{item.extraProduct.name}</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography variant="string">
                                    {item.extraProduct.price + " " + item.extraProduct.denomination}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                        </>
                      )}
                    <Grid item xs={8} sx={{ mt: 2 }}>
                      <Typography variant={"string"} sx={{ position: "left", fontWeight: "bold" }}>
                        Total månedsbeløp:
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ mt: 2 }}>
                      <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                        {totalMonthlySum + " kr/mnd"}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant={"string"} sx={{ position: "left", fontWeight: "bold" }}>
                        {productPrices.value && productPrices.value[0]?.priceType === "FIXED"
                          ? "Total strømpris:"
                          : "Total påslag:"}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                        {totalPaslag + " øre/kwh"}
                      </Typography>
                    </Grid>

                    {list !== null &&
                      list.length > 0 &&
                      list.map(
                        (item) =>
                          item.extraProduct.campaignText &&
                          item.extraProduct.campaignText.length > 0 && (
                            <Grid item xs={12} sx={{ mt: 1 }}>
                              <Typography variant={"string"} sx={{ fontStyle: "italic" }}>
                                {item.extraProduct.campaignText[0]}
                              </Typography>
                            </Grid>
                          )
                      )}
                    {campaignText != null && (
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <Typography variant={"string"} sx={{ fontStyle: "italic" }}>
                          <div dangerouslySetInnerHTML={{ __html: campaignText }} />
                        </Typography>
                      </Grid>
                    )}
                    {callToAction != null && (
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <Typography>
                          <div dangerouslySetInnerHTML={{ __html: callToAction }} />
                        </Typography>
                      </Grid>
                    )}
                    {extraProductIds
                      ?.filter((epId) => SMART_POWER_PRODUCTS.includes(epId))
                      ?.sort()
                      ?.map((epId) => {
                        const product = store
                          .getState()
                          .productState.products.filter(
                            (product) => product.id?.toString() === epId
                          )[0];
                        if (product) {
                          return (
                            <Grid item xs={12} sx={{ mt: 1 }}>
                              <Typography variant={"string"}>
                                <p>
                                  <strong>{product.name}</strong>
                                </p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: product.textGroups[9]
                                  }}
                                />
                              </Typography>
                            </Grid>
                          );
                        }
                      })}
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
        {store.getState().invoiceState.selectedInvoiceOption === 3 && (
          <Grid>
            <Box>
              <Paper elevation={8} className={"whiteBox"} sx={{ ml: -5, mr: -5 }}>
                <Grid container>
                  <Grid item xs={8}>
                    <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                      Papirfaktura:
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                      {isMobile ? InvoicePrices.MobilePaper : InvoicePrices.Paper} kr/stk
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        )}
      </Box>
      {!isMobile ? (
        <div className={"slide"}>
          <div className={termStyles.termsContainer}>
            <div className={termStyles.checkBoxContainer}>
              <button
                className={termStyles.checkBoxButton}
                onClick={() => setAcceptedPowerTerms(!acceptedPowerTerms)}
                ref={powerTarget}
              >
                {acceptedPowerTerms ? (
                  <CheckBox className={termStyles.checkBoxIcon} />
                ) : (
                  <CheckBoxOutlineBlank className={termStyles.checkBoxIcon} />
                )}
              </button>
              <Popover
                classes={{ paper: classes.popoverCheckboxStyle }}
                open={openPowerTermsMessageDialog}
                anchorEl={powerTarget.current}
                onClose={() => setOpenPowerTermsMessageDialog(false)}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left"
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "left"
                }}
              >
                <Box>
                  <Box
                    sx={{
                      padding: 1,
                      paddingLeft: 0,
                      borderRadius: 3,
                      backgroundColor: "#628c2a",
                      color: "white",
                      borderColor: "black"
                    }}
                  >
                    <ArrowLeftIcon fontSize={"large"} />
                    <WarningAmberIcon /> Husk å akseptere strømvilkårene
                  </Box>
                </Box>
              </Popover>
              <div className={termLinkStyles.checkBoxTextTerm}>
                Jeg aksepterer{" "}
                <a
                  className={IsFKTheme ? termLinkStyles.termLink : termLinkStyles.termLinkTK}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  vilkårene
                </a>{" "}
                for avtalen og er kjent med{" "}
                <a
                  className={IsFKTheme ? termLinkStyles.termLink : termLinkStyles.termLinkTK}
                  href={powerRightOfWithdrawalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  opplysninger om angrerett og angreskjema.
                </a>{" "}
                Jeg aksepterer at jeg må betale for de dagene jeg har fått strømleveransen dersom
                jeg har valgt oppstart før utløp av angrefristen.
                {extraProductIds.some((epId) => epId === SMART_PRODUCT_POWER_ID.toString()) && (
                  <div>
                    Jeg aksepterer{" "}
                    <a
                      className={IsFKTheme ? termLinkStyles.termLink : termLinkStyles.termLinkTK}
                      href={baseUrl + stromSmartTermsGuid}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      vilkårene
                    </a>{" "}
                    for avtalen om StrømSmart, og er kjent med{" "}
                    <a
                      className={IsFKTheme ? termLinkStyles.termLink : termLinkStyles.termLinkTK}
                      href={stromSmartCancellationForm}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      opplysninger om angrerett og angreskjema for StrømSmart.
                    </a>
                  </div>
                )}
              </div>
            </div>
            {hasAcceptedStromSmartInsurance && !hasAcceptedPulsInsurance && (
              <AddonView
                acceptedTerms={acceptedStromSmartTerms}
                setAcceptedTerms={setAcceptedStromSmartTerms}
                openTermsMessageDialog={openStromSmartTermsMessageDialog}
                setOpenTermsMessageDialog={setOpenStromSmartTermsMessageDialog}
                currentRef={stromSmartTarget}
                fetchedConsentForms={fetchedConsentForms}
                productName={currentStromSmartAddonProduct?.name}
                withdrawalLink={stromSmartCancellationForm}
                addonProductToDisplay={currentStromSmartAddonProduct}
                termsBaseUrl={baseUrl}
                termSlug="tilleggsprodukter-fk-stromsmartplus"
                popoverConfig={{
                  anchorOrigin: { vertical: "center", horizontal: "left" },
                  transformOrigin: { vertical: "center", horizontal: "left" }
                }}
              />
            )}
            {hasAcceptedPulsInsurance && hasAcceptedStromSmartInsurance && (
              <AddonView
                acceptedTerms={acceptedStromSmartTerms}
                setAcceptedTerms={setAcceptedStromSmartTerms}
                openTermsMessageDialog={openPulsTermsMessageDialog}
                setOpenTermsMessageDialog={setOpenPulsTermsMessageDialog}
                currentRef={pulsTarget}
                fetchedConsentForms={fetchedConsentForms}
                productName={currentStromSmartAddonProduct?.name}
                withdrawalLink={stromSmartCancellationForm}
                addonProductToDisplay={currentStromSmartAddonProduct}
                termsBaseUrl={baseUrl}
                termSlug="tilleggsprodukter-fk-stromsmartplus-puls"
                popoverConfig={{
                  anchorOrigin: { vertical: "center", horizontal: "right" },
                  transformOrigin: { vertical: "center", horizontal: "left" }
                }}
                productName_2={currentPulsAddonProduct?.name}
                withdrawalLink_2={pulsCancellationForm}
                termsLink_2={baseUrl + currentPulsAddonProduct?.termsAndConditionsId}
                addonProductToDisplay_2={currentPulsAddonProduct}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          <div className={termStyles.text}>
            For å opprette er det fint om du leser og aksepterer vilkårene og gjøre deg kjent med
            angreretten.
          </div>
          <a className={termStyles.link} href={link} target="_blank" rel="noopener noreferrer">
            Les alle vilkårene her
          </a>
        </>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <div className={"slide"}>
          <button
            disabled={
              !isMobile && requirePowerSmartTerms
                ? !acceptedPowerTerms || !acceptedStromSmartTerms
                : !acceptedPowerTerms
            }
            className={"btnPrimary " + styles.btn}
            onClick={handleConfirm}
          >
            Fullfør bestilling
          </button>
        </div>
      </Box>
    </FormPageContainer>
  );
}
const useStyles = makeStyles((theme) => ({
  popoverCheckboxStyle: {
    marginLeft: "30px",
    backgroundColor: "transparent !important",
    border: 0,
    boxShadow: "none !important"
  }
}));
export default connect(summaryAndUserAndFormSelector)(PriceSummary);

const findSanityDocumentBySlug = (array, slugString) => {
  return array.find((item) => item.slug?.current === slugString);
};

const AddonView = ({
  acceptedTerms,
  setAcceptedTerms,
  openTermsMessageDialog,
  setOpenTermsMessageDialog,
  currentRef,
  fetchedConsentForms,
  productName,
  withdrawalLink,
  addonProductToDisplay,
  termSlug,
  termsBaseUrl,
  popoverConfig,
  productName_2,
  withdrawalLink_2,
  termsLink_2,
  addonProductToDisplay_2
}) => {
  const [termsGuid, setTermsGuid] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    if (addonProductToDisplay?.termsAndConditionsId != null) {
      const fetchGuid = async () => {
        try {
          const guid = await GetTermsAndConditionsGuidById(
            addonProductToDisplay?.termsAndConditionsId
          );
          setTermsGuid(guid);
        } catch (error) {
          console.error("Error fetching terms and conditions GUID: ", error);
        }
      };
      fetchGuid();
    }
  }, [addonProductToDisplay?.termsAndConditionsId]);

  useEffect(() => {
    if (addonProductToDisplay_2?.productHubInfo?.termsAndConditionsId != null) {
      const fetchGuid = async () => {
        try {
          const guid = await GetTermsAndConditionsGuidById(
            addonProductToDisplay_2?.productHubInfo?.termsAndConditionsId
          );
          setTermsGuid(guid);
        } catch (error) {
          console.error("Error fetching terms and conditions GUID: ", error);
        }
      };
      fetchGuid();
    }
  }, [addonProductToDisplay_2?.productHubInfo?.termsAndConditionsId]);

  return (
    <div className={styles.checkBoxContainer}>
      <button
        className={styles.checkBoxButton}
        onClick={() => setAcceptedTerms(!acceptedTerms)}
        ref={currentRef}
      >
        {acceptedTerms ? (
          <CheckBox className={styles.checkBoxIcon} />
        ) : (
          <CheckBoxOutlineBlank className={styles.checkBoxIcon} />
        )}
      </button>
      <Popover
        classes={{ paper: classes.popoverCheckboxStyle }}
        open={openTermsMessageDialog}
        anchorEl={currentRef.current}
        onClose={() => setOpenTermsMessageDialog(false)}
        anchorOrigin={popoverConfig.anchorOrigin}
        transformOrigin={popoverConfig.transformOrigin}
      >
        <Box>
          <Box
            sx={{
              padding: 1,
              paddingLeft: 0,
              borderRadius: 3,
              backgroundColor: "#628c2a",
              color: "white",
              borderColor: "black"
            }}
          >
            <ArrowLeftIcon fontSize={"large"} />
            <WarningAmberIcon /> Husk å akseptere produktvilkårene
          </Box>
        </Box>
      </Popover>
      <div className={styles.checkBoxText}>
        {fetchedConsentForms && fetchedConsentForms.length > 0 && (
          <>
            <PortableAgreementText
              contentArray={findSanityDocumentBySlug(fetchedConsentForms, termSlug)?.content}
              linkStyle={IsFKTheme ? termLinkStyles.termLink : termLinkStyles.termLinkTK}
              termsLink={termsBaseUrl + termsGuid}
              withdrawalLink={withdrawalLink}
              productName={productName}
              productName_2={productName_2}
              withdrawalLink_2={withdrawalLink_2}
              termsLink_2={termsLink_2}
            />
          </>
        )}
      </div>
    </div>
  );
};
