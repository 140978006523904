import { bindActionCreators } from "redux";
import store from "../../Store/Store";
import { setTrumfId, setQueryStrings, mdmUpdateTrumfId, setError } from "./TrumfActions";

const actions = {
  setTrumfId,
  setQueryStrings,
  mdmUpdateTrumfId,
  setError
};

const trumfActionDispatchers = bindActionCreators(actions, store.dispatch);

export default trumfActionDispatchers;
