import React, { useEffect, useState } from "react";
import * as AxiosFunctions from "../../../Utils/AxiosFunctions";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { useHistory } from "react-router-dom";
import { pages } from "../../../Utils/CustomerFormHelper";
import { IsFKTheme } from "../../../Utils/Themes";
import { Box, Grid } from "@mui/material";
import styles from "./ConfirmExistingAgreement.module.scss";
import FKLoader from "../FKLoader/FKLoader";
import { useSelector } from "react-redux";
import ExistingAgreementProductCard from "./ExistingAgreementProductCard";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import * as AxiosFunction from "../../../Utils/AxiosFunctions";
import { getPriceAreaTags } from "../../../Utils/HelperFunctions";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";

const ExistingAgreement = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [allProducts, setAllProducts] = useState([]);
  const [isAllProductsFetched, setIsAllProductsFetched] = useState(false);
  const optionalMainProducts = useSelector((state) => state.saleState.form.optionalMainProducts);
  const lastProduct = useSelector((state) => state.customerState.lastProduct);
  const [checked, setChecked] = useState(false);
  const [chosenProduct, setChosenProduct] = useState(null);

  // warning message when leaving page
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      onbeforeunload = (e) =>
        `Endringene dine vil bli slettet. Er du sikker på at du vil forlate siden?`;
      return () => {
        onbeforeunload = null;
      };
    }
  });

  const headerTheme = createTheme({ typography: { h1: { fontSize: "2.5rem" } } });

  // initial fetch of products
  useEffect(() => {
    async function getAndSetProductsForSelection() {
      try {
        let prodOptions = optionalMainProducts;
        // add last product to list of products if it is not already there
        if (lastProduct) {
          // Check if lastProduct is not null, not in the current product list, and not already in prodOptions
          if (
            !allProducts.includes(lastProduct.id) &&
            !prodOptions.includes(lastProduct.id.toString())
          ) {
            prodOptions = [lastProduct.id.toString(), ...prodOptions]; // Add lastProduct to prodOptions
          }

          // if oppstartsfond and hovedfond, remove oppstartsfond
          // Spot med forvaltning
          const containsProductId613 = prodOptions.some((product) => product === "613");
          if (containsProductId613 && lastProduct?.id === 612) {
            prodOptions = prodOptions.filter((product) => product !== "613");
          }

          // KNIF Sikret 50
          const containsProductId757 = prodOptions.some((product) => product === "757");
          if (containsProductId757 && lastProduct?.id === 271) {
            prodOptions = prodOptions.filter((product) => product !== "757");
          }

          // Spot med Forvaltning GOBB
          const containsProductId727 = prodOptions.some((product) => product === "727");
          if (containsProductId727 && lastProduct?.id === 217) {
            prodOptions = prodOptions.filter((product) => product !== "727");
          }

          // TOBB Forvaltning
          const containsProductId839 = prodOptions.some((product) => product === "839");
          if (containsProductId839 && lastProduct?.id === 958) {
            prodOptions = prodOptions.filter((product) => product !== "839");
          }

          // Check for matching product names and replace the existing product in prodOptions
          // this will not do crap? cuz prodOptions is an array of strings not objects
          prodOptions = prodOptions.map((product) =>
            product.id === lastProduct.id && product.name === lastProduct.name
              ? lastProduct
              : product
          );
        }

        setAllProducts(await AxiosFunctions.getProductChoiceProductById(prodOptions));
      } catch (e) {
        console.error(
          "Noe gikk galt ved lasting av produkter. Vennligst last opp nettsiden på nytt."
        );
      }
    }

    if (!isAllProductsFetched) {
      getAndSetProductsForSelection().finally(() => setIsAllProductsFetched(true));
    }
  }, [isAllProductsFetched, optionalMainProducts]);

  // loading spinner
  useEffect(() => {
    if (!isAllProductsFetched) return;

    setIsLoading(
      !(
        isAllProductsFetched &&
        allProducts?.length > 0 &&
        allProducts.every((p) => p.productText !== undefined)
      )
    );
  }, [allProducts, isAllProductsFetched]);

  const goBack = () => {
    if (lastProduct) {
      history.push(pages.confirmExistingAgreement);
    } else {
      history.push(pages.deliveryPoints);
    }
  };

  // oppdater korrekt og set producthubinfo
  const handleChange = () => {
    const fetchData = async () => {
      try {
        const productResult = await AxiosFunction.GetProductHubProductWithBonuses(chosenProduct.id);

        const guid = await AxiosFunction.GetTermsAndConditionsGuidById(
          productResult.termsAndConditionsId
        );

        const producthubInfo = {
          ...productResult,
          priceAreaTags: getPriceAreaTags(productResult),
          guid
        };

        saleActionDispatchers.setProducthubInfo(producthubInfo);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData().finally(() => history.push(pages.confirmExistingAgreement));
  };

  return (
    <FormPageContainer
      className={`${styles.container} ${IsFKTheme && styles.containerFK}`}
      onBack={goBack}
      hideFooter={true}
    >
      {IsFKTheme && <div className={styles.houseImgContainer} />}

      {isLoading ? (
        <FKLoader />
      ) : (
        <Box className={"slide"}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between"
            }}
          >
            <ThemeProvider theme={headerTheme}>
              <div className={styles.container}>
                <Typography sx={{ margin: "auto" }} align="center" variant="h1">
                  Velg strømavtaler
                </Typography>
                <p className={styles.text}>
                  Velg den strømavtalen du ønsker å få på din nye adresse.
                </p>
              </div>
            </ThemeProvider>
            {allProducts.map((product) => {
              const handleCardClick = () => {
                setChecked(true);
                setChosenProduct(product);
              };
              const onKeyDown = (event) => {
                if (event.key === "Enter" || event.key === " ") {
                  handleCardClick();
                }
              };
              return (
                <div
                  key={product.id}
                  onClick={handleCardClick}
                  className={styles.existingProductCardWrapper}
                  onKeyDown={onKeyDown}
                  tabIndex={0}
                  role="radio"
                  aria-checked={checked}
                >
                  <ExistingAgreementProductCard
                    product={product}
                    checked={chosenProduct?.id === product?.id}
                  />
                </div>
              );
            })}
          </Grid>
          <Grid container justify="center">
            <button
              className={`btnPrimary ${styles.confirmButton} ${styles.generalMargin}`}
              onClick={handleChange}
              disabled={!checked}
            >
              Velg
            </button>
            <button type="button" className={`btnBorder ${styles.changeButton}`} onClick={goBack}>
              Gå tilbake
            </button>
          </Grid>
          {!checked && (
            <Typography variant="body1" sx={{ color: "white", textAlign: "center" }}>
              Vennligst velg et produkt
            </Typography>
          )}
        </Box>
      )}
    </FormPageContainer>
  );
};

export default ExistingAgreement;
