import React, { useState } from "react";
import styles from "./ManualIdentification.module.scss";
import SimpleInput from "../../../SimpleInput/SimpleInput";
import FkMascot from "../../../../assets/Images/FK/FKMascotFramed.svg";
import FKMobileMascot from "../../../../assets/Images/FK/Mobil/MobileMascotBlush.svg";
import { IsFKTheme } from "../../../../Utils/Themes";
import validateFirstPage from "../../../CustomerForm/Validate/ValidateFirstPage";
import saleActionDispatchers from "../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import { formSelector } from "../../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../../FormPageContainer/FormPageContainer";
import { pageStepAnimationDuration } from "../../../../Utils/CustomerFormHelper";
import customerActionDispatchers from "../../../../ReduxModules/Actions/CustomerActions/CustomerActionDispatchers";
import pageStepActionDispatchers from "../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { getAgeFromSsn } from "../../../../Utils/HelperFunctions";

const ssnRegex = /^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))(0[1-9]|1[0-2])\d{7}$/g;

function ManualIdentification({ onConfirm, form, isMobile }) {
  const { ssn, phone } = form;
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const validInput = ssn && ssn.match(ssnRegex);

  const handleChange = (e) => {
    saleActionDispatchers.updateFormAction({ [e.target.name]: e.target.value });
  };

  const isValidInput =
    ssn.replace(/\s+/g, "").length === 11 && (isMobile || phone.replace(/\s+/g, "").length === 8);

  const validateInput = () => {
    if (!validInput) {
      setValidationError("*Vennligst skriv inn et gyldig Fødselsnummer (11 siffer).");
    } else if (getAgeFromSsn(ssn) < 18) {
      setValidationError("*Personen som bestiller må være minst 18 år gammel.");
    } else {
      setValidationError(null);
    }
  };

  const handleBornAndPersonNumber = () => {
    let year = ssn.slice(4, 6) > 20 ? "19" : "20";
    const born = ssn.slice(0, 2) + "." + ssn.slice(2, 4) + "." + year + ssn.slice(4, 6);
    const personNumber = ssn.slice(6, 11);
    saleActionDispatchers.updateFormAction({ born, personNumber });
  };

  const handleConfirm = () => {
    if (!validInput) {
      setValidationError("*Vennligst skriv inn et gyldig Fødselsnummer (11 siffer).");
      return;
    }
    if (getAgeFromSsn(ssn) < 18) {
      setValidationError("*Personen som bestiller må være minst 18 år gammel.");
      return;
    }
    const errorMessage = validateFirstPage(form, isMobile);
    if (!errorMessage) {
      handleBornAndPersonNumber(); // todo: Remove this
      customerActionDispatchers.getExistingCustomerDataBySsn(ssn);
      if (!isMobile) {
        saleActionDispatchers.getCustomerInfoAction(ssn, phone);
      }
      if (!onConfirm) {
        pageStepActionDispatchers.goToNextStep(window.location.pathname);
      } else {
        onConfirm();
      }
    } else {
      setError(errorMessage);
    }
  };

  return (
    <FormPageContainer className={IsFKTheme ? styles.containerFK : ""}>
      <div>
        {IsFKTheme && !isMobile && (
          <div className={"frameImageContainer"}>
            <img
              className={"frameImage formPage-background fade-"}
              src={FkMascot}
              alt={"mobileMascot"}
            />
          </div>
        )}
        <header className={`${styles.header} fallIn-`}>
          <h1>Først trenger vi litt informasjon om deg</h1>
        </header>
        {isMobile && (
          <div className={"frameImageContainer"}>
            <img
              className={"frameImage formPage-background fade-"}
              src={FKMobileMascot}
              alt={"mobileMascot"}
            />
          </div>
        )}
        <div className={"slide"}>
          <SimpleInput
            className={styles.field}
            type={"ssn"}
            name={"ssn"}
            value={ssn}
            label={"Fødselsnummer"}
            onChange={handleChange}
            placeholder={"11 siffer"}
            showWhiteBox
            autofocus
            focusDelay={pageStepAnimationDuration}
            validationErrorMessage={validationError}
            onBlur={validateInput}
          />
          {!isMobile && (
            <SimpleInput
              className={styles.field}
              type={"phone"}
              name={"phone"}
              value={phone}
              label={"Telefonnummer"}
              onChange={handleChange}
              placeholder={"Ditt nummer"}
              showWhiteBox
            />
          )}
          <div className={styles.alert}>{error}</div>
          <button
            className={"btnPrimary " + styles.btn}
            disabled={!isValidInput}
            onClick={handleConfirm}
          >
            Bekreft
          </button>
        </div>
      </div>
    </FormPageContainer>
  );
}

export default connect(formSelector)(ManualIdentification);
