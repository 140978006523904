import React, { useEffect, useLayoutEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import saleActionDispatchers from "../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { history } from "../../App";
import Preloader from "../Preloader/Preloader";
import { pages, isNextMobileStep, pageStepAnimationDuration } from "../../Utils/CustomerFormHelper";
import { connect } from "react-redux";
import ErrorPage from "../ErrorPage/ErrorPage";
import FormPageContainer from "./FormPageContainer/FormPageContainer";
import Name from "./Name/Name";
import Loader from "./FKLoader/Loader";
import Email from "./Email/Email";
import Summary from "./Summary/Summary";
import SubscriptionConsent from "./SubscriptionConsent/SubscriptionConsent";
import Receipt from "./Receipt/Receipt";
import SecretProductNameAndId from "./SecretProductNameAndId";
import BankIdLogin from "./Identification/BankId/BankIdLogin";
import ManualIdentification from "./Identification/ManualIdentification/ManualIdentification";
import Identification from "./Identification/Identification";
import Phone from "./Phone/Phone";
import InvoiceMenuPage from "./Invoice/InvoiceMenuPage";
import pageStepActionDispatchers from "../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import ChooseMobileSubscriptionReceiver from "./Mobile/ChooseMobileSubscriptionReceiver";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ConfirmOrder from "./Mobile/ConfirmOrder";
import AddUser from "./Mobile/AddUser/AddUser";
import { StepsSelector } from "../../ReduxModules/Selectors/PageStepSelectors";
import { legalOwnerSelector } from "../../ReduxModules/Selectors/SaleSelectors";
import { getQueryStrings } from "../../Utils/UrlParser";
import productActionDispatchers from "../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import TransitionRoute from "../TransitionRoute/TransitionRoute";
import PriceSummaryMobile from "./PriceSummary/PriceSummaryMobile";

function MobileCustomerForm({
  location,
  initialized,
  submitSuccessful,
  criticalError,
  onlyLegalOwner,
  completedUser,
  recommendedProducts,
  avtaletype
}) {
  const [prevLocation, setPrevLocation] = useState(null);
  const [next, setNext] = useState(true); // used for tracking next/back animations
  const keyPath = location.pathname;

  useEffect(() => {
    const queryString = getQueryStrings(location.search);
    fetchData(queryString);
    pageStepActionDispatchers.setFocusDelay(1000);
    if (process.env.NODE_ENV !== "development") {
      onbeforeunload = (e) =>
        `Endringene dine vil bli slettet. Er du sikker på at du vil forlate siden?`;
      return () => {
        onbeforeunload = null;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    setNext(isNextMobileStep(prevLocation?.pathname, keyPath, recommendedProducts));
    setPrevLocation(location);
  }, [location]);

  async function fetchData(queryString) {
    if (!initialized) {
      saleActionDispatchers.parseQueryStringAction(queryString, true);
    }
  }

  useEffect(() => {
    for (let r of recommendedProducts) productActionDispatchers.getAddonProductInfo(r.id);
  }, [recommendedProducts]);

  function startUserSteps() {
    if (!completedUser) {
      saleActionDispatchers.updateUser(1, {
        isOwner: onlyLegalOwner,
        avtaletype: parseInt(avtaletype)
      });
      return () => saleActionDispatchers.startUserSteps(1);
    }
    return null;
  }

  if (submitSuccessful && location.pathname !== pages.mobile + pages.receipt) {
    history.replace(pages.mobile + pages.receipt);
  }

  return (
    <>
      <Preloader />
      <SecretProductNameAndId />
      {!initialized ? (
        <FormPageContainer hideFooter className={"loaderContainer"}>
          <div className={"loader slide"}>
            <Loader text={"Vent mens vi gjør ting klart for deg..."} />
          </div>
        </FormPageContainer>
      ) : (
        <>
          {criticalError ? (
            <ErrorPage />
          ) : (
            <TransitionGroup className={next ? "formPage-next-" : "formPage-back-"}>
              <CSSTransition
                key={keyPath}
                timeout={pageStepAnimationDuration}
                classNames={"formPage-"}
                unmountOnExit
                mountOnEnter
              >
                <Switch location={location}>
                  <TransitionRoute exact path={pages.mobile + pages.receipt} component={Receipt} />
                  <TransitionRoute exact path={pages.mobile} component={Identification} />
                  <TransitionRoute
                    exact
                    path={pages.mobile + pages.bankIdLogin}
                    component={BankIdLogin}
                  />
                  <TransitionRoute
                    exact
                    path={pages.mobile + pages.mobileNumber}
                    component={Phone}
                  />
                  <TransitionRoute
                    exact
                    path={pages.mobile + pages.manualIdentification}
                    component={ManualIdentification}
                  />
                  <TransitionRoute
                    exact
                    path={pages.mobile + pages.bankIdLogin}
                    component={BankIdLogin}
                  />
                  <TransitionRoute
                    exact
                    path={pages.mobile + pages.subscription}
                    component={SubscriptionConsent}
                  />
                  <TransitionRoute
                    exact
                    path={pages.mobile + pages.customerName}
                    component={Name}
                  />
                  <TransitionRoute exact path={pages.mobile + pages.summary} component={Summary} />
                  <TransitionRoute
                    exact
                    path={pages.mobile + pages.invoice}
                    component={InvoiceMenuPage}
                  />
                  <TransitionRoute
                    exact
                    path={pages.mobile + pages.customerEmail}
                    render={() => <Email onConfirm={startUserSteps()} />}
                  />
                  <TransitionRoute
                    exact
                    path={pages.mobile + pages.mobileSubscriptionReceiver}
                    component={ChooseMobileSubscriptionReceiver}
                  />
                  <TransitionRoute
                    exact
                    path={pages.mobile + pages.confirmOrder}
                    component={ConfirmOrder}
                  />
                  <TransitionRoute path={pages.mobile + pages.addUser} component={AddUser} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        </>
      )}
    </>
  );
}

export default connect(legalOwnerSelector)(connect(StepsSelector)(MobileCustomerForm));
