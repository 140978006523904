import React, { useState } from "react";
import { loginRedirect } from "./TrumfStuff";
import { connect } from "react-redux";
import { stateSelector } from "../../ReduxModules/Selectors/SaleSelectors";
import styles from "./Trumf.module.scss";
import LoadingSpinnerTK from "../LoadingSpinner/LoadingSpinnerTK";
import FormPageContainer from "../CustomerForm/FormPageContainer/FormPageContainer";
import { customerServiceLink } from "../../Utils/ExternalLinks";

function TrumfError({ onBack, state }) {
  const [loading, setLoading] = useState(false);
  const trumfLogin = async () => {
    try {
      setLoading(true);
      await loginRedirect(state);
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <FormPageContainer onBack={onBack}>
      {loading ? (
        <LoadingSpinnerTK text={"Vent mens vi sender deg til trumf..."} />
      ) : (
        <div>
          <div className={styles.error}>
            <h1>Noe gikk galt med innloggingen din.</h1>
            <h5>Prøv igjen eller kontakt kundeservice...</h5>
            <div>
              Kundeservice:{" "}
              <a
                href={customerServiceLink}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.errorLink}
              >
                {customerServiceLink}
              </a>
            </div>
          </div>
          <button className={"btnPrimary " + styles.button} onClick={trumfLogin}>
            Prøv igjen
          </button>
        </div>
      )}
    </FormPageContainer>
  );
}

export default connect(stateSelector)(TrumfError);
