import React from "react";
import styles from "./PowerMeter.module.scss";
import { IsFKTheme } from "../../../../../Utils/Themes";
import FormPageContainer from "../../../FormPageContainer/FormPageContainer";
import TKPowerMeter from "../../../../../assets/Images/TK/PowerMeter.svg";
import PowerMeter from "../../../../../assets/Images/FK/PowerMeterFramed.svg";
import WhiteButtonList from "../../../../ButtonList/WhiteButtonList";
import { pages } from "../../../../../Utils/CustomerFormHelper";

function HelpFindPowerMeter({ chooseOne, chooseTwo, chooseThree, onBack }) {
  return (
    <FormPageContainer onBack={onBack} currentStep={pages.deliveryPoints}>
      <div className={(IsFKTheme && styles.containerFK) || ""}>
        <header className={`${styles.header} fallIn-`}>
          <h1>Har du tilgang til strømmåleren din?</h1>
        </header>
        {IsFKTheme ? (
          <div className={"frameImageContainer"}>
            <img
              className={"frameImage formPage-background fade-"}
              src={PowerMeter}
              alt="PowerMeter"
            />
          </div>
        ) : (
          <img src={TKPowerMeter} alt={"Strømmåler"} className={styles.powerMeterTK} />
        )}
        <div className={"slide"}>
          <div className={styles.btnContainer}>
            {
              <WhiteButtonList
                buttons={[
                  { content: <div className={"title"}>Ja, det har jeg!</div>, onClick: chooseOne },
                  {
                    content: <div className={"title"}>Hvor finner jeg den?</div>,
                    onClick: chooseTwo
                  },
                  { content: <div className={"title"}>Nei, ikke nå.</div>, onClick: chooseThree }
                ]}
              />
            }
          </div>
        </div>
      </div>
    </FormPageContainer>
  );
}

export default HelpFindPowerMeter;
