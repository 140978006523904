import React, { useEffect, useState } from "react";
import styles from "./Phone.module.scss";
import SimpleInput from "../../SimpleInput/SimpleInput";
import FKMobileMascot from "../../../assets/Images/FK/Mobil/MobileMascotSmile.svg";
import FKMascot from "../../../assets/Images/FK/FKMascot.svg";
import { IsFKTheme } from "../../../Utils/Themes";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import { formAndUserSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { sanitizeProps } from "../../../Utils/HelperFunctions";
import { pages, pageStepAnimationDuration } from "../../../Utils/CustomerFormHelper";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { useParams } from "react-router-dom";
import * as AxiosFunction from "../../../Utils/AxiosFunctions";
import AlreadyActiveDialog from "../Mobile/AlreadyActiveDialog/AlreadyActiveDialog";
import { Spinner } from "reactstrap";
import { getAgeFromSsn } from "../../../Utils/HelperFunctions";

function Phone({ onConfirm, onBack, form, userId, user, isMobile }) {
  const params = useParams();
  const phone = userId ? user.phone : form.phone;
  const ssn = form.ssn;

  const [alreadyActive, setAlreadyActive] = useState(false);
  const [tooYoungToOrder, setTooYoungToOrder] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationError, setValidationError] = useState(null);

  useEffect(() => {
    if (alreadyActive) {
      console.log("REPORTED ERROR");
      AxiosFunction.reportInfo(
        "Phone number already active",
        "Phone nr " + phone + " is already active"
      ).catch();
    }
  }, [alreadyActive]);

  useEffect(() => {
    if (form.ssn && form.ssn?.length === 11) {
      if (getAgeFromSsn(form.ssn) < 18) {
        setTooYoungToOrder(true);
      }
    }
  }, [form.ssn]);

  const handleChange = (e) => {
    if (userId) {
      saleActionDispatchers.updateUser(user.id, { [e.target.name]: e.target.value });
    } else {
      saleActionDispatchers.updateFormAction({ [e.target.name]: e.target.value });
    }
    setAlreadyActive(false);

    // Since input is validated onblur, we need this to prevent error messages from overstaying their welcome and confusing users.
    if (e.target.value?.length === 8) {
      validateInput(e.target.value);
    }
  };

  async function GetIsActiveMobile() {
    setLoading(true);
    const isActive = await AxiosFunction.getIsActiveMobile(ssn, phone);
    setLoading(false);
    return isActive;
  }

  async function handleConfirm() {
    if (isMobile && userId) {
      let isActive;
      if (alreadyActive) isActive = true;
      else isActive = await GetIsActiveMobile();

      if (isActive) {
        setAlreadyActive(true);
        setShowDialog(true);
        return;
      }
    }

    if (userId) {
      saleActionDispatchers.updateUser(userId, sanitizeProps(user));
    } else {
      saleActionDispatchers.updateFormAction(sanitizeProps(form));
      saleActionDispatchers.getCustomerInfoAction(form.ssn, form.phone, true);
    }

    if (onConfirm) {
      onConfirm();
    } else {
      console.log(window.location.pathname + " " + params);
      pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
    }
  }

  const validInput =
    phone &&
    phone.replace(/\s+/g, "").length === 8 &&
    (phone.startsWith("4") || phone.startsWith("9"));

  const validateInput = (mobileNumber) => {
    let errors = [];
    if (!(mobileNumber?.startsWith("4") || mobileNumber?.startsWith("9"))) {
      errors.push("*Mobilnummeret må starte på 4 eller 9.");
    }
    if (mobileNumber?.replace(/\s+/g, "").length !== 8) {
      errors.push("*Mobilnummeret må være 8 tegn langt.");
    }
    setValidationError(errors.length < 1 ? null : errors.join("\n"));
  };

  return (
    <FormPageContainer
      className={`${styles.container} ${IsFKTheme && styles.containerFK}`}
      onBack={onBack}
      currentStep={userId ? pages.mobile + pages.summary : null}
    >
      {tooYoungToOrder ? (
        <>
          <header className={`${styles.header} fallIn-`}>
            <h1>{`Man må være over 18 år gammel for å bestille`}</h1>
          </header>
          <div className={`frameImageContainer`}>
            <img className={`frameImage formPage-background fade-`} src={FKMascot} alt={"mascot"} />
          </div>
        </>
      ) : (
        <>
          <header className={`${styles.header} fallIn-`}>
            <h1>
              {user?.firstName
                ? `Hva er ${user.firstName} sitt telefonnummer?`
                : "Hva er ditt mobilnummer?"}
            </h1>
          </header>
          {IsFKTheme && (
            <div className={`frameImageContainer`}>
              <img
                className={`frameImage formPage-background fade-`}
                src={FKMobileMascot}
                alt={"phone"}
              />
            </div>
          )}
          <div className={"slide"}>
            {isMobile && !userId && (
              <div style={{ textAlign: "center", marginBottom: "10px" }}>
                Vi trenger dette siden du skal stå som eier, og er juridisk og økonomisk ansvarlig
                for abonnementet hos oss.
              </div>
            )}
            <SimpleInput
              className={styles.field}
              type={"phone"}
              name={"phone"}
              value={phone}
              label={userId ? "Eksisterende mobilnr." : "Telefonnummer"}
              onChange={handleChange}
              placeholder={userId ? "Mobilnummer" : "Ditt nummer"}
              showWhiteBox
              autofocus
              focusDelay={pageStepAnimationDuration}
              validationErrorMessage={validationError}
              onBlur={(e) => validateInput(e.target.value)}
            />
            <button
              className={"btnPrimary " + styles.btn}
              disabled={!validInput || alreadyActive || loading}
              onClick={handleConfirm}
            >
              {loading ? <Spinner size="sm" /> : "Bekreft"}
            </button>
          </div>
          <AlreadyActiveDialog
            open={showDialog}
            handleClose={() => setShowDialog(false)}
            phoneNr={phone}
          />
        </>
      )}
    </FormPageContainer>
  );
}

export default connect(formAndUserSelector)(Phone);
