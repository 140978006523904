import update from "immutability-helper";
import {
  SUBMIT_TOBB_FORM_SUCCESS,
  SUBMIT_TOBB_FORM,
  SUBMIT_TOBB_FORM_ERROR,
  SET_TOBB_ID
} from "../Actions/TobbActions/TobbActionTypes";
import invoiceReducer from "./InvoiceReducer";
import { SET_TRUMF_ID } from "../Actions/TrumfActions/TrumfActionTypes";

const initialState = {
  isLoading: false,
  isSubmitting: false,
  submitSuccessful: false,
  submitError: null,
  tobbId: null
};
const tobbReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOBB_ID:
      return update(state, {
        isLoading: { $set: true },
        tobbId: { $set: action.tobbId }
      });

    case SUBMIT_TOBB_FORM:
      return update(state, {
        isLoading: { $set: true },
        isSubmitting: { $set: true },
        form: { $set: action.form },
        tobbId: { $set: action.tobbId }
      });

    case SUBMIT_TOBB_FORM_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        submitSuccessful: { $set: true },
        isSubmitting: { $set: false },
        form: { $set: action.form },
        tobbId: { $set: action.tobbId }
      });

    case SUBMIT_TOBB_FORM_ERROR:
      return update(state, {
        isLoading: { $set: false },
        submitSuccessful: { $set: false },
        isSubmitting: { $set: false },
        submitError: { $set: action.error }
      });

    default:
      return state;
  }
};
export default tobbReducer;
