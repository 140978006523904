import React from "react";
import { IsFKTheme } from "../../../Utils/Themes";
import styles from "./PowerCard.module.scss";
import CheckBox from "../../CheckBox/CheckBox";
import CheckBoxV2 from "../../CheckBox/CheckBoxV2";
import { FormControlLabel, Checkbox, checkboxClasses } from "@mui/material";
import clsx from "clsx";

function Sticker({ text, color }) {
  return (
    <div className={clsx(styles.sticker)} style={{ color: "white", backgroundColor: color }}>
      {text}
    </div>
  );
}

function Essentials({ title, stickerColor, price, pricePostfix, priceSubtext, image }) {
  return (
    <div className={styles.essentials}>
      <div className={styles.priceContainer}>
        <Sticker text={title} color={stickerColor} />
        <div>
          <span className={styles.price}>{price},- </span>
          <span className={styles.pricePostFix}>{pricePostfix}</span>
        </div>
        {priceSubtext && <div className={styles.subtext}>{priceSubtext}</div>}
      </div>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={image} alt={"bilde"} />
      </div>
    </div>
  );
}

function PowerCard({
  title,
  stickerColor,
  price,
  priceSubtext,
  ctaText,
  ctaAction,
  description,
  icon,
  style,
  terms
}) {
  return (
    <div style={style} className={`${styles.container}`}>
      <Essentials
        title={title}
        stickerColor={stickerColor}
        price={price}
        pricePostfix={"/mnd"}
        priceSubtext={priceSubtext}
        image={icon}
      />
      <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
      <button
        className={clsx("btnPrimary", styles.cta)}
        dangerouslySetInnerHTML={{ __html: ctaText }}
        onClick={ctaAction}
      ></button>
      {terms && <div className={styles.terms}>{terms}</div>}
    </div>
  );
}

export default PowerCard;
