import React from "react";
import FormPageContainer from "../../FormPageContainer/FormPageContainer";
import WhiteButtonList from "../../../ButtonList/WhiteButtonList";
import { IsFKTheme } from "../../../../Utils/Themes";
import MobileMascotSmile from "../../../../assets/Images/FK/Mobil/MobileMascotSmile.svg";
import { connect } from "react-redux";
import { formAndUserSelector } from "../../../../ReduxModules/Selectors/SaleSelectors";
import saleActionDispatchers from "../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import pageStepActionDispatchers from "../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { getMobileExtraProductSteps, pages } from "../../../../Utils/CustomerFormHelper";
import { useParams } from "react-router-dom";
import { MobileServicePrices } from "../../../../Utils/Prices";

function SelectMobileNumber({ onConfirm, onBack, isMobile, userId, user, lastUserStep }) {
  const params = useParams();
  function selectCreateNewNumber(value) {
    if (userId) {
      saleActionDispatchers.updateUser(userId, { createNewNumber: value, phone: "" });
    } else {
      saleActionDispatchers.updateFormAction({ createNewNumber: value });
    }
    const mobileNumberPage =
      (isMobile ? pages.mobile + (userId ? pages.addUser : "") : "") + pages.mobileNumber;
    if (isMobile) {
      pageStepActionDispatchers.removeSteps([mobileNumberPage]);
    }
    if (!value) {
      pageStepActionDispatchers.addAndGoToNextStep(
        window.location.pathname,
        mobileNumberPage,
        params
      );
    } else {
      const isLastStep =
        lastUserStep === pages.mobile + pages.addUser + pages.mobileNumber ||
        lastUserStep === pages.mobile + pages.addUser + pages.mobileSelectNumber;
      if (userId && isLastStep) {
        saleActionDispatchers.updateUser(userId, { completed: true });
      }
      if (onConfirm) {
        onConfirm();
      } else {
        pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
      }
    }
  }
  return (
    <FormPageContainer onBack={onBack} currentStep={userId ? pages.mobile + pages.summary : null}>
      <h1 className={"fallIn-"}>
        {user?.isOwner === false
          ? `Hvilket nummer skal ${user?.firstName} bruke?`
          : "Hvilket nummer vil du bruke?"}
      </h1>
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img
            className={"frameImage formPage-background fade-"}
            src={MobileMascotSmile}
            alt={"mobileMascot"}
          />
        </div>
      )}
      <div className={"slide"}>
        <WhiteButtonList
          buttons={[
            {
              content: (
                <div className={"title"}>
                  {userId ? "Bruk eksisterende nummer" : "Bruk mitt eksisterende nummer"}
                </div>
              ),
              onClick: () => selectCreateNewNumber(false)
            },
            {
              content: (
                <div>
                  <div className={"title"}>Opprett nytt nummer</div>
                  <div className={"priceTag"}>{MobileServicePrices.NewNumber}kr</div>
                </div>
              ),
              onClick: () => selectCreateNewNumber(true)
            }
          ]}
        />
      </div>
    </FormPageContainer>
  );
}

export default connect(formAndUserSelector)(SelectMobileNumber);
