import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import * as _ from "lodash";

const passphrase = process.env.REACT_APP_DECRYPTION_KEY || "1337lord";
export function getQueryStrings(search) {
  if (!search) {
    return;
  }
  const decodedString = decodeURIComponent(search);
  const hashes = decodedString.slice(decodedString.indexOf(`?`) + 1).split(`&`);
  const queryStrings = {};
  for (let i = 0; i < hashes.length; i++) {
    const query = hashes[i].split("=");
    const key = query[0];
    let value = query[1];
    if (value.startsWith("[")) {
      value = value.substring(1, value.length - 1);
      if (value.startsWith("{")) {
        let objects = value.substring(0, value.length - 1).split("}");
        value = objects.map((q) => {
          let string = q.substring(q.startsWith(",") ? 2 : 1, q.length);
          let params = string.split(";");
          let derp = {};
          for (let i = 0; i < params.length; i++) {
            const keyAndValue = params[i].split(":");
            let pKey = keyAndValue[0];
            let pValue = keyAndValue[1];
            if (pValue && pValue.startsWith("[")) {
              pValue = pValue.substring(1, pValue.length - 1).split(",");
            }
            if (!_.isEmpty(pKey) && !_.isEmpty(pValue)) {
              derp[pKey] = pValue;
            }
          }
          return derp;
        });
      } else {
        value = value.substring(1, value.length - 1).split(",");
      }
    }
    queryStrings[key] = value;
  }
  if (queryStrings.hash) {
    const decryptedHash = decrypt(queryStrings.hash);
    queryStrings.hash = null;
    return { ...queryStrings, ...getQueryStrings(decryptedHash) };
  }
  return queryStrings;
}

export const encrypt = (text, key = passphrase) => {
  try {
    return AES.encrypt(text, key).toString();
  } catch (e) {
    console.log(`Failed to encrypt ${text}. Error: ${e.message}`);
  }
};

export const decrypt = (ciphertext, key = passphrase) => {
  try {
    const bytes = AES.decrypt(ciphertext, key);
    const originalText = bytes.toString(Utf8);
    return originalText;
  } catch (e) {
    console.log(`Failed to decrypt ${ciphertext}. Error: ${e.message}`);
  }
};

export function convertToQueryString(object) {
  if (!object) {
    return "";
  }
  const keys = Object.keys(object);
  let queryString = "";
  keys.forEach((key) => {
    if (object[key] && _.isString(object[key])) {
      if (queryString === "") {
        queryString += `?${key}=${object[key]}`;
      } else {
        queryString += `&${key}=${object[key]}`;
      }
    }
  });
  return queryString;
}

export function convertToEncryptedQueryString(object) {
  return "?hash=" + encrypt(convertToQueryString(object));
}
