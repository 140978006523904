import {
  GET_CUSTOMER_BY_HASH_ID,
  GET_CUSTOMER_BY_HASH_ID_SUCCESS,
  GET_CUSTOMER_BY_HASH_ID_ERROR,
  UPDATE_ORDER_METERNUMBER,
  SUBMIT_METERNUMBER_UPDATE,
  SUBMIT_METERNUMBER_UPDATE_SUCCESS,
  SUBMIT_METERNUMBER_UPDATE_ERROR,
  GET_EXISTING_CUSTOMER_DATA_BY_SSN,
  GET_EXISTING_CUSTOMER_DATA_BY_SSN_SUCCESS,
  GET_EXISTING_CUSTOMER_DATA_BY_SSN_ERROR,
  GET_INVOICE_INFO,
  GET_INVOICE_INFO_ERROR,
  GET_INVOICE_INFO_SUCCESS
} from "./CustomerActionTypes";

export const getCustomerByHashId = (hashId) => {
  return {
    type: GET_CUSTOMER_BY_HASH_ID,
    hashId
  };
};

export const getCustomerByHashIdSuccess = (customer) => {
  return {
    type: GET_CUSTOMER_BY_HASH_ID_SUCCESS,
    customer
  };
};

export const getCustomerByHashIdError = (error) => {
  return {
    type: GET_CUSTOMER_BY_HASH_ID_ERROR,
    error
  };
};

export const updateOrderMeterNumber = (orderId, meterNumber) => {
  return {
    type: UPDATE_ORDER_METERNUMBER,
    orderId,
    meterNumber
  };
};

export const submitMeterNumberUpdate = (orders, customerId) => {
  return {
    type: SUBMIT_METERNUMBER_UPDATE,
    orders,
    customerId
  };
};

export const submitMeterNumberUpdateSuccess = () => {
  return {
    type: SUBMIT_METERNUMBER_UPDATE_SUCCESS
  };
};

export const submitMeterNumberUpdateError = (error) => {
  return {
    type: SUBMIT_METERNUMBER_UPDATE_ERROR,
    error
  };
};

export const getExistingCustomerDataBySsn = (ssn) => {
  return {
    type: GET_EXISTING_CUSTOMER_DATA_BY_SSN,
    ssn
  };
};

export const getExistingCustomerDataBySsnSuccess = (
  deliveryPoints,
  subscriptionConsent,
  invoiceInfo,
  email,
  isActiveCustomer,
  customerLastActiveProduct
) => {
  return {
    type: GET_EXISTING_CUSTOMER_DATA_BY_SSN_SUCCESS,
    deliveryPoints,
    subscriptionConsent,
    invoiceInfo,
    email,
    isActiveCustomer,
    customerLastActiveProduct
  };
};

export const getExistingCustomerDataBySsnError = (error) => {
  return {
    type: GET_EXISTING_CUSTOMER_DATA_BY_SSN_ERROR,
    error
  };
};

export const getInvoiceInfo = (momentId) => {
  return {
    type: GET_INVOICE_INFO,
    momentId
  };
};

export const getInvoiceInfoSuccess = (invoiceInfo) => {
  return {
    type: GET_INVOICE_INFO_SUCCESS,
    invoiceInfo
  };
};

export const getInvoiceInfoError = (error) => {
  return {
    type: GET_INVOICE_INFO_ERROR,
    error
  };
};
