import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bankIdSelector } from "../../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../../FormPageContainer/FormPageContainer";
import Loader from "../../FKLoader/Loader";
import saleActionDispatchers from "../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { getQueryStrings } from "../../../../Utils/UrlParser";
import { pages } from "../../../../Utils/CustomerFormHelper";
import styles from "../../../Trumf/Trumf.module.scss";
import { customerServiceLink } from "../../../../Utils/ExternalLinks";
import { history } from "../../../../App";
import customerActionDispatchers from "../../../../ReduxModules/Actions/CustomerActions/CustomerActionDispatchers";
import pageStepActionDispatchers from "../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { SALE_CONTEXT } from "../../../../ReduxModules/Sagas/SaleSaga";
import { getBankIdToken } from "../../../../Utils/AxiosFunctions";

function BankIdCallback({ location, isLoading, isBankIdComplete, error, ssn, phone, saleContext }) {
  const isMobile = saleContext === SALE_CONTEXT.mobile;
  useEffect(() => {
    const queryStrings = getQueryStrings(location.search);
    const state = JSON.parse(window.sessionStorage.getItem("state"));
    const nonce = window.sessionStorage.getItem("nonce");
    const stateKey = window.sessionStorage.getItem("stateKey");
    if (decodeURI(queryStrings.nonce) !== nonce && decodeURI(queryStrings.state) !== stateKey) {
      // todo: Go to error page;
    }
    saleActionDispatchers.setStateAction(state);
    if (
      queryStrings.error === "access_denied" &&
      queryStrings.subtype === "authentication_canceled"
    ) {
      pageStepActionDispatchers.goToFirstStep(true);
    } else {
      saleActionDispatchers.getBankIdCustomerInfoAction(
        queryStrings.code,
        queryStrings.error,
        queryStrings.subtype
      );
    }
  }, []);

  const selectManualIdentification = () => {
    clearAnyExtraIdentificationSteps();
    if (isMobile) {
      pageStepActionDispatchers.addAndGoToNextStep(
        pages.mobile,
        pages.mobile + pages.manualIdentification
      );
      pageStepActionDispatchers.addStep(pages.mobile, pages.mobile + pages.customerName);
    } else {
      pageStepActionDispatchers.addAndGoToNextStep(pages.home, pages.manualIdentification);
      pageStepActionDispatchers.addStep(pages.manualIdentification, pages.customerName);
    }
  };

  function clearAnyExtraIdentificationSteps() {
    if (isMobile) {
      pageStepActionDispatchers.removeSteps([
        pages.mobile + pages.manualIdentification,
        pages.mobile + pages.customerName
      ]);
    } else {
      pageStepActionDispatchers.removeSteps([
        pages.mobileNumber,
        pages.manualIdentification,
        pages.customerName
      ]);
    }
  }
  useEffect(() => {
    if (isBankIdComplete) {
      customerActionDispatchers.getExistingCustomerDataBySsn(ssn);
      if (phone) {
        saleActionDispatchers.getCustomerInfoAction(ssn, phone, true);
      }
      if (isMobile) {
        pageStepActionDispatchers.goToNextStep(pages.mobile);
      } else {
        if (phone) {
          pageStepActionDispatchers.goToNextStep(pages.home);
        } else {
          pageStepActionDispatchers.addStep(pages.home, pages.invoice);
          pageStepActionDispatchers.addAndGoToNextStep(pages.home, pages.mobileNumber);
        }
      }
    }
  }, [isBankIdComplete]);

  const handleTryAgain = () => {
    if (isMobile) {
      history.push(pages.mobile);
    } else {
      history.push(pages.home);
    }
  };
  return (
    <FormPageContainer hideFooter>
      {error ? (
        <div>
          <div className={styles.error}>
            <h1>Noe gikk galt med innloggingen din.</h1>
            <h5>Bruk manuell identifisering, prøv igjen eller kontakt kundeservice...</h5>
            <div>
              Kundeservice:{" "}
              <a
                href={customerServiceLink}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.errorLink}
              >
                {customerServiceLink}
              </a>
            </div>
          </div>
          <button className={"btnPrimary " + styles.button} onClick={selectManualIdentification}>
            Bruk manuell identifisering
          </button>
          <button className={"btnSecondary " + styles.tryAgain} onClick={handleTryAgain}>
            Prøv igjen
          </button>
        </div>
      ) : (
        <Loader text={"Bekrefter BankId innlogging.."} />
      )}
    </FormPageContainer>
  );
}

export default connect(bankIdSelector)(BankIdCallback);
