import { IsFKTheme } from "./Themes";
import * as _ from "lodash";
import config from "../config";
import { pages } from "./CustomerFormHelper";

export const TKTestNorwegianPower = "89";
export const TKProdNorwegianPower = "73";
export const FKTestNorwegianPower = "217";
export const FKProdNorwegianPower = "123";

export const TKTestTripleWarranty = "85";
export const TKProdTripleWarranty = "71";
export const FKTestTripleWarranty = "208";
export const FKProdTripleWarranty = "124";
export const FKTestTrumf = "224";
export const FKProdTrumf = "249";

export const PowerDiscount = 1;
export const TrumfBonusType = 2;
export const SasBonusType = 3;

export function getPageFromProductId(productId) {
  switch (productId) {
    case FKTestNorwegianPower:
    case FKProdNorwegianPower:
    case TKTestNorwegianPower:
    case TKProdNorwegianPower:
      return pages.norwegianPower;
    case FKTestTripleWarranty:
    case FKProdTripleWarranty:
    case TKTestTripleWarranty:
    case TKProdTripleWarranty:
      return pages.tripleWarranty;
    case FKTestTrumf:
    case FKProdTrumf:
      return pages.trumf;
    default:
      return null;
  }
}

export function getPageFromBonusTypeId(bonusTypeId) {
  switch (bonusTypeId) {
    case SasBonusType:
      return pages.sasEuroBonus;
    case TrumfBonusType:
      return pages.trumf;
    case PowerDiscount:
    default:
      return null;
  }
}

export function getIdFromPathName(pathName) {
  if (config.env === "prod") {
    switch (pathName) {
      case pages.norwegianPower:
        return IsFKTheme ? FKProdNorwegianPower : TKProdNorwegianPower;
      case pages.tripleWarranty:
        return IsFKTheme ? FKProdTripleWarranty : TKProdTripleWarranty;
      case pages.trumf:
        return FKProdTrumf;
      default:
        return null;
    }
  } else {
    switch (pathName) {
      case pages.norwegianPower:
        return IsFKTheme ? FKTestNorwegianPower : TKTestNorwegianPower;
      case pages.tripleWarranty:
        return IsFKTheme ? FKTestTripleWarranty : TKTestTripleWarranty;
      case pages.trumf:
        return FKTestTrumf;
      default:
        return null;
    }
  }
}

export function getTrumfProductId() {
  return config.env === "prod" ? FKProdTrumf : FKTestTrumf;
}

export function getNorwegianPowerId() {
  return config.env === "prod" ? FKProdNorwegianPower : FKTestNorwegianPower;
}

export function getTripleWarrantyId() {
  return config.env === "prod" ? FKProdTripleWarranty : FKTestTripleWarranty;
}

export function isNorwegianPowerProduct(productId) {
  return getPageFromProductId(productId) === pages.norwegianPower;
}
export function isTripleWarrantyProduct(productId) {
  return getPageFromProductId(productId) === pages.tripleWarranty;
}
// gets the next product page depending on currentPage
// currentPage = additionalProductPages[key]
// whiteListedPofProductIds = array of pofProductIds, used as whitelist
export function getNextProductPage(currentPage, whiteListedPofProductIds) {
  if (_.isEmpty(whiteListedPofProductIds)) {
    return;
  }
  const recommendedArray = whiteListedPofProductIds.map((rp) => {
    return getPageFromProductId(rp);
  });
  if (!currentPage) {
    return recommendedArray[0];
  }
  const currentIndex = recommendedArray.findIndex((r) => r === currentPage);
  if (currentIndex === recommendedArray.length - 1) {
    return null;
  } else {
    return recommendedArray[currentIndex + 1];
  }
}

export function getPrevProductPage(currentPage, whiteListedPofProductIds) {
  if (_.isEmpty(whiteListedPofProductIds)) {
    return;
  }
  const recommendedArray = whiteListedPofProductIds.map((rp) => {
    return getPageFromProductId(rp);
  });
  const currentIndex = recommendedArray.findIndex((r) => r === currentPage);
  if (currentIndex === 0) {
    return null;
  } else if (currentIndex === -1) {
    return recommendedArray[recommendedArray.length - 1];
  } else {
    return recommendedArray[currentIndex - 1];
  }
}
