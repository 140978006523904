import React, { useState } from "react";
import styles from "./PowerMeter.module.scss";
import SimpleInput from "../../../../SimpleInput/SimpleInput";
import { IsFKTheme } from "../../../../../Utils/Themes";
import FormPageContainer from "../../../FormPageContainer/FormPageContainer";
import { Alert, AlertTitle } from "@mui/material";
import TKPowerMeterOutline from "../../../../../assets/Images/TK/PowerMeterNumberOutlined.svg";
import { pages, pageStepAnimationDuration } from "../../../../../Utils/CustomerFormHelper";
import PowerMeter from "../../../../../assets/Images/FK/PowerMeterZoomFramed.svg";

function PowerMeterNumber({
  name,
  onChange,
  deliveryPoints,
  deliveryPoint,
  onConfirm,
  onBack,
  isValid
}) {
  const [error, setError] = useState(false);
  const handleConfirm = () => {
    if (
      !deliveryPoints.find(
        (dp) =>
          dp.id !== deliveryPoint.id && dp.meterIdentification === deliveryPoint.meterIdentification
      )
    ) {
      onConfirm();
    } else {
      setError(true);
    }
  };
  return (
    <FormPageContainer onBack={onBack} currentStep={pages.deliveryPoints}>
      <div className={(IsFKTheme && styles.containerFK) || ""}>
        <header className={`${styles.header} fallIn-`}>
          <h1>Supert! Du finner nummeret rett under strekkoden.</h1>
        </header>
        {IsFKTheme ? (
          <div className={"frameImageContainer"}>
            <img
              className={"frameImage formPage-background fade-"}
              src={PowerMeter}
              alt="PowerMeter"
            />
          </div>
        ) : (
          <img
            src={TKPowerMeterOutline}
            alt={"Strømmåler uthevet"}
            className={styles.TKPowerMeterOutline}
          />
        )}
        <div className={"slide"}>
          <SimpleInput
            className={styles.powerMeterInput}
            name={name || "meterNumber"}
            label={"Målernummer"}
            value={deliveryPoint.meterIdentification}
            type={"meterNumber"}
            onChange={onChange}
            placeholder={"Ditt målernummer"}
            showErase={deliveryPoint.meterIdentification !== ""}
            showWhiteBox
            autofocus
            focusDelay={pageStepAnimationDuration}
          />
          {error && (
            <Alert style={{ marginBottom: "15px" }} severity="error">
              <AlertTitle>Kunne ikke sende inn målernummer</AlertTitle>Målernummeret er allerede
              lagt til
            </Alert>
          )}
          <button
            className={"btnPrimary fadeDownButton " + styles.btn}
            disabled={!isValid}
            onClick={handleConfirm}
          >
            Bekreft
          </button>
        </div>
      </div>
    </FormPageContainer>
  );
}

export default PowerMeterNumber;
