import React, { useEffect } from "react";
import { AuthProvider, hasAuthParams, useAuth } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

const AuthWrapper = (props) => {
  // noinspection JSUnusedGlobalSymbols
  const oidcConfig = {
    authority: "https://auth.fjordkraft.no",
    client_id: "salesmodule",
    response_type: "code",
    grant_type: "authorization_code",
    automaticSilentRenew: true,
    scope: `openid profile fullname roles`,
    redirect_uri: window.location.origin.toString() + "/urlencoder",
    post_logout_redirect_uri: window.location.origin.toString() + "/urlencoder",
    userStore: new WebStorageStateStore({ store: localStorage }), // Store in localstorage to persist login across sessions!
    onSigninCallback: () => {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  };

  return (
    <AuthProvider {...oidcConfig}>
      <ProtectedPage>{props.children}</ProtectedPage>
    </AuthProvider>
  );
};

const ProtectedPage = (props) => {
  const auth = useAuth();

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    if (
      auth.user.profile["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] ===
        "IsReodorUser" ||
      (Array.isArray(
        auth.user.profile["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
      ) &&
        auth.user.profile["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"].includes(
          "IsReodorUser"
        ))
    ) {
      return (
        <>
          <div
            className="d-flex align-items-center"
            style={{ position: "absolute", right: 0, zIndex: 500, float: "right" }}
          >
            <Typography>{auth.user.profile.name} | </Typography>
            <Button
              variant={"text"}
              onClick={() => auth.signoutRedirect()}
              style={{ color: "white" }}
            >
              Logg ut
            </Button>
          </div>
          {props.children}
        </>
      );
    } else {
      return (
        <div>
          <Typography>
            Bruker mangler intern rolle{" "}
            <Button
              variant={"text"}
              onClick={() => auth.signoutRedirect()}
              style={{ color: "white" }}
            >
              Logg ut
            </Button>
          </Typography>
        </div>
      );
    }
  }

  return (
    <div>
      Kunne ikke autentisere <button onClick={() => auth.signinRedirect()}>Logg inn</button>
    </div>
  );
};

export default AuthWrapper;
