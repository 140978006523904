export const UPDATE_SMS_ACCEPT_FORM = "UPDATE_SMS_ACCEPT_FORM";

export const GET_SMS_ACCEPT_CUSTOMER_INFO = "GET_SMS_ACCEPT_CUSTOMER_INFO";
export const GET_SMS_ACCEPT_CUSTOMER_INFO_SUCCESS = "GET_SMS_ACCEPT_CUSTOMER_INFO_SUCCESS";
export const GET_SMS_ACCEPT_CUSTOMER_INFO_ERROR = "GET_SMS_ACCEPT_CUSTOMER_INFO_ERROR";

export const GET_SMS_ACCEPT_ADDON_PRODUCTS = "GET_SMS_ACCEPT_ADDON_PRODUCTS";
export const GET_SMS_ACCEPT_ADDON_PRODUCTS_SUCCESS = "GET_SMS_ACCEPT_ADDON_PRODUCTS_SUCCESS";
export const GET_SMS_ACCEPT_ADDON_PRODUCTS_ERROR = "GET_SMS_ACCEPT_ADDON_PRODUCTS_ERROR";

export const GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT = "GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT";
export const GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT_SUCCESS =
  "GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT_SUCCESS";
export const GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT_ERROR = "GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT_ERROR";

export const SUBMIT_SMS_ACCEPT_FORM = "SUBMIT_SMS_ACCEPT_FORM";
export const SUBMIT_SMS_ACCEPT_FORM_SUCCESS = "SUBMIT_SMS_ACCEPT_FORM_SUCCESS";
export const SUBMIT_SMS_ACCEPT_FORM_ERROR = "SUBMIT_SMS_ACCEPT_FORM_ERROR";

export const SMS_ACCEPT_SUBMIT_CONSENT = "SMS_ACCEPT_SUBMIT_CONSENT";
export const SMS_ACCEPT_SUBMIT_CONSENT_SUCCESS = "SMS_ACCEPT_SUBMIT_CONSENT_SUCCESS";
export const SMS_ACCEPT_SUBMIT_CONSENT_ERROR = "SMS_ACCEPT_SUBMIT_CONSENT_ERROR";

export const ADD_ACCEPTED_ORDER = "ADD_ACCEPTED_ORDER";
export const REMOVE_ORDER_ACCEPT = "REMOVE_ORDER_ACCEPT";

export const GO_BACK = "GO_BACK";
export const UPDATE_HASHED_CUSTOMER_ID = "UPDATE_HASHED_CUSTOMER_ID";
export const GET_PRICE_SUMMARY = "GET_PRICE_SUMMARY";
export const GET_PRICE_SUMMARY_SUCCESS = "GET_PRICE_SUMMARY_SUCCESS";
export const GET_PRICE_SUMMARY_ERROR = "GET_PRICE_SUMMARY_ERROR";
