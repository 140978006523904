import { bindActionCreators } from "redux";
import store from "../../Store/Store";
import {
  getCustomerInfoAction,
  getElhubDataAction,
  updateFormAction,
  updateNewDeliveryPointAction,
  selectDeliveryPoint,
  addDeliveryPoints,
  completeLoadOfInvoiceElhubDataAction,
  submitFormAction,
  reloadAppAndUpdateCacheAction,
  getAddonProductAction,
  setStateAction,
  parseQueryStringAction,
  initializeDeliveryPointDataAction,
  updateDeliveryPointAction,
  setCurrentDeliveryPointId,
  getProductInfo,
  getBankIdCustomerInfoAction,
  updateTrumfId,
  setFormFromSmsMoveInInfo,
  setFormFromInternalMoveInInfo,
  setProducthubInfo,
  setHasChangedProduct,
  selectAddonProduct,
  startUserSteps,
  removeUser,
  updateUser,
  setSessionId,
  startDeliveryPointSteps,
  startDeliveryPointStepsBedrift,
  initializeDeliveryPoints,
  submitMobileFormAction,
  submitBedriftFormAction
} from "./SaleActions";

const actions = {
  getCustomerInfoAction,
  getElhubDataAction,
  updateFormAction,
  updateNewDeliveryPointAction,
  selectDeliveryPoint,
  addDeliveryPoints,
  completeLoadOfInvoiceElhubDataAction,
  submitFormAction,
  reloadAppAndUpdateCacheAction,
  getAddonProductAction,
  setStateAction,
  parseQueryStringAction,
  initializeDeliveryPointDataAction,
  initializeDeliveryPoints,
  updateDeliveryPointAction,
  setCurrentDeliveryPointId,
  getProductInfo,
  getBankIdCustomerInfoAction,
  updateTrumfId,
  setFormFromSmsMoveInInfo,
  setFormFromInternalMoveInInfo,
  setProducthubInfo,
  setHasChangedProduct,
  selectAddonProduct,
  startUserSteps,
  removeUser,
  updateUser,
  setSessionId,
  startDeliveryPointSteps,
  startDeliveryPointStepsBedrift,
  submitMobileFormAction,
  submitBedriftFormAction
};

const saleActionDispatchers = bindActionCreators(actions, store.dispatch);

export default saleActionDispatchers;
