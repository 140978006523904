export const SET_STATE = "SET_STATE";
export const UPDATE_FORM = "UPDATE_FORM";
export const UPDATE_NEW_DELIVERY_POINT = "UPDATE_NEW_DELIVERY_POINT";
export const UPDATE_DELIVERY_POINT = "UPDATE_DELIVERY_POINT";
export const SET_CURRENT_DELIVERY_POINT_ID = "SET_CURRENT_DELIVERY_POINT_ID";
export const ADD_DELIVERY_POINTS = "ADD_DELIVERY_POINTS";
export const SELECT_DELIVERY_POINT = "SELECT_DELIVERY_POINT";
export const COMPLETE_LOAD_OF_INVOICE_ELHUB_DATA = "COMPLETE_LOAD_OF_INVOICE_ELHUB_DATA";
export const RELOAD_APP_AND_UPDATE_CACHE = "RELOAD_APP_AND_UPDATE_CACHE";
export const UPDATE_TRUMF_ID = "UPDATE_TRUMF_ID";
export const SET_PRODUCT_HUB_INFO = "SET_PRODUCT_HUB_INFO";
export const SET_HAS_CHANGED_PRODUCT = "SET_HAS_CHANGED_PRODUCT";
export const SELECT_ADDON_PRODUCT = "SELECT_ADDON_PRODUCT";
export const SET_E_INVOICE_REFERENCE = "SET_E_INVOICE_REFERENCE";
export const SET_SESSION_ID = "SET_SESSION_ID";
export const START_DELIVERY_POINT_STEPS = "START_DELIVERY_POINT_STEPS";

// Mobile
export const START_FAMILY_MEMBER_STEPS = "START_FAMILY_MEMBER_STEPS";
export const REMOVE_FAMILY_MEMBER = "REMOVE_FAMILY_MEMBER";
export const UPDATE_FAMILY_MEMBER = "UPDATE_FAMILY_MEMBER";

//Move in
export const SET_FORM_FROM_SMS_MOVE_IN_INFO = "SET_FORM_FROM_SMS_MOVE_IN_INFO";
export const SET_FORM_FROM_INTERNAL_MOVE_IN_INFO = "SET_FORM_FROM_INTERNAL_MOVE_IN_INFO";

// ASYNC ACTION TYPES
export const PARSE_QUERY_STRING = "PARSE_QUERY_STRING";
export const PARSE_QUERY_STRING_SUCCESS = "PARSE_QUERY_STRING_SUCCESS";
export const PARSE_QUERY_STRING_ERROR = "PARSE_QUERY_STRING_ERROR";

export const GET_CUSTOMER_INFO = "GET_CUSTOMER_INFO";
export const GET_CUSTOMER_INFO_SUCCESS = "GET_CUSTOMER_INFO_SUCCESS";
export const GET_CUSTOMER_INFO_ERROR = "GET_CUSTOMER_INFO_ERROR";

export const INITIALIZE_DELIVERYPOINT_DATA = "INITIALIZE_DELIVERYPOINT_DATA";
export const INITIALIZE_DELIVERYPOINT_DATA_SUCCESS = "INITIALIZE_DELIVERYPOINT_DATA_SUCCESS";
export const INITIALIZE_DELIVERYPOINT_DATA_ERROR = "INITIALIZE_DELIVERYPOINT_DATA_ERROR";

export const GET_ELHUB_DATA = "GET_ELHUB_DATA";
export const GET_ELHUB_DATA_SUCCESS = "GET_ELHUB_DATA_SUCCESS";
export const GET_ELHUB_DATA_ERROR = "GET_ELHUB_DATA_ERROR";

export const SUBMIT_FORM = "SUBMIT_FORM";
export const SUBMIT_FORM_SUCCESS = "SUBMIT_FORM_SUCCESS";
export const SUBMIT_FORM_ERROR = "SUBMIT_FORM_ERROR";

export const SUBMIT_MOBILE_FORM = "SUBMIT_MOBILE_FORM";
export const SUBMIT_MOBILE_FORM_SUCCESS = "SUBMIT_MOBILE_FORM_SUCCESS";
export const SUBMIT_MOBILE_FORM_ERROR = "SUBMIT_MOBILE_FORM_ERROR";

export const SUBMIT_BEDRIFT_FORM = "SUBMIT_BEDRIFT_FORM";
export const SUBMIT_BEDRIFT_FORM_SUCCESS = "SUBMIT_BEDRIFT_FORM_SUCCESS";
export const SUBMIT_BEDRIFT_FORM_ERROR = "SUBMIT_BEDRIFT_FORM_ERROR";

export const GET_ADDONPRODUCT = "GET_ADDONPRODUCT";
export const GET_ADDONPRODUCT_SUCCESS = "GET_ADDONPRODUCT_SUCCESS";
export const GET_ADDONPRODUCT_ERROR = "GET_ADDONPRODUCT_ERROR";

export const GET_PRODUCT_INFO = "GET_PRODUCT_INFO";
export const GET_PRODUCT_INFO_SUCCESS = "GET_PRODUCT_INFO_SUCCESS";
export const GET_PRODUCT_INFO_ERROR = "GET_PRODUCT_INFO_ERROR";

export const GET_BANK_ID_TOKEN_RESPONSE = "GET_BANK_ID_TOKEN_RESPONSE";
export const GET_BANK_ID_CUSTOMER_INFO_SUCCESS = "GET_BANK_ID_CUSTOMER_INFO_SUCCESS";
export const GET_BANK_ID_CUSTOMER_INFO_ERROR = "GET_BANK_ID_CUSTOMER_INFO_ERROR";
export const GET_MIN_SIDE_LOGIN_SUCCESS = "GET_MIN_SIDE_LOGIN_SUCCESS";

export const SET_TOBB_ID = "SET_TOBB_ID";
