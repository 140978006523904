import React from "react";
import { pages } from "../../../../Utils/CustomerFormHelper";
import AddUserContainer from "./AddUserContainer";

function AddUser(props) {
  const userId = props.match.params.userId;
  return <AddUserContainer userId={userId} path={pages.mobile + pages.addUser} />;
}

export default AddUser;
