export const GET_CUSTOMER_BY_HASH_ID = "GET_CUSTOMER_BY_HASH_ID";
export const GET_CUSTOMER_BY_HASH_ID_SUCCESS = "GET_CUSTOMER_BY_HASH_ID_SUCCESS";
export const GET_CUSTOMER_BY_HASH_ID_ERROR = "GET_CUSTOMER_BY_HASH_ID_ERROR";

export const SUBMIT_METERNUMBER_UPDATE = "SUBMIT_METERNUMBER_UPDATE";
export const SUBMIT_METERNUMBER_UPDATE_SUCCESS = "SUBMIT_METERNUMBER_UPDATE_SUCCESS";
export const SUBMIT_METERNUMBER_UPDATE_ERROR = "SUBMIT_METERNUMBER_UPDATE_ERROR";

export const UPDATE_ORDER_METERNUMBER = "UPDATE_ORDER_METERNUMBER";

export const GET_EXISTING_CUSTOMER_DATA_BY_SSN = "GET_EXISTING_CUSTOMER_DATA_BY_SSN";
export const GET_EXISTING_CUSTOMER_DATA_BY_SSN_SUCCESS =
  "GET_EXISTING_CUSTOMER_DATA_BY_SSN_SUCCESS";
export const GET_EXISTING_CUSTOMER_DATA_BY_SSN_ERROR = "GET_EXISTING_CUSTOMER_DATA_BY_SSN_ERROR";

export const GET_INVOICE_INFO = "GET_INVOICE_INFO";
export const GET_INVOICE_INFO_SUCCESS = "GET_INVOICE_INFO_SUCCESS";
export const GET_INVOICE_INFO_ERROR = "GET_INVOICE_INFO_ERROR";
export const ADD_SUMMARY_POWER = "ADD_SUMMARY_POWER";
export const ADD_SUMMARY_MOBILE = "ADD_SUMMARY_MOBILE";
