import styles from "./MobileProductSubtext.module.scss";
import React from "react";

function MobileProductSubtext() {
  return (
    <div className={styles.subText}>
      Fri tale, SMS og MMS inntil 10.000 stk. Egne priser gjelder for spesialnummer, bruk utenfor
      EU/EØS, bruk fra Norge til utlandet, skip, fly og satellitt. Her finner du våre{" "}
      <a
        className={styles.subTextLink}
        href={"https://www.fjordkraft.no/mobilabonnement/nye-mobilpriser/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        priser.
      </a>
      Hastighet følger Telia sitt nett og varierer etter{" "}
      <a
        className={styles.subTextLink}
        href={"https://www.fjordkraft.no/mobilabonnement/dekning/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        dekning
      </a>{" "}
      og andre forhold. For Fri Data abonnement senkes hastigheten til 3 mbit/s etter 100 GB.
    </div>
  );
}

export default MobileProductSubtext;
