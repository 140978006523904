import update from "immutability-helper";
import {
  GET_EXISTING_CUSTOMER_DATA_BY_SSN,
  GET_EXISTING_CUSTOMER_DATA_BY_SSN_ERROR,
  GET_EXISTING_CUSTOMER_DATA_BY_SSN_SUCCESS,
  GET_CUSTOMER_BY_HASH_ID,
  GET_CUSTOMER_BY_HASH_ID_ERROR,
  GET_CUSTOMER_BY_HASH_ID_SUCCESS,
  SUBMIT_METERNUMBER_UPDATE,
  SUBMIT_METERNUMBER_UPDATE_ERROR,
  SUBMIT_METERNUMBER_UPDATE_SUCCESS,
  UPDATE_ORDER_METERNUMBER
} from "../Actions/CustomerActions/CustomerActionTypes";
import { SET_STATE } from "../Actions/SaleActions/SaleActionTypes";

const initialState = {
  hashId: null,
  customer: null,
  isLoadingCustomer: false,
  isSubmittingCustomerUpdate: false,
  customerUpdateSubmitComplete: false,
  activeDeliveryPoints: [],
  subscriptionConsent: null,
  invoiceInfo: null,
  isLoadingExistingCustomerData: false,
  errorLoadingExistingCustomerData: null,
  error: null,
  sessionId: null
};

// Used to track existing customer data in orderModule/mdm/afi
const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_BY_HASH_ID:
      return update(state, {
        hashId: { $set: action.hashId },
        isLoadingCustomer: { $set: true }
      });

    case GET_CUSTOMER_BY_HASH_ID_SUCCESS:
      return update(state, {
        customer: { $set: action.customer },
        isLoadingCustomer: { $set: false }
      });

    case GET_CUSTOMER_BY_HASH_ID_ERROR:
      return update(state, {
        error: { $set: action.error },
        isLoadingCustomer: { $set: false }
      });

    case GET_EXISTING_CUSTOMER_DATA_BY_SSN:
      return update(state, {
        isLoadingExistingCustomerData: { $set: true }
      });

    case GET_EXISTING_CUSTOMER_DATA_BY_SSN_SUCCESS:
      return update(state, {
        isLoadingExistingCustomerData: { $set: false },
        activeDeliveryPoints: { $set: action.deliveryPoints },
        subscriptionConsent: { $set: action.subscriptionConsent },
        invoiceInfo: { $set: action.invoiceInfo },
        isActiveCustomer: { $set: action.isActiveCustomer },
        lastProduct: { $set: action.customerLastActiveProduct }
      });

    case GET_EXISTING_CUSTOMER_DATA_BY_SSN_ERROR:
      return update(state, {
        isLoadingExistingCustomerData: { $set: false },
        errorLoadingExistingCustomerData: { $set: action.error }
      });

    case UPDATE_ORDER_METERNUMBER:
      return update(state, {
        customer: {
          orders: {
            [state.customer.orders.findIndex((o) => o.id === action.orderId)]: {
              powerSaleData: {
                meterNumber: { $set: action.meterNumber }
              },
              isUpdated: { $set: true }
            }
          }
        }
      });

    case SUBMIT_METERNUMBER_UPDATE:
      return update(state, {
        isSubmittingCustomerUpdate: { $set: true },
        customerUpdateSubmitComplete: { $set: false }
      });

    case SUBMIT_METERNUMBER_UPDATE_SUCCESS:
      return update(state, {
        isSubmittingCustomerUpdate: { $set: false },
        customerUpdateSubmitComplete: { $set: true }
      });

    case SUBMIT_METERNUMBER_UPDATE_ERROR:
      return update(state, {
        isSubmittingCustomerUpdate: { $set: false },
        error: { $set: action.error }
      });

    case SET_STATE:
      return { ...state, ...action.state.customerState };

    default:
      return state;
  }
};

export default customerReducer;
