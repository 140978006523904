import * as _ from "lodash";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { pages, radioGroupTag } from "../../../Utils/CustomerFormHelper";
import { IsFKTheme } from "../../../Utils/Themes";
import MobileMascotSmile from "../../../assets/Images/FK/Mobil/MobileMascotSmile.svg";
import WhiteButtonList from "../../ButtonList/WhiteButtonList";
import ModalWindow from "../../Modal/Modal";
import styles from "../Mobile/ModalInfo.module.scss";
import MascotBlush from "../../../assets/Images/FK/Mobil/MascotBlush.svg";
import Icon from "../../Icon/Icon";
import { productGroupSelector } from "../../../ReduxModules/Selectors/ProductSelectors";
import { connect } from "react-redux";
import { formAndUserSelector } from "../../../ReduxModules/Selectors/SaleSelectors";

function ProductRadioGroup({ groupId, products, form, userId, user, onConfirm, onBack }) {
  const [showProductModal, setShowProductModal] = useState(false);
  const params = useParams();
  const groupTag = radioGroupTag + groupId;

  function handleClick(productId) {
    let newExtraProducts = (userId ? user.extraProducts : form.extraProducts) || [];
    newExtraProducts = newExtraProducts.filter((ep) => ep.tags.includes(groupTag));
    if (productId) {
      newExtraProducts = newExtraProducts.concat([
        form.recommendedProducts.find((rp) => parseInt(rp.id) === productId)
      ]);
    }
    if (userId) {
      saleActionDispatchers.updateUser(userId, { extraProducts: newExtraProducts });
    } else {
      saleActionDispatchers.updateFormAction({ extraProducts: newExtraProducts });
    }
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
    }
  }

  function handleMoreInfoClick(e, product) {
    e.stopPropagation();
    setShowProductModal(product);
  }
  return (
    <FormPageContainer onBack={onBack} currentStep={userId ? pages.mobile + pages.summary : null}>
      <header className={"fallIn-"}>
        <div
          style={{ textAlign: "center" }}
          dangerouslySetInnerHTML={{ __html: products[0].textGroups[0] }}
        />
      </header>
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img
            className={"frameImage formPage-background fade-"}
            src={MobileMascotSmile}
            alt={"mobileMascot"}
          />
        </div>
      )}
      <div className={"slide"}>
        <ModalWindow open={showProductModal} onClose={() => setShowProductModal(false)}>
          <img className={styles.modalImage} src={MascotBlush} alt={"mascot"} />
          {showProductModal && (
            <div dangerouslySetInnerHTML={{ __html: showProductModal.textGroups[1] }} />
          )}
        </ModalWindow>
        <WhiteButtonList
          buttons={[
            ...products.map((product) => {
              return {
                content: (
                  <div>
                    <div
                      className={"title"}
                      dangerouslySetInnerHTML={{
                        __html: product.textGroups[5]
                          .replaceAll("<p>", "<div>")
                          .replaceAll("</p>", "</div>")
                      }}
                    />
                    {_.isNumber(product.recPrice) && (
                      <div className={"priceTag"}>{product.recPrice} kr/mnd</div>
                    )}
                    <div className={"grayText"}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product.textGroups[4]
                            .replaceAll("<p>", "<div>")
                            .replaceAll("</p>", "</div>")
                        }}
                      />
                      {_.isNumber(product.initPrice) && (
                        <div>Etablering kr {product.initPrice}</div>
                      )}
                    </div>
                    {product.textGroups[1] && (
                      <div
                        tabIndex={0}
                        className={`btnUnderline ${styles.blackMoreInfoButton}`}
                        onClick={(e) => handleMoreInfoClick(e, product)}
                      >
                        <Icon className={styles.warningIconBlack} icon={"WarningCircle"} />
                        Mer informasjon
                      </div>
                    )}
                  </div>
                ),
                onClick: () => handleClick(product.id)
              };
            }),
            { content: <div className={"title"}>Nei, takk</div>, onClick: () => handleClick() }
          ]}
        />
      </div>
    </FormPageContainer>
  );
}

export default connect(productGroupSelector)(connect(formAndUserSelector)(ProductRadioGroup));
