import React, { useEffect, useRef } from "react";
import Select from "react-select";
import _ from "lodash";
import { useAdditionalSales } from "../hooks/additional-sales";
import { getAdditionalSalesType } from "../Utils/additional-sales-utils";
import { IsFKTheme } from "../Utils/Themes";

function AdditionalSalesSelect({ onChange, value, isBedrift, isMobile }) {
  const { data, isLoading, error } = useAdditionalSales(
    getAdditionalSalesType(IsFKTheme, isBedrift, isMobile)
  );

  const selectRef = useRef();

  const handleChange = (selectedOptions) => {
    onChange(selectedOptions);
  };

  return (
    <div>
      <Select
        ref={selectRef}
        value={value}
        defaultValue={[]}
        onChange={handleChange}
        isMulti
        onClear={() => {
          console.log("TYEST");
        }}
        name="additionalSales"
        isLoading={isLoading}
        options={(data ?? []).map((sale) => ({
          value: sale._id,
          label: sale.title
        }))}
        placeholder={"Velg mersalg..."}
      />
      {error && (
        <div>
          Noe gikk galt ved lasting av tileggsprodukter. Vennligst last opp nettsiden på nytt.
        </div>
      )}
    </div>
  );
}

export default AdditionalSalesSelect;
