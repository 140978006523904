import {
  UPDATE_SMS_ACCEPT_FORM,
  GET_SMS_ACCEPT_ADDON_PRODUCTS,
  GET_SMS_ACCEPT_ADDON_PRODUCTS_SUCCESS,
  GET_SMS_ACCEPT_ADDON_PRODUCTS_ERROR,
  GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT,
  GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT_SUCCESS,
  GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT_ERROR,
  SUBMIT_SMS_ACCEPT_FORM,
  SUBMIT_SMS_ACCEPT_FORM_SUCCESS,
  SUBMIT_SMS_ACCEPT_FORM_ERROR,
  GET_SMS_ACCEPT_CUSTOMER_INFO,
  GET_SMS_ACCEPT_CUSTOMER_INFO_SUCCESS,
  GET_SMS_ACCEPT_CUSTOMER_INFO_ERROR,
  SMS_ACCEPT_SUBMIT_CONSENT,
  UPDATE_HASHED_CUSTOMER_ID,
  SMS_ACCEPT_SUBMIT_CONSENT_SUCCESS,
  SMS_ACCEPT_SUBMIT_CONSENT_ERROR,
  ADD_ACCEPTED_ORDER,
  REMOVE_ORDER_ACCEPT,
  GET_PRICE_SUMMARY,
  GET_PRICE_SUMMARY_SUCCESS,
  GET_PRICE_SUMMARY_ERROR
} from "./SmsAcceptActionTypes";

export const getSmsAcceptCustomerInfoAction = (hashedCustomerId) => ({
  type: GET_SMS_ACCEPT_CUSTOMER_INFO,
  hashedCustomerId
});

export const getSmsAcceptCustomerInfoSuccessAction = (customerInfo) => ({
  type: GET_SMS_ACCEPT_CUSTOMER_INFO_SUCCESS,
  customerInfo
});

export const getSmsAcceptCustomerInfoErrorAction = (error) => ({
  type: GET_SMS_ACCEPT_CUSTOMER_INFO_ERROR,
  error
});

export const updateSmsAcceptFormAction = (smsAcceptForm) => ({
  type: UPDATE_SMS_ACCEPT_FORM,
  smsAcceptForm
});

export const getSmsAcceptAddonProducts = (brand, customerType) => ({
  type: GET_SMS_ACCEPT_ADDON_PRODUCTS,
  brand,
  customerType
});

export const getSmsAcceptAddonProductsSuccess = (addonProducts) => ({
  type: GET_SMS_ACCEPT_ADDON_PRODUCTS_SUCCESS,
  addonProducts
});

export const getSmsAcceptAddonProductsError = (error) => ({
  type: GET_SMS_ACCEPT_ADDON_PRODUCTS_ERROR,
  error
});

export const getSmsAcceptExtraProductText = (productId) => ({
  type: GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT,
  productId
});

export const getSmsAcceptExtraProductTextSuccess = (productText) => ({
  type: GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT_SUCCESS,
  productText
});

export const getSmsAcceptExtraProductTextError = (error) => ({
  type: GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT_ERROR,
  error
});

export const submitSmsAcceptForm = (hashedCustomerId, orderIds, subscriptionConsent) => ({
  type: SUBMIT_SMS_ACCEPT_FORM,
  hashedCustomerId,
  orderIds,
  subscriptionConsent
});

export const submitSmsAcceptFormSuccess = () => ({
  type: SUBMIT_SMS_ACCEPT_FORM_SUCCESS
});

export const submitSmsAcceptFormError = (error) => ({
  type: SUBMIT_SMS_ACCEPT_FORM_ERROR,
  error
});

export const smsAcceptSubmitConsent = (hashedCustomerId, subscriptionConsent) => ({
  type: SMS_ACCEPT_SUBMIT_CONSENT,
  hashedCustomerId,
  subscriptionConsent
});

export const smsAcceptSubmitConsentSuccess = () => ({
  type: SMS_ACCEPT_SUBMIT_CONSENT_SUCCESS
});

export const smsAcceptSubmitConsentError = (error) => ({
  type: SMS_ACCEPT_SUBMIT_CONSENT_ERROR,
  error
});

export const updateHashedCustomerID = (hashedCustomerId) => ({
  type: UPDATE_HASHED_CUSTOMER_ID,
  hashedCustomerId
});

export const addOrderAccept = (id) => ({
  type: ADD_ACCEPTED_ORDER,
  id
});

export const removeOrderAccept = (id) => ({
  type: REMOVE_ORDER_ACCEPT,
  id
});

export const getPriceSummary = (id, productHubId, extraProducts) => ({
  type: GET_PRICE_SUMMARY,
  id,
  productHubId,
  extraProducts
});

export const getPriceSummarySuccess = (id, priceSummary) => ({
  type: GET_PRICE_SUMMARY_SUCCESS,
  id,
  priceSummary
});

export const getPriceSummaryError = (id, error) => ({
  type: GET_PRICE_SUMMARY_ERROR,
  id,
  error
});
