import React from "react";
import SubscriptionConsent from "../../CustomerForm/SubscriptionConsent/SubscriptionConsent";
import { history } from "../../../App";
import { pages } from "../../../Utils/CustomerFormHelper";
import smsAcceptActionDispatchers from "../../../ReduxModules/Actions/SmsAcceptActions/SmsAcceptActionDispatcher";
import { connect } from "react-redux";
import { smsAcceptSimpleSelector } from "../../../ReduxModules/Selectors/SmsAcceptSelector";

function Subscription({hashedCustomerId}) {
    const onConfirm = (subscriptionConsent) => {
        try{
            smsAcceptActionDispatchers.smsAcceptSubmitConsent(hashedCustomerId, subscriptionConsent);
            history.push(pages.smsAcceptReceipt);
        }catch(error) {
            var teststring = "Subscription onConfirm";
            window.newrelic.noticeError(teststring, error);
            console.log(teststring + " failed")
        }

    }
    return (
        <>
            <SubscriptionConsent onSubmit={(subscriptionConsent) => onConfirm(subscriptionConsent)} onBack={() => history.push(pages.smsAcceptConfirm)} hideFooter/>
        </>
    );
}

export default connect(smsAcceptSimpleSelector)(Subscription);
