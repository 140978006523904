import React, { useEffect, useState } from "react";
import FormPageContainer from "../../FormPageContainer/FormPageContainer";
import { connect, useSelector } from "react-redux";
import { formAndUserSelector } from "../../../../ReduxModules/Selectors/SaleSelectors";
import saleActionDispatchers from "../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import pageStepActionDispatchers from "../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import styles from "./SelectProduct.module.scss";
import ProductPill from "../../../ProductPill/ProductPill";
import FkPris from "../../../../assets/Images/FK/Mobil/kundeservicepris.png";
import {
  pages,
  productCategories as csProductCategories
} from "../../../../Utils/CustomerFormHelper";
import { useParams } from "react-router-dom";
import SlidingTabButtons from "../../../SlidingTabButtons/SlidingTabButtons";
import * as _ from "lodash";
import TooOldDialog from "./TooOldDialog";
import {
  calculateAge,
  getAgeFromBirthDate,
  getAgeFromSsn
} from "../../../../Utils/HelperFunctions";

function SelectProduct(props) {
  const { onConfirm, userId, form, user, whiteBackground, className } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [productCategories, setProductCategories] = useState([
    { text: "Ung", tag: "Ung" },
    {
      text: "Strømkunde",
      active: true,
      tag: "Fordel"
    },
    {
      text: form.isMember ? "Medlem" : "Ikke strømkunde",
      tag: form.isMember ? "Medlem" : "Vanlig"
    }
  ]);

  const products = useSelector((state) =>
    state.productState.products.filter(
      (p) => p.productCategory === csProductCategories.productOption
    )
  );
  const filteredProducts = products.filter(
    (p) => p.tags && p.tags.includes(productCategories.find((pc) => pc.active)?.tag)
  );
  const isUng = productCategories.find((pc) => pc.tag === "Ung")?.active;
  useEffect(() => {
    if (user) {
      if (user.avtaletype) {
        const currentProduct = products.find((p) => p.id === user.avtaletype);
        setProductCategories(
          productCategories.map((pc) => {
            if (currentProduct.tags.includes(pc.tag)) {
              return { ...pc, active: true };
            }
            return { ...pc, active: false };
          })
        );
      }
    } else {
      const currentProduct = products.find((p) => p.id === parseInt(form.avtaletype));
      setProductCategories(
        productCategories.map((pc) => {
          if (currentProduct.tags.includes(pc.tag)) {
            return { ...pc, active: true };
          }
          return { ...pc, active: false };
        })
      );
    }
  }, []);

  function handleClick(productId) {
    if (isUng) {
      const age = user.isOwner ? getAgeFromSsn(form.ssn) : getAgeFromBirthDate(user.birthDate);
      if (age >= 30) {
        setOpenDialog(true);
        return;
      }
    }
    if (userId) {
      saleActionDispatchers.updateUser(userId, { avtaletype: productId });
    } else {
      saleActionDispatchers.updateFormAction({ avtaletype: productId });
    }
    if (onConfirm) {
      onConfirm();
    }
  }

  const selectCategory = (index) => {
    setProductCategories(
      productCategories.map((pc, i) => {
        if (i === index) {
          return { ...pc, active: true };
        }
        return { ...pc, active: false };
      })
    );
  };
  return (
    <>
      <TooOldDialog handleClose={() => setOpenDialog(false)} open={openDialog} />
      <div className={`slide ${styles.content} ${isUng && styles.contentUng} ${className}`}>
        <SlidingTabButtons
          className={styles.tabButtons}
          buttons={productCategories}
          onClick={selectCategory}
        />
        <div className={styles.pillContainer}>
          {_.sortBy(filteredProducts, (p) => p.data?.priceIncVat).map((p, i) => (
            <ProductPill
              key={i}
              name={p.name}
              price={p.data?.priceIncVat}
              isUng={p.tags.includes("Ung")}
              onClick={() => handleClick(p.id)}
              color={p.tags.includes("Ung") ? "pink" : whiteBackground ? "orange" : "white"}
              packageName={p.data?.packageName}
            />
          ))}
        </div>
        <div className={styles.text}>
          Fri tale, SMS og MMS inntil 10.000 stk. Egne priser gjelder for spesialnummer, bruk
          utenfor EU/EØS, bruk fra Norge til utlandet, skip, fly og satellitt. Her finner du våre{" "}
          <a
            className={styles.textLink}
            href={"https://www.fjordkraft.no/mobilabonnement/nye-mobilpriser/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            priser
          </a>
          . Hastighet følger Telia sitt nett og varierer etter{" "}
          <a
            className={styles.textLink}
            href={"https://www.fjordkraft.no/mobilabonnement/dekning/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            dekning
          </a>{" "}
          og andre forhold. For Fri Data abonnement senkes hastigheten til 3 mbit/s etter 100 GB.
        </div>
        <img className={styles.fkPris} src={FkPris} alt={"FkPris"} />
      </div>
    </>
  );
}

export default connect(formAndUserSelector)(SelectProduct);
