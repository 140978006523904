import React, { useState } from "react";
import styles from "./PowerSmartLanding.module.scss";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import clsx from "clsx";
import PowersmartIllustration from "../../../assets/Images/FK/Stromsmart.svg";
import PowersmartPlusIllustration from "../../../assets/Images/FK/Stromsmart_plus.svg";
import PowerCard from "./PowerCard";
import { getSmartPowerProductId } from "../../../Utils/PowerSmartUtils";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { useHistory } from "react-router-dom";

function PowerSmartLanding({
  powerSmart,
  powerSmartPlus,
  termsUrl,
  stepDescription,
  cancellationFormUrl
}) {
  const { SMART_PRODUCT_POWER_ID, SMART_PRODUCT_POWER_PLUS_ID, PULSE_METER_ID } =
    getSmartPowerProductId();
  const [expandedProductInfo, setExpandedProductInfo] = useState(false);
  const history = useHistory();

  const termsContent = (
    <span>
      <a href={termsUrl} rel="noopener noreferrer" target="_blank">
        Vilkår,
      </a>{" "}
      <a href={cancellationFormUrl} rel="noopener noreferrer" target="_blank">
        angreskjema og skjema for opplysninger om angrerett
      </a>
    </span>
  );

  return (
    <>
      <h1 className={`${styles.header} fallIn-`}>{stepDescription?.title}</h1>
      <div className={`${styles.content} slide`}>
        <p className={styles.powerSmartIntro}>{stepDescription?.lede}</p>

        <ButtonGroup
          className={styles.optInOut}
          variant="contained"
          sx={{ boxShadow: "unset" }}
          disableElevation
        >
          <Button
            className={clsx("btnPrimary")}
            onClick={() => {
              setExpandedProductInfo(true);
            }}
          >
            Ja
          </Button>
          <Button
            className={clsx("btnPrimary", styles.optOut)}
            onClick={() => {
              //to account for user going back and forth
              saleActionDispatchers.selectAddonProduct(SMART_PRODUCT_POWER_ID, false);
              saleActionDispatchers.selectAddonProduct(SMART_PRODUCT_POWER_PLUS_ID, false);
              saleActionDispatchers.selectAddonProduct(PULSE_METER_ID, false);
              pageStepActionDispatchers.goToNextStep(window.location.pathname);
            }}
          >
            Nei
          </Button>
        </ButtonGroup>
        {expandedProductInfo && (
          <div className={styles.productContainer}>
            <p className={styles.productLead}>Tjenester i Fjordkraft-appen:</p>
            <PowerCard
              {...powerSmart}
              key={SMART_PRODUCT_POWER_ID}
              stickerColor="#7C1A00"
              icon={PowersmartIllustration}
              priceSubtext={"Ingen bindingstid"}
              ctaAction={() => {
                history.push("Stromsmart/stromsmart");
              }}
              terms={termsContent}
            />
            <PowerCard
              {...powerSmartPlus}
              key={SMART_PRODUCT_POWER_PLUS_ID}
              stickerColor="#7C1A00"
              icon={PowersmartPlusIllustration}
              priceSubtext={"6 måneders bindingstid"}
              ctaAction={() => {
                history.push("Stromsmart/stromsmartplus");
              }}
              terms={termsContent}
            />
          </div>
        )}
      </div>
    </>
  );
}

export { PowerSmartLanding };
