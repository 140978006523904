import { call, put, takeLatest, all } from "redux-saga/effects";
import * as _ from "lodash";
import * as AxiosFunction from "../../Utils/AxiosFunctions";
import {
  getExistingCustomerDataBySsnError,
  getExistingCustomerDataBySsnSuccess,
  getCustomerByHashIdError,
  getCustomerByHashIdSuccess,
  submitMeterNumberUpdateError,
  submitMeterNumberUpdateSuccess
} from "../Actions/CustomerActions/CustomerActions";
import {
  GET_EXISTING_CUSTOMER_DATA_BY_SSN,
  GET_CUSTOMER_BY_HASH_ID,
  SUBMIT_METERNUMBER_UPDATE
} from "../Actions/CustomerActions/CustomerActionTypes";
import store from "../Store/Store";

function* getCustomerByHashId(action) {
  try {
    const customer = yield call(AxiosFunction.getFullCustomer, action.hashId);
    yield put(getCustomerByHashIdSuccess(customer));
  } catch (e) {
    yield put(getCustomerByHashIdError(e));
  }
}

export function* watchGetCustomerByHashId() {
  yield takeLatest(GET_CUSTOMER_BY_HASH_ID, getCustomerByHashId);
}

function* submitMeterNumberUpdate(action) {
  try {
    const updates = action.orders.map((o) =>
      call(AxiosFunction.updateMeterNumber, o, action.customerId)
    );
    yield all(updates);
    yield put(submitMeterNumberUpdateSuccess());
  } catch (e) {
    yield put(submitMeterNumberUpdateError(e));
  }
}

export function* watchSubmitMeterNumberUpdate() {
  yield takeLatest(SUBMIT_METERNUMBER_UPDATE, submitMeterNumberUpdate);
}

function* reflect(saga) {
  try {
    return { v: yield saga, status: "success" };
  } catch (err) {
    return { e: err, status: "error" };
  }
}
function* getExistingCustomerData(action) {
  try {
    const [deliveryPoints, subscriptionConsent, customerInfo] = yield all(
      [
        call(AxiosFunction.getSubscriptionConsent, action.ssn),

        store.getState().bankIdState.isBankIdComplete &&
          call(AxiosFunction.getActiveDeliveryPointsBySsn, action.ssn),
        store.getState().bankIdState.isBankIdComplete &&
          call(AxiosFunction.getExistingCustomerInfo, action.ssn)
      ].map(reflect)
    );
    let customerLastActiveProduct;
    if (store.getState().saleState.form?.continueExistingAgreement) {
      customerLastActiveProduct = yield call(
        AxiosFunction.getCustomerLastActiveProduct,
        action.ssn
      ) ?? null;
    }

    let isActiveCustomer = false;
    if (deliveryPoints.status === "error" && subscriptionConsent === "error") {
      throw deliveryPoints.e;
    } else if (_.isEmpty(deliveryPoints.v) && customerInfo.v?.address) {
      customerInfo.v.address = null; // Customer has no active deliverypoints. Disregard any recorded address.
    } else {
      isActiveCustomer = true;
    }
    yield put(
      getExistingCustomerDataBySsnSuccess(
        deliveryPoints.v,
        subscriptionConsent.v,
        customerInfo.v?.address,
        customerInfo.v?.email,
        isActiveCustomer,
        customerLastActiveProduct
      )
    );
  } catch (e) {
    console.log(e);
    yield put(getExistingCustomerDataBySsnError(e));
  }
}

export function* watchGetExistingCustomerData() {
  yield takeLatest(GET_EXISTING_CUSTOMER_DATA_BY_SSN, getExistingCustomerData);
}
