import moment from "moment";
import { useEffect, useRef } from "react";
import { INVOICE_SELECTION_OPTIONS } from "../ReduxModules/Reducers/InvoiceReducer";
import config from "../config";
export function sanitizeProps(object) {
  let newObject = { ...object };
  Object.keys(newObject).map(
    (k) =>
      (newObject[k] =
        typeof newObject[k] === "string" ? sanitizeString(newObject[k]) : newObject[k])
  );
  return newObject;
}

export function sanitizeString(string) {
  let sanitizedString = string.trim();
  sanitizedString.replace(/\s\s+/g, " ");
  return sanitizedString;
}

export function convertToSsn(born, personNumber) {
  return moment(born, "DD.MM.YYYY").format("DDMMYY") + personNumber;
}

export function getBornFromSsn(ssn) {
  let year = ssn.slice(4, 6) > 20 ? "19" : "20";
  let born = ssn.slice(0, 2) + "." + ssn.slice(2, 4) + "." + year + ssn.slice(4, 6);
  let momentBorn = moment(born, "DD-MM-YYYY").add(1, "days");
  return momentBorn;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function toNorwegianNumberFormat(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function findUniqueDeliveryPoints(list, listToCompare) {
  if (!list) {
    return [];
  }
  if (!listToCompare) {
    return list;
  }

  return list.filter(
    (i) => !listToCompare.find((o) => o.meterIdentification === i.meterIdentification)
  );
}

export function isDeliveryPointEqual(deliveryPoint, deliveryPointToCompare) {
  if (
    deliveryPoint.meterIdentification === deliveryPointToCompare.meterIdentification &&
    deliveryPoint.meterIdentification !== ""
  ) {
    return true;
  } else {
    if (
      !deliveryPoint.meterIdentification &&
      deliveryPoint.address === deliveryPointToCompare.address &&
      deliveryPoint.zipCode === deliveryPointToCompare.zipCode
    ) {
      return true;
    }
  }
  return false;
}

export function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}

const noMonths = [
  "Januar",
  "Februar",
  "Mars",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Desember"
];
export function getMonthLong(date) {
  return noMonths[date.getMonth()];
}

export function getPriceAreaTags(product) {
  try {
    if (product && product.tags && product.tags.length > 0) {
      return product.tags
        .filter((t) => t.includes("NO") && t.length === 3)
        .map((t) => parseInt(t.substring(2, 3)));
    }
    return [];
  } catch (e) {
    console.error(e);
    return [];
  }
}

export const productHubTags = {
  bestSelger1: "Bestselger1",
  bestSelger2: "Bestselger2"
};

export function getWebStoreId() {
  return config.env === "dev" ? 1399 : 935;
}

export function getElhubDataAddress(elhubData) {
  return `${elhubData.addressV1.streetName} ${elhubData.addressV1.buildingNumber || ""}${elhubData.addressV1.buildingLetter || ""}`;
}

export function getElhubDataZipCode(elhubData) {
  return elhubData?.addressV1?.zipCode || "";
}

export const CHOSEN_DISTRIBUTION_METHOD = {
  paper: 1,
  eInvoice: 2,
  email: 3,
  electronic: 5
};
export const selectedInvoiceOptionToDistributionMethod = (selectedOption) => {
  switch (selectedOption) {
    case INVOICE_SELECTION_OPTIONS.paper:
      return CHOSEN_DISTRIBUTION_METHOD.paper;
    case INVOICE_SELECTION_OPTIONS.eInvoice:
      return CHOSEN_DISTRIBUTION_METHOD.eInvoice;
    case INVOICE_SELECTION_OPTIONS.email:
      return CHOSEN_DISTRIBUTION_METHOD.email;
    case INVOICE_SELECTION_OPTIONS.electronic:
      return CHOSEN_DISTRIBUTION_METHOD.electronic;
    default:
      return null;
  }
};

// generate uuid
export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators,eqeqeq
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getAgeFromSsn(ssn) {
  const ind = ssn.substr(6, 3);
  let day = ssn.substr(0, 2);
  if (parseInt(ssn.substr(0, 2)) > 31) {
    day = (parseInt(ssn.substring(0, 2)) - 40).toString();
  }
  const month = ssn.substr(2, 2);
  const year = parseInt(ssn.substr(4, 2));
  let yearNo = year;
  if (ind > 0 && ind < 500) {
    yearNo += 1900;
  } else if (ind > 499 && ind < 750 && year > 55 && year < 100) {
    yearNo += 1800;
  } else if (ind > 499 && ind < 999 && year >= 0 && year < 40) {
    yearNo += 2000;
  } else if (ind > 899 && ind < 999 && year > 39 && year < 100) {
    yearNo += 1900;
  } else {
    throw new Error("Invalid ssn");
  }

  return calculateAge(new Date(yearNo, month, day));
}

export function getAgeFromBirthDate(birthDate) {
  // dd.mm.yyyy
  try {
    const day = parseInt(birthDate.substr(0, 2));
    const month = parseInt(birthDate.substr(3, 2));
    const year = parseInt(birthDate.substr(6, 4));
    return calculateAge(new Date(year, month, day));
  } catch {
    return -1;
  }
}

export function calculateAge(birthday) {
  // birthday is a date
  const ageDifMs = Date.now() - birthday;
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
