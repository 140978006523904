import React, { useEffect } from "react";
import customerActionDispatchers from "../../ReduxModules/Actions/CustomerActions/CustomerActionDispatchers";
import { customerSelector } from "../../ReduxModules/Selectors/CustomerSelectors";
import { connect } from "react-redux";
import Loader from "../CustomerForm/FKLoader/Loader";
import SubmitErrorBox from "./SubmitErrorBox";
import FormPageContainer from "../CustomerForm/FormPageContainer/FormPageContainer";
import OrderInfo from "./OrderInfo";
import styles from "./MeterNumberPage.module.scss";

function MissingMeterNumber({
  customer,
  isLoadingCustomer,
  isSubmittingCustomerUpdate,
  error,
  customerUpdateSubmitComplete,
  match
}) {
  useEffect(() => {
    const hashId = match.params.hashId;
    customerActionDispatchers.getCustomerByHashId(hashId);
  }, [match.params]);

  const isUpdated = customer && !!customer.orders.find((o) => o.isUpdated);
  const handleConfirm = () => {
    const changedOrders = customer.orders.filter((o) => o.isUpdated);
    customerActionDispatchers.submitMeterNumberUpdate(changedOrders, match.params.hashId);
  };
  let monthAgo = new Date().setMonth(new Date().getMonth() - 1);
  return (
    <FormPageContainer hideFooter wide>
      <div className={styles.content}>
        {error ? (
          <SubmitErrorBox />
        ) : customerUpdateSubmitComplete ? (
          <header className={styles.successHeader}>
            <h1>Takk for at du sendte inn målernummer!</h1>
            <h4>Vi vil håndtere ordren din snarest mulig!</h4>
          </header>
        ) : isLoadingCustomer || !customer ? (
          <Loader text={"Henter informasjon om deg..."} />
        ) : isSubmittingCustomerUpdate ? (
          <Loader text={"Oppdaterer din ordre..."} />
        ) : (
          <div>
            <h1>
              Hei {customer.firstName} {customer.lastName}
            </h1>
            {customer.orders
              .filter((o) => !o.elhubData)
              .map((o, i) => (
                <OrderInfo key={i} order={o} />
              ))}
            {customer.orders
              .filter((o) => o.elhubData && new Date(o.created) > monthAgo)
              .map((o, i) => (
                <OrderInfo key={i} order={o} />
              ))}
            <button
              className={`btnPrimary ${styles.submitButton}`}
              onClick={handleConfirm}
              disabled={!isUpdated}
            >
              Bekreft målernummer
            </button>
          </div>
        )}
      </div>
    </FormPageContainer>
  );
}

export default connect(customerSelector)(MissingMeterNumber);
