import Icon from "../../../../Icon/Icon";
import React from "react";
import styles from "./MeterFoundMessage.module.scss";
import { IsFKTheme } from "../../../../../Utils/Themes";

function MeterFoundMessage({ success, elhubData }) {
  return (
    <>
      <div className={`${styles.iconContainer} ${IsFKTheme && styles.fk}`}>
        <div className={`${styles.iconInnerContainer} ${!success && styles.fail}`}>
          {success ? (
            <Icon className={styles.checkIcon} icon={"check"} />
          ) : (
            <Icon className={styles.addIcon} icon={"add"} />
          )}
        </div>
      </div>
      <h2 className={styles.text}>
        {success
          ? elhubData.length > 1
            ? `${elhubData.length} strømmålere funnet!`
            : "Strømmåler funnet!"
          : "Fant ikke strømmåler..."}
      </h2>
    </>
  );
}

export default MeterFoundMessage;
