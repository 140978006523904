import config from "../config";

function getSmartPowerProductId() {
  if (config.env === "test") {
    return { SMART_PRODUCT_POWER_ID: 299, SMART_PRODUCT_POWER_PLUS_ID: 300, PULSE_METER_ID: 256 };
  }
  return { SMART_PRODUCT_POWER_ID: 1047, SMART_PRODUCT_POWER_PLUS_ID: 1048, PULSE_METER_ID: 256 };
}

function getSmartPowerProductList<T extends "string" | "number">(
  returnType: T
): T extends "string" ? string[] : number[] {
  const smartPowerProductId = getSmartPowerProductId();
  const array: number[] = [
    smartPowerProductId.SMART_PRODUCT_POWER_ID,
    smartPowerProductId.SMART_PRODUCT_POWER_PLUS_ID,
    smartPowerProductId.PULSE_METER_ID
  ];
  if (returnType === "string") {
    return array.map((item) => String(item)) as T extends "string" ? string[] : number[];
  }
  return array as T extends "string" ? string[] : number[];
}

type QueryResult<T> = {
  [K in keyof T]:
    | { data: Awaited<T[K]>; success: true; error: null }
    | { data: null; success: false; error: string };
};

async function query<T extends Promise<unknown>[]>(queries: [...T]) {
  const results = await Promise.allSettled(queries);

  return results.map((result) => {
    return result.status === "rejected"
      ? { data: null, success: false, error: result.reason }
      : { data: result.value, success: true, error: undefined };
  }, []) as QueryResult<T>;
}

export { getSmartPowerProductId, getSmartPowerProductList, query };
export type { QueryResult };
