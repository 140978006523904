import * as baseAxios from "axios";
import config from "../config";
import { setAuthHeader } from "./axiosHeaders";
import { sanitizeString } from "./HelperFunctions";
import { getBornFromSsn } from "./HelperFunctions";
import {IsFKTheme, IsGETheme} from "./Themes";
import _ from "lodash";

const axios = baseAxios.create({
  headers: {
    Brand: config.theme === "GE" ? "GUEN" : config.theme + "AS"
  }
});

export async function getExtraProductPriceInfoBedrift(extraProductId) {
  const extraProductPriceInfo = await axios.get(
    `${config.baseUrl}/api/salesmodule/ExtraProductPriceInfoBedrift/${extraProductId}`
  );
  return extraProductPriceInfo.data;
}

export async function getProductByIdAndCategory(productId, category) {
  const productInfoResponse = await axios.get(
    `${config.baseUrl}/api/producthub/product/${productId}?productCategory=${category}`
  );
  return productInfoResponse;
}

export async function getProductInfo(productId, isMobile) {
  let content = "";
  if (isMobile) {
    const productInfoResponse = await axios.get(
      `${config.baseUrl}/api/producthub/product/${productId}?productCategory=SPARKLE_PRODUCT`
    );
    content = productInfoResponse;
  } else {
    const brand = IsGETheme ? "GUEN" : config.theme + "AS";
    const productInfoResponse = await axios.get(`${config.baseUrl}/api/producthub/text`, {
      params: {
        productId,
        channelId: 7,
        textGroupId: 1,
        brand
      }
    });
    const agreementResponse = await axios.get(`${config.baseUrl}/api/producthub/text`, {
      params: {
        productId,
        channelId: 7,
        textGroupId: 5,
        brand
      }
    });
    const priceInfoResponse = await axios.get(`${config.baseUrl}/api/producthub/text`, {
      params: {
        productId,
        channelId: 7,
        textGroupId: 3,
        brand
      }
    });
    content =
      productInfoResponse.data.content +
      agreementResponse.data.content +
      priceInfoResponse.data.content;
  }
  return content;
}

export async function getProductInformation(productId) {
  return await getProductHubProductText(productId, 1);
}

export async function getExtendedProductInformation(productId) {
  return await getProductHubProductText(productId, 2);
}

export async function getProductPriceInformation(productId) {
  return await getProductHubProductText(productId, 3);
}

export async function getProductCallToActionText(productId) {
  return await getProductHubProductText(productId, 6);
}

export async function getIngressBedrift(productId) {
  return await getProductHubProductText(productId, 1, 6);
}

export async function getExtendedInformationBedrift(productId) {
  return await getProductHubProductText(productId, 5, 6);
}

export async function getImageBedrift(productId) {
  return await getProductHubProductText(productId, 6, 1);
}

export async function getProductHubProductText(productId, textGroupId, channelId = 0) {
  const brand = IsGETheme ? "GUEN" : config.theme + "AS";
  const productInfoResponse = await axios.get(`${config.baseUrl}/api/producthub/text`, {
    params: {
      productId,
      channelId: channelId,
      textGroupId: textGroupId,
      brand
    }
  });

  return productInfoResponse.data.content;
}

export async function getProductInfoBedrift(productId) {
  const response = await axios.get(
    `${config.baseUrl}/api/producthub/productWithPriceInfo/${productId}`
  );
  return response.data.priceElements;
}

export async function getCampaignInfo(campaignId) {
  try {
    if (!campaignId) {
      return null;
    }
    const brand = IsGETheme ? "GUEN" : config.theme + "AS";
    let channelId = IsFKTheme ? 2 : 4;
    const productHubCampaigns = await axios.get(
      `${config.baseUrl}/api/producthub/getCampaigns/${brand}`
    );
    let productHubId = productHubCampaigns.data.find(
      (element) => element.campaignId === campaignId
    ).id;
    const result = await axios.get(
      `${config.baseUrl}/api/producthub/text?productId=${productHubId}&channelId=${channelId}&textGroupId=1&brand=${brand}`
    );
    return result.data.content;
  } catch (e) {
    console.error(`Error: Failed to get campaignInfo: ${e.message}`);
  }
}

export async function getCampaignText(campaignName, isMobile) {
  try {
    if (!campaignName) {
      return null;
    }
    const brand = IsGETheme ? "GUEN" : config.theme + "AS";
    let channelId = 0;

    let productHubCampaings = null;
    if (isMobile) {
      productHubCampaings = await axios.get(`${config.baseUrl}/api/producthub/mobileCampaigns`);
    } else {
      productHubCampaings = await axios.get(
        `${config.baseUrl}/api/producthub/getCampaigns/${brand}`
      );
    }

    let productHubId = productHubCampaings?.data?.find(
      (element) => element?.name === campaignName
    )?.id;

    const result = await axios.get(
      `${config.baseUrl}/api/producthub/campaignTextByPhubId/${productHubId}?channelId=${channelId}&textGroupId=1&brand=${brand}`
    );
    return result.data.content;
  } catch (e) {
    console.error(`Error: Failed to get campaignText: ${e.message}`);
  }
}
export async function getCampaignLength(action) {
  try {
    if (action === null) {
      return null;
    }
    const result = await axios.post(`${config.baseUrl}/api/afi/getCampaignLength`, action);
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get campaignLength: ${e.message}`);
  }
}

export async function getPofProductInfo(productId) {
  const productInfoResponse = await axios.get(`${config.baseUrl}/api/producthub/productinfo/pof`, {
    params: {
      productId
    }
  });

  return productInfoResponse.data;
}

export async function getCreditConfirmation(ssn, phone) {
  try {
    const creditInfo = await axios.post(`${config.baseUrl}/api/creditrating/check`, {
      ssn,
      phone,
      brand: config.theme
    });
    return creditInfo.data;
  } catch (e) {
    console.error(`Error: Failed to get credit confirmation: ${e.message}`);
  }
}

export async function getElectricMeterInfo(zipCode, address, ssn, meterNumber) {
  const simplifiedZipCode = zipCode.substr(0, 2) + "*";
  const simplifiedBuildingNr = address.match(/\d+/)[0].substr(0, 1) + "*";
  const streetName = sanitizeString(address.split(simplifiedBuildingNr)[0]);
  const simplifiedStreetName = streetName.length <= 5 ? streetName : streetName.substr(0, 5) + "*";
  const searchParam = ssn.length === 11 ? "personId" : "organizationNumber";
  if (meterNumber !== null) {
    return (
      await axios.get(
        `${config.baseUrl}/api/onyx/meteringpoints?zipCode=${simplifiedZipCode}&streetName=${simplifiedStreetName}&buildingNumber=${simplifiedBuildingNr}&${searchParam}=${ssn}&meterId=${meterNumber}&web=true`
      )
    ).data;
  } else {
    return (
      await axios.get(
        `${config.baseUrl}/api/onyx/meteringpoints?zipCode=${simplifiedZipCode}&streetName=${simplifiedStreetName}&buildingNumber=${simplifiedBuildingNr}&${searchParam}=${ssn}&web=true`
      )
    ).data;
  }
}

export async function getBedriftSiteList(
  orgNr,
  firstName,
  lastName,
  invoiceAddress,
  invoiceZipCode
) {
  try {
    const res = await axios.post(`${config.conductorUrl}/api/v1/ironforge/siteListWeb`, {
      ssn: orgNr,
      isBedrift: true,
      firstName,
      lastName,
      invoiceAddress,
      invoiceZipCode
    });
    return res.data ?? [];
  } catch (e) {
    return [];
  }
}

export async function getPriceArea(zipCode) {
  return (await axios.get(`${config.baseUrl}/api/PriceArea/${zipCode}`)).data;
}
export async function getPriceAreaName(mpid) {
  var brand = config.theme;
  return (await axios.get(`${config.baseUrl}/api/afi/priceAreaName/${mpid}/${brand}`)).data;
}
export async function getEMInfoWithZipAndMeterNr(zipCode, meterNumber) {
  const simplifiedZipCode = zipCode.substr(0, 2) + "*";
  return (
    await axios.get(
      `${config.baseUrl}/api/onyx/meteringpoints?zipCode=${simplifiedZipCode}&meterId=${meterNumber}`
    )
  ).data;
}

export async function getEMInfoWithSsnAndMeterNr(personNumber, meterNumber) {
  const searchParam = personNumber.length === 11 ? "personId" : "organizationNumber";
  return (
    await axios.get(
      `${config.baseUrl}/api/onyx/meteringpoints?${searchParam}=${personNumber}&meterId=${meterNumber}`
    )
  ).data;
}

export async function getPostArea(postcode) {
  try {
    const postArea = (
      await axios.get(
        `https://fraktguide.bring.no/fraktguide/api/postalCode.json?country=no&pnr=${postcode}`
      )
    ).data.result;
    return postArea === "Ugyldig postnummer" || postArea === "EmptyPostnumber" ? "" : postArea;
  } catch (e) {
    console.error(`Error: Failed to get Post Area: ${e.message}`);
  }
}

export async function searchBrReg(searchString) {
  try {
    if (!searchString) {
      return null;
    }

    const brRegRes = await axios.get(
      "https://data.brreg.no/enhetsregisteret/api/enheter?size=4&konkurs=false&navn=" + searchString
    );
    if (brRegRes.status === 200) {
      return brRegRes.data;
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
}

function loopWords(array) {
  for (let word in array) {
    array[word] = array[word].toLowerCase();
    array[word] = array[word].capitalize();
  }
}

export async function getPublicRegistryInfo(ssn) {
  const contactInfo = (await axios.get(`${config.salesmoduleUrl}/api/freg/person?fodselsnr=${ssn}`))
    .data;
  if (contactInfo != null) {
    String.prototype.capitalize = function () {
      return this.charAt(0).toUpperCase() + this.slice(1);
    };

    let addressWords = contactInfo.adr.split(" ");
    loopWords(addressWords);
    let lastName = contactInfo.navn
      .substr(0, contactInfo.navn.indexOf(" "))
      .toLowerCase()
      .capitalize();
    let firstNameWords = contactInfo.navn.substr(contactInfo.navn.indexOf(" ") + 1).split(" ");
    loopWords(firstNameWords);
    for (let word in firstNameWords) {
      firstNameWords[word] = firstNameWords[word].toLowerCase();
      firstNameWords[word] = firstNameWords[word].capitalize();
    }
    return {
      address: addressWords.join(" "),
      lastName: lastName,
      firstName: firstNameWords.join(" "),
      zipCode: contactInfo.postn,
      city: contactInfo.posts
    };
  }
}

export async function getBisnodeInfo(phone) {
  try {
    const info = (await axios.get(`${config.baseUrl}/api/bisnode?mobile=${phone}`)).data.result[0];
    return info;
  } catch (e) {
    console.error(`Error: Failed to get bisnode registry info: ${e.message}`);
    throw e;
  }
}

export async function getEnv(envVariable) {
  try {
    const env = (await axios.get(`${config.baseUrl}/api/env/${envVariable}`)).data;
    return env;
  } catch (e) {
    console.error(`Error: Failed to get env variable ${envVariable} : ${e.message}`);
    throw e;
  }
}

export async function getBankIdToken(code) {
  try {
    const tokenResponse = (
      await axios.post(`${config.baseUrl}/api/evry/BankID/token`, {
        code,
        brand: config.theme
      })
    ).data;
    setAuthHeader(tokenResponse.accessToken);
    return tokenResponse;
  } catch (e) {
    console.error(`Error: Failed to get Bank ID token: ${e.message}`);
    throw e;
  }
}

export async function getBankIdCustomerInfo(code) {
  try {
    const customerInfo = (
      await axios.post(`${config.baseUrl}/api/evry/BankID/CustomerInfo`, {
        code,
        brand: config.theme
      })
    ).data;
    return customerInfo;
  } catch (e) {
    console.error(`Error: Failed to get Bank ID customer info: ${e.message}`);
    throw e;
  }
}

export async function getOrdersGroupedByCustomer(id) {
  try {
    const orders = await axios.get(
      `${config.conductorUrl}/api/v1/Order/OrdersGroupedByCustomer/${id}`
    );
    return orders.data;
  } catch (e) {
    console.error(`Error: Failed to get orders: ${e.message}`);
    throw e;
  }
}

export async function mdmAddTrumfId(momentId, trumfId) {
  try {
    const res = (
      await axios.put(`${config.conductorUrl}/api/v1/Trumf/UpdateTrumfId`, {
        MomentCustomerId: momentId,
        TrumfId: trumfId
      })
    ).data;
  } catch (e) {
    console.error(`Error: Failed to get order: ${e.message}`);
    throw e;
  }
}

function geStoreFromForm(form) {
  if (form.storeId) {
    return {
      identityServerId: form.storeId
    };
  } else if (form.coid) {
    return {
      identityServerId: 413
    };
  } else if (IsFKTheme) {
    return {
      identityServerId: 5
    };
  } else {
    return {
      identityServerId: 309
    };
  }
}

export async function postMobileOrder(form) {
  if (!config.conductorUrl) {
    return;
  }
  let newOrder = {
    ...form,
    users: form.users.map((u) => {
      return {
        ...u,
        mobileNumber: u.phone,
        productHubId: u.avtaletype,
        campaign: form.campaign,
        firstName: u.isOwner ? form.firstName : u.firstName,
        lastName: u.isOwner ? form.lastName : u.lastName
      };
    }),
    termsId: form.productHubInfo?.guid,
    eInvoiceReference: form.eInvoiceReference
  }; // todo: refactor
  newOrder.store = geStoreFromForm(form);
  let res = await axios.post(`${config.conductorUrl}/api/v1/order/CreateMobileOrder`, newOrder);
  return res.data;
}

export async function postOrder(form) {
  if (!config.conductorUrl) {
    return;
  }
  form.productId = form.producthubInfo.id;
  form.born = getBornFromSsn(form.ssn);

  form.store = geStoreFromForm(form);

  let newOrder = { ...form };
  if (!newOrder.invoiceAddress) {
    newOrder.invoiceAddress = newOrder.address;
    newOrder.invoiceZipCode = newOrder.zipCode;
    newOrder.invoiceCity = newOrder.city;
  }
  newOrder.productId = newOrder.producthubInfo.id;
  newOrder.termsId = newOrder.producthubInfo.guid;
  newOrder.extraProducts = newOrder.extraProducts.map((ep) => {
    return {
      ...ep,
      id: parseInt(ep.id),
      productHubId: parseInt(ep.id),
      campaigns:
        ep.campaigns &&
        ep.campaigns.map((c) => {
          return { productHubId: parseInt(c) };
        })
    };
  });
  newOrder.eInvoiceReference = form.eInvoiceReference;
  let res = await axios.post(`${config.conductorUrl}/api/v1/order/CreateOrder`, newOrder);
  return res.data;
}

export async function postBedriftOrder(salesModuleSales) {
  if (!config.conductorUrl) {
    return;
  }
  let res = await axios.post(
    `${config.conductorUrl}/api/v1/order/CreateOrdersBedriftWeb`,
    salesModuleSales
  );
  return res.data;
}

//TODO: Husk å implementere det som ble implementert i post Order.
export async function postOrders(form) {
  if (!config.conductorUrl) {
    return;
  }

  let res = await axios.post(`${config.conductorUrl}/api/v1/order/CreateOrders`, form);
  return res.data;
}

export async function updateMeterNumber(order, customerId) {
  try {
    const newOrder = await axios.put(
      `${config.conductorUrl}/api/v1/order/UpdateMeterNumber/${order.id}`,
      {
        MeterNumber: order.powerSaleData.meterNumber,
        LastUpdated: order.lastUpdated,
        HashedCustomerId: customerId
      }
    );
    return newOrder.data;
  } catch (e) {
    console.error(`Error: Failed to update meternumber on order: ${e.message}`);
    throw e;
  }
}

export async function GetProductHubProductWithBonuses(id) {
  let product = await GetProductHubProductById(id);

  if (_.isEmpty(product.tags)) {
    return product;
  }

  product.bonusCampaigns = await getBonusCampaignsByTags(product.tags);

  return product;
}

export async function GetProductHubProductById(id) {
  try {
    const result = (await axios.get(`${config.baseUrl}/api/producthub/product/${id}`)).data;
    return result;
  } catch (e) {
    console.error(`Error: ${e.message}`);
  }
}

export async function GetCampaignByMomentId(id) {
  try {
    const result = (await axios.get(`${config.baseUrl}/api/producthub/getCampaignByMomentId/${id}`))
      .data;
    return result;
  } catch (e) {
    console.error(`Error: ${e.message}`);
  }
}

export async function GetProductHubProductPriceListById(id) {
  try {
    const result = (await axios.get(`${config.baseUrl}/api/producthub/product/${id}/pricelist`))
      .data;
    return result.value;
  } catch (e) {
    console.error(`Error: ${e.message}`);
  }
}

export async function GetTermsAndConditionsGuidById(id) {
  try {
    const result = (
      await axios.get(`${config.baseUrl}/api/producthub/termsandconditions/guid/${id}`)
    ).data;
    return result;
  } catch (e) {
    console.error(`Error: ${e.message}`);
  }
}

export async function getAddonProducts(brand, customerType) {
  try {
    const result = await axios.get(
      `${config.baseUrl}/api/producthub/addons?brand=${brand}&customerType=${customerType}`
    );
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get product addons: ${e.message}`);
  }
}

export async function getProducts(brand, customerType, productCategories, storeIds) {
  try {
    const result = await axios.post(`${config.baseUrl}/api/producthub/products`, {
      brand,
      customerType,
      productCategories,
      storeIds,
      withPrices: true
    });
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get products: ${e.message}`);
    throw e;
  }
}

export async function getHashedCustomer(hashedCustomerId) {
  try {
    const result = await axios.get(
      `${config.conductorUrl}/api/v1/Customer/HashedCustomer/${hashedCustomerId}`
    );
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get hashed customer: ${e.message}`);
    throw e;
  }
}

export async function getFullCustomer(hashId) {
  try {
    const result = await axios.get(`${config.conductorUrl}/api/v1/Customer/HashCustomer/${hashId}`);
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get hashed customer: ${e.message}`);
    throw e;
  }
}

export async function submitAcceptOrders(hashedCustomerId, orderIds, subscriptionConsent) {
  // if(IsGETheme){
  //   const result = await axios.post(
  //       `${config.salesModuleBff}/api/v2/Order/AcceptOrders/${hashedCustomerId}`,
  //       {
  //         orderIds,
  //         subscriptionConsent
  //       }
  //   );
  //   return result.data;
  // }

  try {
    const result = await axios.post(
      `${config.conductorUrl}/api/v1/Order/AcceptOrders/${hashedCustomerId}`,
      {
        orderIds,
        subscriptionConsent
      }
    );
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to submit accepted orders: ${e.message}`);
    throw e;
  }
}

export async function submitConsent(hashedCustomerId, subscriptionConsent) {
  // if(IsGETheme) {
  //   const result = await axios.put(
  //       `${config.salesModuleBff}/api/v2/Order/addconsent/${hashedCustomerId}`,
  //       {
  //         "subscriptionConsent": subscriptionConsent
  //       }
  //   );
  //   return result.data;
  // }

  try {
    const result = await axios.put(
      `${config.conductorUrl}/api/v1/Order/AcceptOrders/AddConsent/${hashedCustomerId}`,
      {
        subscriptionConsent
      }
    );
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to submit consent: ${e.message}`);
    throw e;
  }
}

export async function getProductChoiceProducts() {
  try {
    const result = await axios.get(`${config.baseUrl}/api/producthub/productchoices`);
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get products: ${e.message}`);
  }
}

export async function getProductChoiceProductById(idArray) {
  try {
    const filteredIds = idArray.filter((id) => id !== null && id !== undefined);
    const queryString = filteredIds.map((id) => `ids=${id}`).join("&");
    const result = await axios.get(
      `${config.baseUrl}/api/producthub/productChoicesForSalesmodule?${queryString}`
    );
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get product: ${e.message}`);
  }
}

export async function getPofProduct(productId) {
  try {
    const result = await axios.get(`${config.baseUrl}/api/producthub/pof/${productId}`);
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get pof product: ${e.message}`);
  }
}

export async function getBonusCampaignsByTags(tags) {
  try {
    let tagParams = tags.map((tag) => ["productHubTags", tag]);
    let params = new URLSearchParams(tagParams);
    const result = await axios.get(`${config.conductorUrl}/api/v1/bonus/BonusCampaigns/ByTag`, {
      params
    });
    return result.data;
  } catch (e) {
    //ignore
  }
  return [];
}

export async function getEInvoiceReference(ssn) {
  try {
    let brand = config.theme;
    const result = await axios.post(`${config.baseUrl}/api/EInvoiceReferences/${brand}`, { ssn });
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get eInvoice reference: ${e.message}`);
    throw e;
  }
}

export async function CheckIfStoreIsWeb(productHubId) {
  try {
    const webId = 935;
    const result = await axios.get(`${config.baseUrl}/api/producthub/productsByStoreId/${webId}`);
    let isWeb = result.data.some((item) => item.toString() === productHubId);
    if (isWeb) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.error(`Error: Failed to get productsByStoreId: ${e.message}`);
    return false;
  }
}

export async function getActiveDeliveryPointsBySsn(ssn) {
  try {
    const result = await axios.get(
      `${config.baseUrl}/api/afi/GetActiveDeliveryPoints?ssn=${ssn}&brand=${config.theme}AS`
    );
    console.log(result.data);
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get active deliverypoints: ${e.message}`);
    throw e;
  }
}

export async function getSubscriptionConsent(ssn) {
  try {
    const result = await axios.get(`${config.baseUrl}/api/SamtykkeDb/DoesCustomerHaveConsent`, {
      params: { ssn, brand: IsGETheme ? "GUEN" : config.theme + "AS", consentType: "Legacy1" }
    });
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get subscription consent: ${e.message}`);
    throw e;
  }
}

export async function getInvoiceInfo(ssn, momentId) {
  try {
    const result = await axios.get(`${config.baseUrl}/api/afi/GetCustomerInvoice`, {
      params: { ssn, momentId, brand: config.theme + "AS" }
    });
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get CustomerInvoice: ${e.message}`);
  }
}

export async function getExistingCustomerInfo(ssn, momentId) {
  try {
    const result = await axios.get(`${config.baseUrl}/api/afi/GetCustomerInfo`, {
      params: { ssn, momentId, brand: config.theme + "AS" }
    });
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get CustomerInvoice: ${e.message}`);
  }
}

export async function getIsActiveMobile(ssn, phoneNr) {
  try {
    const result = await axios.post(`${config.sparkleUrl}/api/sparkle/getIsActiveBySsn`, {
      ssn,
      phoneNr
    });
    console.log(result);
    return result.data.active;
  } catch (e) {
    console.error("Failed to get isActiveMobile: " + e.message);
    await reportError(
      "Aktivt nummer check failed",
      "hasActiveSubscription failed for phoneNr: " + phoneNr,
      e
    );
    return false;
  }
}

export async function reportError(title, message, error, sessionId) {
  const { stackTrace, request, response } = error;
  let truncateResponse = response && truncateString(JSON.stringify(response));
  let truncateRequest = request && truncateString(JSON.stringify(request));
  let truncateData = error?.config?.data && truncateString(JSON.stringify(error.config.data));
  message = truncateString(message);
  try {
    await axios.post(`${config.baseUrl}/api/salesmodule/reportError`, {
      title,
      message,
      sessionId,
      error: error !== null ? error.message : "error",
      stackTrace,
      request: truncateRequest,
      requestBody: truncateData,
      response: truncateResponse,
      pageUrl: window.location.href
    });
  } catch (e) {
    console.error(`Error: Failed to report error: ${e.message}`);
  }
}
export async function getProductwithPrices(productHubId) {
  try {
    const result = await axios.get(
      `${config.baseUrl}/api/producthub/product/${productHubId}/pricelist`
    );
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get products: ${e.message}`);
  }
}
export async function getExtraProductWithPrices(idList) {
  try {
    const extraProductPriceInfoList = [];

    for (const id of idList) {
      const result = await axios.get(
        `${config.baseUrl}/api/producthub/addonproductwithpriceinfo/${id}`
      );
      const extraProductPriceInfo = result.data;
      extraProductPriceInfoList.push(extraProductPriceInfo);
    }

    return extraProductPriceInfoList;
  } catch (e) {
    console.error(`Error: Failed to get products: ${e.message}`);
    return null;
  }
}
export async function getPriceSummarys(productHubId, extraProductIds, id) {
  try {
    const result = await axios.post(`${config.baseUrl}/api/producthub/priceSummary`, {
      productHubId,
      extraProductIds,
      id
    });
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get products: ${e.message}`);
    return null;
  }
}
export async function getExistingCustomerSSNAndEmail(phoneNumber, customerId, brand) {
  try {
    const result = await axios.get(`${config.baseUrl}/api/afi/GetCustomerSSN`, {
      params: { phoneNumber: phoneNumber, momentCustomerId: customerId, brand: brand }
    });
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get CustomerInvoice for MinSide: ${e.message}`);
  }
}
export async function reportInfo(title, message) {
  try {
    await axios.post(`${config.baseUrl}/api/salesmodule/reportError`, {
      title,
      message,
      pageUrl: window.location.href
    });
  } catch (e) {
    console.error(`Error: Failed to report error: ${e.message}`);
  }
}
export async function verifyMembershipNumber(medlemsnummer) {
  try {
    const result = await axios.get(
      `${config.conductorUrl}/api/v1/Tobb/VerifyMembershipNumber/${medlemsnummer}`
    );
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to verify membershipNumber: ${e.message}`);
  }
}
export async function submitTobbForm(formObject, tobbId) {
  const form = {
    ssn: formObject.ssn,
    tobbId: tobbId,
    mobile: formObject.mobile,
    customerId: formObject.customerId,
    mdmId: formObject.mdmId
  };
  try {
    const res = await axios.post(`${config.conductorUrl}/api/v1/Tobb/ExternalMembership`, form);
  } catch (e) {
    console.error(`Error: Failed to get order: ${e.message}`);
    throw e;
  }
}
function truncateString(string) {
  return string.length > 3999 ? string.substring(0, 3996) + "..." : string;
}

export async function getCustomerLastActiveProduct(ssn) {
  try {
    const brand = config.theme + "AS";
    const result = await axios.get(
      `${config.conductorUrl}/api/v1/order/GetCustomerLastProduct/${brand}/${ssn}`
    );
    return result.data;
  } catch (e) {
    console.error(`Error: Failed to get CustomerLastActiveProduct: ${e.message}`);
  }
}

export async function getIsProductExchange(ssn, brand, meterPointId) {
  try {
    //const response = await axios.get(`https://localhost:44391/api/afi/getIsProductExchange`, {
    const response = await axios.get(`${config.baseUrl}/api/afi/getIsProductExchange`, {
      params: {
        ssn: ssn,
        brand: brand,
        meterPointId: meterPointId
      }
    });
    return response;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
}
