import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { formSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { pages } from "../../../Utils/CustomerFormHelper";
import { INVOICE_SELECTION_OPTIONS } from "../../../ReduxModules/Reducers/InvoiceReducer";
import {
  setEInvoiceReference,
  setEInvoiceReferenceFound,
  setIsLoadingReference,
  setSelectedIndex as setReduxInvoiceIndex
} from "../../../ReduxModules/Actions/InvoiceActions/InvoiceActions";
import { getEInvoiceReference } from "../../../Utils/AxiosFunctions";
import { setFormEInvoiceReference } from "../../../ReduxModules/Actions/SaleActions/SaleActions";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import WhiteButtonList from "../../ButtonList/WhiteButtonList";
import MobileMascotBlush from "../../../assets/Images/FK/Mobil/MobileMascotBlushOpenEyes.svg";
import PostBox from "../../../assets/Images/FK/PostBoxFramed.svg";
import { IsFKTheme } from "../../../Utils/Themes";
import { InvoicePrices } from "../../../Utils/Prices";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";

function InvoiceMenuPage({ onConfirm, onBack, isMobile, form }) {
  const dispatch = useDispatch();
  const { selectedIndex, isLoadingEInvoiceReference, reference, eInvoiceReferenceFound } =
    useSelector((state) => state.invoiceState);
  const ssn = form.ssn;
  const eInvoiceWarning = useMemo(
    () =>
      !isLoadingEInvoiceReference &&
      (reference === undefined || reference === "" || !eInvoiceReferenceFound),
    [reference, eInvoiceReferenceFound, isLoadingEInvoiceReference]
  );
  const eInvoiceDisabled = useMemo(
    () => !isLoadingEInvoiceReference && !eInvoiceReferenceFound,
    [eInvoiceReferenceFound, isLoadingEInvoiceReference]
  );
  const eInvoiceReferenceNotFoundText = useMemo(() => {
    if (!eInvoiceReferenceFound)
      return "Siden du ikke har valgt eFaktura tidligere må du først velge dette i din nettbank";
    return "Vi fikk ikke sjekket om du kan velge eFaktura nå, men du kan likevel velge dette om du har valgt eFaktura fra andre tilbydere tidligere";
  }, [eInvoiceReferenceFound]);
  const [mobileTryIsUpdated, setMobileTryIsUpdated] = useState(false);

  const loadReference = useCallback(async () => {
    if (!isLoadingEInvoiceReference && (reference === null || (isMobile && !mobileTryIsUpdated))) {
      dispatch(setIsLoadingReference(true));
      let referenceResponse = await getEInvoiceReference(ssn);
      dispatch(setEInvoiceReference(referenceResponse.reference));
      isMobile && dispatch(setFormEInvoiceReference(referenceResponse.reference));
      dispatch(setEInvoiceReferenceFound(referenceResponse.found));
      isMobile && setMobileTryIsUpdated(true);
      dispatch(setIsLoadingReference(false));
    }
  }, [isLoadingEInvoiceReference, reference, ssn, dispatch]);

  useEffect(() => {
    if (!isLoadingEInvoiceReference && (reference === null || (isMobile && !mobileTryIsUpdated))) {
      loadReference();
    }
  }, [isLoadingEInvoiceReference, reference]);

  const setSelectedIndex = (index) => {
    !isMobile && dispatch(setFormEInvoiceReference(reference));
    dispatch(setReduxInvoiceIndex(index));
    handleNext(index);
  };

  function handleNext(index) {
    if (index === INVOICE_SELECTION_OPTIONS.paper) {
      if (isMobile) {
        pageStepActionDispatchers.goToNextStep(window.location.pathname);
      } else {
        pageStepActionDispatchers.addAndGoToNextStep(pages.invoice, pages.invoiceDetails);
      }
    } else {
      pageStepActionDispatchers.removeSteps([
        isMobile ? pages.mobile + pages.invoiceDetails : pages.invoiceDetails
      ]);
      if (onConfirm) {
        onConfirm();
      } else {
        pageStepActionDispatchers.goToNextStep(window.location.pathname);
      }
    }
  }

  const powerOptions = [
    {
      content: (
        <div>
          <div className={"title"}>eFaktura</div>
          <div className={"priceTag"}>0 kr/faktura</div>
          <div className={"grayText"}>
            {eInvoiceWarning
              ? "Vi fikk ikke sjekket om du kan velge eFaktura nå, men du kan likevel velge dette om du har valgt eFaktura fra andre tilbydere tidligere"
              : "Få regningene rett i banken uten å gjøre noe. Bare husk å godkjenne!"}
          </div>
        </div>
      ),
      onClick: () => setSelectedIndex(INVOICE_SELECTION_OPTIONS.eInvoice),
      isLoading: isLoadingEInvoiceReference && reference === null
    },
    {
      content: (
        <div>
          <div className={"title"}>E-post</div>
          <div className={"priceTag"}>0 kr/faktura</div>
          <div className={"grayText"}>Motta faktura på e-post</div>
        </div>
      ),
      onClick: () => setSelectedIndex(INVOICE_SELECTION_OPTIONS.email)
    }
  ];

  const mobileOptions = [
    {
      content: (
        <div>
          <div className={"title"}>eFaktura</div>
          <div className={"priceTag"}>0 kr/faktura</div>
          <div className={"grayText"}>
            {`Velger du eFaktura opprettes det automatisk om du har Alltid eFaktura aktivert i din nettbank. Hvis ikke sendes din første faktura til ${form.email}, og du kan selv aktivere Alltid eFaktura i nettbanken.`}
          </div>
        </div>
      ),
      onClick: () => setSelectedIndex(INVOICE_SELECTION_OPTIONS.eInvoice),
      isLoading: isLoadingEInvoiceReference && reference === null
    }
  ];

  return (
    <FormPageContainer onBack={onBack}>
      <h1 className={"fallIn-"}>Hvordan ønsker du å motta din faktura?</h1>
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img
            className={"frameImage formPage-background fade-"}
            src={isMobile ? MobileMascotBlush : PostBox}
            alt={"bilde"}
          />
        </div>
      )}
      <div className={"slide"}>
        <WhiteButtonList
          buttons={[
            ...(isMobile ? mobileOptions : powerOptions),
            {
              content: (
                  <div>
                    <div className={"title"}>Papirfaktura</div>
                    <div className={"priceTag"}>
                      {isMobile ? InvoicePrices.MobilePaper : InvoicePrices.Paper} kr/faktura
                    </div>
                    <div className={"grayText"}>Motta fakturaene dine på papir i posten</div>
                  </div>
              ),
              onClick: () => setSelectedIndex(INVOICE_SELECTION_OPTIONS.paper)
            }
          ]}
        />
      </div>
    </FormPageContainer>
  );
}

export default connect(formSelector)(InvoiceMenuPage);
