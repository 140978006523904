import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getQueryStrings } from "../../Utils/UrlParser";
import saleActionDispatchers from "../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import FKLoader from "../CustomerForm/FKLoader/FKLoader";
import * as AxiosFunction from "../../Utils/AxiosFunctions";
import FormPageContainer from "../CustomerForm/FormPageContainer/FormPageContainer";
import { pages } from "../../Utils/CustomerFormHelper";
import pageStepActionDispatchers from "../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";

const MoveInLandingPage = ({ match }) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const history = useHistory();

  useEffect(() => {
    onbeforeunload = (e) =>
      `Endringene dine vil bli slettet. Er du sikker på at du vil forlate siden?`;
    return () => {
      onbeforeunload = null;
    };
  });

  useEffect(() => {
    let hash = params.get("hash");
    if (hash) {
      let moveIn = getQueryStrings(search);
      moveIn.moveOutId = isNaN(moveIn.moveOutId) ? 0 : parseInt(moveIn.moveOutId);
      if (!moveIn.moveOutId) {
        handleMoveInExistingCustomer(moveIn);
      } else {
        handleMoveInFromSms(moveIn);
      }
    }
  });

  const handleMoveInExistingCustomer = async (moveIn) => {
    if (moveIn.productHubUtilityId === "0") {
      saleActionDispatchers.setHasChangedProduct(true);
      saleActionDispatchers.setFormFromInternalMoveInInfo(moveIn);
      history.push(pages.changeProduct);
    } else {
      let productHubProduct = await AxiosFunction.GetProductHubProductWithBonuses(
        moveIn.productHubUtilityId
      );
      if (productHubProduct) {
        let guid = await AxiosFunction.GetTermsAndConditionsGuidById(
          productHubProduct.termsAndConditionsId
        );
        moveIn.producthubInfo = {
          id: productHubProduct.id,
          name: productHubProduct.name,
          guid: guid
        };
        moveIn.bonusCampaigns = productHubProduct.bonusCampaigns;
        moveIn.avtaleName = productHubProduct.name;
        saleActionDispatchers.setFormFromInternalMoveInInfo(moveIn);
        history.push(pages.selectProduct);
      }
    }
  };

  const handleMoveInFromSms = (moveIn) => {
    moveIn.ssn = "";
    saleActionDispatchers.setFormFromSmsMoveInInfo(moveIn);
    let [day, month, year] = moveIn.moveInDate.split(".").map((t) => parseInt(t));
    let moveInDate = new Date(year, month - 1, day, 2);
    saleActionDispatchers.addDeliveryPoints({
      address: moveIn.addressText,
      meteringPointIdentification: moveIn.meterPointId,
      moveInDate: moveInDate,
      zipCode: moveIn.postNr,
      city: moveIn.postArea,
      selected: true,
      complete: true,
      rightOfWithdrawal: false
    });
    pageStepActionDispatchers.goToNextStep(pages.moveInLandingPage);
  };

  return (
    <>
      <FormPageContainer hideFooter>
        <FKLoader text={"Vent mens vi gjør ting klart for deg..."} />
      </FormPageContainer>
    </>
  );
};

export default MoveInLandingPage;
