import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formAndUserSelector } from "../../../../ReduxModules/Selectors/SaleSelectors";
import saleActionDispatchers from "../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { generatePath, Route, Switch } from "react-router-dom";
import { pages } from "../../../../Utils/CustomerFormHelper";
import SelectProductPage from "../SelectProduct/SelectProductPage";
import ChooseMobileNumber from "../SelectPhoneNumber/SelectMobileNumber";
import { history } from "../../../../App";
import Phone from "../../Phone/Phone";
import pageStepActionDispatchers from "../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import Name from "../../Name/Name";
import BirthDate from "../../BirthDate/BirthDate";
import AddonProductContainer from "../../AddonProduct/AddonProductContainer";
import ProductGroupContainer from "../../AddonProduct/ProductGroupContainer";
import ChooseMobileSubscriptionReceiver from "../ChooseMobileSubscriptionReceiver";
import Email from "../../Email/Email";
import TransitionRoute from "../../../TransitionRoute/TransitionRoute";

function AddUserContainer({ userId, path, form, user, lastUserStep, firstUserStep }) {
  useEffect(() => {
    pageStepActionDispatchers.setMobileUserSteps(userId);
  }, []);
  if (!user) {
    history.replace(pages.mobile + pages.summary);
  }
  const handleCompleteUserSteps = () => {
    saleActionDispatchers.updateUser(userId, { completed: true });
    history.push(pages.mobile + pages.summary);
  };
  const handleBack = () => {
    if (form.users.some((f) => f.completed)) {
      history.push(pages.mobile + pages.summary);
    } else {
      pageStepActionDispatchers.goToPreviousStep(pages.mobile + pages.summary);
    }
  };
  const onConfirm =
    window.location.pathname === generatePath(lastUserStep, { userId })
      ? handleCompleteUserSteps
      : null;
  const onBack =
    window.location.pathname === generatePath(firstUserStep, { userId }) ? handleBack : null;
  return user ? (
    <>
      <Switch>
        <TransitionRoute
          exact
          path={path}
          render={() => <ChooseMobileSubscriptionReceiver userId={userId} onBack={onBack} />}
        />
        <TransitionRoute
          exact
          path={path + pages.selectProduct}
          render={() => <SelectProductPage userId={userId} onBack={onBack} onConfirm={onConfirm} />}
        />
        <TransitionRoute
          exact
          path={path + pages.customerName}
          render={() => <Name userId={userId} onBack={onBack} />}
        />
        <TransitionRoute
          exact
          path={path + pages.birthDate}
          render={() => <BirthDate userId={userId} />}
        />
        <TransitionRoute
          exact
          path={path + pages.customerEmail}
          render={() => <Email userId={userId} isMobile />}
        />
        <TransitionRoute
          exact
          path={path + pages.mobileSelectNumber}
          render={() => <ChooseMobileNumber userId={userId} onBack={onBack} />}
        />
        <TransitionRoute
          exact
          path={path + pages.mobileNumber}
          render={() => <Phone userId={userId} onConfirm={onConfirm} />}
        />
        <TransitionRoute
          exact
          path={path + pages.addonProduct}
          render={() => <AddonProductContainer v2 userId={userId} onConfirm={onConfirm} />}
        />
        <TransitionRoute
          exact
          path={path + pages.productCheckGroup}
          render={() => <ProductGroupContainer userId={userId} onConfirm={onConfirm} />}
        />
        <TransitionRoute
          exact
          path={path + pages.productRadioGroup}
          render={() => (
            <ProductGroupContainer userId={userId} radioGroup={true} onConfirm={onConfirm} />
          )}
        />
      </Switch>
    </>
  ) : (
    <div></div>
  );
}

export default connect(formAndUserSelector)(AddUserContainer);
