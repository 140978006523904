import React, { useEffect, useState } from "react";
import Loader from "../../CustomerForm/FKLoader/Loader";
import styles from "./ProductInfo.module.scss";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import { connect, useSelector } from "react-redux";
import AddonProduct from "../ProductInfo/AddonProduct";
import { productSalesTypeSelector } from "../../../ReduxModules/Selectors/ProductSelectors";
import { IsFKTheme, IsGETheme } from "../../../Utils/Themes";
import config from "../../../config";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import moment from "moment";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import { IsBedrift, IsForutsigbarBetaling } from "../../../Utils/OrderHelper";
import { AddonProductBedrift } from "./AddonProductBedrift";
import { InfoOutlined } from "@mui/icons-material";

function ProductInfo({ productId, product, customer, order }) {
  const termsBaseUrl =
    config.env === "prod"
      ? "https://avtale.fjordkraft.no/api/term/"
      : "https://test-avtale.fjordkraft.no/api/term/";
  const [modalOpen, setModalOpen] = useState(false);
  let campaignLength = useSelector((state) => state.productState.campaignLength);

  const campaignDuration = () => {
    if (
      campaignLength !== null &&
      campaignLength !== undefined &&
      order?.campaign !== null &&
      order?.powerSaleData !== null
    ) {
      let price = 0;
      let length = 0;

      const campaignId = order?.campaign?.productHubId;
      campaignLength?.forEach((campaign) => {
        if (campaignId === campaign.campaignId) {
          length = campaign?.defaultDuration?.months ?? null;

          const campaignName = order?.campaign?.campaignName;
          if (campaignName?.includes("Energi") || campaignName.includes("ENERGI")) {
            if (order.powerSaleData.priceElementList != null)
              order.powerSaleData.priceElementList.forEach((element) => {
                if (element?.name.includes("Energi") || element?.name.includes("ENERGI")) {
                  price = element?.price;
                } else {
                  if (product.productPriceList !== null && product.productPriceList !== undefined) {
                    product?.productPriceList?.forEach((element) => {
                      if (element?.name.includes("Energi") || element?.name.includes("ENERGI")) {
                        price = element?.vatExclusivePrice;
                      }
                    });
                  }
                }
              });
          } else {
            return null;
          }
        }
      });

      if (price !== 0 && length !== 0) {
        const getDates = oppstart(length);

        const total = price * length;
        return {
          price: price,
          length: length,
          total: total,
          start: getDates.start,
          end: getDates.end
        };
      }
      return null;
    }
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day < 10 ? "0" : ""}${day}.${month < 10 ? "0" : ""}${month}.${year}`;
  };

  const addMonths = (inputDate, numberOfMonths) => {
    const date = new Date(inputDate);
    const originalMonth = date.getMonth();
    date.setMonth(originalMonth + numberOfMonths);

    if (date.getMonth() !== (originalMonth + numberOfMonths) % 12) {
      date.setDate(0);
    }
    return date;
  };

  const oppstart = (length) => {
    if (order.powerSaleData.startUpDate === null || order.powerSaleData.startUpDate === undefined) {
      const startDate = new Date(order.powerSaleData.created);
      const start = new Date(startDate.setDate(startDate.getDate() + 14)); // Adding 14 days
      const end = addMonths(start, length);
      return { start: formatDate(start), end: formatDate(end) };
    } else {
      const start = new Date(order.powerSaleData.startUpDate);
      const end = addMonths(start, length);
      return { start: formatDate(start), end: formatDate(end) };
    }
  };

  const duration = campaignDuration();

  useEffect(() => {
    if (!product && productId) {
      IsBedrift(customer.salesTypeId)
        ? productActionDispatchers.getProductInfoBedrift(productId, order?.campaign?.productHubId)
        : productActionDispatchers.getProductInfo(
            productId,
            order?.campaign?.productHubId,
            order.mobileSaleData
          );
    }
  }, []);

  const handleClickModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const elCertNames = ["Påslag inkl. elsertifikater", "Påslag inkl. elsert"];
  const showPriceInfoBedrift = () => {
    return (
      <>
        {product?.productInfo &&
          product.productInfo.map((item, index) => {
            // Check if both conditions are met: 'PAASLAG' and 'Strøm påslag fast pakkepris'
            const shouldDisplayItem =
              product.productInfo.some((info) => info.externalId === "PAASLAG") &&
              product.productInfo.some((info) => info.name === "Strøm påslag fast pakkepris");
            return (
              (item.externalId === "MAANEDSBELOEP" ||
                (item.externalId === "PAASLAG" && !shouldDisplayItem) ||
                item.name === "Strøm påslag fast pakkepris" ||
                item.externalId === "FASTPRIS") && (
                <div key={index} className={`${styles.productPrice} ${styles.marginBottom}`}>
                  {item.externalId === "PAASLAG" ? (
                    <div>
                      {"Påslag inkl. elsertifikater "}
                      {order?.powerSaleData?.priceElementList?.some(
                        (element) => element.externalId === item.externalId
                      )
                        ? (
                            (product.productPriceList &&
                              product.productPriceList.find((elCert) =>
                                elCertNames.includes(elCert.name)
                              ) !== undefined &&
                              product.productPriceList.find((elCert) =>
                                elCertNames.includes(elCert.name)
                              ).vatExclusivePrice) -
                            item.price * item.factor +
                            order?.powerSaleData?.priceElementList?.find(
                              (element) => element.externalId === item.externalId
                            ).price *
                              item.factor
                          ).toFixed(2)
                        : product.productPriceList &&
                          product.productPriceList.find((elCert) =>
                            elCertNames.includes(elCert.name)
                          ) !== undefined &&
                          product.productPriceList.find((elCert) =>
                            elCertNames.includes(elCert.name)
                          ).vatExclusivePrice}{" "}
                      {item.denomination}
                    </div>
                  ) : item.name === "Strøm påslag fast pakkepris" ? (
                    <div>
                      {"Strøm påslag  "}
                      {order?.powerSaleData?.priceElementList?.some(
                        (element) => element.name === item.name
                      )
                        ? (
                            (product.productPriceList &&
                              product.productPriceList.find((elCert) =>
                                elCertNames.includes(elCert.name)
                              ) !== undefined &&
                              product.productPriceList.find((elCert) =>
                                elCertNames.includes(elCert.name)
                              ).vatExclusivePrice) -
                            item.price * item.factor +
                            order?.powerSaleData?.priceElementList?.find(
                              (element) => element.name === item.name
                            ).price *
                              item.factor
                          ).toFixed(2)
                        : product.productPriceList &&
                          product.productPriceList.find((elCert) =>
                            elCertNames.includes(elCert.name)
                          ) !== undefined &&
                          product.productPriceList.find((elCert) =>
                            elCertNames.includes(elCert.name)
                          ).vatExclusivePrice}{" "}
                      {item.denomination}
                    </div>
                  ) : (
                    <div>
                      {item.name === "Fastpris Strøm" &&
                      product?.productPriceList?.some((item) => item.name === "Variabel Strømpris")
                        ? "Variabel Strømpris"
                        : item.name}{" "}
                      {order?.powerSaleData?.priceElementList?.some(
                        (element) => element.externalId === item.externalId
                      )
                        ? item.denomination === "øre/kWh"
                          ? (
                              order?.powerSaleData?.priceElementList?.find(
                                (element) => element.externalId === item.externalId
                              ).price * item.factor
                            ).toFixed(1)
                          : order?.powerSaleData?.priceElementList?.find(
                              (element) => element.externalId === item.externalId
                            ).price
                        : item.denomination === "øre/kWh"
                          ? (item.price * item.factor).toFixed(1)
                          : item.price}{" "}
                      {item.denomination}
                    </div>
                  )}
                </div>
              )
            );
          })}
      </>
    );
  };

  function formatCampaignInfo(campaignInfo) {
    // Find the heading and add a style to align it to the left
    return campaignInfo.replace(
      /<h5/g,
      '<h5 style="text-align: left; font-family: Fjkfont, NeoSans, Arial, sans-serif;\n}"'
    );
  }

  return (
    <div className={`${IsFKTheme && styles.containerFK} ${IsGETheme && styles.containerGE}`}>
      {IsBedrift(customer.salesTypeId) ? (
        <div className={styles.marginBottom}>
          {productId && (
            <>
              <>
                <div>
                  <a className={styles.productName} onClick={() => handleClickModalOpen(true)}>
                    {product && product.productHubInfo?.name} <InfoOutlined fontSize={"small"} />
                  </a>
                </div>
              </>
              {showPriceInfoBedrift()}
            </>
          )}

          {product && product.campaignInfo && (
            <>
              <div className={styles.boxTitle}>Kampanje</div>
              <div
                className={styles.productInfo}
                dangerouslySetInnerHTML={{ __html: formatCampaignInfo(product.campaignInfo) }}
              />
            </>
          )}

          {order.campaign !== null && duration !== null && campaignLength !== null && (
            <Box sx={{ mb: 3 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "15px" }} variant={"subtitle2"}>
                    Rabatten er gjeldende f.o.m.{" "}
                    {duration && `${duration.start} - t.o.m. ${duration.end}`}{" "}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography sx={{ fontSize: "15px" }} variant={"subtitle2"}>
                    Rabatten utgjør kr {duration && duration.total},- i reduksjon.
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography sx={{ fontSize: "15px", color: "gray" }} variant={"subtitle2"}>
                    ({duration && `${duration.price} kr * ${duration.length}`} mnd ={" "}
                    {duration && duration.total} kr)
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}

          <div className={styles.productInfo}>
            {order?.powerSaleData?.fixedPrice !== null && (
              <>
                <div className={`${styles.productPrice} ${styles.marginBottom}`}>
                  Avtalt årsvolum: {order?.powerSaleData?.fixedPrice?.agreedVolume} kWh
                </div>
                <div className={`${styles.productPrice} ${styles.marginBottom}`}>
                  Sluttdato:{" "}
                  {moment(order?.powerSaleData?.fixedPrice?.endDate).format("DD/MM/YYYY")}
                </div>
                <div className={`${styles.productPrice} ${styles.marginBottom}`}>
                  Forbruksprofil: {order?.powerSaleData?.fixedPrice?.consumptionProfileName}
                </div>
              </>
            )}

            <Dialog
              onClose={handleModalClose}
              aria-labelledby="customized-dialog-title"
              open={modalOpen}
            >
              <DialogTitle id="customized-dialog-title" onClose={handleModalClose}>
                {product && product.productHubInfo?.name}
              </DialogTitle>
              <DialogContent dividers>
                {!product || product.isLoading ? (
                  <div className={styles.marginBottom}>
                    <Loader text={"Henter produktinfo..."} className={styles.loader} />
                  </div>
                ) : (
                  <>
                    <div
                      className={`${styles.productInfo} ${styles.marginBottom}`}
                      dangerouslySetInnerHTML={{ __html: product.ingress }}
                    />
                  </>
                )}
                <br></br>

                {showPriceInfoBedrift()}
                <br></br>
                {order?.powerSaleData?.fixedPrice !== null && (
                  <>
                    <div className={`${styles.productPrice} ${styles.marginBottom}`}>
                      Avtalt årsvolum: {order?.powerSaleData?.fixedPrice?.agreedVolume} kWh
                    </div>
                    <div className={`${styles.productPrice} ${styles.marginBottom}`}>
                      Sluttdato:{" "}
                      {moment(order?.powerSaleData?.fixedPrice?.endDate).format("DD/MM/YYYY")}
                    </div>
                    <div className={`${styles.productPrice} ${styles.marginBottom}`}>
                      Forbruksprofil: {order?.powerSaleData?.fixedPrice?.consumptionProfileName}
                    </div>
                  </>
                )}

                <br></br>
                {!product || product.isLoading ? (
                  <div className={styles.marginBottom}>
                    <Loader text={"Henter produktinfo..."} className={styles.loader} />
                  </div>
                ) : (
                  <>
                    <div
                      className={`${styles.productInfo} ${styles.marginBottom}`}
                      dangerouslySetInnerHTML={{ __html: product.extendedProductInfo }}
                    />
                  </>
                )}
                {!product || product.isLoading ? (
                  <div className={styles.marginBottom}>
                    <Loader text={"Henter produktinfo..."} className={styles.loader} />
                  </div>
                ) : (
                  <>
                    <div
                      className={`${styles.productInfo} ${styles.marginBottom} ${styles.productImage}`}
                      dangerouslySetInnerHTML={{ __html: product.image }}
                    />
                  </>
                )}
                <br></br>
              </DialogContent>
              <DialogActions>
                <Button autoFocus variant="contained" color="success" onClick={handleModalClose}>
                  Lukk
                </Button>
              </DialogActions>
            </Dialog>

            {order?.extraProducts?.map((ep) => (
              <AddonProductBedrift productId={ep.productHubId} extraProduct={ep} key={ep.id} />
            ))}

            {product?.productTerms && (
              <>
                <div className={`${styles.boxTitle} ${styles.marginBottom}`}>Avtalevilkår</div>
                <a
                  className={styles.termsLink}
                  href={termsBaseUrl + product?.productTerms}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link til avtalevilkår
                </a>
              </>
            )}
          </div>
        </div>
      ) : (
        <div>
          {productId && (
            <>
              {!product || product.isLoading ? (
                <div className={styles.marginBottom}>
                  <Loader text={"Henter produktinfo..."} className={styles.loader} />
                </div>
              ) : (
                <>
                  <div className={styles.productName}>{product.productHubInfo?.name}</div>
                  {order?.campaign?.campaignName && (
                    <div
                      className={styles.productInfo}
                      dangerouslySetInnerHTML={{ __html: product.campaignInfo }}
                    />
                  )}
                  <div
                    className={styles.productInfo}
                    dangerouslySetInnerHTML={{
                      __html: order.mobileSaleData
                        ? product && product.productInfo?.data?.data?.salesTextPre
                        : product.productInfo
                    }}
                  />
                  {order.mobileSaleData !== null &&
                    order?.campaign?.campaignName !== null &&
                    order.campaign && (
                      <>
                        <div className={styles.boxTitle}>Kampanje</div>
                        <div className={styles.campaignName}>{order?.campaign?.campaignName}</div>
                      </>
                    )}
                </>
              )}
            </>
          )}
          {order?.extraProducts
            ?.filter((p) => !IsForutsigbarBetaling(p?.productHubId))
            .map((ep) => (
              <AddonProduct productId={ep.productHubId} extraProduct={ep} key={ep.id} />
            ))}
        </div>
      )}
    </div>
  );
}

export default connect(productSalesTypeSelector)(ProductInfo);
