import * as Oidc from "oidc-client";
import config from "../../config";

let oidcConfig = {
  authority: config.trumfUrl,
  //authority: 'https://test.id.trumf.no/',
  client_id: "pnFjordkraft",
  client_name: "Fjordkraft",
  client_secret: config.trumfPassword,
  //client_secret: 'z5dJtf3$QM~zFPHeu@x3RHD#K5tzPUQj',
  redirect_uri: window.location.origin + "/trumf/login",
  automaticSilentRenew: true,
  grantType: "authorization_code",
  response_type: "code",
  scope: "openid http://id.trumf.no/scopes/medlem",
};

export let userManager = new Oidc.UserManager(oidcConfig);

export async function loginRedirect(state) {
  userManager = new Oidc.UserManager(oidcConfig);
  window.sessionStorage.setItem("state", JSON.stringify(state));
  await userManager.signinRedirect({ state: state });
}
