import React from "react";
import styles from "./Icon.module.scss";
import { ReactComponent as Pencil } from "../../assets/Icons/Pencil.svg";
import { ReactComponent as Chevron } from "../../assets/Icons/Chevron.svg";
import { ReactComponent as Clear } from "../../assets/Icons/Clear.svg";
import { ReactComponent as Check } from "../../assets/Icons/Check.svg";
import { ReactComponent as TkClear } from "../../assets/Icons/TkClear.svg";
import { ReactComponent as GeClear } from "../../assets/Icons/GeClear.svg";
import { ReactComponent as Add } from "../../assets/Icons/Add.svg";
import { ReactComponent as ArrowLeft } from "../../assets/Icons/Arrowleft.svg";
import { ReactComponent as WarningCircle } from "../../assets/Icons/WarningCircle.svg";
import { ReactComponent as CheckBox } from "../../assets/Icons/Checkbox.svg";
import { ReactComponent as CheckBoxChecked } from "../../assets/Icons/CheckboxChecked.svg";
import { InfoOutlined } from "@mui/icons-material";

function Icon(props) {
  const getIcon = () => {
    switch (props.icon) {
      case "pencil":
        return (
          <Pencil {...{ ...props, className: `${styles.iconUsingStroke} ${props.className}` }} />
        );
      case "chevron":
        return (
          <Chevron {...{ ...props, className: `${styles.iconUsingStroke} ${props.className}` }} />
        );
      case "clear":
        return <Clear {...{ ...props, className: `${styles.iconUsingFill} ${props.className}` }} />;
      case "check":
        return (
          <Check {...{ ...props, className: `${styles.iconUsingStroke} ${props.className}` }} />
        );
      case "tkClear":
        return (
          <TkClear {...{ ...props, className: `${styles.iconUsingFill} ${props.className}` }} />
        );
      case "geClear":
        return (
            <GeClear {...{ ...props, className: `${styles.iconUsingFill} ${props.className}` }} />
        );
      case "add":
        return <Add {...{ ...props, className: `${styles.iconUsingFill} ${props.className}` }} />;
      case "ArrowLeft":
        return (
          <ArrowLeft {...{ ...props, className: `${styles.iconUsingFill} ${props.className}` }} />
        );
      case "WarningCircle":
        return (
          <WarningCircle
            {...{ ...props, className: `${styles.iconUsingStroke} ${props.className}` }}
          />
        );
      case "checkBox":
        return <CheckBox {...{ ...props, className: `${props.className}` }} />;
      case "checkBoxChecked":
        return (
          <CheckBoxChecked
            {...{ ...props, className: `${styles.iconUsingFill} ${props.className}` }}
          />
        );
      case "info":
        return (
          <InfoOutlined
            {...{ ...props, className: `${styles.iconUsingFill} ${props.className}` }}
          />
        );
      default:
        return <div></div>;
    }
  };

  return getIcon();
}

export default Icon;
