import * as React from "react";
import { useAdditionalSales } from "../../../hooks/additional-sales";
import { AdditionalSalesType } from "../../../types";
import { AdditionalSales } from "./AdditionalSales";

function AdditionalSalesByType({ type }: { type: AdditionalSalesType }) {
  const { data, isLoading, error } = useAdditionalSales(type);

  return <AdditionalSales data={data} isLoading={isLoading} error={error} />;
}

export { AdditionalSalesByType };
