import { PortableText } from "@portabletext/react";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { logError, logError2 } from "../../../index";

const MergeFieldComponent = ({ value, children }) => {
  return (
    <a target="_blank" rel="noreferrer noopener" href={"/"}>
      {children}
    </a>
  );
};

const portabletextComponent = (
  linkStyle,
  termsLink,
  withdrawalLink,
  productName,
  termsLink_2,
  withdrawalLink_2,
  productName_2
) => {
  return {
    marks: {
      linkAnnotation: ({ value, children }) => {
        return (
          <a
            className={linkStyle && linkStyle}
            target="_blank"
            rel="noreferrer noopener"
            href={value?.href}
          >
            {children}
          </a>
        );
      },
      fileAnnotation: ({ value, children }) => {
        return (
          <a
            className={linkStyle && linkStyle}
            target="_blank"
            rel="noreferrer noopener"
            href={value?.href}
          >
            {children}
          </a>
        );
      },
      mergeField: ({ value, children }) => {
        let href = "/";
        let newChildren = children;
        const mergeType = value?.mergeType;

        switch (mergeType) {
          case "productNameType":
            newChildren = productName || children;
            break;
          case "productNameType_2":
            newChildren = productName_2 || children;
            break;
          case "withdrawalType":
            href = withdrawalLink || "#";
            break;
          case "withdrawalType_2":
            href = withdrawalLink_2 || "#";
            break;
          case "termsType":
            href = termsLink || "/";
            break;
          case "termsType_2":
            href = termsLink_2 || "/";
            break;
          default:
            break;
          // Other cases can be added here
        }

        if (mergeType === "productNameType" || mergeType === "productNameType_2") {
          return <span>{newChildren}</span>;
        } else {
          return (
            <a
              className={linkStyle && linkStyle}
              target="_blank"
              rel="noreferrer noopener"
              href={href}
            >
              {newChildren}
            </a>
          );
        }
      }
    }
  };
};

export const PortableAgreementText = ({
  contentArray = [],
  linkStyle,
  termsLink,
  withdrawalLink,
  productName,
  productName_2,
  withdrawalLink_2,
  termsLink_2
}) => {
  return (
    <ErrorBoundary fallback={() => <div>Kunne ikke laste avtale tekst</div>} onError={logError2}>
      {contentArray && contentArray.length > 0 && (
        <PortableText
          value={contentArray}
          components={portabletextComponent(
            linkStyle,
            termsLink,
            withdrawalLink,
            productName,
            termsLink_2,
            withdrawalLink_2,
            productName_2
          )}
        />
      )}
    </ErrorBoundary>
  );
};
