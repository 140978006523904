import React, { useEffect, useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { productCampaignDropdownSelector } from "../ReduxModules/Selectors/ProductSelectors";

function AdditionalProductCampaignDropdownSelect({
  onChange,
  productId,
  product,
  options,
  isLoading,
  error
}) {
  const [campaigns, setCampaigns] = useState([]);
  useEffect(() => {
    setCampaigns(
      product?.availableCampaigns?.map((p) => ({ label: p.campaignName, value: p.campaignId }))
    );
  }, [product]);

  const handleChange = (selectedOptions) => {
    onChange(productId, selectedOptions);
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <div style={{ marginTop: "5px", color: "white" }}>{`Velg kampanje til ${product.name}`}</div>
      <Select
        defaultValue={[]}
        onChange={handleChange}
        isMulti
        name="addons"
        isLoading={isLoading}
        options={campaigns}
        placeholder={"Velg kampanje..."}
      />
      {error && <div>{error}</div>}
    </div>
  );
}

export default connect(productCampaignDropdownSelector)(AdditionalProductCampaignDropdownSelect);
