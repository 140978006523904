import React, { useEffect } from "react";
import FormPageContainer from "../../CustomerForm/FormPageContainer/FormPageContainer";
import { trumfSelector } from "../../../ReduxModules/Selectors/TrumfSelectors";
import { connect } from "react-redux";

function AddTrumfProductError({ momentId, redirectUri, error }) {
  useEffect(() => {
    if (redirectUri) {
      redirect();
    }
  }, []);
  let errorMessage = error.message;
  if (error?.response?.data?.referenceId) {
    errorMessage = `En feil har oppstått. ReferenceId: ${error.response.data.referenceId}`;
  }
  async function redirect() {
    if (!redirectUri) {
      setTimeout(
        () =>
          window.location.replace(
            `${redirectUri}?kundeId=${momentId}&status=error&message=${errorMessage}`
          ),
        2000
      );
    }
  }
  return (
    <FormPageContainer hideFooter={true}>
      <h1>Kunne ikke registrere trumf...</h1>
      <h5>Error: {errorMessage}</h5>
    </FormPageContainer>
  );
}

export default connect(trumfSelector)(AddTrumfProductError);
