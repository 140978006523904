import { call, takeEvery } from "redux-saga/effects";
import * as AxiosFunction from "../../Utils/AxiosFunctions";
import store from "../Store/Store";

function* reportError(action) {
  try {
    const sessionId = store.getState().sessionState.sessionId;
    yield call(AxiosFunction.reportError, action.type, "SAGA ERROR", action.error, sessionId);
  } catch (e) {
    console.log(e);
  }
}

export function* watchReportError() {
  yield takeEvery((action) => /_ERROR$/.test(action.type), reportError);
}
