import {
  GET_ADDON_PRODUCTS,
  GET_ADDON_PRODUCTS_ERROR,
  GET_ADDON_PRODUCTS_SUCCESS,
  GET_PRODUCT_INFO,
  GET_PRODUCT_INFO_ERROR,
  GET_PRODUCT_INFO_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_INFO_BEDRIFT,
  GET_PRODUCT_INFO_BEDRIFT_SUCCESS,
  GET_PRODUCT_INFO_BEDRIFT_ERROR,
  GET_ADDON_PRODUCT_INFO_SUCCESS,
  GET_ADDON_PRODUCT_INFO,
  GET_ADDON_PRODUCT_INFO_ERROR,
  GET_PRICE_INFO,
  GET_PRICE_INFO_SUCCESS,
  GET_PRICE_INFO_ERROR,
  GET_EXTRAPRODUCT_PRICE_INFO,
  GET_EXTRAPRODUCT_PRICE_INFO_SUCCESS,
  GET_EXTRAPRODUCT_PRICE_INFO_ERROR,
  GET_CAMPAIGN_TEXT,
  GET_CAMPAIGN_TEXT_SUCCESS,
  GET_CAMPAIGN_TEXT_ERROR,
  GET_CAMPAIGN_LENGTH,
  GET_CAMPAIGN_LENGTH_SUCCESS,
  GET_CAMPAIGN_LENGTH_ERROR
} from "./ProductActionTypes";

export const getProductInfo = (productId, campaignId, isMobile) => ({
  type: GET_PRODUCT_INFO,
  productId,
  campaignId,
  isMobile
});

export const getProductInfoSuccess = (
  productId,
  productInfo,
  productHubInfo,
  campaignInfo,
  textGroups
) => ({
  type: GET_PRODUCT_INFO_SUCCESS,
  productId,
  productInfo,
  productHubInfo,
  campaignInfo,
  textGroups
});

export const getProductInfoError = (error) => ({
  type: GET_PRODUCT_INFO_ERROR,
  error
});
export const getPriceInfo = (productId) => ({
  type: GET_PRICE_INFO,
  productId
});

export const getPriceInfoSuccess = (priceInfo) => ({
  type: GET_PRICE_INFO_SUCCESS,
  priceInfo
});

export const getPriceInfoError = (error) => ({
  type: GET_PRICE_INFO_ERROR,
  error
});
export const getExtraProductPriceInfo = (productIdList) => {
  return {
    type: GET_EXTRAPRODUCT_PRICE_INFO,
    productIdList // Pass the productIdList as a parameter
  };
};

export const getExtraProductPriceInfoSuccess = (extraProductPriceInfo) => ({
  type: GET_EXTRAPRODUCT_PRICE_INFO_SUCCESS,
  extraProductPriceInfo
});

export const getExtraProductPriceInfoError = (error) => ({
  type: GET_EXTRAPRODUCT_PRICE_INFO_ERROR,
  error
});

export const getProductInfoBedrift = (productId, campaignId) => ({
  type: GET_PRODUCT_INFO_BEDRIFT,
  productId,
  campaignId
});

export const getProductInfoBedriftSuccess = (
  productId,
  productInfo,
  productHubInfo,
  productPriceList,
  campaignInfo,
  ingress,
  extendedProductInfo,
  image,
  productTerms
) => ({
  type: GET_PRODUCT_INFO_BEDRIFT_SUCCESS,
  productId,
  productInfo,
  productHubInfo,
  productPriceList,
  campaignInfo,
  ingress,
  extendedProductInfo,
  image,
  productTerms
});

export const getProductInfoBedriftError = (error) => ({
  type: GET_PRODUCT_INFO_BEDRIFT_ERROR,
  error
});

export const getProducts = (productCategories, storeIds, customerType = "P") => ({
  type: GET_PRODUCTS,
  productCategories,
  storeIds,
  customerType
});

export const getProductsSuccess = (products, bedriftProducts) => ({
  type: GET_PRODUCTS_SUCCESS,
  products,
  bedriftProducts
});

export const getProductsError = (error) => ({
  type: GET_PRODUCTS_ERROR,
  error
});

export const getAddonProductInfo = (productId) => ({
  type: GET_ADDON_PRODUCT_INFO,
  productId: parseInt(productId)
});

export const getAddonProductInfoSuccess = (
  productId,
  addonProductInfo,
  addonProductExtendedInfo,
  productPriceInfo,
  callToActionText,
  textGroups
) => ({
  type: GET_ADDON_PRODUCT_INFO_SUCCESS,
  productId: parseInt(productId),
  addonProductInfo,
  addonProductExtendedInfo,
  productPriceInfo,
  callToActionText,
  textGroups
});

export const getAddonProductInfoError = (error) => ({
  type: GET_ADDON_PRODUCT_INFO_ERROR,
  error
});

export const getAddonProducts = () => ({
  type: GET_ADDON_PRODUCTS
});

export const getAddonProductsSuccess = (addonProducts) => ({
  type: GET_ADDON_PRODUCTS_SUCCESS,
  addonProducts
});

export const getAddonProductsError = (error) => ({
  type: GET_ADDON_PRODUCTS_ERROR,
  error
});

export const getCampaignText = (campaignName, isMobile) => ({
  type: GET_CAMPAIGN_TEXT,
  campaignName,
  isMobile
});

export const getCampaignTextSuccess = (campaignText) => ({
  type: GET_CAMPAIGN_TEXT_SUCCESS,
  campaignText
});

export const getCampaignTextError = (error) => ({
  type: GET_CAMPAIGN_TEXT_ERROR,
  error
});

export const getCampaignLength = (id, campaignId) => ({
  type: GET_CAMPAIGN_LENGTH,
  id,
  campaignId
});

export const getCampaignLengthSuccess = (campaignLength) => ({
  type: GET_CAMPAIGN_LENGTH_SUCCESS,
  campaignLength
});

export const getCampaignLengthError = (error) => ({
  type: GET_CAMPAIGN_LENGTH_ERROR,
  error
});
