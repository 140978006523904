import styles from "../DeliveryPoints/NewDeliveryPoint/NewDeliveryPointMovingDate/DeliveryPointMovingDate.module.scss";
import React from "react";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { deliveryPointV2Selector } from "../../../ReduxModules/Selectors/SaleSelectors";
import { pages } from "../../../Utils/CustomerFormHelper";
import { IsFKTheme } from "../../../Utils/Themes";
import WhiteButtonList from "../../ButtonList/WhiteButtonList";
import Calendar from "../../../assets/Images/FK/CalendarFramed.svg";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { useParams } from "react-router-dom";

function BedriftMovingDate(props) {
  const params = useParams();

  const handleMovingDateChange = (moveInDate) => {
    if (moveInDate) {
      moveInDate.setHours(moveInDate.getHours() + 2);
    }

    props.deliveryPoints.map(
      (item) =>
        item.selected === true &&
        saleActionDispatchers.updateDeliveryPointAction(item.id, {
          moveInDate,
          complete: true,
          selected: true
        })
    );

    pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
  };

  const handleGoToCalendar = () => {
    pageStepActionDispatchers.addAndGoToNextStep(
      window.location.pathname,
      pages.bedrift + pages.bedriftCalendar,
      params
    );
  };

  const handleBack = () => {
    pageStepActionDispatchers.goToPreviousStep(window.location.pathname, params);
  };

  return (
    <FormPageContainer onBack={handleBack} currentStep={pages.bedriftMovingDate}>
      <h2 className={`${styles.header} fallIn-`}>Når skal strømavtalen gjelde fra?</h2>
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img className={"frameImage formPage-background fade-"} src={Calendar} alt="kalender" />
        </div>
      )}
      <div className={"slide"}>
        <div className={styles.btnContainer}>
          <WhiteButtonList
            buttons={[
              {
                content: (
                  <div>
                    <div className={"title"}>Oppstart snarest mulig!</div>
                  </div>
                ),
                onClick: () => handleMovingDateChange(new Date(), false)
              },
              {
                content: <div className={"title"}>Velg en senere dato</div>,
                icon: "calendar",
                onClick: handleGoToCalendar
              }
            ]}
          />
        </div>
      </div>
    </FormPageContainer>
  );
}

export default connect(deliveryPointV2Selector)(BedriftMovingDate);
