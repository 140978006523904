import React from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./CalendarSelector.scss";
import Icon from "../Icon/Icon";
import { IsFKTheme } from "../../Utils/Themes";
import { getMonthLong } from "../../Utils/HelperFunctions";

function CalendarSelector({ onChange, className, value }) {
  const onClickDay = (value) => {
    if (typeof onChange === "function") {
      onChange(value);
    }
  };
  return (
    <div className={IsFKTheme ? `calendarContainer calendarFK` : "calendarContainer"}>
      <Calendar
        nextLabel={<Icon className={"ArrowBtn"} icon={"chevron"} />}
        prevLabel={
          <div className={"ArrowBtnLeft"}>
            <Icon className={"ArrowBtn"} icon={"chevron"} />
          </div>
        }
        next2Label={null}
        prev2Label={null}
        navigationLabel={({ date, locale }) =>
          `${getMonthLong(date)}, ${date.toLocaleString("default", { year: "numeric" })}`
        }
        maxDetail={"month"}
        minDetail={"month"}
        formatDay={(locale, date) => date.getDate("dd")}
        onClickDay={onClickDay}
        className={className}
        value={value}
      />
    </div>
  );
}

export default CalendarSelector;
