import CalendarSelector from "../../Calendar/CalendarSelector";
import styles from "../DeliveryPoints/NewDeliveryPoint/NewDeliveryPointMovingDate/DeliveryPointMovingDate.module.scss";
import React, { useState } from "react";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import {
  deliveryPointSelector,
  deliveryPointV2Selector
} from "../../../ReduxModules/Selectors/SaleSelectors";
import { history } from "../../../App";
import { pages } from "../../../Utils/CustomerFormHelper";
import { addDays } from "../../../Utils/HelperFunctions";
import ConfirmDateModal from "../DeliveryPoints/NewDeliveryPoint/NewDeliveryPointMovingDate/DeliveryPointCalendar/ConfirmDateModal";
import { IsFKTheme } from "../../../Utils/Themes";
import PowerMeter from "../../../assets/Images/FK/CalendarFramed.svg";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { useParams } from "react-router-dom";

function BedriftCalendar(props) {
  const { deliveryPoints } = props;
  const { moveInDate } = deliveryPoints.find((item) => item.selected === true);
  const params = useParams();

  const handleMovingDateClick = (moveInDate) => {
    handleMovingDateChange(moveInDate);
  };
  const handleMovingDateChange = (moveInDate) => {
    if (moveInDate) {
      moveInDate.setHours(moveInDate.getHours() + 2);
    }
    deliveryPoints.map(
      (item) =>
        item.selected === true &&
        saleActionDispatchers.updateDeliveryPointAction(parseInt(item.id), { moveInDate })
    );
  };

  const handleConfirm = () => {
    deliveryPoints.map(
      (item) =>
        item.selected === true &&
        saleActionDispatchers.updateDeliveryPointAction(parseInt(item.id), {
          complete: true,
          selected: true
        })
    );
    pageStepActionDispatchers.removeAndGoToNextStep(pages.bedrift + pages.bedriftCalendar, params);
  };

  const handleBack = () => {
    pageStepActionDispatchers.goToPreviousStep(window.location.pathname, params);
    pageStepActionDispatchers.removeSteps([pages.bedrift + pages.bedriftCalendar], params);
  };

  return (
    <FormPageContainer onBack={handleBack} currentStep={pages.bedriftCalendar}>
      <h2 className={`${styles.header} fallIn-`}>Når skal strømavtalen gjelde fra?</h2>
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img
            className={"frameImage formPage-background fade-"}
            src={PowerMeter}
            alt="PowerMeter"
          />
        </div>
      )}
      <div className={"slide"}>
        <CalendarSelector onChange={handleMovingDateClick} value={moveInDate} />
        <br></br>
        <button
          disabled={!moveInDate}
          className={`btnPrimary fadeDownButton ${styles.button}`}
          onClick={handleConfirm}
        >
          Bekreft
        </button>
      </div>
    </FormPageContainer>
  );
}

export default connect(deliveryPointV2Selector)(BedriftCalendar);
