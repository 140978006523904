import React, { useEffect } from "react";
import AddTrumfProduct from "./AddTrumfProduct";
import { Route, Switch } from "react-router-dom";
import AddTrumfProductError from "./AddTrumfProductError";
import { pages } from "../../../Utils/CustomerFormHelper";
import { getQueryStrings } from "../../../Utils/UrlParser";
import trumfActionDispatchers from "../../../ReduxModules/Actions/TrumfActions/TrumfActionDispatchers";
import { history } from "../../../App";
import { queryStringSelector } from "../../../ReduxModules/Selectors/TrumfSelectors";
import { connect } from "react-redux";
import FormPageContainer from "../../CustomerForm/FormPageContainer/FormPageContainer";
import Loader from "../../CustomerForm/FKLoader/Loader";

function AddTrumfProductPage({ location, redirectUri, momentId, mdmId, isQueryStringsLoaded }) {
  useEffect(() => {
    if (!isQueryStringsLoaded) {
      const queryStrings = getQueryStrings(location.search);
      let newRedirectUri = queryStrings && queryStrings.redirectUri;
      let newMomentId = queryStrings && queryStrings.momentId;
      let newMdmId = queryStrings && queryStrings.mdmId;
      if (!newMomentId && !newMdmId) {
        const state = JSON.parse(window.sessionStorage.getItem("state"));
        if (!state || (!state.momentId && !state.mdmId)) {
          trumfActionDispatchers.setError(new Error("Missing customer reference"));
          history.push(pages.addTrumfProductError);
        } else {
          newRedirectUri = state.redirectUri;
          newMomentId = state.momentId;
        }
      }
      trumfActionDispatchers.setQueryStrings(newRedirectUri, newMomentId, newMdmId);
    }
  });
  return !isQueryStringsLoaded ? (
    <FormPageContainer hideFooter>
      <Loader text={"Laster..."} />
    </FormPageContainer>
  ) : (
    <Switch>
      <Route
        exact
        path={pages.addTrumfProductError}
        render={() => (
          <AddTrumfProductError momentId={momentId} mdmId={mdmId} redirectUri={redirectUri} />
        )}
      />
      <Route
        path={pages.addTrumfProduct}
        render={() => <AddTrumfProduct momentId={momentId} mdmId={mdmId} />}
      />
    </Switch>
  );
}

export default connect(queryStringSelector)(AddTrumfProductPage);
