import SimpleInput from "../SimpleInput/SimpleInput";
import styles from "./MeterNumberPage.module.scss";
import Modal from "../CustomerForm/Modal";
import React, { useEffect, useState } from "react";
import { GetOrderMeterNumber, GetOrderAddress } from "../../Utils/OrderHelper";
import customerActionDispatchers from "../../ReduxModules/Actions/CustomerActions/CustomerActionDispatchers";

function OrderInfo({ order }) {
  const [message, setMessage] = useState("");
  const [inputEnabled, setInputEnabled] = useState(false);
  const meterNumber = GetOrderMeterNumber(order);
  const address = GetOrderAddress(order);

  useEffect(() => {
    setMessage(getMessage());
  }, []);
  function getMessage() {
    if (order.elhubData) {
      //LÅS INPUT, SKRIV INN METERNUMBER, LEGG TIL TITTEL AT FINNES
      return (
        <h4>
          Målernummer på adresse {address} er allerede registrert. Kontakt <a>kundeservice</a> hvis
          feil.
        </h4>
      );
    } else if (meterNumber) {
      //TITTEL AT VI HAR FEIL METERNUMBER, LEGG TIL METERNUMBER I INPUT
      setInputEnabled(true);
      return <h4>{`Feil målernummer registrert på adresse ${address}:`}</h4>;
    } else {
      //STANDARD, LA DE SKRIVE INN MÅLERNUMMER
      setInputEnabled(true);
      return <h4>{`Skriv inn målernummer til adresse ${address}:`}</h4>;
    }
  }
  const onChange = (value) => {
    customerActionDispatchers.updateOrderMeterNumber(order.id, value);
  };

  return (
    <div>
      {message}
      <SimpleInput
        className={styles.powerMeterInput}
        label={"Målernummer"}
        value={meterNumber}
        type={"meterNumber"}
        onChange={onChange}
        placeholder={"Ditt målernummer"}
        showErase={!!meterNumber}
        showWhiteBox
        autofocus
        disabled={!inputEnabled}
      />
      {inputEnabled && (
        <div className={styles.modal}>
          <Modal></Modal>
        </div>
      )}
    </div>
  );
}

export default OrderInfo;
