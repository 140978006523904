import * as update from "immutability-helper";
import {
  ADD_DELIVERY_POINTS,
  GET_ELHUB_DATA,
  GET_ELHUB_DATA_ERROR,
  GET_ELHUB_DATA_SUCCESS,
  SELECT_DELIVERY_POINT,
  SET_STATE,
  INITIALIZE_DELIVERYPOINT_DATA,
  INITIALIZE_DELIVERYPOINT_DATA_SUCCESS,
  INITIALIZE_DELIVERYPOINT_DATA_ERROR,
  PARSE_QUERY_STRING_SUCCESS,
  UPDATE_DELIVERY_POINT,
  SET_CURRENT_DELIVERY_POINT_ID,
  START_DELIVERY_POINT_STEPS
} from "../Actions/SaleActions/SaleActionTypes";
import * as _ from "lodash";
import { GET_EXISTING_CUSTOMER_DATA_BY_SSN_SUCCESS } from "../Actions/CustomerActions/CustomerActionTypes";

const initialNewDeliveryPoint = {
  id: null,
  address: "",
  zipCode: "",
  city: "",
  meterIdentification: "",
  moveInDate: null,
  elhubData: null,
  complete: false,
  selected: false,
  priceArea: null,
  rightOfWithdrawal: null
};
const initialState = {
  initialized: false,
  isInitializing: false,
  currentId: null,
  deliveryPoints: [],
  selectedDeliveryPoints: [],
  newDeliveryPoint: { ...initialNewDeliveryPoint },
  elhubData: null,
  isLoading: false,
  error: null
};

const deliveryPointReducer = (state = initialState, action) => {
  let newDeliveryPoints = [];
  switch (action.type) {
    case SET_CURRENT_DELIVERY_POINT_ID:
      return update(state, {
        currentId: { $set: action.id },
        newDeliveryPoint: {
          $set:
            state.newDeliveryPoint.id !== action.id
              ? { ...initialNewDeliveryPoint }
              : state.newDeliveryPoint
        } // todo: refactor this mess
      });
    case UPDATE_DELIVERY_POINT:
      return update(state, {
        deliveryPoints: {
          $set: state.deliveryPoints.map((dp) =>
            dp.id === action.id ? { ...dp, complete: false, ...action.deliveryPoint } : dp
          )
        } // complete to false if we detect any changes
      });

    case START_DELIVERY_POINT_STEPS:
      if (!action.newDeliveryPointId) {
        return state;
      }
      newDeliveryPoints = [...state.deliveryPoints];
      newDeliveryPoints.push({ ...initialNewDeliveryPoint, id: action.newDeliveryPointId });
      return update(state, {
        deliveryPoints: {
          $set: newDeliveryPoints
        }
      });

    case ADD_DELIVERY_POINTS:
      let id = _.isEmpty(state.deliveryPoints)
        ? 1
        : Math.max(...state.deliveryPoints.map((dp) => dp.id)) + 1;
      if (_.isArray(action.deliveryPoints)) {
        for (let dp of action.deliveryPoints) {
          id = id + 1;
          newDeliveryPoints.push({ ...dp, id });
        }
      } else {
        id++;
        newDeliveryPoints.push({ ...action.deliveryPoints, id });
      }
      return update(state, {
        deliveryPoints: { $set: state.deliveryPoints.concat(newDeliveryPoints) },
        newDeliveryPoint: { $set: { ...initialNewDeliveryPoint } },
        currentId: { $set: id }
      });

    case SELECT_DELIVERY_POINT:
      return update(state, {
        deliveryPoints: {
          $set: state.deliveryPoints.map((dp) => {
            return dp.id === action.deliveryPoint.id ? { ...dp, selected: !dp.selected } : dp;
          })
        }
      });

    // ASYNC CALLS
    case INITIALIZE_DELIVERYPOINT_DATA:
      return update(state, {
        initializing: { $set: true },
        initialized: { $set: true }
      });

    case GET_EXISTING_CUSTOMER_DATA_BY_SSN_SUCCESS:
      if (!_.isEmpty(action.deliveryPoints)) {
        let nextId = _.isEmpty(state.deliveryPoints)
          ? 1
          : Math.max(...state.deliveryPoints.map((dp) => dp.id)) + 1;
        for (let newDeliveryPoint of action.deliveryPoints) {
          const existingDeliveryPoint = state.deliveryPoints.find(
            (dp) =>
              dp.elhubData?.meterIdentification === newDeliveryPoint.elhubData.meterIdentification
          );
          if (existingDeliveryPoint) {
            newDeliveryPoints.push({ ...existingDeliveryPoint, ...newDeliveryPoint });
          } else {
            newDeliveryPoints.push({ ...newDeliveryPoint, id: nextId++ });
          }
        }
      }

      return update(state, {
        deliveryPoints: {
          $set: _.sortBy(_.uniqBy(newDeliveryPoints.concat(state.deliveryPoints), "id"), "id")
        }, // add updated/new deliverypoints first, then remove duplicates, then sort.
        loadingWtf: { $set: false }
      });

    case INITIALIZE_DELIVERYPOINT_DATA_SUCCESS:
      if (action.isBedrift && action.bedriftSearch) {
        let nextId = _.isEmpty(state.deliveryPoints)
          ? 1
          : Math.max(...state.deliveryPoints.map((dp) => dp.id)) + 1;
        for (let e of action.bedriftSearch) {
          newDeliveryPoints.push({
            id: nextId,
            address: e.address,
            zipCode: e.zipCode,
            city: e.postArea,
            elhubData:
              e.elhubHit === true
                ? {
                    meterIdentification: e.meterNumber,
                    meteringPointIdentification: e.meterPointId
                  }
                : null,
            priceArea: e.priceArea
          });
          nextId++;
        }
      } else if (_.isArray(action.elhubData)) {
        let nextId = _.isEmpty(state.deliveryPoints)
          ? 1
          : Math.max(...state.deliveryPoints.map((dp) => dp.id)) + 1;
        for (let e of action.elhubData) {
          newDeliveryPoints.push({
            id: nextId,
            address: action.address,
            zipCode: action.zipCode,
            city: action.city,
            elhubData: e,
            priceArea: action.priceArea
          });
          nextId++;
        }
      } else if (action.address) {
        newDeliveryPoints.push({
          id: _.isEmpty(state.deliveryPoints)
            ? 1
            : Math.max(...state.deliveryPoints.map((dp) => dp.id)) + 1,
          address: action.address,
          zipCode: action.zipCode,
          city: action.city,
          elhubData: action.elhubData,
          priceArea: action.priceArea
        });
      } else {
        newDeliveryPoints = [];
      }
      return update(state, {
        initializing: { $set: false },
        deliveryPoints: action.isBedrift
          ? { $set: state.deliveryPoints.concat(newDeliveryPoints) }
          : {
              $set: _.uniqBy(
                state.deliveryPoints.concat(newDeliveryPoints),
                (o) => o.elhubData?.meterIdentification
              )
            } // if deliverypoint already exist, don't add it to list. (filtered by uniqBy)
      });

    case INITIALIZE_DELIVERYPOINT_DATA_ERROR:
      return update(state, {
        initializing: { $set: false },
        elhubData: { $set: null }
      });

    case GET_ELHUB_DATA:
      return update(state, {
        isLoading: { $set: true }
      });

    case GET_ELHUB_DATA_SUCCESS:
      if (_.isArray(action.data) && action.data.length > 0) {
        if (!action.isBedrift) {
          let nextId = _.isEmpty(state.deliveryPoints)
            ? 1
            : Math.max(...state.deliveryPoints.map((dp) => dp.id)) + 1;
          for (let e of action.data) {
            let latestDeliveryPoint = state.deliveryPoints[state.deliveryPoints.length - 1];
            if (latestDeliveryPoint.complete === false) {
              latestDeliveryPoint.priceArea = action.priceArea;
              latestDeliveryPoint.elhubData = e;
            } else {
              newDeliveryPoints.push({
                id: nextId,
                address: action.address,
                zipCode: action.zipCode,
                city: action.city,
                priceArea: action.priceArea,
                elhubData: e
              });
              nextId++;
            }
          }
        }
      }
      if (action.isBedrift) {
        const newArray = [...state.deliveryPoints];
        newArray[action.id - 1].elhubData = {};
        newArray[action.id - 1].elhubData.meterIdentification = action.data[0].meterIdentification;
        newArray[action.id - 1].elhubData.meteringPointIdentification =
          action.data[0].meteringPointIdentification;
        return update(state, {
          isLoading: { $set: false },
          elhubData: { $set: action.data },
          deliveryPoints: { $set: newArray }
        });
      } else {
        const newDeliveryPointer =
          newDeliveryPoints.length === 1
            ? newDeliveryPoints[0]
            : newDeliveryPoints.length === 0
              ? state.newDeliveryPoint
              : { ...initialNewDeliveryPoint };
        newDeliveryPointer.priceArea = action.priceArea;
        return update(state, {
          isLoading: { $set: false },
          elhubData: { $set: action.data },
          newDeliveryPoint: { $set: newDeliveryPointer },
          deliveryPoints: { $set: state.deliveryPoints.concat(newDeliveryPoints) }
        });
      }

    case GET_ELHUB_DATA_ERROR:
      return update(state, {
        isLoading: { $set: false },
        elhubData: { $set: null },
        error: { $set: action.error }
      });

    case PARSE_QUERY_STRING_SUCCESS:
      if (!_.isEmpty(action.formData.deliveryPoints)) {
        return update(state, {
          initialized: { $set: true },
          deliveryPoints: {
            $set: action.formData.deliveryPoints.map((dp, i) => ({ ...dp, id: i }))
          }
        });
      } else {
        return state;
      }
    case SET_STATE:
      return { ...state, ...action.state.deliveryPointState };
    default:
      return state;
  }
};

export default deliveryPointReducer;
