import {
  ADD_DELIVERY_POINTS,
  COMPLETE_LOAD_OF_INVOICE_ELHUB_DATA,
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_INFO_ERROR,
  GET_CUSTOMER_INFO_SUCCESS,
  GET_ELHUB_DATA,
  GET_ELHUB_DATA_ERROR,
  GET_ELHUB_DATA_SUCCESS,
  SELECT_DELIVERY_POINT,
  UPDATE_FORM,
  UPDATE_NEW_DELIVERY_POINT,
  SUBMIT_FORM,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_ERROR,
  RELOAD_APP_AND_UPDATE_CACHE,
  SET_STATE,
  GET_ADDONPRODUCT,
  GET_ADDONPRODUCT_SUCCESS,
  GET_ADDONPRODUCT_ERROR,
  PARSE_QUERY_STRING,
  PARSE_QUERY_STRING_SUCCESS,
  PARSE_QUERY_STRING_ERROR,
  INITIALIZE_DELIVERYPOINT_DATA,
  INITIALIZE_DELIVERYPOINT_DATA_SUCCESS,
  INITIALIZE_DELIVERYPOINT_DATA_ERROR,
  UPDATE_DELIVERY_POINT,
  SET_CURRENT_DELIVERY_POINT_ID,
  GET_PRODUCT_INFO,
  GET_PRODUCT_INFO_SUCCESS,
  GET_PRODUCT_INFO_ERROR,
  GET_BANK_ID_TOKEN_RESPONSE,
  GET_BANK_ID_CUSTOMER_INFO_SUCCESS,
  GET_BANK_ID_CUSTOMER_INFO_ERROR,
  UPDATE_TRUMF_ID,
  SET_FORM_FROM_SMS_MOVE_IN_INFO,
  SET_FORM_FROM_INTERNAL_MOVE_IN_INFO,
  SET_PRODUCT_HUB_INFO,
  SET_HAS_CHANGED_PRODUCT,
  SELECT_ADDON_PRODUCT,
  SET_E_INVOICE_REFERENCE,
  SET_SESSION_ID,
  REMOVE_FAMILY_MEMBER,
  UPDATE_FAMILY_MEMBER,
  START_FAMILY_MEMBER_STEPS,
  START_DELIVERY_POINT_STEPS,
  SUBMIT_MOBILE_FORM,
  SUBMIT_MOBILE_FORM_ERROR,
  SUBMIT_MOBILE_FORM_SUCCESS,
  SUBMIT_BEDRIFT_FORM,
  SUBMIT_BEDRIFT_FORM_ERROR,
  SUBMIT_BEDRIFT_FORM_SUCCESS
} from "./SaleActionTypes";
import { getAgeFromBirthDate, getAgeFromSsn, uuidv4 } from "../../../Utils/HelperFunctions";
import { history } from "../../../App";
import { getMobileUserSteps, pages } from "../../../Utils/CustomerFormHelper";
import * as _ from "lodash";
import store from "../../Store/Store";
import { generatePath } from "react-router-dom";
import { SALE_CONTEXT } from "../../Sagas/SaleSaga";

export const getProductInfo = (productId) => ({
  type: GET_PRODUCT_INFO,
  productId
});
export const getProductInfoSuccess = (productInfo) => ({
  type: GET_PRODUCT_INFO_SUCCESS,
  productInfo
});
export const getProductInfoError = (error) => ({
  type: GET_PRODUCT_INFO_ERROR,
  error
});
export const submitMobileFormAction = (form, products) => ({
  type: SUBMIT_MOBILE_FORM,
  form,
  products
});
export const submitMobileFormSuccessAction = (response) => ({
  type: SUBMIT_MOBILE_FORM_SUCCESS,
  response
});

export const submitMobileFormErrorAction = (error) => ({
  type: SUBMIT_MOBILE_FORM_ERROR,
  error
});
export const submitFormAction = (form) => {
  const products = store.getState().productState.products;
  return {
    type: SUBMIT_FORM,
    form,
    products
  };
};

export const submitFormSuccessAction = (response) => ({
  type: SUBMIT_FORM_SUCCESS,
  response
});

export const submitFormErrorAction = (error) => ({
  type: SUBMIT_FORM_ERROR,
  error
});

export const submitBedriftFormAction = (form, deliveryPoints) => {
  return {
    type: SUBMIT_BEDRIFT_FORM,
    form,
    deliveryPoints
  };
};

export const submitBedriftFormSuccessAction = (response) => ({
  type: SUBMIT_BEDRIFT_FORM_SUCCESS,
  response
});

export const submitBedriftFormErrorAction = (error) => ({
  type: SUBMIT_BEDRIFT_FORM_ERROR,
  error
});

export const setStateAction = (state) => ({
  type: SET_STATE,
  state
});

export const updateFormAction = (form) => ({
  type: UPDATE_FORM,
  form
});
export const setCurrentDeliveryPointId = (id) => ({
  type: SET_CURRENT_DELIVERY_POINT_ID,
  id
});
export const updateDeliveryPointAction = (id, deliveryPoint) => ({
  type: UPDATE_DELIVERY_POINT,
  id,
  deliveryPoint
});
export const updateNewDeliveryPointAction = (newDeliveryPoint) => ({
  type: UPDATE_NEW_DELIVERY_POINT,
  newDeliveryPoint
});

export const addDeliveryPoints = (deliveryPoints) => ({
  type: ADD_DELIVERY_POINTS,
  deliveryPoints
});

export const selectDeliveryPoint = (deliveryPoint) => ({
  type: SELECT_DELIVERY_POINT,
  deliveryPoint
});

export const getCustomerInfoAction = (ssn, phone, bankId = null) => ({
  type: GET_CUSTOMER_INFO,
  ssn,
  phone,
  bankId
});

export const getCustomerInfoSuccessAction = (bisnodeData, bankId) => ({
  type: GET_CUSTOMER_INFO_SUCCESS,
  bisnodeData,
  bankId
});

export const getCustomerInfoErrorAction = (error) => ({
  type: GET_CUSTOMER_INFO_ERROR,
  error
});

export const initializeDeliveryPoints = () => {
  const { ssn, address, zipCode, city, saleContext } = store.getState().saleState.form;
  const isBedrift = saleContext === SALE_CONTEXT.bedrift;
  return initializeDeliveryPointDataAction({ ssn, address, zipCode, city, isBedrift });
};
export const initializeDeliveryPointDataAction = ({
  ssn,
  address,
  zipCode,
  city,
  isBedrift,
  bedriftSearch
}) => ({
  type: INITIALIZE_DELIVERYPOINT_DATA,
  ssn,
  address,
  zipCode,
  city,
  isBedrift,
  bedriftSearch
});

export const initializeDeliveryPointDataSuccessAction = (
  elhubData,
  address,
  zipCode,
  city,
  priceArea,
  isBedrift,
  bedriftSearch
) => ({
  type: INITIALIZE_DELIVERYPOINT_DATA_SUCCESS,
  elhubData,
  address,
  zipCode,
  city,
  priceArea,
  isBedrift,
  bedriftSearch
});

export const initializeDeliveryPointDataErrorAction = (error, address, zipCode, city) => ({
  type: INITIALIZE_DELIVERYPOINT_DATA_ERROR,
  error,
  address,
  zipCode,
  city
});

export const getElhubDataAction = (
  ssn,
  address,
  zipCode,
  meterIdentification,
  id,
  priceArea,
  isBedrift
) => ({
  type: GET_ELHUB_DATA,
  ssn,
  address,
  zipCode,
  meterIdentification,
  id,
  priceArea,
  isBedrift
});

export const getElhubDataSuccessAction = (data, id, priceArea, isBedrift) => ({
  type: GET_ELHUB_DATA_SUCCESS,
  data,
  id,
  priceArea,
  isBedrift
});

export const getElhubDataErrorAction = (error, id) => ({
  type: GET_ELHUB_DATA_ERROR,
  error,
  id
});

export const completeLoadOfInvoiceElhubDataAction = () => ({
  type: COMPLETE_LOAD_OF_INVOICE_ELHUB_DATA
});

export const reloadAppAndUpdateCacheAction = (registration) => ({
  type: RELOAD_APP_AND_UPDATE_CACHE,
  registration
});

export const getAddonProductAction = (brand, customerType) => ({
  type: GET_ADDONPRODUCT,
  brand,
  customerType
});

export const getAddonProductSuccessAction = (data) => ({
  type: GET_ADDONPRODUCT_SUCCESS,
  data
});

export const getAddonProductErrorAction = (error) => ({
  type: GET_ADDONPRODUCT_ERROR,
  error
});

export const parseQueryStringAction = (queryString, isMobile, isBedrift) => ({
  type: PARSE_QUERY_STRING,
  queryString,
  isMobile,
  isBedrift
});

export const parseQueryStringSuccessAction = (payload) => ({
  type: PARSE_QUERY_STRING_SUCCESS,
  formData: payload.formData,
  bonusCampaigns: payload.bonusCampaigns ?? []
});

export const parseQueryStringErrorAction = (error) => ({
  type: PARSE_QUERY_STRING_ERROR,
  error
});

export const getBankIdCustomerInfoAction = (code, error, subtype) => ({
  type: GET_BANK_ID_TOKEN_RESPONSE,
  code,
  error,
  subtype
});

export const getBankIdCustomerInfoSuccessAction = (customerInfo) => ({
  type: GET_BANK_ID_CUSTOMER_INFO_SUCCESS,
  customerInfo
});

export const getBankIdCustomerInfoErrorAction = (error) => ({
  type: GET_BANK_ID_CUSTOMER_INFO_ERROR,
  error
});

export const updateTrumfId = (trumfId) => ({
  type: UPDATE_TRUMF_ID,
  trumfId
});

export const setFormFromSmsMoveInInfo = (moveIn) => ({
  type: SET_FORM_FROM_SMS_MOVE_IN_INFO,
  moveIn
});

export const setFormFromInternalMoveInInfo = (moveIn) => ({
  type: SET_FORM_FROM_INTERNAL_MOVE_IN_INFO,
  moveIn
});

export const setProducthubInfo = (producthubInfo) => {
  return {
    type: SET_PRODUCT_HUB_INFO,
    producthubInfo
  };
};

export const setHasChangedProduct = (hasChangedProduct) => {
  return {
    type: SET_HAS_CHANGED_PRODUCT,
    hasChangedProduct
  };
};

export const selectAddonProduct = (productId, selected) => {
  return {
    type: SELECT_ADDON_PRODUCT,
    productId,
    selected
  };
};

export const setFormEInvoiceReference = (eInvoiceReference) => {
  return {
    type: SET_E_INVOICE_REFERENCE,
    payload: eInvoiceReference
  };
};
export const startDeliveryPointSteps = (deliveryPointId, replace = false) => {
  const { deliveryPoints, initialized } = store.getState().deliveryPointState;
  const { saleContext } = store.getState().saleState.form;

  const isBedrift = saleContext === SALE_CONTEXT.bedrift;
  if (!initialized) {
    store.dispatch(initializeDeliveryPoints());
  }
  const deliveryPoint =
    !_.isEmpty(deliveryPoints) && deliveryPoints.find((dp) => dp.id === parseInt(deliveryPointId));
  let newDeliveryPointId;
  let newPath;
  if (deliveryPoint) {
    if (deliveryPoint.elhubData) {
      newPath = generatePath(
        (isBedrift ? pages.bedrift : "") + pages.deliveryPointV2 + pages.deliveryPointV2MovingDate,
        { id: deliveryPoint.id }
      );
    } else {
      newPath = generatePath((isBedrift ? pages.bedrift : "") + pages.deliveryPointV2, {
        id: deliveryPoint.id
      });
    }
  } else {
    newDeliveryPointId = !!deliveryPoint
      ? null
      : !_.isEmpty(deliveryPoints)
        ? Math.max(...deliveryPoints.map((dp) => dp.id)) + 1
        : 1;
    newPath = generatePath((isBedrift ? pages.bedrift : "") + pages.deliveryPointV2, {
      id: newDeliveryPointId
    });
  }
  if (replace) {
    history.replace(newPath);
  } else {
    history.push(newPath);
  }
  return {
    type: START_DELIVERY_POINT_STEPS,
    newDeliveryPointId,
    deliveryPointId
  };
};

export const startDeliveryPointStepsBedrift = (deliveryPointId, replace = false) => {
  const { deliveryPoints, initialized } = store.getState().deliveryPointState;

  if (!initialized) {
    store.dispatch(initializeDeliveryPoints());
  }

  let newDeliveryPointId;
  let newPath;

  if (!deliveryPointId) {
    newPath = generatePath(pages.bedrift + pages.deliveryPoints);
  } else {
    const deliveryPoint =
      !_.isEmpty(deliveryPoints) &&
      deliveryPoints.find((dp) => dp.id === parseInt(deliveryPointId));

    if (deliveryPoint) {
      if (deliveryPoint.elhubData) {
        newPath = generatePath(
          pages.bedrift + pages.deliveryPointV2 + pages.deliveryPointV2MovingDate,
          { id: deliveryPoint.id }
        );
      } else {
        newPath = generatePath(pages.bedrift + pages.deliveryPointV2, { id: deliveryPoint.id });
      }
    } else {
      newDeliveryPointId = !!deliveryPoint
        ? null
        : !_.isEmpty(deliveryPoints)
          ? Math.max(...deliveryPoints.map((dp) => dp.id)) + 1
          : 1;
      newPath = generatePath(pages.bedrift + pages.deliveryPointV2, { id: newDeliveryPointId });
    }
  }

  if (replace) {
    history.replace(newPath);
  } else {
    history.push(newPath);
  }
  return {
    type: START_DELIVERY_POINT_STEPS,
    newDeliveryPointId,
    deliveryPointId
  };
};

export const startUserSteps = (userId) => {
  const { users, recommendedProducts, ssn } = store.getState().saleState.form;
  let newUserId;
  if (userId === 1 && _.isEmpty(users)) {
    newUserId = 1;
  } else {
    newUserId = userId ? null : !_.isEmpty(users) ? Math.max(...users.map((f) => f.id)) + 1 : 1;
  }
  const existingUser = userId && users.find((f) => f.id === userId);
  let path = generatePath(pages.mobile + pages.addUser, { userId: userId || newUserId });
  const user = users.find((f) => f.id === userId);
  const initialized = users.some((u) => u.completed);
  const age = user && (user.isOwner ? getAgeFromSsn(ssn) : getAgeFromBirthDate(user.birthDate));
  let skipProductStep = false;
  if (!initialized) {
    const product = store.getState().productState.products.find((p) => p.id === user.avtaletype);
    if (!(product.tags.includes("Ung") && age >= 30)) skipProductStep = true;
  }
  if (user) {
    path = generatePath(getMobileUserSteps(user, recommendedProducts, users, skipProductStep)[0], {
      userId
    });
  }
  history.push(path);
  return {
    type: START_FAMILY_MEMBER_STEPS,
    newUserId,
    existingUser
  };
};

export const removeUser = (id) => {
  return {
    type: REMOVE_FAMILY_MEMBER,
    id
  };
};
export const updateUser = (id, user) => {
  return {
    type: UPDATE_FAMILY_MEMBER,
    id,
    user
  };
};

export const setSessionId = () => {
  let sessionId = sessionStorage.getItem("SALES_MODULE_SESSION_ID");
  if (!sessionId) {
    sessionId = uuidv4();
    window.sessionStorage.setItem("SALES_MODULE_SESSION_ID", sessionId);
  }
  return {
    type: SET_SESSION_ID,
    sessionId
  };
};
