import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import styles from "./AlreadyActiveDialog.module.scss";
import React from "react";

function AlreadyActiveDialog({ open, handleClose, phoneNr, header }) {
  return (
    <Dialog open={open} PaperProps={{ className: styles.dialogBox }} onClose={handleClose}>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Telefonnummeret <b>{phoneNr}</b> er allerede i bruk hos oss. Vil du å endre
          mobilabonnementet ditt? Det kan du enkelt gjøre på{" "}
          <a
            className={styles.link}
            href="https://minsidemobil.fjordkraft.no"
            target="_blank"
            rel="noreferrer"
          >
            Min Side
          </a>
          , eller du kan ringe oss på 55 52 67 00 – så hjelper vi deg.
        </DialogContentText>
      </DialogContent>
      <div className={styles.dialogButtons}>
        <button
          className={`btnSecondary  ${styles.dialogButtonYes}`}
          onClick={handleClose}
          autoFocus
        >
          Forstått
        </button>
      </div>
    </Dialog>
  );
}

export default AlreadyActiveDialog;
