import React from "react";
import AddonProduct from "./AddonProduct";
import AddonProductV2 from "../Mobile/AddonProductV2";
import { useParams } from "react-router-dom";

function AddonProductContainer({ v2, userId, onConfirm }) {
  const { id } = useParams();
  return v2 ? (
    <AddonProductV2 productId={id} userId={userId} onConfirm={onConfirm} />
  ) : (
    <AddonProduct addonProductId={id} onConfirm={onConfirm} />
  );
}

export default AddonProductContainer;
