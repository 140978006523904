import classes from "./ExistingAgreementProductCard.module.scss";
import React, { useEffect, useState } from "react";
import { ReactComponent as GreenCheckMark } from "../../../assets/Images/FK/GreenCheckMark.svg";
import { Box, CardActions, Grid, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import ProductInfoModal from "../ProductInfoModal/ProductInfoModal";
import * as AxiosFunction from "../../../Utils/AxiosFunctions";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import { getProductHubProductText } from "../../../Utils/AxiosFunctions";
import FKLoader from "../FKLoader/FKLoader";
import config from "../../../config";

const ExistingAgreementProductCard = ({ product, checked }) => {
  const [showModal, setShowModal] = useState(false);
  const [productPrice, setProductPrice] = useState(null);
  const lastProduct = useSelector((state) => state.customerState.lastProduct);
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const termsBaseUrl =
    config.env === "prod"
      ? "https://avtale.fjordkraft.no/api/term/"
      : "https://test-avtale.fjordkraft.no/api/term/";
  const [powerTermsGuid, setPowerTermsGuid] = useState("error");
  let tags =
    product.tags !== null &&
    product.tags !== undefined &&
    product.tags.some((item) => item.includes("NO4"));

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const result = await AxiosFunction.getProductwithPrices(product?.id);
        setProductPrice(result);
        fetchPriceInfo(product?.id).then(() => setIsLoading(false));
        if (product?.termsAndConditionsId) {
          await AxiosFunction.GetTermsAndConditionsGuidById(product?.termsAndConditionsId).then(
            (res) => {
              setPowerTermsGuid(res);
            }
          );
        }
      } catch (error) {
        console.error("Error fetching price:", error);
      }
    };
    fetchPrice();
  }, [product]);

  const fetchPriceInfo = async (productId) => {
    try {
      productActionDispatchers.getPriceInfo(productId);
      const callToActionResult = await getProductHubProductText(productId, 6, 7);
      setDescription(callToActionResult);
    } catch (error) {
      console.error("Error fetching price info:", error);
    }
  };

  const handlePrices = (monthly, deno) => {
    let price =
      productPrice !== undefined &&
      productPrice !== null &&
      productPrice?.value?.find((price) => {
        if (monthly) {
          return price.name.includes("Månedlig");
        } else {
          return price.name.includes("Påslag strøm");
        }
      });
    if (price) {
      if (deno) {
        return (
          (tags ? price?.vatExclusivePrice : price.vatInclusivePrice) + " " + price.denomination
        );
      } else {
        return tags ? price?.vatExclusivePrice : price.vatInclusivePrice;
      }
    } else {
      return 0;
    }
  };

  const handleReadAboutClicked = () => {
    setShowModal(true);
  };

  // dobbeltsjekk css
  return (
    <Box
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "center"
      }}
    >
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Paper
          elevation={8}
          className={"whiteBox"}
          sx={{
            ml: -5,
            mr: -5,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
          }}
        >
          <Grid container item xs={10} direction="column" alignItems="left">
            <Grid item>
              <div>
                {lastProduct && product.id === lastProduct.id && (
                  <span
                    style={{ left: "4px", color: "green", fontWeight: "bold", fontStyle: "italic" }}
                  >
                    Siste aktive avtale:
                  </span>
                )}
              </div>
            </Grid>
            <div className={classes.smallProductCardProduct}>
              <Grid container item xs={12} alignItems="center" spacing={2}>
                <Grid item>
                  <div style={{ width: "1.2rem" }}>
                    {checked ? (
                      <GreenCheckMark style={{ marginTop: "-12px" }} />
                    ) : (
                      <div style={{ position: "relative", top: "-3px", left: "4px" }}>
                        <input
                          tabIndex={-1}
                          className={classes.topRadioButton}
                          type="radio"
                          checked={checked}
                          size="500"
                          readOnly
                        />
                      </div>
                    )}
                  </div>
                </Grid>
                <Grid item>
                  <h5 className={classes.smallProductCardTitle}>{product.name}</h5>
                </Grid>
              </Grid>
              {!isLoading && (
                <p
                  className={classes.smallProductCardGrayText}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </div>
          </Grid>

          <Grid container spacing={3} rowSpacing={1}>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ textAlign: "left" }}>
                Månedspris:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ textAlign: "right", fontWeight: "bold" }}>
                {handlePrices(true, true)}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" sx={{ textAlign: "left" }}>
                Påslag:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ textAlign: "right", fontWeight: "bold" }}>
                {handlePrices(false, true)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ position: "left" }}>
                <a
                  href={termsBaseUrl + powerTermsGuid}
                  target="_blank"
                  referrerPolicy="no-referrer"
                >
                  Avtalevilkår
                </a>
              </Typography>
            </Grid>

            {/*<Grid item xs={6}>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Bindingstid:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                Ingen
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Bruddgebyr:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                0 Kr.
              </Typography>
            </Grid>*/}
          </Grid>

          <ProductInfoModal
            show={showModal}
            productName={"tull"}
            closeModal={() => setShowModal(false)}
            productId={product.id}
          />
        </Paper>
        <CardActions className={classes.cardActions}>
          <button
            title={`Mer info om ${product.name}`}
            className={classes.readmoreButton}
            onClick={handleReadAboutClicked}
          >
            Mer info om {product.name}
          </button>
          <br />
        </CardActions>
      </Grid>
    </Box>
  );
};

export default ExistingAgreementProductCard;
