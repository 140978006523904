import React, { useEffect } from "react";
import styles from "./InvoiceAddress.module.scss";
import SimpleInput from "../../../SimpleInput/SimpleInput";
import { IsFKTheme } from "../../../../Utils/Themes";
import saleActionDispatchers from "../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import { formSelector } from "../../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../../FormPageContainer/FormPageContainer";
import {
  getElhubDataAddress,
  getElhubDataZipCode,
  sanitizeProps
} from "../../../../Utils/HelperFunctions";
import MobileMascotBlush from "../../../../assets/Images/FK/Mobil/MobileMascotBlush.svg";
import PostBox from "../../../../assets/Images/FK/PostBoxFramed.svg";
import pageStepActionDispatchers from "../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";

function InvoiceAddress({ onConfirm, onBack, form, deliveryPoints, isMobile }) {
  useEffect(() => {
    if (!form.address) {
      const dp = deliveryPoints.find((dp) => dp.selected);
      if (dp) {
        const address = dp.elhubData ? getElhubDataAddress(dp.elhubData) : dp.address;
        const zipCode = dp.elhubData ? getElhubDataZipCode(dp.elhubData) : dp.zipCode;
        saleActionDispatchers.updateFormAction({ address, zipCode });
      }
    }
  });
  const handleChange = (e) => {
    saleActionDispatchers.updateFormAction({ [e.target.name]: e.target.value });
  };

  const handleConfirm = () => {
    saleActionDispatchers.updateFormAction(sanitizeProps(form));
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname);
    }
  };

  const validInput = form.address && form.zipCode && form.city;

  return (
    <FormPageContainer
      className={`${styles.container} ${IsFKTheme && styles.containerFK}`}
      onBack={onBack}
    >
      <header className={styles.header}>
        <h1 className={"fallIn-"}>Du har valgt å få faktura i posten, har vi riktig adresse?</h1>
      </header>
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img
            className={"frameImage formPage-background fade-"}
            src={isMobile ? MobileMascotBlush : PostBox}
            alt={"bilde"}
          />
        </div>
      )}
      <div className={"slide"}>
        <SimpleInput
          className={styles.field}
          name="address"
          label="Gateadresse"
          value={form.address}
          onChange={handleChange}
          placeholder="Din adresse"
          type={"text"}
          autoComplete="newPassword"
          showWhiteBox
        />
        <SimpleInput
          className={styles.field}
          name="zipCode"
          label="Postnummer"
          value={form.zipCode}
          onChange={handleChange}
          placeholder="1234"
          type={"postcode"}
          city={"city"}
          autoComplete="newPassword"
          showWhiteBox
        />
        <button
          className={"btnPrimary " + styles.btn}
          disabled={!validInput}
          onClick={handleConfirm}
        >
          Bekreft
        </button>
      </div>
    </FormPageContainer>
  );
}

export default connect(formSelector)(InvoiceAddress);
