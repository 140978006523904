export const customerSelector = ({ customerState }) => {
  const {
    isLoadingCustomer,
    customer,
    isSubmittingCustomerUpdate,
    customerUpdateSubmitComplete,
    error
  } = customerState;
  return {
    customer,
    isLoadingCustomer,
    isSubmittingCustomerUpdate,
    customerUpdateSubmitComplete,
    error
  };
};

export const meterNumberChangeSelector = ({ customerState }) => {
  const { isLoading, customer, error } = customerState;
  return {
    customer,
    isLoading,
    error
    //isUpdating,
    //updateSuccess,
    //updateError,
  };
};
