import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import styles from "../ProductInfo/ProductInfo.module.scss";
import smsAcceptActionDispatcher from "../../../ReduxModules/Actions/SmsAcceptActions/SmsAcceptActionDispatcher";
import { useSelector } from "react-redux";
import { IsAddonOrder } from "../../../Utils/OrderHelper";
import { InvoicePrices } from "../../../Utils/Prices";
import {IsFKTheme, IsGETheme} from "../../../Utils/Themes";

function PriceSummarySmsAccept({ order, power, addon, addonCampaign, campaign }) {
  const [loading, setLoading] = useState(true);
  const priceSummaries = useSelector((state) => state.smsAcceptState.priceSummary);

  const products = useSelector((state) => state.productState.products);
  const orderProductHubId = order.productHubId;
  const isAddonOrder = IsAddonOrder(order.productTypeId);
  const matchingProduct = products.find(
    (product) =>
      product.productHubInfo !== undefined &&
      product.productHubInfo !== null &&
      product.productHubInfo.id === orderProductHubId
  );
  const productName = matchingProduct ? matchingProduct.productHubInfo.name : "";

  useEffect(() => {
    const fetchPriceSummary = async () => {
      smsAcceptActionDispatcher.getPriceSummary(order.id, order.productHubId, order.extraProducts);
      setLoading(false);
    };

    fetchPriceSummary().catch(console.error);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const priceSummary = priceSummaries && priceSummaries[order.id] && priceSummaries[order.id][0];

  if (!priceSummary) {
    return null; // Render nothing if priceSummary is not available
  }
  return (
    priceSummary && (
      <Grid
        container
        className={`${IsFKTheme && styles.containerFK} ${IsGETheme && styles.containerGE}`}
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between"
        }}
      >
        <Grid item xs={12}>
          <Typography className={styles.boxTitle}>Prisoppsummering</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={styles.summary}>
            {power ? "Oppstartsdato: " : "Porteringsdato:"}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={styles.summary} textAlign={"right"}>
            {priceSummary.startUpDate}
          </Typography>
        </Grid>
        {!isAddonOrder && (
          <>
            <Grid item xs={12}>
              <Typography
                className={styles.summary}
                sx={{ mt: 1, mb: 0.5, textDecoration: "underline" }}
              >
                {productName}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography className={styles.summary}>Månedsbeløp</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={styles.summary} textAlign={"right"}>
                {priceSummary.monthlyPrice + " kr/mnd"}
              </Typography>
            </Grid>
            {power && (
              <>
                <Grid item xs={8}>
                  <Typography className={styles.summary}>Påslag</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={styles.summary} textAlign={"right"}>
                    {power && priceSummary.surchargePrice + " øre/kWh"}
                  </Typography>
                </Grid>
              </>
            )}
          </>
        )}
        {addon && (
          <>
            <Grid item xs={12}>
              <Typography
                className={styles.summary}
                sx={{ mt: 1, mb: 0.5, textDecoration: "underline" }}
              >
                Valgte tilleggstjenester
              </Typography>
            </Grid>
            {priceSummary.extraProducts.map((product, index) => {
              return (
                <React.Fragment key={index}>
                  <Grid item xs={8}>
                    <Typography className={styles.summary}>{product.name}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={styles.summary} textAlign={"right"}>
                      {product.price + " " + product.denomination}
                    </Typography>
                  </Grid>
                  {product.initialPrice != null && product.initialPrice > 0 && (
                    <>
                      <Grid item xs={8} mt={-1}>
                        <Typography className={styles.summary} pl={1} color={"#5e5e5e"}>
                          └ Etablering
                        </Typography>
                      </Grid>
                      <Grid item xs={4} mt={-1}>
                        <Typography
                          className={styles.summary}
                          textAlign={"right"}
                          color={"#5e5e5e"}
                        >
                          {product.initialPrice} kr
                        </Typography>
                      </Grid>
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </>
        )}
        {priceSummary.totalInitialPrice != null && priceSummary.totalInitialPrice > 0 && (
          <>
            <Grid item xs={8} sx={{ mt: 3, mb: -3 }}>
              <Typography className={styles.summary} sx={{ fontWeight: "bold" }}>
                Total etablering:
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ mt: 3, mb: -3 }}>
              <Typography className={styles.summary} textAlign={"right"}>
                {priceSummary.totalInitialPrice + " kr"}
              </Typography>
            </Grid>
          </>
        )}
        <Grid item xs={8} sx={{ mt: 3 }}>
          <Typography className={styles.summary} sx={{ fontWeight: "bold" }}>
            Total månedsbeløp:
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{ mt: 3 }} textAlign={"right"}>
          <Typography className={styles.summary}>
            {priceSummary.totalMonthly + " kr/mnd"}
          </Typography>
        </Grid>
        {power && (
          <>
            <Grid item xs={8}>
              <Typography className={styles.summary} sx={{ fontWeight: "bold" }}>
                {priceSummary.fixed ? "Total strømpris: " : "Total påslag:"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={styles.summary} textAlign={"right"}>
                {priceSummary.totalSurcharge + " øre/kWh"}
              </Typography>
            </Grid>
          </>
        )}
        {addonCampaign && (
          <>
            {order.extraProducts.map((product, index) => {
              return (
                product.campaigns.length > 0 && (
                  <Grid item xs={12} key={index} sx={{ mt: 1, fontStyle: "italic" }}>
                    <Typography className={styles.summary}>
                      {product.campaigns[0].campaignName}
                    </Typography>
                  </Grid>
                )
              );
            })}
          </>
        )}
        {campaign && (
          <Grid item xs={12} sx={{ mt: 1, fontStyle: "italic" }}>
            <Typography className={styles.summary}>{order.campaign.campaignName}</Typography>
          </Grid>
        )}
        {priceSummary?.paperInvoice && (
          <>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={8}>
                <Typography className={styles.summary}>Papirfaktura:</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={styles.summary} textAlign={"right"}>
                 {power ? InvoicePrices.Paper : InvoicePrices.MobilePaper} kr/stk
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    )
  );
}

export default PriceSummarySmsAccept;
