import update from "immutability-helper";
import {
  GET_PRODUCT_INFO,
  GET_PRODUCT_INFO_SUCCESS,
  GET_PRODUCT_INFO_ERROR,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_ADDON_PRODUCTS,
  GET_ADDON_PRODUCTS_SUCCESS,
  GET_ADDON_PRODUCTS_ERROR,
  GET_PRODUCT_INFO_BEDRIFT,
  GET_PRODUCT_INFO_BEDRIFT_SUCCESS,
  GET_PRODUCT_INFO_BEDRIFT_ERROR,
  GET_ADDON_PRODUCT_INFO,
  GET_ADDON_PRODUCT_INFO_SUCCESS,
  GET_ADDON_PRODUCT_INFO_ERROR,
  GET_PRICE_INFO,
  GET_PRICE_INFO_ERROR,
  GET_PRICE_INFO_SUCCESS,
  GET_EXTRAPRODUCT_PRICE_INFO_SUCCESS,
  GET_EXTRAPRODUCT_PRICE_INFO_ERROR,
  GET_EXTRAPRODUCT_PRICE_INFO,
  GET_CAMPAIGN_TEXT,
  GET_CAMPAIGN_TEXT_SUCCESS,
  GET_CAMPAIGN_TEXT_ERROR,
  GET_CAMPAIGN_LENGTH_ERROR,
  GET_CAMPAIGN_LENGTH_SUCCESS,
  GET_CAMPAIGN_LENGTH
} from "../Actions/ProductActions/ProductActionTypes";
import _ from "lodash";
import { SET_STATE } from "../Actions/SaleActions/SaleActionTypes";

const initialState = {
  products: [],
  isLoadingProducts: false,
  addonProducts: [],
  mobileCampaigns: [],
  campaigns: [],
  isLoadingAddonProducts: false,
  getProductsError: null,
  getAddonProductsError: null,
  priceInfo: [],
  priceInfoError: null,
  isLoadingPriceInfo: false,
  extraProductPriceInfo: [],
  extraProductPriceInfoError: null,
  campaignText: null,
  isLoadingCampaignText: false,
  isLoadingExtraProductPriceInfo: false,
  isLoadingCampaignLength: false,
  campaignLength: null
};

const smsAcceptReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_INFO:
    case GET_PRODUCT_INFO_BEDRIFT:
      return update(state, {
        products: {
          $set: state.products.find((p) => p.id === action.productId)
            ? state.products.map((p) => {
                if (p.id === action.productId) {
                  p.isLoading = true;
                }
                return p;
              })
            : state.products.concat({ id: action.productId, isLoading: true })
        }
      });
    case GET_PRICE_INFO_SUCCESS:
      return update(state, {
        isLoadingPriceInfo: { $set: false },
        priceInfo: { $set: action.priceInfo }
      });

    case GET_PRICE_INFO_ERROR:
      return update(state, {
        isLoadingPriceInfo: { $set: false },
        priceInfoError: { $set: action.error }
      });
    case GET_PRICE_INFO:
      return update(state, {
        isLoadingPriceInfo: { $set: true }
      });
    case GET_EXTRAPRODUCT_PRICE_INFO:
      return update(state, {
        isLoadingPriceInfo: { $set: true }
      });

    case GET_EXTRAPRODUCT_PRICE_INFO_SUCCESS:
      const updatedExtraProductPriceInfo = action.extraProductPriceInfo.filter(
        (newProduct) =>
          !state.extraProductPriceInfo.some(
            (existingProduct) => existingProduct.id === newProduct.id
          )
      );
      return update(state, {
        isLoadingPriceInfo: { $set: false },
        extraProductPriceInfo: { $push: updatedExtraProductPriceInfo }
      });

    case GET_EXTRAPRODUCT_PRICE_INFO_ERROR:
      return update(state, {
        isLoadingPriceInfo: { $set: false },
        extraProductPriceInfoError: { $set: action.error }
      });

    case GET_PRODUCT_INFO_SUCCESS:
    case GET_PRODUCT_INFO_BEDRIFT_SUCCESS:
      return update(state, {
        products: {
          $set: state.products.map((p) => {
            if (p.id === action.productId) {
              return update(p, {
                isLoading: { $set: false },
                productInfo: { $set: action.productInfo },
                productHubInfo: { $set: action.productHubInfo },
                productPriceList: { $set: action.productPriceList },
                campaignInfo: { $set: action.campaignInfo },
                textGroups: { $set: action.textGroups },
                ingress: { $set: action.ingress },
                extendedProductInfo: { $set: action.extendedProductInfo },
                image: { $set: action.image },
                productTerms: { $set: action.productTerms }
              });
            }
            return p;
          })
        }
      });

    case GET_PRODUCT_INFO_ERROR:
    case GET_PRODUCT_INFO_BEDRIFT_ERROR:
      return update(state, {
        products: {
          $set: state.products.map((p) => {
            if (p.id === action.productId) {
              p.isLoading = false;
              p.productInfoError = action.error;
            }
            return p;
          })
        }
      });

    case GET_PRODUCTS:
      return update(state, {
        isLoadingProducts: { $set: true }
      });

    case GET_PRODUCTS_SUCCESS:
      const updatedProductList = state.products.map((p) => {
        const newProduct = action.products.find((np) => np.id === p.id);
        if (newProduct) {
          return { ...p, ...newProduct };
        }
        return p;
      });
      return update(state, {
        isLoadingProducts: { $set: false },
        products: { $set: _.uniqBy(updatedProductList.concat(action.products), (p) => p.id) }
      });

    case GET_PRODUCTS_ERROR:
      return update(state, {
        isLoadingProducts: { $set: false },
        error: { $set: action.error }
      });

    case GET_ADDON_PRODUCTS:
      return update(state, {
        isLoadingAddonProducts: { $set: true }
      });

    case GET_ADDON_PRODUCTS_SUCCESS:
      return update(state, {
        isLoadingAddonProducts: { $set: false },
        addonProducts: { $set: action.addonProducts } // todo: Handle overwrite if addon product has been fetched ahead of time with extended info.
      });

    case GET_ADDON_PRODUCTS_ERROR:
      return update(state, {
        isLoadingAddonProducts: { $set: false },
        error: { $set: action.error }
      });
    case GET_ADDON_PRODUCT_INFO:
      const doesExist = state.products.find((x) => x.id === action.productId);
      return update(state, {
        products: {
          $set: doesExist
            ? state.products.map((x) =>
                x.id === action.productId ? { ...x, isLoadingProductInfo: true } : x
              )
            : state.products.concat([{ id: action.productId, isLoadingProductInfo: true }])
        }
      });
    case GET_ADDON_PRODUCT_INFO_SUCCESS:
    case GET_ADDON_PRODUCT_INFO_ERROR:
      return update(state, {
        products: {
          $set: state.products.map((x) =>
            x.id === action.productId ? { ...x, ...action, isLoadingProductInfo: false } : x
          )
        }
      });
    case SET_STATE:
      const newState = {
        ...action.state.productState,
        isLoadingProducts: false,
        isLoadingAddonProducts: false
      };
      if (!_.isEmpty(newState.products)) {
        newState.products = newState.products.map((p) => {
          return { ...p, isLoading: false };
        });
      }
      return newState;
    default:
      return state;

    case GET_CAMPAIGN_TEXT:
      return update(state, {
        isLoadingCampaignText: { $set: true }
      });

    case GET_CAMPAIGN_TEXT_ERROR:
      return update(state, {
        isLoadingCampaignText: { $set: false },
        error: { $set: action.error }
      });

    case GET_CAMPAIGN_TEXT_SUCCESS:
      return update(state, {
        isLoadingCampaignText: { $set: false },
        campaignText: { $set: action.campaignText }
      });
    case GET_CAMPAIGN_LENGTH:
      return update(state, {
        isLoadingCampaignLength: { $set: true }
      });

    case GET_CAMPAIGN_LENGTH_ERROR:
      return update(state, {
        isLoadingCampaignLength: { $set: false },
        error: { $set: action.error }
      });

    case GET_CAMPAIGN_LENGTH_SUCCESS:
      return update(state, {
        isLoadingCampaignLength: { $set: false },
        campaignLength: { $set: action.campaignLength }
      });
  }
};

export default smsAcceptReducer;
