import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import styles from "./FKLoader.module.scss";
import {IsFKTheme, IsGETheme} from "../../../Utils/Themes";

function Loader({ text, className }) {
  return (
    <>
      <div
        className={`${styles.loaderContainer} ${!IsFKTheme && styles.loaderContainerTK} ${IsGETheme && styles.loaderContainerGE} ${className}`}
      >
        <CircularProgress
          className={styles.bottom}
          variant="determinate"
          size={80}
          thickness={4}
          value={100}
          color={"inherit"}
        />
        <CircularProgress
          className={styles.top}
          size={80}
          thickness={4}
          disableShrink
          variant="indeterminate"
          color={"inherit"}
        />
      </div>
      <h2 className={styles.message}>{text}</h2>
    </>
  );
}

export default Loader;
