import { useAuth } from "react-oidc-context";
import React, { useEffect, useState } from "react";
import { getQueryStrings } from "../../../../Utils/UrlParser";
import saleActionDispatchers from "../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { uuidv4 } from "../../../../Utils/HelperFunctions";
import { connect } from "react-redux";
import { stateSelector } from "../../../../ReduxModules/Selectors/SaleSelectors";
import customerActionDispatchers from "../../../../ReduxModules/Actions/CustomerActions/CustomerActionDispatchers";
import { useDispatch } from "react-redux";
import * as AxiosFunction from "../../../../Utils/AxiosFunctions";
import { setAuthHeader } from "../../../../Utils/axiosHeaders";
import config from "../../../../config";

function MinSideLogIn(props) {
  const dispatch = useDispatch();
  const [isAxiosCalled, setIsAxiosCalled] = useState(false);
  const auth = useAuth();
  useEffect(() => {
    auth.signinSilent().catch((error) => {
      if (error.message === "login_required") {
        props.getLogInResult(false);
      }
    });
    // eslint-disable-next-line
  }, []);
  const saveInfo = () => {
    let brand = config.theme === "FK" ? "FKAS" : "TKAS";
    if (auth.user.profile !== null) {
      AxiosFunction.getExistingCustomerSSNAndEmail(
        auth.user.profile.phone_number,
        auth.user.profile.customerId,
        brand
      )
        .then((info) => {
          const customerInfo = {
            firstName:
              auth.user !== null
                ? auth.user.profile[
                    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"
                  ]
                : "",
            lastName:
              auth.user !== null
                ? auth.user.profile["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"]
                : "",
            email: auth.user !== null ? auth.user.profile.email : "",
            phone:
              auth.user !== null
                ? auth.user.profile.phone_number[0] === "+"
                  ? auth.user.profile.phone_number.slice(3)
                  : auth.user.profile.phone_number
                : "",
            customerId: auth.user !== null ? auth.user.profile.customerId : "",
            ssn: info.toString()
          };
          const personNumber = info.toString();
          saleActionDispatchers.updateFormAction({ personNumber });
          dispatch({
            type: "GET_MIN_SIDE_LOGIN_SUCCESS",
            payload: { customerInfo: { ...customerInfo } }
          });
          customerActionDispatchers.getExistingCustomerDataBySsn(info);
          saleActionDispatchers.getCustomerInfoAction(info, customerInfo.phone);
          setIsAxiosCalled(true);
        })
        .catch((error) => {
          console.error("Error: Failed to get SSN and Email for MinSide: ", error);
        });
    }
  };

  useEffect(() => {
    //SUCCESS
    if (!isAxiosCalled && auth.isAuthenticated && !auth.isLoading) {
      setAuthHeader(auth.user.access_token);
      console.log("Success!");
      if (auth.user.profile?.store !== null && auth.user.profile?.store !== undefined) {
        props.getLogInResult(false);
      } else {
        saveInfo();
      }
    }
    //FAIL
    if (!auth.isAuthenticated && !auth.isLoading) {
      props.getLogInResult(false);
    }
    if (auth.error) {
      props.getLogInResult(false);
    }
  }, [auth.isAuthenticated, auth.isLoading]);

  useEffect(() => {
    if (props.trigger && !isAxiosCalled) {
      const nonce = uuidv4();
      const stateKey = uuidv4();
      const queryStrings = getQueryStrings(window.location.search);
      window.sessionStorage.setItem("nonce", encodeURI(nonce));
      window.sessionStorage.setItem("stateKey", encodeURI(stateKey));
      window.sessionStorage.setItem("state", JSON.stringify(props.state));
      const urls = window.location.search;
      window.sessionStorage.setItem("query", urls);
      auth.signinRedirect();
      saleActionDispatchers.getCustomerInfoAction(
        queryStrings?.code,
        queryStrings?.error,
        queryStrings?.subtype
      );
    }
  }, [props.trigger]);

  if (auth.isLoading) {
    console.log("Loading...");
  }
  if (auth.error) {
    console.log("Error: " + auth.error.message);
  }
  return "";
}

function MinSideWrapper(props) {
  return <MinSideLogIn {...props} />;
}

export default connect(stateSelector)(MinSideWrapper);
