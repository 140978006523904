import React, { useState } from "react";
import styles from "./PowerMeter.module.scss";
import SimpleInput from "../../../../SimpleInput/SimpleInput";
import { IsFKTheme } from "../../../../../Utils/Themes";
import { Alert, AlertTitle } from "@mui/material";
import FormPageContainer from "../../../FormPageContainer/FormPageContainer";
import { history } from "../../../../../App";
import { pages, pageStepAnimationDuration } from "../../../../../Utils/CustomerFormHelper";
import PowerMeter from "../../../../../assets/Images/FK/PowerMeterFramed.svg";
import WhiteButtonList from "../../../../ButtonList/WhiteButtonList";
import { generatePath, useParams } from "react-router-dom";

function PowerMeterInputOrHelp({
  text,
  onChange,
  deliveryPoint,
  onConfirm,
  deliveryPoints,
  onSkip,
  onBack,
  isValid,
  path
}) {
  const [error, setError] = useState(false);
  const params = useParams();

  const handleConfirm = () => {
    if (
      !deliveryPoints.find(
        (dp) =>
          dp.id !== deliveryPoint.id && dp.meterIdentification === deliveryPoint.meterIdentification
      )
    ) {
      onConfirm();
    } else {
      setError(true);
    }
  };

  return (
    <FormPageContainer onBack={onBack} currentStep={pages.deliveryPoints}>
      <div className={`${IsFKTheme && styles.containerFK}`}>
        <header className={`fallIn- ${styles.header}`}>
          <h1>{text}</h1>
        </header>
        {IsFKTheme && (
          <div className={"frameImageContainer"}>
            <img
              className={"frameImage formPage-background fade-"}
              src={PowerMeter}
              alt="PowerMeter"
            />
          </div>
        )}
        <div className={"slide"}>
          <SimpleInput
            className={styles.powerMeterInput}
            label={"Målernummer"}
            value={deliveryPoint.meterIdentification}
            type={"meterNumber"}
            name={"meterNumber"}
            onChange={onChange}
            placeholder={"Ditt målernummer"}
            showErase={deliveryPoint.meterIdentification !== ""}
            showWhiteBox
            autofocus
            focusDelay={pageStepAnimationDuration}
          />
          {error && (
            <Alert style={{ marginBottom: "15px" }} severity="error">
              <AlertTitle>Kunne ikke sende inn målernummer</AlertTitle>Målernummeret er allerede
              lagt til
            </Alert>
          )}
          <div className={styles.fadingButtonContainer}>
            <div className={styles.fadingButton}>
              <button
                className={`btnPrimary fadeDownButton ${styles.submitButton}`}
                disabled={!isValid}
                style={{ zIndex: !isValid ? -1 : 1 }}
                onClick={handleConfirm}
              >
                Bekreft
              </button>
            </div>
            <div className={styles.fadingButton}>
              <div className={`${styles.btnContainer} fadeDown- ${isValid && "fadeDown-on"}`}>
                <div style={{ zIndex: isValid ? -1 : 1 }}>
                  {
                    <WhiteButtonList
                      buttons={[
                        {
                          content: <div className={"title"}>Hjelp meg å finne nummeret</div>,
                          onClick: () =>
                            history.push(
                              generatePath(path + pages.deliveryPointV2MeterNumberHelp, params)
                            )
                        },
                        {
                          content: <div className={"title"}>Fortsett uten målernummer</div>,
                          onClick: onSkip
                        }
                      ]}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormPageContainer>
  );
}

export default PowerMeterInputOrHelp;
