import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { AdditionalSalesType } from "../../../types";
import { AdditionalSalesByType } from "./AdditionalSalesByType";

function AdditionalSalesPreview() {
  const m = useLocation();
  const searchParams = new URLSearchParams(m.search);
  const type = (searchParams.get("type") ?? "fkas") as AdditionalSalesType;

  React.useEffect(() => {
    if (type === "fkas" || type === "fkasbedrift" || type === "mobile") {
      document.body.style.backgroundColor = "#FF5500";
      document.body.classList.remove("tkStyles");
      document.body.classList.add("fkStyles");
      return;
    }

    if (type === "tkas" || type === "tkasbedrift") {
      document.body.style.backgroundColor = "#7DECE8";

      document.body.classList.remove("fkStyles");
      document.body.classList.add("tkStyles");
      return;
    }
  }, [type]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: "20px",
          margin: "20px 0 0"
        }}
      >
        <Link to="/preview/additional-sales?type=fkas">Fjordkraft</Link>
        <Link to="/preview/additional-sales?type=fkasbedrift">Fjordkraft bedrift</Link>
        <Link to="/preview/additional-sales?type=tkas">Trøndelagkraft</Link>
        <Link to="/preview/additional-sales?type=tkasbedrift">Trøndelagkraft bedrift</Link>
        <Link to="/preview/additional-sales?type=mobile">Fjordkraft Mobil</Link>
      </div>
      <div
        style={{
          margin: "0 auto",
          maxWidth: "375px",
          width: "100%",
          padding: "50px 24px"
        }}
      >
        <AdditionalSalesByType type={type} />
      </div>
    </>
  );
}

export { AdditionalSalesPreview };
