import { withStyles } from "@mui/styles";
import { IsFKTheme } from "../../Utils/Themes";
import Checkbox from "@mui/material/Checkbox";
import React from "react";

const SimpleCheckBox = withStyles({
  root: {
    color: IsFKTheme ? "white" : "black",
    "&$checked": {
      color: IsFKTheme ? "white" : "black"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

export default SimpleCheckBox;
