import React from "react";
import styles from "../CustomerInfo/CustomerInfo.module.scss";
import config from "../../../config";
import { getBrandFriendlyName } from "../../../Utils/Themes";

function FreeText({ freeText }) {
  return (
    <div className={`whiteBox ${styles.freeText}`}>
      <div className={styles.boxTitle}>{`Tilbud fra ${getBrandFriendlyName()}`}</div>
      <div
        className={styles.infoSection}
        dangerouslySetInnerHTML={{ __html: freeText.replace(/\n/g, "<br />") }}
      />
    </div>
  );
}

export default FreeText;
