import useSWR from "swr";
import { additionalSales, additionalSalesByIds, client } from "../Utils/sanityClient";
import { AdditionalSalesSanityData, AdditionalSalesType } from "../types";

function getQueryForBrand(brand: AdditionalSalesType) {
  switch (brand) {
    case "fkas":
      return additionalSales.fkasQuery;
    case "tkas":
      return additionalSales.tkasQuery;
    case "mobile":
      return additionalSales.mobileQuery;
    case "fkasbedrift":
      return additionalSales.fkasBedriftQuery;
    case "tkasbedrift":
      return additionalSales.tkasBedriftQuery;
    default:
      return null;
  }
}

function useAdditionalSales(brand: AdditionalSalesType) {
  const query = getQueryForBrand(brand);

  const { data, isLoading, error, isValidating, mutate } = useSWR<AdditionalSalesSanityData[]>(
    query,
    () => (query === null ? Promise.resolve([]) : client.fetch(query))
  );

  return { data, isLoading, error, isValidating, mutate };
}

function useAdditionalSalesIds(ids: string[]) {
  const query = additionalSalesByIds(ids);

  const { data, isLoading, error, isValidating, mutate } = useSWR<AdditionalSalesSanityData[]>(
    query,
    () => client.fetch(query)
  );

  return { data, isLoading, error, isValidating, mutate };
}

export { useAdditionalSales, useAdditionalSalesIds };
