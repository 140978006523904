export const GET_PRODUCT_INFO = "GET_PRODUCT_INFO";
export const GET_PRODUCT_INFO_SUCCESS = "GET_PRODUCT_INFO_SUCCESS";
export const GET_PRODUCT_INFO_ERROR = "GET_PRODUCT_INFO_ERROR";
export const GET_PRICE_INFO = "GET_PRICE_INFO";
export const GET_PRICE_INFO_SUCCESS = "GET_PRICE_INFO_SUCCESS";
export const GET_PRICE_INFO_ERROR = "GET_PRICE_INFO_ERROR";
export const GET_EXTRAPRODUCT_PRICE_INFO = "GET_EXTRAPRODUCT_PRICE_INFO";
export const GET_EXTRAPRODUCT_PRICE_INFO_SUCCESS = "GET_EXTRAPRODUCT_PRICE_INFO_SUCCESS";
export const GET_EXTRAPRODUCT_PRICE_INFO_ERROR = "GET_EXTRAPRODUCT_PRICE_INFO_ERROR";

export const GET_PRODUCT_INFO_BEDRIFT = "GET_PRODUCT_INFO_BEDRIFT";
export const GET_PRODUCT_INFO_BEDRIFT_SUCCESS = "GET_PRODUCT_INFO_BEDRIFT_SUCCESS";
export const GET_PRODUCT_INFO_BEDRIFT_ERROR = "GET_PRODUCT_INFO_BEDRIFT_ERROR";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";

export const GET_ADDON_PRODUCT_INFO = "GET_ADDON_PRODUCT_INFO";
export const GET_ADDON_PRODUCT_INFO_SUCCESS = "GET_ADDON_PRODUCT_INFO_SUCCESS";
export const GET_ADDON_PRODUCT_INFO_ERROR = "GET_ADDON_PRODUCT_INFO_ERROR";

export const GET_ADDON_PRODUCTS = "GET_ADDON_PRODUCTS";
export const GET_ADDON_PRODUCTS_SUCCESS = "GET_ADDON_PRODUCTS_SUCCESS";
export const GET_ADDON_PRODUCTS_ERROR = "GET_ADDON_PRODUCTS_ERROR";

export const GET_ADDON_PRODUCT_CAMPAIGNS = "GET_ADDON_PRODUCT_CAMPAIGNS";
export const GET_ADDON_PRODUCT_CAMPAIGNS_SUCCESS = "GET_ADDON_PRODUCT_CAMPAIGNS_SUCCESS";
export const GET_ADDON_PRODUCT_CAMPAIGNS_ERROR = "GET_ADDON_PRODUCT_CAMPAIGNS_ERROR";

export const GET_CAMPAIGN_TEXT = "GET_CAMPAIGN_TEXT";
export const GET_CAMPAIGN_TEXT_SUCCESS = "GET_CAMPAIGN_TEXT_SUCCESS";
export const GET_CAMPAIGN_TEXT_ERROR = "GET_CAMPAIGN_TEXT_ERROR";
export const GET_CAMPAIGN_LENGTH = "GET_CAMPAIGN_LENGTH";
export const GET_CAMPAIGN_LENGTH_SUCCESS = "GET_CAMPAIGN_LENGTH_SUCCESS";
export const GET_CAMPAIGN_LENGTH_ERROR = "GET_CAMPAIGN_LENGTH_ERROR";
