import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { INVOICE_SELECTION_OPTIONS } from "../../../ReduxModules/Reducers/InvoiceReducer";
import InvoiceAddress from "./InvoiceAddress/InvoiceAddress";

const InvoiceDetails = () => {
  const selectedInvoiceOption = useSelector((state) => state.invoiceState.selectedInvoiceOption);

  const invoiceComponent = useMemo(() => {
    switch (selectedInvoiceOption) {
      case INVOICE_SELECTION_OPTIONS.eInvoice:
        return <>eFaktura</>;
      case INVOICE_SELECTION_OPTIONS.email:
        return <>e-post</>;
      case INVOICE_SELECTION_OPTIONS.emailAndSms:
        return <>e-post og sms</>;
      case INVOICE_SELECTION_OPTIONS.paper:
        return <InvoiceAddress />;

      default:
        return <></>;
    }
  }, [selectedInvoiceOption]);

  return <>{invoiceComponent}</>;
};

export default InvoiceDetails;
