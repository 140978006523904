import React from "react";
import styles from "./ErrorPage.module.scss";
import { customerFormSelector } from "../../ReduxModules/Selectors/SaleSelectors";
import { connect } from "react-redux";
import FormPageContainer from "../CustomerForm/FormPageContainer/FormPageContainer";
import { customerServiceLink, powerDealsLink } from "../../Utils/ExternalLinks";

function ErrorPage({ criticalError }) {
  return (
    <FormPageContainer className={"centeredContainerContent"} hideFooter wide>
      <div className={styles.content}>
        {criticalError && criticalError.message === "Error: Missing avtaleType" ? (
          <div>
            <h1>Produkt ikke tilgjengelig.</h1>
            <h3>
              Velg en <a href={powerDealsLink}>strømavtale</a> for å kunne bestille strøm.
            </h3>
            Strømavtaler:{" "}
            <a href={powerDealsLink} className={styles.errorLink}>
              {powerDealsLink}
            </a>
          </div>
        ) : (
          <div className={styles.errorContent}>
            <h1>En feil har oppstått.</h1>
            <h3>
              Prøv å last siden på nytt eller kontakt{" "}
              <a href={customerServiceLink} target="_blank" rel="noopener noreferrer">
                kundeservice
              </a>{" "}
              hvis du ønsker å fortsette bestillingen din, eller rapportere feil.
            </h3>
            <button
              className={"btnPrimary " + styles.button}
              onClick={() => window.location.reload()}
            >
              Last siden på nytt
            </button>
            Kundeservice:{" "}
            <a
              href={customerServiceLink}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.errorLink}
            >
              {customerServiceLink}
            </a>
          </div>
        )}
      </div>
    </FormPageContainer>
  );
}

export default connect(customerFormSelector)(ErrorPage);
