import React, { useEffect, useRef } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import { productsDropdownSelector } from "../ReduxModules/Selectors/ProductSelectors";
import _ from "lodash";

function AdditionalProductDropdownSelect({
  onChange,
  options,
  isLoading,
  error,
  productCategories,
  selected,
  isBedrift
}) {
  useEffect(() => {
    if (_.isEmpty(selected)) {
      onClear();
    }
  }, [selected]);

  const selectRef = useRef();
  const onClear = () => {
    selectRef.current.select.clearValue();
  };

  const handleChange = (selectedOptions) => {
    onChange(selectedOptions);
  };

  return (
    <div>
      <Select
        ref={selectRef}
        defaultValue={[]}
        onChange={handleChange}
        isMulti
        name="addons"
        isLoading={isLoading}
        options={options}
        placeholder={"Velg tilleggsprodukter..."}
      />
      {error && (
        <div>
          Noe gikk galt ved lasting av tileggsprodukter. Vennligst last opp nettsiden på nytt.
        </div>
      )}
    </div>
  );
}

export default connect(productsDropdownSelector)(AdditionalProductDropdownSelect);
