import React, { useState, useEffect, useRef } from "react";
import { usePrevious } from "../../../../Utils/HelperFunctions";
import { connect } from "react-redux";
import saleActionDispatchers from "../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { history } from "../../../../App";
import PowerMeterInputOrHelp from "../NewDeliveryPoint/NewDeliveryPointMeterNumber/PowerMeterInputOrHelp";
import MeterFoundMessage from "../NewDeliveryPoint/MeterFoundMessage/MeterFoundMessage";
import Loader from "../../FKLoader/Loader";
import { pages, pageStepAnimationDuration } from "../../../../Utils/CustomerFormHelper";
import FormPageContainer from "../../FormPageContainer/FormPageContainer";
import { Switch, Route, generatePath, useParams, matchPath } from "react-router-dom";
import HelpFindPowerMeter from "../NewDeliveryPoint/NewDeliveryPointMeterNumber/HelpFindPowerMeter";
import WherePowerMeter from "../NewDeliveryPoint/NewDeliveryPointMeterNumber/WherePowerMeter";
import PowerMeterNumber from "../NewDeliveryPoint/NewDeliveryPointMeterNumber/PowerMeterNumber";
import { deliveryPointV2Selector } from "../../../../ReduxModules/Selectors/SaleSelectors";
import pageStepActionDispatchers from "../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { CSSTransition } from "react-transition-group";

function DeliveryPointMeterNumber(props) {
  const [loadingTimeout, setLoadingTimeout] = useState(null);
  const {
    deliveryPointId,
    ssn,
    deliveryPoints,
    deliveryPoint,
    elhubData,
    isLoading,
    isMoving,
    onBack,
    path,
    isBedrift
  } = props;
  const { address, zipCode, city, meterIdentification } = deliveryPoint;
  const params = useParams();
  const prevProps = usePrevious(props);
  const loadingJustFinished = prevProps && prevProps.isLoading && !isLoading;
  const timer = useRef(null);

  useEffect(() => {
    // to prevent any buggy behavior if user redirects during loading.
    return history.listen((location) => {
      if (
        (matchPath(window.location.pathname, {
          path: pages.deliveryPointV2 + pages.deliveryPointV2MeterNumber,
          exact: true
        }),
        matchPath(window.location.pathname, {
          path: pages.deliveryPointV2 + pages.deliveryPointV2MeterNumberFound,
          exact: true
        }))
      ) {
        setLoadingTimeout(false);
      }
      clearTimeout(timer.current);
    });
  }, [history]);

  useEffect(() => {
    if (loadingJustFinished) {
      const isNewDeliveryPoint = !!(
        elhubData &&
        !deliveryPoints.find(
          (dp) => dp.elhubData && dp.elhubData.meterIdentification === elhubData.meterIdentification
        )
      );
      if (!isNewDeliveryPoint) {
        // No deliverypoint was found in last search. Make user input meternumber.

        // Nothing found in elhub search.
        if (isBedrift) {
          timeOutRedirect(generatePath(pages.bedrift + pages.deliveryPoints, params));
        } else {
          timeOutRedirect(
            generatePath(pages.deliveryPointV2 + pages.deliveryPointV2Calendar, params)
          );
        }
      } else {
        // Deliverypoint was found.
        if (isMoving || (elhubData && elhubData[0] !== null)) {
          // Make user select date.
          timeOutRedirect(
            generatePath(pages.deliveryPointV2 + pages.deliveryPointV2Calendar, params)
          );
        } else {
          // found with SSN
          timeOutRedirect();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    return () => {
      setLoadingTimeout(null);
    };
  }, []);

  function timeOutRedirect(redirectPath) {
    const timeOutDuration = 1500;
    setLoadingTimeout(true);
    if (redirectPath) {
      timer.current = setTimeout(() => history.push(redirectPath), timeOutDuration);
    } else {
      timer.current = setTimeout(
        () => pageStepActionDispatchers.goToNextStep(window.location.pathname, params),
        timeOutDuration
      );
    }
  }

  const handleChange = (e) => {
    saleActionDispatchers.updateDeliveryPointAction(parseInt(deliveryPointId), {
      meterIdentification: e.target.value
    });
  };

  const handleConfirm = () => {
    saleActionDispatchers.getElhubDataAction(
      ssn,
      address,
      zipCode,
      meterIdentification,
      deliveryPoint.id
    );
  };

  const handleSkip = () => {
    saleActionDispatchers.updateDeliveryPointAction(parseInt(deliveryPointId), {
      meterIdentification: ""
    });
    if (isBedrift) {
      saleActionDispatchers.updateDeliveryPointAction(parseInt(deliveryPointId), {
        meterIdentification: "",
        isSkipped: true
      });
      history.push(pages.bedrift + pages.deliveryPoints);
      // this branch can never be reached. isBedrift is always true.
      // consider moving above if statement to the top of this function.
      // eslint-disable-next-line no-dupe-else-if
    } else if (isBedrift && meterIdentification) {
      saleActionDispatchers.updateDeliveryPointAction(parseInt(deliveryPointId), {
        meterIdentification: meterIdentification,
        isSkipped: true
      });
      history.push(pages.bedrift + pages.deliveryPoints);
    } else {
      pageStepActionDispatchers.addAndGoToNextStep(
        window.location.pathname,
        pages.deliveryPointV2 + pages.deliveryPointV2Calendar,
        params
      );
    }
  };

  const handleBack = () => {
    pageStepActionDispatchers.goToPreviousStep(window.location.pathname, params);
  };
  const mainPath = path + pages.deliveryPointV2MeterNumber;
  const isPowerMeterNumber = meterIdentification && meterIdentification.match("^[0-9]{1,18}$");

  const timoutMessage = loadingJustFinished || loadingTimeout;
  return (
    <>
      <CSSTransition
        in={!!timoutMessage}
        timeout={pageStepAnimationDuration}
        classNames={"formPage-"}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <FormPageContainer hideFooter className={"loaderContainer"}>
          <div className={"loader slide"}>
            <MeterFoundMessage success={elhubData} elhubData={elhubData} />
          </div>
        </FormPageContainer>
      </CSSTransition>
      <CSSTransition
        in={!timoutMessage && isLoading}
        timeout={pageStepAnimationDuration}
        classNames={"formPage-"}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <FormPageContainer hideFooter className={"loaderContainer"}>
          <div className={"loader slide"}>
            <Loader text={"Finner måler..."} />
          </div>
        </FormPageContainer>
      </CSSTransition>
      <CSSTransition
        in={!timoutMessage && !isLoading}
        timeout={pageStepAnimationDuration}
        classNames={"formPage-"}
        mountOnEnter={false}
        unmountOnExit={true}
      >
        <Switch>
          <Route
            exact
            path={mainPath}
            render={() => (
              <PowerMeterInputOrHelp
                deliveryPoints={deliveryPoints}
                text={"Hva er nummeret til strømmåleren din?"}
                deliveryPoint={deliveryPoint}
                onChange={handleChange}
                onConfirm={handleConfirm}
                onSkip={handleSkip}
                onBack={handleBack}
                isValid={isPowerMeterNumber}
                path={path}
              />
            )}
          />
          <Route
            exact
            path={path + pages.deliveryPointV2MeterNumberHelp}
            render={() => (
              <HelpFindPowerMeter
                onBack={() => history.push(generatePath(mainPath, params))}
                chooseOne={() =>
                  history.push(generatePath(path + pages.deliveryPointV2MeterNumberFound, params))
                }
                chooseTwo={() =>
                  history.push(generatePath(path + pages.deliveryPointV2MeterNumberWhere, params))
                }
                chooseThree={handleSkip}
              />
            )}
          />
          <Route
            exact
            path={path + pages.deliveryPointV2MeterNumberWhere}
            render={() => (
              <WherePowerMeter
                onBack={() => history.push(generatePath(mainPath, params))}
                chooseOne={() =>
                  history.push(generatePath(path + pages.deliveryPointV2MeterNumberFound, params))
                }
                chooseTwo={handleSkip}
              />
            )}
          />
          <Route
            exact
            path={path + pages.deliveryPointV2MeterNumberFound}
            render={() => (
              <PowerMeterNumber
                onChange={handleChange}
                deliveryPoints={deliveryPoints}
                deliveryPoint={deliveryPoint}
                onConfirm={handleConfirm}
                onBack={() => history.push(generatePath(mainPath, params))}
                isValid={isPowerMeterNumber}
              />
            )}
          />
        </Switch>
      </CSSTransition>
    </>
  );
}

export default connect(deliveryPointV2Selector)(DeliveryPointMeterNumber);
