import React, { useState, useCallback } from "react";
import styles from "./BedriftSearchInput.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { searchBrReg } from "../../Utils/AxiosFunctions";
import { debounce } from "lodash/function";

function BedriftSearchInput({
  name,
  value,
  onChange,
  onCompanyChange,
  placeholder,
  disabled,
  autoComplete,
  inputRef,
  label,
  className
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchResult, setSearchResult] = useState([]);

  const fetchData = async (nextValue) => {
    if (!nextValue || nextValue.length <= 2) {
      return;
    }

    setIsLoading(true);
    const searchRes = await searchBrReg(nextValue);
    if (searchRes != null)
      setSearchResult(
        searchRes["_embedded"]?.enheter.map((i) => {
          let postNr = "";
          let address = "";
          if (i.forretningsadresse && i.forretningsadresse.adresse.length > 0) {
            postNr = i.forretningsadresse.postnummer;
            address = i.forretningsadresse.adresse[0];
          } else if (i.postadresse && i.postadresse.adresse.length > 0) {
            postNr = i.postadresse.postnummer;
            address = i.postadresse.adresse[0];
          }
          return { name: i.navn, orgNr: i.organisasjonsnummer, postNr, address };
        }) ?? []
      );
    setIsLoading(false);
  };

  // Using debounce to prevent api spam
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((nextValue) => fetchData(nextValue), 1500),
    [] // will be created only once initially
  );

  const handleChange = (e) => {
    if (onChange === undefined) return;

    const value = e.target.value;
    onChange(e);

    if (value.length > 2) {
      setShowSearch(true);
      setIsLoading(true);
      debouncedSearch(value);
    }
  };

  const handleCompanyClicked = (company) => {
    if (onCompanyChange === undefined) return;

    onCompanyChange(company);
    setShowSearch(false);
  };

  const onFocus = () => {
    if (value && value.length > 2) setShowSearch(true);
  };

  const handleBlur = useCallback((e) => {
    const currentTarget = e.currentTarget;

    // Give browser time to focus the next element
    requestAnimationFrame(() => {
      // Check if the new focused element is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        setShowSearch(false);
      }
    });
  }, []);

  return (
    <div className={`${className} whiteBox`} style={{ position: "relative" }}>
      <div className={styles.inputName}>{label}</div>

      <div onBlur={handleBlur} className={styles.inputValueBox}>
        <input
          ref={inputRef}
          className={styles.inputValue}
          name={name}
          value={value}
          type={""}
          onChange={handleChange}
          onFocus={onFocus}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autoComplete}
        />
        {showSearch && (
          <div className={"whiteBox " + styles.bedriftSearchContainer} tabIndex="-1">
            <hr />
            {isLoading && (
              <div className="d-flex align-items-center flex-column">
                Søker
                <CircularProgress
                  sx={{ color: "#FF5500FF" }}
                  thickness={4}
                  disableShrink
                  variant="indeterminate"
                  color={"inherit"}
                  size={20}
                />
              </div>
            )}
            {!isLoading && (!searchResult || searchResult.length === 0) && (
              <div className={styles.bedriftSearchPrompt}>
                Skriv inn navn for å søke etter bedrifter
              </div>
            )}
            {searchResult.map((i) => (
              <p
                key={i.orgNr}
                className={styles.bedriftSearchRes}
                onClick={() => handleCompanyClicked(i)}
              >
                {i.name}, Org nr: {i.orgNr}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default BedriftSearchInput;
