import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { summarySelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import styles from "./Summary.module.scss";
import { IsFKTheme } from "../../../Utils/Themes";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import ProductInfoModal from "../ProductInfoModal/ProductInfoModal";
import { useSelector } from "react-redux";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import MobileMascotStarEyes from "../../../assets/Images/FK/Mobil/MobileMascotStarEyes.svg";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import * as _ from "lodash";
import Icon from "../../Icon/Icon";
import { getElhubDataAddress, getElhubDataZipCode } from "../../../Utils/HelperFunctions";
import House from "../../../assets/Images/FK/HouseFramed.svg";
import { pages } from "../../../Utils/CustomerFormHelper";
import MobileSummary from "./MobileSummary";

function PowerSummary({
  form,
  onBack,
  onConfirm,
  selectedAddonProducts,
  deliveryPoints,
  isActiveCustomer,
  isMobile,
  setShowProductInfoModal
}) {
  const isPartOfInternalMoveFlow = useSelector((state) => state.saleState.isPartOfInternalMoveFlow);
  const isPartOfSmsMoveFlow = useSelector((state) => state.saleState.isPartOfSmsMoveFlow);
  const isMoveIn = useMemo(
    () => isPartOfInternalMoveFlow || isPartOfSmsMoveFlow,
    [isPartOfSmsMoveFlow, isPartOfInternalMoveFlow]
  );
  useEffect(() => {
    if (form.address.length === 0 && isMoveIn && !isMobile) {
      form.address = deliveryPoints[0].address;
      form.zipCode = deliveryPoints[0].zipCode;
      form.city = deliveryPoints[0].city;
      saleActionDispatchers.updateFormAction(form);
    }
  }, []);

  function handleConfirm() {
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname);
    }
  }

  useEffect(() => {
    if (!form.address && !isMoveIn && !isMobile) {
      const dp = deliveryPoints.find((dp) => dp.selected);
      if (dp) {
        const address = dp.elhubData ? getElhubDataAddress(dp.elhubData) : dp.address;
        const zipCode = dp.elhubData ? getElhubDataZipCode(dp.elhubData) : dp.zipCode;
        saleActionDispatchers.updateFormAction({ address, zipCode });
      }
    }
  });
  return (
    <div className={styles.containerPower}>
      <header className={"fallIn-"}>
        <h1>
          Hei {form.firstName},{" "}
          {isActiveCustomer
            ? "så kjekt at du fortsetter å være kunde hos oss!"
            : "så flott at du vil bli kunde hos oss!"}
        </h1>
      </header>
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img className={"frameImage formPage-background fade-"} src={House} alt="House" />
        </div>
      )}
      <div className={"slide"}>
        <div className={`whiteBox ${styles.summary} ${IsFKTheme && styles.containerFK}`}>
          <div className={styles.grayText}>Du har valgt</div>
          <button
            className={styles.productName}
            onClick={() => setShowProductInfoModal(parseInt(form.avtaletype))}
          >
            {form.producthubInfo.name}
          </button>
          {selectedAddonProducts &&
            selectedAddonProducts.map((item, i) => (
              <div key={i}>
                <h6 className={styles.addonProduct}>{item.name}</h6>
              </div>
            ))}
          <div>
            <div className={styles.blackText}>
              {form.firstName} {form.lastName}
            </div>
            <div className={styles.grayText}>Tlf: {form.phone}</div>
          </div>
          {deliveryPoints &&
            deliveryPoints.map((deliveryPoint, i) => (
              <div key={i}>
                {deliveryPoint.elhubData ? (
                  <div className={styles.deliveryPoint}>
                    <div className={styles.blackText}>
                      {deliveryPoint.elhubData.addressV1.streetName}{" "}
                      {deliveryPoint.elhubData.addressV1.buildingNumber}
                      {deliveryPoint.elhubData.addressV1.buildingLetter}
                    </div>
                    <div className={styles.blackText}>
                      {deliveryPoint.elhubData.addressV1.zipCode}{" "}
                      {deliveryPoint.elhubData.addressV1.city}
                    </div>
                  </div>
                ) : (
                  <div className={styles.deliveryPoint}>
                    <div className={styles.blackText}>{deliveryPoint.address}</div>
                    <div className={styles.blackText}>
                      {deliveryPoint.zipCode} {deliveryPoint.city}
                    </div>
                  </div>
                )}
                {isMoveIn ? (
                  <div className={styles.grayText}>
                    Målepunkt Id: {deliveryPoint.meteringPointIdentification || "Ikke funnet"}
                  </div>
                ) : (
                  <div className={styles.grayText}>
                    Målernr:{" "}
                    {(deliveryPoint.elhubData && deliveryPoint.elhubData.meterIdentification) ||
                      deliveryPoint.meterIdentification ||
                      "Ikke funnet"}
                  </div>
                )}
              </div>
            ))}
          <button className={`btnPrimary ${styles.button}`} onClick={() => handleConfirm()}>
            Fortsett bestilling
          </button>
        </div>
      </div>
    </div>
  );
}

export default connect(summarySelector)(PowerSummary);
