import { bindActionCreators } from "redux";
import store from "../../Store/Store";
import {
  setCustomerFormSteps,
  setMobileSteps,
  setBedriftSteps,
  goToNextStep,
  goToPreviousStep,
  addAndGoToNextStep,
  removeSteps,
  removeAndGoToNextStep,
  addStep,
  addSteps,
  setFocusDelay,
  goToFirstStep,
  setMobileUserSteps
} from "./PageStepActions";

const actions = {
  setCustomerFormSteps,
  goToNextStep,
  setMobileSteps,
  setBedriftSteps,
  goToPreviousStep,
  addAndGoToNextStep,
  removeSteps,
  addStep,
  addSteps,
  setFocusDelay,
  goToFirstStep,
  removeAndGoToNextStep,
  setMobileUserSteps
};

const pageStepActionDispatchers = bindActionCreators(actions, store.dispatch);

export default pageStepActionDispatchers;
