import React, { useEffect, useState } from "react";
import styles from "./CustomerInfo.module.scss";
import OrderInfo from "../OrderInfo/OrderInfo";
import HouseWelcome from "../../../assets/Images/FK/HouseWelcome.svg";
import { IsFKTheme, IsGETheme } from "../../../Utils/Themes";
import MobileOrderInfo from "../OrderInfo/MobileOrderInfo";
import MobileProductSubtext from "../../CustomerForm/Mobile/MobileProductSubtext/MobileProductSubtext";
import { FormGroup, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FreeText from "../FreeText/FreeText";
import { IsBedrift } from "../../../Utils/OrderHelper";

function CustomerInfo({ customerInfo, showHouse, acceptedOrders, confirmed }) {
  const [globalCheck, setGloabalCheck] = useState(false);
  const [state, updateState] = React.useState(false);
  let isBedrift = IsBedrift(customerInfo?.salesTypeId);

  useEffect(() => {
    if (!confirmed) {
      if (acceptedOrders?.length === customerInfo.orders.length) {
        setGloabalCheck(true);
        updateState(false);
      }
    }
  }, [acceptedOrders]);

  const duplicateMeterpointId = () => {
    let meterpointIds = customerInfo.orders.filter(
      (o) => o.powerSaleData !== null && o.powerSaleData.meterPointId !== null
    );
    meterpointIds = meterpointIds.map((o) => o.powerSaleData.meterPointId);
    if (meterpointIds.length < 2) return true;
    return new Set(meterpointIds).size === meterpointIds.length;
  };

  const handleGlobalCheck = () => {
    if (!confirmed) {
      if (acceptedOrders.length === customerInfo.orders.length) {
        setGloabalCheck(false);
        updateState(false);
      } else {
        updateState(true);
        setGloabalCheck(true);
      }
    }
  };

  return (
    <div className={IsGETheme ? styles.ContainerTK : IsFKTheme && styles.ContainerFK}>
      <div className={`whiteBox ${showHouse && styles.houseContainer}`}>
        {showHouse && <img className={styles.house} src={HouseWelcome} alt={"HouseWelcome"} />}
        <div className={styles.boxTitle}>
          {isBedrift ? "Firmaopplysinger" : "Personopplysninger"}
        </div>
        <div className={styles.infoSection}>
          <div className={styles.grayText}>{isBedrift ? "Bedriftsnavn" : "Navn"}</div>
          <div>
            {customerInfo.firstName !== null
              ? `${customerInfo.firstName} ${customerInfo.lastName}`
              : `${customerInfo.organizationName}`}
          </div>
        </div>
        {isBedrift && (
          <div className={styles.infoSection}>
            <div className={styles.grayText}>Organisasjonsnummer</div>
            <div>{customerInfo.organizationNumber}</div>
          </div>
        )}

        <div className={styles.infoSection}>
          {isBedrift ? (
            <>
              {customerInfo.orders[0]?.invoiceDetails && (
                <>
                  <div className={styles.grayText}>Fakturaadresse</div>
                  <div>{customerInfo.orders[0]?.invoiceDetails?.invoiceAddress}</div>
                  <div>{customerInfo.orders[0]?.invoiceDetails?.invoiceZipCode}</div>
                  <div className={styles.infoSection}>
                    <div className={styles.grayText}>Epost (faktura)</div>
                    <div>{customerInfo.orders[0]?.invoiceDetails?.invoiceEmail}</div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {customerInfo.invoiceInfo.invoiceAddress && (
                <>
                  <div className={styles.grayText}>Adresse</div>
                  <div>{customerInfo.invoiceInfo.invoiceAddress}</div>
                  <div>{`${customerInfo.invoiceInfo.invoiceZipCode} ${customerInfo.invoiceInfo.invoiceCity || ""}`}</div>
                </>
              )}
            </>
          )}
        </div>
        {isBedrift && (
          <>
            <div className={styles.boxTitle}>Kontaktopplysninger</div>
            <div className={styles.infoSection}>
              <div className={styles.grayText}>Kontaktperson</div>
              <div>
                {customerInfo.orders[0]?.powerSaleData?.contactPerson !== null &&
                  customerInfo.orders[0]?.powerSaleData?.contactPerson}
              </div>
            </div>
          </>
        )}
        <div className={styles.infoSection}>
          <div className={styles.grayText}>Epost</div>
          <div>{customerInfo.contactInfo.email}</div>
        </div>
        <div className={styles.infoSection}>
          <div className={styles.grayText}>Mobilnummer</div>
          <div>{customerInfo.contactInfo.phone}</div>
        </div>
      </div>
      <div>
        <FormGroup row className={styles.customCheckbox}>
          {!confirmed && duplicateMeterpointId() && customerInfo.orders.length > 1 && isBedrift && (
            <FormControlLabel
              className={styles.customCheckbox}
              control={
                <Checkbox
                  style={{ color: "white", transform: "scale(1.3)" }}
                  checked={acceptedOrders?.length === customerInfo?.orders.length}
                  onChange={handleGlobalCheck}
                  name="checkedG2"
                />
              }
              label={"Velg alle avtaler"}
            />
          )}
        </FormGroup>
      </div>

      {isBedrift && customerInfo?.orders?.some((o) => o.powerSaleData?.freeText !== null) && (
        <FreeText
          freeText={
            customerInfo?.orders?.find((o) => o.powerSaleData?.freeText !== null).powerSaleData
              ?.freeText
          }
        />
      )}

      {!confirmed &&
        [...customerInfo.orders]
          .sort((a, b) => (a.powerSaleData?.meterPointId < b.powerSaleData?.meterPointId ? 1 : -1))
          .map(
            (o, i) =>
              o.powerSaleDataId !== null && (
                <OrderInfo
                  confirmed={confirmed}
                  update={state}
                  orderInfo={o}
                  key={i}
                  salesTypeId={customerInfo.salesTypeId}
                  globalCheck={globalCheck}
                />
              )
          )}
      {confirmed &&
        customerInfo.orders
          .filter((o) => o.powerSaleData)
          .map(
            (o, i) =>
              acceptedOrders.includes(o.id) && (
                <OrderInfo
                  confirmed={confirmed}
                  update={state}
                  orderInfo={o}
                  key={i}
                  salesTypeId={customerInfo.salesTypeId}
                  globalCheck={globalCheck}
                />
              )
          )}

      {!confirmed &&
        customerInfo.orders
          .filter((o) => o.mobileSaleData)
          .map((o, i) => (
            <MobileOrderInfo
              globalCheck={globalCheck}
              confirmed={confirmed}
              update={state}
              orderInfo={o}
              customer={customerInfo}
              key={i}
              salesTypeId={customerInfo.salesTypeId}
            />
          ))}
      {confirmed &&
        customerInfo.orders
          .filter((o) => o.mobileSaleData)
          .map(
            (o, i) =>
              acceptedOrders.includes(o.id) && (
                <MobileOrderInfo
                  globalCheck={globalCheck}
                  confirmed={confirmed}
                  update={state}
                  orderInfo={o}
                  customer={customerInfo}
                  key={i}
                  salesTypeId={customerInfo.salesTypeId}
                />
              )
          )}

      {customerInfo.orders.some((o) => o.mobileSaleData) && <MobileProductSubtext />}
      {!confirmed &&
        duplicateMeterpointId() &&
        !globalCheck &&
        customerInfo.orders.length > 2 &&
        isBedrift && (
          <div>
            <FormGroup row className={styles.customCheckbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "white", transform: "scale(1.3)" }}
                    checked={acceptedOrders?.length === customerInfo.orders.length}
                    onChange={handleGlobalCheck}
                    name="checkedG2"
                  />
                }
                label={"Velg alle avtaler"}
              />
            </FormGroup>
          </div>
        )}
    </div>
  );
}

export default CustomerInfo;
