import { call, put, takeLatest } from "redux-saga/effects";
import * as AxiosFunction from "../../Utils/AxiosFunctions";
import { MDM_UPDATE_TRUMF_ID } from "../Actions/TrumfActions/TrumfActionTypes";
import {
  mdmUpdateTrumfIdError,
  mdmUpdateTrumfIdSuccess
} from "../Actions/TrumfActions/TrumfActions";

function* mdmAddTrumfId(action) {
  try {
    yield call(AxiosFunction.mdmAddTrumfId, action.momentId, action.trumfId);
    yield put(mdmUpdateTrumfIdSuccess());
  } catch (e) {
    yield put(mdmUpdateTrumfIdError(e));
  }
}

export function* watchMdmAddTrumfId() {
  yield takeLatest(MDM_UPDATE_TRUMF_ID, mdmAddTrumfId);
}
