import React, { useEffect, useRef, useState } from "react";
import FormPageContainer from "../CustomerForm/FormPageContainer/FormPageContainer";
import styles from "./SmsAcceptLandingPage.module.scss";
import CustomerInfo from "./CustomerInfo/CustomerInfo";
import ErrorPage from "../ErrorPage/ErrorPage";
import Loader from "../CustomerForm/FKLoader/Loader";
import { history } from "../../App";
import { pages } from "../../Utils/CustomerFormHelper";
import { smsAcceptCustomerInfoSelector } from "../../ReduxModules/Selectors/SmsAcceptSelector";
import { connect } from "react-redux";
import { IsFKTheme, IsGETheme } from "../../Utils/Themes";
import smsAcceptActionDispatchers from "../../ReduxModules/Actions/SmsAcceptActions/SmsAcceptActionDispatcher";
import {
  customerServiceLink,
  mobileRightOfWithdrawalLink,
  powerRightOfWithdrawalLink
} from "../../Utils/ExternalLinks";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import config from "../../config";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Popover } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { makeStyles } from "@mui/styles";
import { IsBedrift } from "../../Utils/OrderHelper";
import termLinkStyles from "../CustomerForm/Terms/TermLinks.module.scss";
import { getAllConsentForms } from "../../Utils/sanityClient";
import { PortableAgreementText } from "../CustomerForm/Agreement/PortableAgreementText";
import { GetTermsAndConditionsGuidById } from "../../Utils/AxiosFunctions";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  popoverCheckboxStyle: {
    marginLeft: "30px",
    backgroundColor: "transparent !important",
    border: 0,
    boxShadow: "none !important"
  }
}));

const findSanityDocumentBySlug = (array, slugString) => {
  return array.find((item) => item.slug?.current === slugString);
};

const AddonView = ({
  acceptedTerms,
  setAcceptedTerms,
  openTermsMessageDialog,
  setOpenTermsMessageDialog,
  currentRef,
  fetchedConsentForms,
  productName,
  withdrawalLink,
  addonProductToDisplay,
  termSlug,
  termsBaseUrl,
  popoverConfig,
  productName_2,
  withdrawalLink_2,
  termsLink_2,
  addonProductToDisplay_2
}) => {
  const [termsGuid, setTermsGuid] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (addonProductToDisplay?.productHubInfo?.termsAndConditionsId != null) {
      const fetchGuid = async () => {
        try {
          const guid = await GetTermsAndConditionsGuidById(
            addonProductToDisplay?.productHubInfo?.termsAndConditionsId
          );
          setTermsGuid(guid);
        } catch (error) {
          console.error("Error fetching terms and conditions GUID: ", error);
        }
      };
      fetchGuid();
    }
  }, [addonProductToDisplay?.productHubInfo?.termsAndConditionsId]);

  useEffect(() => {
    if (addonProductToDisplay_2?.productHubInfo?.termsAndConditionsId != null) {
      const fetchGuid = async () => {
        try {
          const guid = await GetTermsAndConditionsGuidById(
            addonProductToDisplay_2?.productHubInfo?.termsAndConditionsId
          );
          setTermsGuid(guid);
        } catch (error) {
          console.error("Error fetching terms and conditions GUID: ", error);
        }
      };
      fetchGuid();
    }
  }, [addonProductToDisplay_2?.productHubInfo?.termsAndConditionsId]);

  return (
    <div className={styles.checkBoxContainer}>
      <button
        className={styles.checkBoxButton}
        onClick={() => setAcceptedTerms(!acceptedTerms)}
        ref={currentRef}
      >
        {acceptedTerms ? (
          <CheckBox className={styles.checkBoxIcon} />
        ) : (
          <CheckBoxOutlineBlank className={styles.checkBoxIcon} />
        )}
      </button>
      <Popover
        classes={{ paper: classes.popoverCheckboxStyle }}
        open={openTermsMessageDialog}
        anchorEl={currentRef.current}
        onClose={() => setOpenTermsMessageDialog(false)}
        anchorOrigin={popoverConfig.anchorOrigin}
        transformOrigin={popoverConfig.transformOrigin}
      >
        <Box>
          <Box
            sx={{
              padding: 1,
              paddingLeft: 0,
              borderRadius: 3,
              backgroundColor: "#628c2a",
              color: "white",
              borderColor: "black"
            }}
          >
            <ArrowLeftIcon fontSize={"large"} />
            <WarningAmberIcon /> Husk å akseptere produktvilkårene
          </Box>
        </Box>
      </Popover>
      <div className={styles.checkBoxText}>
        {fetchedConsentForms && fetchedConsentForms.length > 0 && (
          <>
            <PortableAgreementText
              contentArray={findSanityDocumentBySlug(fetchedConsentForms, termSlug)?.content}
              linkStyle={IsFKTheme ? termLinkStyles.termLink : termLinkStyles.termLinkTK}
              termsLink={termsBaseUrl + termsGuid}
              withdrawalLink={withdrawalLink}
              productName={productName}
              productName_2={productName_2}
              withdrawalLink_2={withdrawalLink_2}
              termsLink_2={termsLink_2}
            />
          </>
        )}
      </div>
    </div>
  );
};

function ConfirmPage({ customerInfo, error, isLoading, hashedCustomerId, acceptedOrders }) {
  const [acceptedPowerTerms, setAcceptedPowerTerms] = useState(false);
  const [acceptedInsuranceTerms, setAcceptedInsuranceTerms] = useState(false);
  const [acceptedMobileTerms, setAcceptedMobileTerms] = useState(false);
  const [acceptedStromSmartPlusTerms, setAcceptedStromSmartPlusTerms] = useState(false);
  const [acceptedStromSmartTerms, setAcceptedStromSmartTerms] = useState(false);
  const [acceptedPulsTerms, setAcceptedPulsTerms] = useState(false);
  const [openPowerTermsMessageDialog, setOpenPowerTermsMessageDialog] = useState(false);
  const [openMobileTermsMessageDialog, setOpenMobileTermsMessageDialog] = useState(false);
  const [openInsuranceTermsMessageDialog, setOpenInsuranceTermsMessageDialog] = useState(false);
  const [openStromSmartPlusTermsMessageDialog, setOpenStromSmartPlusTermsMessageDialog] =
    useState(false);
  const [openStromSmartTermsMessageDialog, setOpenStromSmartTermsMessageDialog] = useState(false);
  const [openPulsTermsMessageDialog, setOpenPulsTermsMessageDialog] = useState(false);

  const termsBaseUrl = IsGETheme
    ? config.env === "prod"
      ? "https://avtale.ge.no/api/term/"
      : "https://test-avtale.ge.no/api/term/"
    : config.env === "prod"
      ? "https://avtale.fjordkraft.no/api/term/"
      : "https://test-avtale.fjordkraft.no/api/term/";

  const mobileTermsGuid =
    customerInfo && customerInfo.orders.find((o) => o.mobileSaleData)?.termsGuid;
  const powerTermsGuid =
    customerInfo && customerInfo.orders.find((o) => o.powerSaleData)?.termsGuid;

  const powerTarget = useRef(null);
  const mobileTarget = useRef(null);
  const powerInsuranceTarget = useRef(null);
  const stromSmartPlusTarget = useRef(null);
  const stromSmartTarget = useRef(null);
  const pulsTarget = useRef(null);

  const powerInsuranceIds = [124, 71];
  const stromSmartPlusIds = [1048];
  const stromSmartIds = [1047];
  const pulsIds = [256];

  const hasOnlyStromSmartPlusOrPuls = (acceptedOrders, customerInfo) => {
    const addonIds = [...stromSmartPlusIds, ...pulsIds];
    return acceptedOrders.every((orderId) =>
      customerInfo?.orders
        ?.find((o) => o.id === orderId && o.productHubId === null)
        ?.extraProducts?.every((extraProduct) => addonIds.includes(extraProduct.productHubId))
    );
  };

  const currentAddonProduct = useSelector((state) =>
    state.productState.products.find((element) => powerInsuranceIds.includes(element.id))
  );
  const currentStromSmartPlusAddonProduct = useSelector((state) =>
    state.productState.products.find((element) => stromSmartPlusIds.includes(element.id))
  );
  const currentPulsAddonProduct = useSelector((state) =>
    state.productState.products.find((element) => pulsIds.includes(element.id))
  );

  //If its an addon order and the customer has accepted strømsmart.
  const currentStromSmartAddonProduct = useSelector((state) =>
    state.productState.products.find((element) => stromSmartIds.includes(element.id))
  );

  const hasAcceptedPowerInsurance = acceptedOrders.some((orderId) =>
    customerInfo?.orders
      ?.find((o) => o.id === orderId)
      ?.extraProducts?.some((extraProduct) => powerInsuranceIds.includes(extraProduct.productHubId))
  );

  const hasAcceptedStromSmartPlusInsurance = acceptedOrders.some((orderId) =>
    customerInfo?.orders
      ?.find((o) => o.id === orderId)
      ?.extraProducts?.some((extraProduct) => stromSmartPlusIds.includes(extraProduct.productHubId))
  );
  const hasAcceptedStromSmartInsurance = acceptedOrders.some((orderId) =>
    customerInfo?.orders
      ?.find((o) => o.id === orderId)
      ?.extraProducts?.some((extraProduct) => stromSmartIds.includes(extraProduct.productHubId))
  );

  const hasAcceptedPulsInsurance = acceptedOrders.some((orderId) =>
    customerInfo?.orders
      ?.find((o) => o.id === orderId)
      ?.extraProducts?.some((extraProduct) => pulsIds.includes(extraProduct.productHubId))
  );

  const hasOnlyPowerInsurance = acceptedOrders.every((orderId) =>
    customerInfo?.orders
      ?.find((o) => o.id === orderId && o.productHubId === null)
      ?.extraProducts?.every((extraProduct) =>
        powerInsuranceIds.includes(extraProduct.productHubId)
      )
  );

  const hasOnlyStromSmartPlusInsurance = acceptedOrders.every((orderId) =>
    customerInfo?.orders
      ?.find((o) => o.id === orderId && o.productHubId === null)
      ?.extraProducts?.every((extraProduct) =>
        stromSmartPlusIds.includes(extraProduct.productHubId)
      )
  );

  //If its an addon order and the customer has accepted strømsmart.
  const hasOnlyStromSmartInsurance = acceptedOrders.every((orderId) =>
    customerInfo?.orders
      ?.find((o) => o.id === orderId && o.productHubId === null)
      ?.extraProducts?.every((extraProduct) => stromSmartIds.includes(extraProduct.productHubId))
  );

  const hasPower = acceptedOrders.some((orderId) =>
    customerInfo?.orders.find((o) => o.id === orderId && o.powerSaleData && o.productHubId !== null)
  );
  const hasPowerAndStromSmart = hasPower && hasAcceptedStromSmartInsurance;

  const classes = useStyles();
  const [confirmed, setConfirmed] = useState(false);
  const [fetchedConsentForms, setFetchedConsentForms] = useState([]);

  useEffect(() => {
    getAllConsentForms()
      .then((consentForms) => setFetchedConsentForms(consentForms))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (customerInfo) {
      setAcceptedMobileTerms(
        !customerInfo.orders
          .filter((o) => o.mobileSaleData !== null)
          .some((o) => acceptedOrders.includes(o.id))
      );
      setAcceptedPowerTerms(
        !customerInfo.orders
          .filter((o) => o.powerSaleData !== null)
          .some((o) => acceptedOrders.includes(o.id))
      );
      setAcceptedInsuranceTerms(
        !customerInfo.orders
          .filter((o) => o.powerSaleData !== null)
          .some((o) => acceptedOrders.includes(o.id))
      );
      setAcceptedStromSmartPlusTerms(
        !customerInfo.orders
          .filter(
            (o) =>
              o.powerSaleData !== null &&
              o.extraProducts.some((p) => stromSmartPlusIds.includes(p.productHubId))
          )
          .some((o) => acceptedOrders.includes(o.id))
      );
      setAcceptedStromSmartTerms(
        !customerInfo.orders
          .filter(
            (o) =>
              o.powerSaleData !== null &&
              o.extraProducts.some((p) => stromSmartIds.includes(p.productHubId))
          )
          .some((o) => acceptedOrders.includes(o.id))
      );
    }
  }, [acceptedOrders]);

  const onConfirm = () => {
    try {
      let allTermsAccepted = true;

      if (acceptedOrders.length === 0) {
        allTermsAccepted = false;
      }

      if (
        !acceptedPowerTerms &&
        !hasOnlyPowerInsurance &&
        !hasOnlyStromSmartPlusInsurance &&
        !hasOnlyStromSmartPlusOrPuls(acceptedOrders, customerInfo) &&
        !hasPowerAndStromSmart &&
        !hasOnlyStromSmartInsurance
      ) {
        allTermsAccepted = false;
        setOpenPowerTermsMessageDialog(true);
      }

      if (!acceptedMobileTerms && customerInfo?.salesTypeId !== 2) {
        allTermsAccepted = false;
        setOpenMobileTermsMessageDialog(true);
      }

      if (hasAcceptedPowerInsurance && !acceptedInsuranceTerms) {
        allTermsAccepted = false;
        setOpenInsuranceTermsMessageDialog(true);
      }

      if (
        hasAcceptedStromSmartPlusInsurance &&
        !hasAcceptedPulsInsurance &&
        !acceptedStromSmartPlusTerms
      ) {
        allTermsAccepted = false;
        setOpenStromSmartPlusTermsMessageDialog(true);
      }
      if (hasAcceptedStromSmartInsurance && !hasPower && !acceptedStromSmartTerms) {
        allTermsAccepted = false;
        setOpenStromSmartTermsMessageDialog(true);
      }

      if (hasAcceptedStromSmartInsurance && hasPowerAndStromSmart && !acceptedStromSmartTerms) {
        allTermsAccepted = false;
        setOpenStromSmartTermsMessageDialog(true); //endre denne til sanity pulsen?
      }

      // Combined condition for both puls and stromsmartPlus
      if (hasAcceptedPulsInsurance && hasAcceptedStromSmartPlusInsurance) {
        if (!acceptedStromSmartPlusTerms || !acceptedPulsTerms) {
          allTermsAccepted = false;
          if (!acceptedStromSmartPlusTerms) {
            setOpenStromSmartPlusTermsMessageDialog(true);
          }
          if (!acceptedPulsTerms) {
            setOpenPulsTermsMessageDialog(true);
          }
        }
      }
      // Combined condition for both puls and stromsmartPlus
      if (hasPowerAndStromSmart && hasAcceptedStromSmartInsurance) {
        if (!acceptedStromSmartTerms || !acceptedPowerTerms) {
          allTermsAccepted = false;
          if (!acceptedStromSmartTerms) {
            setOpenStromSmartTermsMessageDialog(true);
          }
          if (!acceptedPowerTerms) {
            setOpenPowerTermsMessageDialog(true);
          }
        }
      }

      if (allTermsAccepted) {
        if (IsGETheme || IsBedrift(customerInfo.salesTypeId)) {
          smsAcceptActionDispatchers.submitSmsAcceptForm(hashedCustomerId, acceptedOrders, false);
          history.push(pages.smsAcceptReceipt);
        } else {
          smsAcceptActionDispatchers.submitSmsAcceptForm(hashedCustomerId, acceptedOrders, null);
          history.push(pages.smsAcceptSubscription);
        }
      }
    } catch (error) {
      var teststring = "onConfirmFail";
      window.newrelic.noticeError(teststring, error);
      console.log("onConfirm function failed.");
    }
  };

  return error ? (
    <FormPageContainer hideFooter wide>
      <div className={styles.innerFormPageContainer}>
        {error.response && error.response.data === "Fant ingen uaksepterte ordre på kunde." ? (
          <div>
            <h1 style={{ textAlign: "left" }}>
              Du har ingen bestillinger som mangler aksept, dersom du mener dette er feil kontakt{" "}
              <a href={customerServiceLink} target="_blank" rel="noopener noreferrer">
                kundeservice
              </a>
              .
            </h1>
            <div>
              Kundeservice:{" "}
              <a
                href={customerServiceLink}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.errorLink}
              >
                {customerServiceLink}
              </a>
            </div>
          </div>
        ) : (
          <ErrorPage criticalError={error} />
        )}
      </div>
    </FormPageContainer>
  ) : !customerInfo || isLoading ? (
    <FormPageContainer hideFooter wide>
      <Loader text={"Henter bestilling..."} />
    </FormPageContainer>
  ) : IsBedrift(customerInfo.salesTypeId) ? (
    <FormPageContainer hideFooter wide>
      <div className={styles.innerFormPageContainer}>
        <h1 className={styles.header}>Bekreft bestilling</h1>

        <div className={styles.center}>Kontroller at all informasjonen vi har er riktig</div>
        {customerInfo && (
          <>
            <CustomerInfo
              customerInfo={customerInfo}
              acceptedOrders={acceptedOrders}
              confirmed={confirmed}
            />
            {customerInfo.orders
              .filter((o) => o.mobileSaleData !== null)
              .some((o) => acceptedOrders.includes(o.id)) && (
              <div>
                <div className={styles.checkBoxContainer}>
                  <button
                    className={styles.checkBoxButton}
                    ref={mobileTarget}
                    onClick={() => setAcceptedMobileTerms(!acceptedMobileTerms)}
                  >
                    {acceptedMobileTerms ? (
                      <CheckBox className={styles.checkBoxIcon} />
                    ) : (
                      <CheckBoxOutlineBlank className={styles.checkBoxIcon} />
                    )}
                  </button>
                  <Popover
                    classes={{ paper: classes.popoverCheckboxStyle }}
                    open={openMobileTermsMessageDialog}
                    anchorEl={mobileTarget.current}
                    onClose={() => {
                      setOpenMobileTermsMessageDialog(false);
                    }}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "left"
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "left"
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          padding: 1,
                          paddingLeft: 0,
                          borderRadius: 3,
                          backgroundColor: "#628c2a",
                          color: "white",
                          borderColor: "black"
                        }}
                      >
                        <ArrowLeftIcon fontSize={"large"} />
                        <WarningAmberIcon /> Husk å akseptere vilkårene
                      </Box>
                    </Box>
                  </Popover>
                  <div className={styles.checkBoxText}>
                    {fetchedConsentForms && fetchedConsentForms.length > 0 && (
                      <PortableAgreementText
                        contentArray={
                          findSanityDocumentBySlug(fetchedConsentForms, "mobil")?.content
                        }
                        linkStyle={IsFKTheme ? termLinkStyles.termLink : termLinkStyles.termLinkTK}
                        termsLink={termsBaseUrl + mobileTermsGuid}
                        withdrawalLink={mobileRightOfWithdrawalLink}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            {customerInfo.orders
              .filter((o) => o.powerSaleData != null)
              .some((o) => acceptedOrders.includes(o.id)) && (
              <div>
                {!hasOnlyPowerInsurance &&
                  !hasOnlyStromSmartPlusOrPuls(acceptedOrders, customerInfo) && (
                    <div className={styles.checkBoxContainer}>
                      <button
                        className={styles.checkBoxButton}
                        onClick={() => setAcceptedPowerTerms(!acceptedPowerTerms)}
                        ref={powerTarget}
                      >
                        {acceptedPowerTerms ? (
                          <CheckBox className={styles.checkBoxIcon} />
                        ) : (
                          <CheckBoxOutlineBlank className={styles.checkBoxIcon} />
                        )}
                      </button>
                      <Popover
                        classes={{ paper: classes.popoverCheckboxStyle }}
                        open={openPowerTermsMessageDialog}
                        anchorEl={powerTarget.current}
                        onClose={() => setOpenPowerTermsMessageDialog(false)}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "left"
                        }}
                        transformOrigin={{
                          vertical: "center",
                          horizontal: "left"
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              padding: 1,
                              paddingLeft: 0,
                              borderRadius: 3,
                              backgroundColor: "#628c2a",
                              color: "white",
                              borderColor: "black"
                            }}
                          >
                            <ArrowLeftIcon fontSize={"large"} />
                            <WarningAmberIcon /> Husk å akseptere strømvilkårene
                          </Box>
                        </Box>
                      </Popover>
                      {!hasOnlyStromSmartPlusOrPuls(acceptedOrders, customerInfo) &&
                        (customerInfo.salesTypeId === 1 || customerInfo.salesTypeId === null ? (
                          <>
                            <div className={styles.checkBoxText}>
                              {fetchedConsentForms && fetchedConsentForms.length > 0 && (
                                <>
                                  <PortableAgreementText
                                    contentArray={
                                      findSanityDocumentBySlug(
                                        fetchedConsentForms,
                                        "signatur-tekst-strom"
                                      )?.content
                                    }
                                    linkStyle={
                                      IsFKTheme
                                        ? termLinkStyles.termLink
                                        : termLinkStyles.termLinkTK
                                    }
                                    termsLink={termsBaseUrl + powerTermsGuid}
                                    withdrawalLink={powerRightOfWithdrawalLink}
                                  />
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className={styles.checkBoxText}>
                            Jeg bekrefter å ha lest, og godkjenner,{" "}
                            <a
                              className={styles.termsLink}
                              href={termsBaseUrl + powerTermsGuid}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              avtalevilkårene
                            </a>{" "}
                            for levering av strøm.
                          </div>
                        ))}
                    </div>
                  )}
              </div>
            )}
          </>
        )}

        <div className={styles.generalMargin}>
          <div className={styles.center}>
            Trykk "velg" i en avtale for å klargjøre avtale(ene) til aksept.
          </div>
        </div>
        <div className={styles.confirmButtonDiv}>
          {customerInfo && (
            <>
              {acceptedOrders.length > 0 && (
                <button className={`btnPrimary ${styles.confirmButton}`} onClick={onConfirm}>
                  {acceptedOrders.length === customerInfo.orders.length
                    ? "Fullfør bestilling"
                    : "Fullfør bestilling for " +
                      acceptedOrders.length +
                      " av " +
                      customerInfo.orders.length +
                      " avtaler"}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </FormPageContainer>
  ) : (
    <FormPageContainer hideFooter wide>
      <div className={styles.innerFormPageContainer}>
        <h1 className={styles.header}>Bekreft bestilling</h1>

        <div className={styles.center}>Kontroller at all informasjonen vi har er riktig</div>
        {customerInfo && (
          <>
            <CustomerInfo
              customerInfo={customerInfo}
              acceptedOrders={acceptedOrders}
              confirmed={confirmed}
            />
            {customerInfo.orders
              .filter((o) => o.mobileSaleData !== null)
              .some((o) => acceptedOrders.includes(o.id)) && (
              <div>
                <div className={styles.checkBoxContainer}>
                  <button
                    className={styles.checkBoxButton}
                    ref={mobileTarget}
                    onClick={() => setAcceptedMobileTerms(!acceptedMobileTerms)}
                  >
                    {acceptedMobileTerms ? (
                      <CheckBox className={styles.checkBoxIcon} />
                    ) : (
                      <CheckBoxOutlineBlank className={styles.checkBoxIcon} />
                    )}
                  </button>
                  <Popover
                    classes={{ paper: classes.popoverCheckboxStyle }}
                    open={openMobileTermsMessageDialog}
                    anchorEl={mobileTarget.current}
                    onClose={() => {
                      setOpenMobileTermsMessageDialog(false);
                    }}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "left"
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "left"
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          padding: 1,
                          paddingLeft: 0,
                          borderRadius: 3,
                          backgroundColor: "#628c2a",
                          color: "white",
                          borderColor: "black"
                        }}
                      >
                        <ArrowLeftIcon fontSize={"large"} />
                        <WarningAmberIcon /> Husk å akseptere vilkårene
                      </Box>
                    </Box>
                  </Popover>
                  <div className={styles.checkBoxText}>
                    {fetchedConsentForms && fetchedConsentForms.length > 0 && (
                      <PortableAgreementText
                        contentArray={
                          findSanityDocumentBySlug(fetchedConsentForms, "mobil")?.content
                        }
                        linkStyle={IsFKTheme ? termLinkStyles.termLink : termLinkStyles.termLinkTK}
                        termsLink={termsBaseUrl + mobileTermsGuid}
                        withdrawalLink={mobileRightOfWithdrawalLink}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            {customerInfo.orders
              .filter((o) => o.powerSaleData != null)
              .some((o) => acceptedOrders.includes(o.id)) && (
              <div>
                {!hasOnlyPowerInsurance &&
                  !hasOnlyStromSmartPlusOrPuls(acceptedOrders, customerInfo) &&
                  !hasOnlyStromSmartInsurance && (
                    <>
                      {hasPowerAndStromSmart ? (
                        <AddonView
                          acceptedTerms={acceptedStromSmartTerms && acceptedPowerTerms} // Combine the terms state
                          setAcceptedTerms={(termsAccepted) => {
                            // Handle both terms together
                            setAcceptedStromSmartTerms(termsAccepted);
                            setAcceptedPowerTerms(termsAccepted);
                          }}
                          openTermsMessageDialog={
                            openStromSmartTermsMessageDialog || openPowerTermsMessageDialog
                          } // Combine the message dialog state
                          setOpenTermsMessageDialog={(open) => {
                            // Handle both dialogs together
                            setOpenStromSmartTermsMessageDialog(open);
                            setOpenPowerTermsMessageDialog(open);
                          }}
                          currentRef={stromSmartTarget}
                          fetchedConsentForms={fetchedConsentForms}
                          withdrawalLink={powerRightOfWithdrawalLink}
                          termsLink={termsBaseUrl + powerTermsGuid}
                          linkStyle={termLinkStyles.termLink}
                          termSlug="signatur-tekst-strom-stromsmart"
                          popoverConfig={{
                            anchorOrigin: { vertical: "center", horizontal: "right" },
                            transformOrigin: { vertical: "center", horizontal: "left" }
                          }}
                          withdrawalLink_2={
                            currentStromSmartAddonProduct?.productHubInfo?.cancellationForm?.url
                          }
                          termsLink_2={
                            termsBaseUrl +
                            currentStromSmartAddonProduct?.productHubInfo?.termsAndConditionsId
                          }
                          addonProductToDisplay_2={currentStromSmartAddonProduct}
                        />
                      ) : (
                        (customerInfo.salesTypeId === 1 || customerInfo.salesTypeId === null) && (
                          <div className={styles.checkBoxContainer}>
                            <button
                              className={styles.checkBoxButton}
                              onClick={() => setAcceptedPowerTerms(!acceptedPowerTerms)}
                              ref={powerTarget}
                            >
                              {acceptedPowerTerms ? (
                                <CheckBox className={styles.checkBoxIcon} />
                              ) : (
                                <CheckBoxOutlineBlank className={styles.checkBoxIcon} />
                              )}
                            </button>
                            <Popover
                              classes={{ paper: classes.popoverCheckboxStyle }}
                              open={openPowerTermsMessageDialog}
                              anchorEl={powerTarget.current}
                              onClose={() => setOpenPowerTermsMessageDialog(false)}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "left"
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "left"
                              }}
                            >
                              <Box>
                                <Box
                                  sx={{
                                    padding: 1,
                                    paddingLeft: 0,
                                    borderRadius: 3,
                                    backgroundColor: "#628c2a",
                                    color: "white",
                                    borderColor: "black"
                                  }}
                                >
                                  <ArrowLeftIcon fontSize={"large"} />
                                  <WarningAmberIcon /> Husk å akseptere strømvilkårene
                                </Box>
                              </Box>
                            </Popover>
                            <div className={styles.checkBoxText}>
                              {fetchedConsentForms && fetchedConsentForms.length > 0 ? (
                                <PortableAgreementText
                                  contentArray={
                                    findSanityDocumentBySlug(
                                      fetchedConsentForms,
                                      "signatur-tekst-strom"
                                    )?.content
                                  }
                                  linkStyle={
                                    IsFKTheme ? termLinkStyles.termLink : termLinkStyles.termLinkTK
                                  }
                                  termsLink={termsBaseUrl + powerTermsGuid}
                                  withdrawalLink={powerRightOfWithdrawalLink}
                                />
                              ) : (
                                <div className={styles.checkBoxText}>
                                  Jeg bekrefter å ha lest, og godkjenner,{" "}
                                  <a
                                    className={styles.termsLink}
                                    href={termsBaseUrl + powerTermsGuid}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    avtalevilkårene
                                  </a>{" "}
                                  for levering av strøm.
                                </div>
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </>
                  )}

                {hasAcceptedPowerInsurance && (
                  <AddonView
                    acceptedTerms={acceptedInsuranceTerms}
                    setAcceptedTerms={setAcceptedInsuranceTerms}
                    openTermsMessageDialog={openInsuranceTermsMessageDialog}
                    setOpenTermsMessageDialog={setOpenInsuranceTermsMessageDialog}
                    currentRef={powerInsuranceTarget}
                    fetchedConsentForms={fetchedConsentForms}
                    productName={currentAddonProduct?.productHubInfo?.name}
                    withdrawalLink={currentAddonProduct?.productHubInfo?.cancellationForm?.url}
                    addonProductToDisplay={currentAddonProduct}
                    termsBaseUrl={termsBaseUrl}
                    termSlug={
                      IsFKTheme
                        ? "tilleggsprodukter-fk-stromforsikring"
                        : "tilleggsprodukter-tk-stromforsikring"
                    }
                    popoverConfig={{
                      anchorOrigin: { vertical: "center", horizontal: "left" },
                      transformOrigin: { vertical: "center", horizontal: "left" }
                    }}
                  />
                )}

                {hasAcceptedStromSmartPlusInsurance && !hasAcceptedPulsInsurance && (
                  <AddonView
                    acceptedTerms={acceptedStromSmartPlusTerms}
                    setAcceptedTerms={setAcceptedStromSmartPlusTerms}
                    openTermsMessageDialog={openStromSmartPlusTermsMessageDialog}
                    setOpenTermsMessageDialog={setOpenStromSmartPlusTermsMessageDialog}
                    currentRef={stromSmartPlusTarget}
                    fetchedConsentForms={fetchedConsentForms}
                    productName={currentStromSmartPlusAddonProduct?.productHubInfo?.name}
                    withdrawalLink={
                      currentStromSmartPlusAddonProduct?.productHubInfo?.cancellationForm?.url
                    }
                    addonProductToDisplay={currentStromSmartPlusAddonProduct}
                    termsBaseUrl={termsBaseUrl}
                    termSlug="tilleggsprodukter-fk-stromsmartplus"
                    popoverConfig={{
                      anchorOrigin: { vertical: "center", horizontal: "left" },
                      transformOrigin: { vertical: "center", horizontal: "left" }
                    }}
                  />
                )}
                {hasAcceptedStromSmartInsurance && !hasPowerAndStromSmart && (
                  <AddonView
                    acceptedTerms={acceptedStromSmartTerms}
                    setAcceptedTerms={setAcceptedStromSmartTerms}
                    openTermsMessageDialog={openStromSmartTermsMessageDialog}
                    setOpenTermsMessageDialog={setOpenStromSmartTermsMessageDialog}
                    currentRef={stromSmartTarget}
                    fetchedConsentForms={fetchedConsentForms}
                    productName={currentStromSmartAddonProduct?.productHubInfo?.name}
                    withdrawalLink={
                      currentStromSmartAddonProduct?.productHubInfo?.cancellationForm?.url
                    }
                    addonProductToDisplay={currentStromSmartAddonProduct}
                    termsBaseUrl={termsBaseUrl}
                    termSlug="tilleggsprodukter-fk-stromsmart"
                    popoverConfig={{
                      anchorOrigin: { vertical: "center", horizontal: "left" },
                      transformOrigin: { vertical: "center", horizontal: "left" }
                    }}
                  />
                )}

                {hasAcceptedPulsInsurance && hasAcceptedStromSmartPlusInsurance && (
                  <AddonView
                    acceptedTerms={acceptedPulsTerms && acceptedStromSmartPlusTerms} // Combine the terms state
                    setAcceptedTerms={(termsAccepted) => {
                      // Handle both terms together
                      setAcceptedPulsTerms(termsAccepted);
                      setAcceptedStromSmartPlusTerms(termsAccepted);
                    }}
                    openTermsMessageDialog={
                      openPulsTermsMessageDialog || openStromSmartPlusTermsMessageDialog
                    } // Combine the message dialog state
                    setOpenTermsMessageDialog={(open) => {
                      // Handle both dialogs together
                      setOpenPulsTermsMessageDialog(open);
                      setOpenStromSmartPlusTermsMessageDialog(open);
                    }}
                    currentRef={pulsTarget}
                    fetchedConsentForms={fetchedConsentForms}
                    productName={currentStromSmartPlusAddonProduct?.productHubInfo?.name}
                    withdrawalLink={
                      currentStromSmartPlusAddonProduct?.productHubInfo?.cancellationForm?.url
                    }
                    addonProductToDisplay={currentStromSmartPlusAddonProduct}
                    termsBaseUrl={termsBaseUrl}
                    termSlug="tilleggsprodukter-fk-stromsmartplus-puls"
                    popoverConfig={{
                      anchorOrigin: { vertical: "center", horizontal: "right" },
                      transformOrigin: { vertical: "center", horizontal: "left" }
                    }}
                    productName_2={currentPulsAddonProduct?.productHubInfo?.name}
                    withdrawalLink_2={
                      currentPulsAddonProduct?.productHubInfo?.cancellationForm?.url
                    }
                    termsLink_2={
                      termsBaseUrl + currentPulsAddonProduct?.productHubInfo?.termsAndConditionsId
                    }
                    addonProductToDisplay_2={currentPulsAddonProduct}
                  />
                )}
              </div>
            )}
          </>
        )}

        <div className={styles.generalMargin}>
          <div className={styles.center}>
            Trykk "velg" i en avtale for å klargjøre avtale(ene) til aksept.
          </div>
        </div>
        <div className={styles.confirmButtonDiv}>
          {customerInfo && (
            <>
              {acceptedOrders.length > 0 && (
                <button className={`btnPrimary ${styles.confirmButton}`} onClick={onConfirm}>
                  {acceptedOrders.length === customerInfo.orders.length
                    ? "Fullfør bestilling"
                    : "Fullfør bestilling for " +
                      acceptedOrders.length +
                      " av " +
                      customerInfo.orders.length +
                      " avtaler"}
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </FormPageContainer>
  );
}

export default connect(smsAcceptCustomerInfoSelector)(ConfirmPage);
