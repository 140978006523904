import React from "react";
import styles from "./BedriftIdentification.module.scss";
import Identifisering from "../../../assets/Images/Bedrift/Identifisering.svg";
import { IsFKTheme } from "../../../Utils/Themes";
import { connect } from "react-redux";
import { formSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import SimpleInput from "../../SimpleInput/SimpleInput";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import BedriftSearchInput from "../../SimpleInput/BedriftSearchInput";

function BedriftIdentification({ form }) {
  const { companyName, orgNumber } = form;
  const isValidInput = companyName && orgNumber && orgNumber.length === 9;

  const continueForward = () => {
    pageStepActionDispatchers.goToNextStep(window.location.pathname);
  };

  const handleChange = (e) => {
    saleActionDispatchers.updateFormAction({ [e.target.name]: e.target.value });
  };

  const handleOrgNr = (e) => {
    if (e.target.value.length <= 9)
      saleActionDispatchers.updateFormAction({
        [e.target.name]: e.target.value,
        ["ehfOrgNumber"]: e.target.value
      });
  };

  const handleCompanySelected = (c) => {
    saleActionDispatchers.updateFormAction({
      ["orgNumber"]: c.orgNr,
      ["ehfOrgNumber"]: c.orgNr,
      ["companyName"]: c.name,
      ["address"]: c.address,
      ["zipCode"]: c.postNr,
      ["ssn"]: c.orgNr,
      ["invoiceAddress"]: c.address,
      ["invoiceZipCode"]: c.postNr
    });
  };

  const handleConfirm = () => {
    continueForward();
  };

  return (
    <FormPageContainer>
      <div className={"slide"}>
        <div className={styles.imgContainer}>
          {<img className={styles.mascot} src={Identifisering} alt="mascot" />}
        </div>
        <header className={styles.header}>
          <h1>Først må vi identifisere bedriften</h1>
        </header>

        <BedriftSearchInput
          className={styles.field}
          value={companyName}
          name={"companyName"}
          placeholder={"Navn på selskapet"}
          onChange={handleChange}
          onCompanyChange={handleCompanySelected}
          label={"Selskapsnavn"}
        />

        <SimpleInput
          className={styles.field}
          type={"tel"}
          name={"orgNumber"}
          value={orgNumber}
          label={"Organisasjonsnummer"}
          onChange={handleOrgNr}
          placeholder={"9 siffer"}
          showWhiteBox
        />

        <button
          className={"btnPrimary " + styles.btn}
          disabled={!isValidInput}
          onClick={handleConfirm}
        >
          Bekreft
        </button>
      </div>
    </FormPageContainer>
  );
}

export default connect(formSelector)(BedriftIdentification);
