import React, { useState } from "react";
import { connect } from "react-redux";
import { formSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { INVOICE_SELECTION_OPTIONS } from "../../../ReduxModules/Reducers/InvoiceReducer";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import WhiteButtonList from "../../ButtonList/WhiteButtonList";
import styles from "../BirthDate/BirthDate.module.scss";
import SimpleInput from "../../SimpleInput/SimpleInput";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import Icon from "../../Icon/Icon";
import IconButton from "@mui/material/IconButton";

function BedriftInvoiceMenu({ onConfirm, onBack, isMobile, isBedrift, form }) {
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [showEditEHF, setShowEditEHF] = useState(false);
  const [showEditPaper, setShowEditPaper] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editEHF, setEditEHF] = useState(false);
  const [editPaper, setEditPaper] = useState(false);
  const [index, setIndex] = useState(null);
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const setSelectedIndex = (index) => {
    saleActionDispatchers.updateFormAction({ chosenDistributionMethod: index });
    handleNext(index);
  };

  function handleNext(index) {
    if (index === INVOICE_SELECTION_OPTIONS.paper) {
      //pageStepActionDispatchers.addAndGoToNextStep(pages.invoice, pages.invoiceDetails)
      pageStepActionDispatchers.goToNextStep(window.location.pathname);
    } else {
      if (onConfirm) {
        onConfirm();
      } else {
        pageStepActionDispatchers.goToNextStep(window.location.pathname);
      }
    }
  }
  function handleChange(e) {
    saleActionDispatchers.updateFormAction({ [e.target.name]: e.target.value });
  }

  function handleSetEditEmail() {
    saleActionDispatchers.updateFormAction({ invoiceEmail: "" });
    setEditEmail(true);
  }

  function handleSetEditEHF() {
    saleActionDispatchers.updateFormAction({ ehfOrgNumber: "" });
    setEditEHF(true);
  }

  function handleSetEditPaper() {
    setEditPaper(true);
  }

  function handleShowEmail() {
    setShowEditEmail(true);
    setShowEditPaper(false);
    setShowEditEHF(false);
    setIndex(INVOICE_SELECTION_OPTIONS.email);
  }

  function handleShowEHF() {
    setShowEditEmail(false);
    setShowEditPaper(false);
    setShowEditEHF(true);
    setIndex(INVOICE_SELECTION_OPTIONS.EHF);
  }

  function handleShowPaper() {
    setShowEditEmail(false);
    setShowEditEHF(false);
    setShowEditPaper(true);
    setIndex(INVOICE_SELECTION_OPTIONS.paper);
  }

  function canContinue() {
    if (index === null) return false;
    switch (index) {
      default:
      case INVOICE_SELECTION_OPTIONS.email:
        return !emailRegex.test(form.invoiceEmail);
      case INVOICE_SELECTION_OPTIONS.EHF:
        return form.ehfOrgNumber.length !== 9;
      case INVOICE_SELECTION_OPTIONS.paper:
        return form.invoiceAddress.length <= 2 || form.invoiceZipCode.length !== 4;
    }
  }

  return (
    <FormPageContainer onBack={onBack}>
      <h1 className={"fallIn-"}>Hvordan ønsker du å motta din faktura?</h1>
      <div className={"slide"} style={{ marginTop: "15px" }}>
        <WhiteButtonList
          buttons={[
            {
              content: (
                <div>
                  <div className={"title"}>E-post</div>
                  {showEditEmail && (
                    <div className={"d-flex justify-content-between"}>
                      <SimpleInput
                        className={styles.emailField}
                        disabled={!editEmail}
                        type={"text"}
                        name={"invoiceEmail"}
                        value={form.invoiceEmail}
                        onChange={handleChange}
                        placeholder={"Skriv e-postadressen"}
                        autofocus={editEmail}
                        inputMode={"email"}
                      />
                      <IconButton onClick={handleSetEditEmail}>
                        <Icon icon={"pencil"} />
                      </IconButton>
                    </div>
                  )}
                </div>
              ),
              onClick: () => handleShowEmail()
            },
            {
              content: (
                <div>
                  <div className={"title"}>EHF</div>
                  {showEditEHF && (
                    <div className={"d-flex justify-content-between"}>
                      <SimpleInput
                        className={styles.emailField}
                        disabled={!editEHF}
                        type={"text"}
                        name={"ehfOrgNumber"}
                        value={form.ehfOrgNumber}
                        onChange={handleChange}
                        placeholder={"EHF orgnummer"}
                        autofocus={editEHF}
                        inputMode={"text"}
                      />
                      <IconButton onClick={handleSetEditEHF}>
                        <Icon icon={"pencil"} />
                      </IconButton>
                    </div>
                  )}
                </div>
              ),
              onClick: handleShowEHF
            },
            {
              content: (
                <div>
                  <div className={"title"}>Papirfaktura</div>
                  {showEditPaper && (
                    <div className={"d-flex justify-content-between"}>
                      <div>
                        <SimpleInput
                          className={styles.emailField}
                          disabled={!editPaper}
                          type={"text"}
                          name={"invoiceAddress"}
                          value={form.invoiceAddress}
                          onChange={handleChange}
                          placeholder={"Fakturaadresse"}
                          autofocus={editPaper}
                          inputMode={"text"}
                        />
                        <SimpleInput
                          className={styles.emailField}
                          disabled={!editPaper}
                          type={"text"}
                          name={"invoiceZipCode"}
                          value={form.invoiceZipCode}
                          onChange={handleChange}
                          placeholder={"Faktura postnummer"}
                          inputMode={"text"}
                        />
                      </div>
                      <div>
                        <IconButton onClick={handleSetEditPaper}>
                          <Icon icon={"pencil"} />
                        </IconButton>
                      </div>
                    </div>
                  )}
                </div>
              ),
              onClick: handleShowPaper
            }
          ]}
        />

        <button
          className={"btnPrimary " + styles.button}
          disabled={canContinue()}
          onClick={() => setSelectedIndex(index)}
        >
          Bekreft
        </button>
      </div>
    </FormPageContainer>
  );
}

export default connect(formSelector)(BedriftInvoiceMenu);
