import React, { useState } from "react";
import { connect } from "react-redux";
import { summarySelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import ProductInfoModal from "../ProductInfoModal/ProductInfoModal";
import MobileSummary from "./MobileSummary";
import PowerSummary from "./PowerSummary";

function Summary({ onBack, onConfirm, isMobile }) {
  const [showProductInfoModal, setShowProductInfoModal] = useState(false); // productId
  return (
    <>
      {
        <ProductInfoModal
          show={showProductInfoModal}
          closeModal={() => setShowProductInfoModal(false)}
          productId={showProductInfoModal}
          isMobile={isMobile}
        />
      }
      <FormPageContainer onBack={onBack}>
        {isMobile ? (
          <MobileSummary onConfirm={onConfirm} setShowProductInfoModal={setShowProductInfoModal} />
        ) : (
          <PowerSummary onConfirm={onConfirm} setShowProductInfoModal={setShowProductInfoModal} />
        )}
      </FormPageContainer>
    </>
  );
}

export default connect(summarySelector)(Summary);
