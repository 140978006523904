import React from "react";
import styles from "./Trumf.module.scss";
import { IsFKTheme } from "../../Utils/Themes";
import Trumf from "../../assets/Images/FK/TrumfFramed.svg";
import FormPageContainer from "../CustomerForm/FormPageContainer/FormPageContainer";
import saleActionDispatchers from "../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { getTrumfProductId } from "../../Utils/AdditionalProductHelper";
import pageStepActionDispatchers from "../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";

function TrumfRegistered({ onNext, onBack, formPageAnimation }) {
  // if trumfId was added, this component will be displayed. Add trumf as a selected extraProduct
  const selectTrumfAndMoveOn = () => {
    saleActionDispatchers.selectAddonProduct(getTrumfProductId(), true);
    pageStepActionDispatchers.goToNextStep(window.location.pathname);
  };
  return (
    <FormPageContainer onBack={onBack}>
      <h1 className={`${formPageAnimation && "fallIn-"}`}>Din Trumf konto er registrert!</h1>
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img className={"frameImage formPage-background fade-"} src={Trumf} alt={"trumf"} />
        </div>
      )}
      <div className={`${formPageAnimation && "slide"}`}>
        <button className={"btnPrimary " + styles.button} onClick={selectTrumfAndMoveOn}>
          Gå videre
        </button>
      </div>
    </FormPageContainer>
  );
}

export default TrumfRegistered;
