import React from "react";
import styles from "./Modal.module.scss";
import Icon from "../Icon/Icon";
import { IsFKTheme } from "../../Utils/Themes";
import { Dialog } from "@mui/material";

function ModalWindow({ open, onClose, children }) {
  return (
    <Dialog open={open} onClose={onClose} scroll={"body"} className={styles.modal}>
      <div className={IsFKTheme && styles.containerFK}>
        <Icon
          className={styles.smallCloseButton}
          icon={IsFKTheme ? "clear" : "tkClear"}
          onClick={onClose}
          type="button"
        />
        {children}
        <button className={`btnUnderline ${styles.closeButton}`} onClick={onClose}>
          Lukk
        </button>
      </div>
    </Dialog>
  );
}

export default ModalWindow;
