import React from "react";
import styles from "./ProductPill.module.scss";
import MoreDataBadgeBlack from "../../assets/Images/FK/Mobil/mer-data-samme-pris-sort.gif";
import MoreDataBadgeWhite from "../../assets/Images/FK/Mobil/mer-data-samme-pris-hvit.gif";
import NewProductBlack from "../../assets/Images/FK/Mobil/nyhet-sort.gif";
import NewProductWhite from "../../assets/Images/FK/Mobil/nyhet-hvit.gif";

function ProductPill({ name, price, prevSize, newProduct, color, onClick, isUng, packageName }) {
  function getPillColorStyle() {
    switch (color) {
      case "pink":
        return styles.pillPink;
      case "orange":
        return styles.pillOrange;
      default:
        return "";
    }
  }
  return (
    <button className={`${styles.pill} ${getPillColorStyle()}`} onClick={onClick}>
      <div className={styles.size}>{packageName}</div>
      {prevSize && <div className={styles.prevSize}>Før {prevSize} GB</div>}
      <div className={styles.price}>{price},-</div>
      <div className={styles.pillSeperator} />
      <div className={styles.text}>{isUng ? "For deg som\ner under 30 år" : "pr.mnd."}</div>
      {newProduct ? (
        <img
          className={styles.badge}
          src={isUng ? NewProductWhite : NewProductBlack}
          alt={"NewProduct"}
        />
      ) : (
        prevSize && (
          <img
            className={styles.badge}
            src={isUng ? MoreDataBadgeWhite : MoreDataBadgeBlack}
            alt={"MoreDataBadge"}
          />
        )
      )}
    </button>
  );
}

export default ProductPill;
