import { AdditionalSalesType } from "../types";

function getAdditionalSalesType(
  isFKTheme: boolean,
  isBedrift: boolean,
  isMobile: boolean
): AdditionalSalesType {
  if (isMobile) {
    return "mobile";
  }

  if (isBedrift) {
    return isFKTheme ? "fkasbedrift" : "tkasbedrift";
  }

  return isFKTheme ? "fkas" : "tkas";
}

export { getAdditionalSalesType };
