import { pages } from "../../../../Utils/CustomerFormHelper";
import React from "react";
import AddDeliveryPointContainer from "./AddDeliveryPointContainer";
import { isBedriftSelector } from "../../../../ReduxModules/Selectors/SaleSelectors";
import { connect } from "react-redux";

function AddDeliveryPoint({ isBedrift, ...props }) {
  const { id } = props.match.params;
  return (
    <AddDeliveryPointContainer
      deliveryPointId={id}
      path={(isBedrift ? pages.bedrift : "") + pages.deliveryPointV2}
    />
  );
}

export default connect(isBedriftSelector)(AddDeliveryPoint);
