import React, { useEffect } from "react";
import DeliveryPointAddress from "./DeliveryPointAddress";
import { connect } from "react-redux";
import { deliveryPointV2Selector } from "../../../../ReduxModules/Selectors/SaleSelectors";
import DeliveryPointMeterNumber from "./DeliveryPointMeterNumber";
import { matchPath, Switch } from "react-router-dom";
import { pages } from "../../../../Utils/CustomerFormHelper";
import DeliveryPointCalendar from "../NewDeliveryPoint/NewDeliveryPointMovingDate/DeliveryPointCalendar/DeliveryPointCalendar";
import DeliveryPointMovingDate from "../NewDeliveryPoint/NewDeliveryPointMovingDate/DeliveryPointMovingDate";
import saleActionDispatchers from "../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { history } from "../../../../App";
import pageStepActionDispatchers from "../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import TransitionRoute from "../../../TransitionRoute/TransitionRoute";

function AddDeliveryPointContainer({
  deliveryPointId,
  deliveryPoint,
  deliveryPoints,
  deliveryPointSteps,
  path,
  isBedrift
}) {
  useEffect(() => {
    if (!deliveryPointSteps.some((dps) => matchPath(window.location.pathname, dps))) {
      saleActionDispatchers.startDeliveryPointSteps(deliveryPointId, true);
    }
  }, []);
  useEffect(() => {
    if (!deliveryPointSteps.some((dps) => matchPath(window.location.pathname, dps))) {
      saleActionDispatchers.startDeliveryPointSteps(deliveryPointId, true);
    }
  }, [deliveryPoint.elhubData]);

  const handleBack = () => {
    if (deliveryPoints.some((dp) => dp.elhubData)) {
      history.push(pages.deliveryPoints);
    } else {
      pageStepActionDispatchers.goToPreviousStep(
        isBedrift ? pages.bedrift + pages.deliveryPoints : pages.deliveryPoints
      );
    }
  };
  return deliveryPoint ? (
    <>
      <Switch>
        <TransitionRoute
          exact
          path={path}
          render={() => (
            <DeliveryPointAddress deliveryPointId={deliveryPointId} onBack={handleBack} />
          )}
        />
        <TransitionRoute
          path={path + pages.deliveryPointV2MeterNumber}
          render={() => <DeliveryPointMeterNumber deliveryPointId={deliveryPointId} path={path} />}
        />
        <TransitionRoute
          path={path + pages.deliveryPointV2MovingDate}
          render={() => <DeliveryPointMovingDate deliveryPointId={deliveryPointId} path={path} />}
        />
        <TransitionRoute
          path={path + pages.deliveryPointV2Calendar}
          render={() => <DeliveryPointCalendar deliveryPointId={deliveryPointId} path={path} />}
        />
      </Switch>
    </>
  ) : (
    <div />
  );
}

export default connect(deliveryPointV2Selector)(AddDeliveryPointContainer);
