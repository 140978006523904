export const stateSelector = ({ smsAcceptState }) => {
  return { smsAcceptState };
};

export const smsAcceptCustomerInfoSelector = ({ smsAcceptState }) => {
  return {
    customerInfo: smsAcceptState.customerInfo,
    isLoading: smsAcceptState.isLoadingCustomerInfo,
    error: smsAcceptState.loadCustomerInfoError,
    initialized: smsAcceptState.initialized,
    hashedCustomerId: smsAcceptState.hashedCustomerId,
    acceptedOrders: smsAcceptState.acceptedOrders
  };
};

export const smsAcceptSimpleSelector = ({ smsAcceptState }) => {
  return {
    orderIds: smsAcceptState.customerInfo.orders.map((o) => o.id),
    hashedCustomerId: smsAcceptState.hashedCustomerId
  };
};

export const smsAcceptReceiptSelector = ({ smsAcceptState }) => {
  return {
    customerInfo: smsAcceptState.customerInfo,
    isLoading: smsAcceptState.isSubmitting,
    error: smsAcceptState.submitError,
    initialized: smsAcceptState.initialized,
    acceptedOrders: smsAcceptState.acceptedOrders
  };
};
