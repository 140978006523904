import React from "react";
import { connect } from "react-redux";
import FormPageContainer from "../../CustomerForm/FormPageContainer/FormPageContainer";
import { trumfSelector } from "../../../ReduxModules/Selectors/TrumfSelectors";
import Loader from "../../CustomerForm/FKLoader/Loader";
import AddTrumfProductSuccess from "./AddTrumfProductSuccess";
import AddTrumfProductError from "./AddTrumfProductError";
import AddTrumfProductLogin from "./AddTrumfProductLogin";

function AddTrumfProduct({
  momentId,
  mdmId,
  isLoading,
  isMdmUpdated,
  error,
  trumfId,
  redirectUri
}) {
  return isLoading ? (
    <FormPageContainer hideFooter>
      <Loader text={"Registrerer trumf..."} />
    </FormPageContainer>
  ) : isMdmUpdated ? (
    <AddTrumfProductSuccess momentId={momentId} mdmId={mdmId} redirectUri={redirectUri} />
  ) : error ? (
    <AddTrumfProductError
      momentId={momentId}
      trumfId={trumfId}
      redirectUri={redirectUri}
      error={error}
    />
  ) : (
    <AddTrumfProductLogin momentId={momentId} mdmId={mdmId} />
  );
}

export default connect(trumfSelector)(AddTrumfProduct);
