import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { productInfoV2Selector } from "../../../ReduxModules/Selectors/ProductSelectors";
import { useParams } from "react-router-dom";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import AddonProductV2 from "../Mobile/AddonProductV2";
import Loader from "../FKLoader/Loader";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import Preloader from "../../Preloader/Preloader";
import AddonProduct from "../AddonProduct/AddonProduct";
import { productCategories } from "../../../Utils/CustomerFormHelper";
import config from "../../../config";
import { formSelector, isBedriftSelector } from "../../../ReduxModules/Selectors/SaleSelectors";

export default function PreviewAddon() {
  let { id } = useParams();
  const { product } = useSelector((state) => productInfoV2Selector(state, { productId: id }));
  const isBedrift = useSelector((state) => isBedriftSelector(state));

  useEffect(() => {
    const storeId = config.env === "dev" ? 1399 : 935;
    productActionDispatchers.getProducts(
      [productCategories.serviceOption, productCategories.addon],
      [storeId],
      isBedrift ? "B" : "P"
    );
    productActionDispatchers.getAddonProductInfo(id);
  }, []);

  return (
    <>
      <Preloader />
      {!product?.textGroups || !product?.productCategory ? (
        <FormPageContainer hideFooter>
          <Loader text={"Vent mens vi gjør ting klart for deg..."} />
        </FormPageContainer>
      ) : (
        <div>
          {product?.textGroups && product.productCategory === productCategories.serviceOption ? (
            <AddonProductV2
              productId={id}
              product={product}
              onConfirm={() => console.log("Test")}
            />
          ) : (
            <AddonProduct addonProductId={id} onConfirm={() => console.log("Test")} />
          )}
        </div>
      )}
    </>
  );
}
