import React, { useState } from "react";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import WhiteButtonList from "../../ButtonList/WhiteButtonList";
import { IsFKTheme } from "../../../Utils/Themes";
import MobileMascotSmile from "../../../assets/Images/FK/Mobil/MobileMascotSmile.svg";
import { connect } from "react-redux";
import { formAndUserSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { pages } from "../../../Utils/CustomerFormHelper";
import { useParams } from "react-router-dom";
import { productInfoV2Selector } from "../../../ReduxModules/Selectors/ProductSelectors";
import styles from "./ModalInfo.module.scss";
import MascotBlush from "../../../assets/Images/FK/Mobil/MascotBlush.svg";
import ModalWindow from "../../Modal/Modal";
import Icon from "../../Icon/Icon";
import _ from "lodash";

function AddonProductV2({ onConfirm, onBack, isMobile, userId, productId, product, form, user }) {
  const params = useParams();
  const [showModal, setShowModal] = useState(false);

  function handleClick(value) {
    let newExtraProducts = (userId ? user.extraProducts : form.extraProducts) || [];
    if (value) {
      newExtraProducts = _.uniqBy(
        newExtraProducts.concat([form.recommendedProducts.find((rp) => rp.id === productId)]),
        (p) => p.id
      );
    } else {
      newExtraProducts = newExtraProducts.filter((ep) => ep.id !== productId);
    }
    if (userId) {
      saleActionDispatchers.updateUser(userId, { extraProducts: newExtraProducts });
    } else {
      saleActionDispatchers.updateFormAction({ extraProducts: newExtraProducts });
    }
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
    }
  }

  const getMobileHeader = () => {
    switch (productId) {
      case "920": // ID-sikring
        return "Sikre deg og din personlige informasjon";
      case "884": // Data SIM
        return "Knytt flere SIM-kort til ditt abonnement";
      case "883": // Tvilling SIM
        return "Knytt flere SIM-kort til ditt abonnement";
      case "882": // Ikke i opplysningen
        return "Vil du at andre skal finne nummeret ditt i opplysningen?";

      default:
        return "";
    }
  };

  return (
    <FormPageContainer onBack={onBack} currentStep={userId ? pages.mobile + pages.summary : null}>
      {productId && (
        <header className={"fallIn-"}>
          {isMobile ? (
            <div style={{ textAlign: "center" }}>
              <h3>{getMobileHeader()}</h3>
            </div>
          ) : (
            <div
              style={{ textAlign: "center" }}
              dangerouslySetInnerHTML={{ __html: product.textGroups[0] }}
            />
          )}
        </header>
      )}
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img
            className={"frameImage formPage-background fade-"}
            src={MobileMascotSmile}
            alt={"mobileMascot"}
          />
        </div>
      )}
      <div className={"slide"}>
        <WhiteButtonList
          buttons={[
            {
              content: (
                <div>
                  <div
                    className={"title"}
                    dangerouslySetInnerHTML={{
                      __html: isMobile
                        ? product.data?.productName
                        : product.textGroups[5]
                            .replaceAll("<p>", "<div>")
                            .replaceAll("</p>", "</div>")
                    }}
                  />
                  {_.isNumber(isMobile ? product.data?.monthlyFeeIncVat : product.recPrice) && (
                    <div className={"priceTag"}>
                      {isMobile ? product.data?.monthlyFeeIncVat : product.recPrice} kr/mnd
                    </div>
                  )}
                  <div className={"grayText"}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: isMobile ? product.data?.description : product.textGroups[4]
                      }}
                    />
                    {_.isNumber(isMobile ? product.data?.initialFeeIncVat : product.initPrice) && (
                      <div>
                        Etablering kr{" "}
                        {isMobile ? product.data?.initialFeeIncVat : product.initPrice}
                      </div>
                    )}
                  </div>
                </div>
              ),
              onClick: () => handleClick(true)
            },
            {
              content: (
                <div className={"title"}>{productId === "882" ? "Ja, takk" : "Nei, takk"}</div>
              ),
              onClick: () => handleClick(false)
            }
          ]}
        />
        {product.textGroups[1] && (
          <>
            <ModalWindow open={showModal} onClose={() => setShowModal(false)}>
              <img className={styles.modalImage} src={MascotBlush} alt={"mascot"} />
              <div dangerouslySetInnerHTML={{ __html: product.textGroups[1] }} />
            </ModalWindow>
            <button
              className={`btnUnderline ${styles.moreInfoButton}`}
              onClick={() => setShowModal(true)}
            >
              <Icon className={styles.warningIcon} icon={"WarningCircle"} />
              Mer informasjon
            </button>
          </>
        )}
      </div>
    </FormPageContainer>
  );
}

export default connect(productInfoV2Selector)(connect(formAndUserSelector)(AddonProductV2));
