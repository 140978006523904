import React, { useState } from "react";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { IsFKTheme } from "../../../Utils/Themes";
import MobileMascotStarEyes from "../../../assets/Images/FK/Mobil/MobileMascotStarEyes.svg";
import { connect } from "react-redux";
import { summarySelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import styles from "./ConfirmOrder.module.scss";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { CheckBoxOutlineBlank, CheckBox, RemoveCircleOutline } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import _ from "lodash";
import { InvoicePrices, MobileServicePrices } from "../../../Utils/Prices";
import { CHOSEN_DISTRIBUTION_METHOD } from "../../../Utils/HelperFunctions";
import config from "../../../config";
import SelectProductModal from "./SelectProduct/SelectProductModal";
import Icon from "../../Icon/Icon";
import MobileProductSubtext from "./MobileProductSubtext/MobileProductSubtext";
import { mobileRightOfWithdrawalLink } from "../../../Utils/ExternalLinks";

function ConfirmOrder({ onConfirm, onBack, form, products }) {
  const [openChangeProduct, setOpenChangeProduct] = useState(false);
  const [openRemoveExtraProduct, setOpenRemoveExtraProduct] = useState(false);
  const [selectedUserExtraProduct, setSelectedUserExtraProduct] = useState(null);
  const [isAccepted, setIsAccepted] = useState(false);

  const termsBaseUrl =
    config.env === "prod"
      ? "https://avtale.fjordkraft.no/api/term/"
      : "https://test-avtale.fjordkraft.no/api/term/";
  const link = termsBaseUrl + form.producthubInfo.guid;
  const rightOfWithdrawalLink = mobileRightOfWithdrawalLink;

  const confirmOrder = () => {
    saleActionDispatchers.submitMobileFormAction(form, products);
    pageStepActionDispatchers.goToNextStep(window.location.pathname);
  };
  const handleOpenChangeProduct = (user) => {
    setOpenChangeProduct(user.id);
  };
  const handleOpenRemoveProductDialog = (user, extraProduct) => {
    setOpenRemoveExtraProduct(true);
    setSelectedUserExtraProduct({ user, extraProduct });
  };
  const handleRemoveProduct = () => {
    saleActionDispatchers.updateFormAction({
      users: form.users.map((u) => {
        if (u.id === selectedUserExtraProduct.user.id) {
          return {
            ...u,
            extraProducts: u.extraProducts.filter(
              (ep) => ep.id !== selectedUserExtraProduct.extraProduct.id
            )
          };
        }
        return u;
      })
    });
    setOpenRemoveExtraProduct(false);
  };
  const invoicePrice =
    form.chosenDistributionMethod === CHOSEN_DISTRIBUTION_METHOD.paper
      ? InvoicePrices.MobilePaper
      : 0;
  const totalInitPrice = form.users
    .map(
      (u) =>
        (u.createNewNumber ? MobileServicePrices.NewNumber : 0) +
        (_.isEmpty(u.extraProducts)
          ? 0
          : u.extraProducts
              .map((ep) => ep.product.data?.initialFeeIncVat || 0)
              .reduce((totalPrice, partialPrice) => totalPrice + partialPrice))
    )
    .reduce((partialPrice, price) => partialPrice + price);
  const totalRecPrice =
    invoicePrice +
    form.users
      .map(
        (u) =>
          u.product.data?.priceIncVat +
          (_.isEmpty(u.extraProducts)
            ? 0
            : u.extraProducts
                .map((ep) => ep.product.data?.monthlyFeeIncVat || 0)
                .reduce((partialPrice, price) => partialPrice + price))
      )
      .reduce((partialPrice, price) => partialPrice + price);
  const footerContent = (
    <>
      <div className={`slide ${styles.stickyFooter} ${styles.footer}`}>
        <button
          className={`btnSecondary ${styles.backButton}`}
          onClick={() => pageStepActionDispatchers.goToPreviousStep(window.location.pathname)}
        >
          Tilbake
        </button>
        <div className={styles.totalPrices}>
          <div className={styles.totalSum}>
            <div className={styles.flex}>
              <div className={styles.total}>Total per mnd</div>
              <div className={styles.totalPrice}>{totalRecPrice} kr</div>
            </div>
            <div className={styles.flex}>
              <div className={styles.subTotal}>Total etablering</div>
              <div className={styles.subTotalPrice}>{totalInitPrice} kr</div>
            </div>
          </div>
        </div>
      </div>
      <div className={`slide ${styles.footer}`}>
        <div className={styles.footerContainer}>
          <div>
            <div className={styles.checkBoxContainer}>
              <button className={styles.checkBoxButton} onClick={() => setIsAccepted(!isAccepted)}>
                {isAccepted ? (
                  <CheckBox className={styles.checkBoxIcon} />
                ) : (
                  <CheckBoxOutlineBlank className={styles.checkBoxIcon} />
                )}
              </button>
              <div className={styles.checkBoxText}>
                Jeg aksepterer{" "}
                <a
                  className={styles.termsLink}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  vilkårene
                </a>{" "}
                for avtalen og er kjent med{" "}
                <a
                  className={styles.termsLink}
                  href={rightOfWithdrawalLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  opplysninger om angrerett og angreskjema.
                </a>
              </div>
            </div>
          </div>
        </div>
        <button
          className={`btnPrimary ${styles.confirmButton}`}
          onClick={confirmOrder}
          disabled={!isAccepted}
        >
          Bekreft bestilling
        </button>
      </div>
    </>
  );
  return (
    <>
      {openChangeProduct && (
        <SelectProductModal
          className={styles.selectProductModal}
          open={openChangeProduct}
          userId={openChangeProduct}
          handleClose={() => setOpenChangeProduct(false)}
        />
      )}
      {openRemoveExtraProduct && selectedUserExtraProduct && (
        <ConfirmModal
          open={openRemoveExtraProduct}
          handleConfirm={handleRemoveProduct}
          handleClose={() => setOpenRemoveExtraProduct(false)}
          header={`Fjerne ${selectedUserExtraProduct?.extraProduct?.product?.name}?`}
          content={`Er du sikker på at du vil fjerne ${selectedUserExtraProduct?.extraProduct?.product?.name} fra bestillingen?`}
          confirmText={"Fjern"}
        />
      )}
      <FormPageContainer onBack={onBack} hideFooter className={styles.formPage}>
        <div className={styles.content}>
          <div className={styles.mainContent}>
            <h1 className={"fallIn-"}>Sjekk at alt stemmer.</h1>
            {IsFKTheme && (
              <div className={"frameImageContainer"}>
                <img
                  className={"frameImage formPage-background fade-"}
                  src={MobileMascotStarEyes}
                  alt={"mobileMascot"}
                />
              </div>
            )}
            <div className={"slide"}>
              {form.users.map((u) => (
                <div className={`whiteBox ${styles.box}`} key={u.id}>
                  <div className={`${styles.customerName} ${styles.grayText}`}>
                    {u.isOwner
                      ? `${form.firstName} ${form.lastName}`
                      : `${u.firstName} ${u.lastName}`}
                  </div>
                  <div className={styles.item}>
                    <div className={styles.productName}>{u.product.name}</div>
                    <div className={`${styles.itemPrice} ${styles.orangeText}`}>
                      {u.product.data?.priceIncVat} kr/mnd
                    </div>
                    <Tooltip title={"Bytt produkt"} arrow>
                      <button
                        className={styles.removeButton}
                        onClick={() => handleOpenChangeProduct(u)}
                      >
                        <Icon className={styles.changeProductIcon} icon={"pencil"} />
                      </button>
                    </Tooltip>
                  </div>
                  <div className={styles.seperationLine} />
                  {
                    <div className={styles.item}>
                      <div className={styles.itemName}>
                        {u.createNewNumber ? "Opprett nytt nummer" : `Eksisterende nummer`}
                      </div>
                      <div className={`${styles.itemPrice}`}>
                        {u.createNewNumber ? MobileServicePrices.NewNumber : 0} kr
                      </div>
                    </div>
                  }
                  {!u.createNewNumber && (
                    <div className={styles.subItem}>
                      <div className={styles.grayText}>{u.phone}</div>
                    </div>
                  )}

                  {!_.isEmpty(u.extraProducts) &&
                    u.extraProducts.map((ep, i) => (
                      <div key={i}>
                        <div className={styles.item}>
                          <div className={styles.itemName}>{ep.product?.name}</div>
                          {_.isNumber(ep.product.data?.monthlyFeeIncVat) && (
                            <div className={`${styles.itemPrice}`}>
                              {ep.product.data?.monthlyFeeIncVat} kr/mnd
                            </div>
                          )}
                          <Tooltip title={"Fjern tilleggstjeneste"} arrow>
                            <button
                              className={styles.removeButton}
                              onClick={() => handleOpenRemoveProductDialog(u, ep)}
                            >
                              <RemoveCircleOutline className={styles.removeIcon} />
                            </button>
                          </Tooltip>
                        </div>
                        {_.isNumber(ep.product.initPrice) && (
                          <div className={styles.subItem}>
                            <div className={styles.grayText}>Etablering</div>
                            <div className={`${styles.itemPrice}`}>{ep.product.initPrice} kr</div>
                          </div>
                        )}
                      </div>
                    ))}

                  {form.campaign && (
                    <div>
                      <div className={styles.campaign}>
                        <div className={styles.itemName}>{form.campaign?.campaignName}</div>
                        {form.campaign.text && (
                          <div
                            className={styles.campaignDesc}
                            dangerouslySetInnerHTML={{ __html: form.campaign.text }}
                          ></div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className={`whiteBox ${styles.box}`}>
                <div className={`${styles.grayText}`}>Fakturainformasjon</div>
                <div className={styles.item}>
                  <div className={styles.itemName}>
                    {form.chosenDistributionMethod === CHOSEN_DISTRIBUTION_METHOD.paper
                      ? "Faktura i postkassen"
                      : "eFaktura"}
                  </div>
                  <div className={`${styles.itemPrice}`}>{invoicePrice} kr/mnd</div>
                </div>
                {form.chosenDistributionMethod === CHOSEN_DISTRIBUTION_METHOD.paper && (
                  <div className={styles.subItem}>
                    <div className={styles.grayText}>
                      Faktura sendes til folkeregistrert adresse
                    </div>
                  </div>
                )}
              </div>

              <MobileProductSubtext />
            </div>
          </div>
          {footerContent}
        </div>
      </FormPageContainer>
    </>
  );
}

export default connect(summarySelector)(ConfirmOrder);
