import * as update from "immutability-helper";
import {
  MDM_UPDATE_TRUMF_ID,
  MDM_UPDATE_TRUMF_ID_ERROR,
  MDM_UPDATE_TRUMF_ID_SUCCESS,
  SET_ERROR,
  SET_QUERY_STRINGS,
  SET_TRUMF_ID
} from "../Actions/TrumfActions/TrumfActionTypes";

const initialState = {
  trumfId: null,
  redirectUri: null,
  momentId: null,
  isQueryStringsLoaded: false,
  isLoading: false,
  isMdmUpdated: false,
  error: null
};

const trumfReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRUMF_ID:
      return update(state, {
        trumfId: { $set: action.trumfId }
      });

    case SET_QUERY_STRINGS:
      return update(state, {
        redirectUri: { $set: action.redirectUri },
        momentId: { $set: action.momentId },
        isQueryStringsLoaded: { $set: true }
      });

    case SET_ERROR:
      return update(state, {
        error: { $set: action.error },
        isQueryStringsLoaded: { $set: true }
      });

    case MDM_UPDATE_TRUMF_ID:
      return update(state, {
        isLoading: { $set: true },
        error: { $set: null }
      });

    case MDM_UPDATE_TRUMF_ID_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        isMdmUpdated: { $set: true }
      });

    case MDM_UPDATE_TRUMF_ID_ERROR:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: action.error }
      });

    default:
      return state;
  }
};

export default trumfReducer;
