export const trumfSelector = ({ trumfState }) => {
  return {
    trumfId: trumfState.trumfId,
    isLoading: trumfState.isLoading,
    error: trumfState.error,
    isMdmUpdated: trumfState.isMdmUpdated,
    redirectUri: trumfState.redirectUri,
    momentId: trumfState.momentId,
    mdmId: trumfState.mdmId
  };
};

export const queryStringSelector = ({ trumfState }) => {
  return {
    redirectUri: trumfState.redirectUri,
    momentId: trumfState.momentId,
    mdmId: trumfState.mdmId,
    isQueryStringsLoaded: trumfState.isQueryStringsLoaded
  };
};
