import { Alert, AlertTitle } from "@mui/material";
import { customerServiceLink } from "../../Utils/ExternalLinks";
import styles from "./SubmitErrorBox.module.scss";
import React from "react";

function SubmitErrorBox() {
  return (
    <div>
      <Alert className={styles.alertBox} severity="error">
        <AlertTitle>Noe gikk galt. </AlertTitle>
        <div>
          <h1>En feil har oppstått.</h1>
          <h3>
            Prøv å last siden på nytt eller kontakt{" "}
            <a href={customerServiceLink} target="_blank" rel="noopener noreferrer">
              kundeservice
            </a>{" "}
            hvis du ønsker å fortsette bestillingen din, eller rapportere feil.
          </h3>
          Kundeservice:{" "}
          <a
            href={customerServiceLink}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.errorLink}
          >
            {customerServiceLink}
          </a>
        </div>
      </Alert>
      <button className={`${styles.btnReload} btnPrimary`} onClick={() => window.location.reload()}>
        Last siden på nytt
      </button>
    </div>
  );
}

export default SubmitErrorBox;
