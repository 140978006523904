export const SET_CUSTOMER_FORM_STEPS = "SET_CUSTOMER_FORM_STEPS";
export const GO_TO_FIRST_STEP = "GO_TO_FIRST_STEP";
export const GO_TO_NEXT_STEP = "GO_TO_NEXT_STEP";
export const GO_TO_PREVIOUS_STEP = "GO_TO_PREVIOUS_STEP";
export const ADD_STEP = "ADD_STEP";
export const ADD_STEPS = "ADD_STEPS";
export const ADD_AND_GO_TO_NEXT_STEP = "ADD_AND_GO_TO_NEXT_STEP";
export const REMOVE_STEPS = "REMOVE_STEPS";
export const REMOVE_AND_GO_TO_NEXT_STEP = "REMOVE_AND_GO_TO_NEXT_STEP";
export const SET_MOBILE_SALE_STEPS = "SET_MOBILE_SALE_STEPS";
export const SET_BEDRIFT_STEPS = "SET_BEDRIFT_STEPS";
export const SET_MOBILE_USER_STEPS = "SET_MOBILE_USER_STEPS";
export const COMPLETE_FAMILY_MEMBER = "COMPLETE_FAMILY_MEMBER";
export const SET_FOCUS_DELAY = "SET_FOCUS_DELAY"; // used to prevent bugs in combination of css transition animations
export const SET_NEXT_ANIMATION = "SET_NEXT_ANIMATION"; // for handling formPage animations outside redirects. (E.g. DeliveryPointAdress)

export const ADD_RECOMMENDED_PRODUCT_STEPS = "ADD_RECOMMENDED_PRODUCT_STEPS";
export const ADD_BONUS_STEPS = "ADD_BONUS_STEPS";
export const SET_CONTINUE_AGREEMENT_STEPS = "SET_CONTINUE_AGREEMENT_STEPS";
