import React, { useState, useEffect, useRef } from "react";
import styles from "../NewDeliveryPoint/NewDeliveryPoint.module.scss";
import SimpleInput from "../../../SimpleInput/SimpleInput";
import { usePrevious } from "../../../../Utils/HelperFunctions";
import { connect } from "react-redux";
import saleActionDispatchers from "../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import {
  deliveryPointSelector,
  deliveryPointV2Selector
} from "../../../../ReduxModules/Selectors/SaleSelectors";
import { history } from "../../../../App";
import * as _ from "lodash";
import MeterFoundMessage from "../NewDeliveryPoint/MeterFoundMessage/MeterFoundMessage";
import { pages, pageStepAnimationDuration } from "../../../../Utils/CustomerFormHelper";
import FormPageContainer from "../../FormPageContainer/FormPageContainer";
import Loader from "../../FKLoader/Loader";
import { IsFKTheme } from "../../../../Utils/Themes";
import { CSSTransition } from "react-transition-group";
import Map from "../../../../assets/Images/FK/MapFramed.svg";
import { generatePath, matchPath, useParams } from "react-router-dom";
import pageStepActionDispatchers from "../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";

const possibleAdditionalSteps = [
  pages.deliveryPointV2 + pages.deliveryPointV2MeterNumber,
  pages.deliveryPointV2 + pages.deliveryPointV2MovingDate,
  pages.deliveryPointV2 + pages.deliveryPointV2Calendar
];
function DeliveryPointAddress(props) {
  const { deliveryPointId, isBedrift } = props;
  const [loadingTimeout, setLoadingTimeout] = useState(null);
  const timer = useRef(null);
  const { ssn, deliveryPoints, elhubData, isLoading, deliveryPoint, priceAreaTags } = props;
  const { address, zipCode, priceArea } = deliveryPoint;
  const prevProps = usePrevious(props);
  const params = useParams();

  const loadingJustFinished = prevProps && prevProps.isLoading && !isLoading;

  useEffect(() => {
    pageStepActionDispatchers.removeSteps(possibleAdditionalSteps);
    return () => {
      if (timer?.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);
  useEffect(() => {
    // to prevent any buggy behavior if user redirects during loading.
    return history.listen((location) => {
      if (matchPath(window.location.pathname, { path: pages.deliveryPointV2, exact: true })) {
        setLoadingTimeout(false);
      }
      clearTimeout(timer.current);
    });
  }, [history]);
  useEffect(() => {
    if (loadingJustFinished) {
      if (_.isEmpty(elhubData)) {
        // elhubdata not found. Ask for meternumber.

        if (!deliveryPoint.id) {
          saleActionDispatchers.addDeliveryPoints([{ ...deliveryPoint }]);
        }
        if (!isBedrift) {
          pageStepActionDispatchers.addStep(
            window.location.pathname,
            pages.deliveryPointV2 + pages.deliveryPointV2MeterNumber
          );
        }

        timeOutRedirect();
      } else {
        // elhubdata found.
        if (isBedrift) {
          timeOutRedirect(pages.bedrift + pages.deliveryPoints);
        } else if (_.isArray(elhubData) && elhubData.length > 1) {
          timeOutRedirect(pages.deliveryPoints);
        } else {
          saleActionDispatchers.updateDeliveryPointAction({
            ...deliveryPoint,
            elhubData: elhubData[0]
          });
          if (elhubData[0].isMoving || elhubData[0] !== null) {
            // isMoving. Ask for moving date.
            if (!isBedrift) {
              pageStepActionDispatchers.addStep(
                window.location.pathname,
                pages.deliveryPointV2 + pages.deliveryPointV2Calendar
              );
            }
            timeOutRedirect();
          } else {
            if (!isBedrift) {
              pageStepActionDispatchers.addStep(
                window.location.pathname,
                pages.deliveryPointV2 + pages.deliveryPointV2MovingDate
              );
            }
            timeOutRedirect();
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  function timeOutRedirect(redirectPath) {
    const timeOutDuration = 1500;
    setLoadingTimeout(true);
    if (redirectPath) {
      timer.current = setTimeout(() => history.push(redirectPath), timeOutDuration);
    } else {
      timer.current = setTimeout(
        () => pageStepActionDispatchers.goToNextStep(window.location.pathname, params),
        timeOutDuration
      );
    }
  }

  const handleChange = (e) => {
    // equals check to stop city from bugging out
    if (e.target.value !== deliveryPoint[e.target.name]) {
      saleActionDispatchers.updateDeliveryPointAction(parseInt(deliveryPointId), {
        [e.target.name]: e.target.value
      });
    }
  };

  const handleConfirm = () => {
    saleActionDispatchers.getElhubDataAction(
      ssn,
      address,
      zipCode,
      null,
      deliveryPoint.id,
      null,
      isBedrift
    );
  };

  const handleBack = () => {
    if (props.onBack) {
      props.onBack();
    } else {
      pageStepActionDispatchers.goToPreviousStep(window.location.pathname);
    }
  };

  const isValidInput = address && zipCode.length === 4;
  const timoutMessage = loadingJustFinished || loadingTimeout;

  return (
    <>
      <CSSTransition
        in={!!timoutMessage}
        timeout={pageStepAnimationDuration}
        classNames={"formPage-"}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <FormPageContainer hideFooter className={"loaderContainer"}>
          <div className={"loader slide"}>
            <MeterFoundMessage success={elhubData} elhubData={elhubData} />
          </div>
        </FormPageContainer>
      </CSSTransition>
      <CSSTransition
        in={!timoutMessage && isLoading}
        timeout={pageStepAnimationDuration}
        classNames={"formPage-"}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <FormPageContainer hideFooter className={"loaderContainer"}>
          <div className={"loader slide"}>
            <Loader text={"Finner adresse..."} />
          </div>
        </FormPageContainer>
      </CSSTransition>
      <CSSTransition
        in={!timoutMessage && !isLoading}
        timeout={pageStepAnimationDuration}
        classNames={"formPage-"}
        mountOnEnter={false}
        unmountOnExit={true}
      >
        <FormPageContainer onBack={handleBack} currentStep={pages.deliveryPoints}>
          <header className={styles.header}>
            <h1 className={"fallIn-"}>Skriv adressen du vil bestille strøm til</h1>
          </header>
          {IsFKTheme && (
            <div className={"frameImageContainer"}>
              <img className={"frameImage formPage-background fade-"} src={Map} alt="map" />
            </div>
          )}
          <div className={"slide"}>
            <div>
              <SimpleInput
                className={styles.field}
                name="address"
                label="Gateadresse"
                value={address}
                onChange={handleChange}
                placeholder="Adresseveien 123"
                type={"text"}
                autoComplete="newPassword"
                showWhiteBox
                autofocus
                focusDelay={pageStepAnimationDuration}
              />
              <SimpleInput
                className={styles.field}
                name="zipCode"
                label="Postnummer"
                value={zipCode}
                onChange={handleChange}
                placeholder="2020"
                type={"postcode"}
                city={"city"}
                autoComplete="newPassword"
                showWhiteBox
              />
            </div>
            <button
              className={`btnPrimary ${styles.nextButton}`}
              onClick={handleConfirm}
              disabled={!isValidInput}
            >
              {deliveryPoint.id ? "Bekreft" : "Legg til"}
            </button>
          </div>
        </FormPageContainer>
      </CSSTransition>
    </>
  );
}

export default connect(deliveryPointV2Selector)(DeliveryPointAddress);
