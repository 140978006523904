import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSmartPowerProductId, query } from "../../../Utils/PowerSmartUtils";
import FormPageContainer from "../../CustomerForm/FormPageContainer/FormPageContainer";
import * as AxiosFunction from "../../../Utils/AxiosFunctions";
import { PowerSmartLanding } from "./PowerSmartLanding";
import { PowerSmartDetails } from "./PowerSmartDetails";
import { Switch } from "react-router-dom";
import { getStep } from "../../../Utils/sanityClient";
import { powerSmartStepSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import { connect } from "react-redux";
import Loader from "../../CustomerForm/FKLoader/Loader";
import TransitionRoute from "../../TransitionRoute/TransitionRoute";

function PowerSmartStep({ isLoading }) {
  const { SMART_PRODUCT_POWER_ID, SMART_PRODUCT_POWER_PLUS_ID, PULSE_METER_ID } =
    getSmartPowerProductId();
  const [stepDescription, setStepDescription] = useState([]);
  const [model, setModel] = useState({});

  const products = useSelector((state) =>
    state.productState.products.filter(
      (product) =>
        product.id === SMART_PRODUCT_POWER_ID || product.id === SMART_PRODUCT_POWER_PLUS_ID
    )
  );

  const productMap = {};
  products.forEach((product) => {
    productMap[product.id] = product;
  });

  // We need to fetch some additional data..
  // TODO: provide a loading state
  useEffect(() => {
    const fetchAdditionalProductInfo = async () => {
      const [
        smartPowerProduct,
        termsAndConditionsGuidById,
        smartPowerProductPrices,
        smartPowerPlusProductPrices
      ] = await query([
        AxiosFunction.GetProductHubProductById(productMap[SMART_PRODUCT_POWER_ID].id),
        AxiosFunction.GetTermsAndConditionsGuidById(
          productMap[SMART_PRODUCT_POWER_ID].termsAndConditionsId
        ),
        AxiosFunction.getProductwithPrices(SMART_PRODUCT_POWER_ID),
        AxiosFunction.getProductwithPrices(SMART_PRODUCT_POWER_PLUS_ID)
      ]);
      try {
        const saneModel = {
          cancellationFormUrl: smartPowerProduct.data.cancellationForm?.url,
          termsUrl: "https://avtale.fjordkraft.no/api/term/" + termsAndConditionsGuidById.data,
          powerSmart: {
            price: smartPowerProductPrices.data.value[0].vatInclusivePrice,
            title: productMap[SMART_PRODUCT_POWER_ID].name,
            description: productMap[SMART_PRODUCT_POWER_ID].textGroups[6],
            features: productMap[SMART_PRODUCT_POWER_ID].textGroups[7],
            ctaText: productMap[SMART_PRODUCT_POWER_ID].textGroups[8]
          },
          powerSmartPlus: {
            price: smartPowerPlusProductPrices.data.value[0].vatInclusivePrice,
            title: productMap[SMART_PRODUCT_POWER_PLUS_ID].name,
            description: productMap[SMART_PRODUCT_POWER_PLUS_ID].textGroups[6],
            features: productMap[SMART_PRODUCT_POWER_PLUS_ID].textGroups[7],
            ctaText: productMap[SMART_PRODUCT_POWER_PLUS_ID].textGroups[8]
          }
        };
        setModel(saneModel);
      } catch (error) {
        console.error("Error fetching price and terms", error);
      }
    };
    fetchAdditionalProductInfo();
    getStep("stromsmart")
      .then((data) => setStepDescription(data))
      .catch(console.error);
  }, []);

  return (
    <>
      <Switch>
        <TransitionRoute
          exact
          path="/Stromsmart"
          render={() => (
            <FormPageContainer>
              {isLoading ? (
                <Loader text={"Vent mens vi gjør ting klart for deg..."} />
              ) : (
                <PowerSmartLanding {...model} stepDescription={stepDescription} />
              )}
            </FormPageContainer>
          )}
        />
        <TransitionRoute
          path="/Stromsmart/:id"
          render={({ match }) => (
            <FormPageContainer>
              <PowerSmartDetails
                {...(match.params.id === "stromsmart" ? model.powerSmart : model.powerSmartPlus)}
                productId={
                  match.params.id === "stromsmart"
                    ? SMART_PRODUCT_POWER_ID
                    : SMART_PRODUCT_POWER_PLUS_ID
                }
                termsUrl={model.termsUrl}
                cancellationFormUrl={model.cancellationFormUrl}
                pulsProductId={PULSE_METER_ID}
              />
            </FormPageContainer>
          )}
        />
      </Switch>
    </>
  );
}

export default connect(powerSmartStepSelector)(PowerSmartStep);
