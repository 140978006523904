import React, { useEffect } from "react";
import styles from "./ProductInfoModal.module.scss";
import Icon from "../../Icon/Icon";
import { IsFKTheme } from "../../../Utils/Themes";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import Loader from "../FKLoader/Loader";
import { connect, useSelector } from "react-redux";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import Spotpris from "../../../assets/Images/FK/SpotprisWhite.svg";
import { productInfoSelector } from "../../../ReduxModules/Selectors/ProductSelectors";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import store from "../../../ReduxModules/Store/Store";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

function ProductInfoModal({
  show,
  closeModal,
  productName,
  productInfo,
  productId,
  textGroups,
  loading,
  isMobile,
  product
}) {
  const campaignText = useSelector((state) => state.productState.campaignText);
  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      closeModal();
    }
  };
  useEffect(() => {
    const fetchCampaignText = async () => {
      try {
        productActionDispatchers.getCampaignText(
          store.getState().saleState?.form?.campaign?.campaignName, isMobile
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchCampaignText();
  }, [store.getState().saleState?.form?.campaign]);

  useEffect(() => {
    if ((!productInfo || !textGroups) && !isMobile) {
      productActionDispatchers.getProductInfo(productId, null, isMobile);
    }
  }, [productId]);
  return show ? (
    <div className={`${styles.overlay} ${IsFKTheme && styles.containerFK}`}>
      <FormPageContainer hideFooter wide>
        <div className={styles.modal}>
          <Icon
            className={styles.smallCloseButton}
            icon={IsFKTheme ? "clear" : "tkClear"}
            onClick={closeModal}
            type="button"
            tabIndex={0}
            onKeyDown={onKeyDown}
          />
          {loading ? (
            <Loader text={"Henter produktinfo..."} className={styles.loader} />
          ) : (
            <>
              {IsFKTheme && <img className={styles.mascot} src={Spotpris} alt="mascot" />}
              <h1>{productName}</h1>
              {textGroups && <div dangerouslySetInnerHTML={{ __html: textGroups[2] }} />}
              {campaignText != null && (
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Typography variant={"string"}>
                    <div dangerouslySetInnerHTML={{ __html: campaignText }} />
                  </Typography>
                </Grid>
              )}
              {isMobile && <div dangerouslySetInnerHTML={{ __html: product.data?.salesTextPre }} />}
            </>
          )}
          <button
            title="Lukk modal"
            type="button"
            className={`btnUnderline ${styles.closeButton}`}
            onClick={closeModal}
          >
            Lukk
          </button>
        </div>
      </FormPageContainer>
    </div>
  ) : (
    <></>
  );
}

export default connect(productInfoSelector)(ProductInfoModal);
