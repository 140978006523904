import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Preloader from "../../Preloader/Preloader";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { useParams } from "react-router-dom";
import Loader from "../FKLoader/Loader";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import { productCategories } from "../../../Utils/CustomerFormHelper";
import config from "../../../config";
import ProductGroupContainer from "../AddonProduct/ProductGroupContainer";
import _ from "lodash";
import { checkGroupTag, radioGroupTag } from "../../../Utils/CustomerFormHelper";

export default function PreviewAddonGroup() {
  let { tag } = useParams();
  const { products } = useSelector((state) => state.productState);

  useEffect(() => {
    const storeId = config.env === "dev" ? 1399 : 935;
    productActionDispatchers.getProducts(
      [productCategories.serviceOption, productCategories.addon],
      [storeId]
    );
  }, []);

  useEffect(() => {
    tagNumberChecker(tag);
  }, [products]);

  const tagNumberGetter = (tag) => {
    if (tag.includes(checkGroupTag)) {
      return tag.replace(checkGroupTag, "");
    }
    if (tag.includes(radioGroupTag)) {
      return tag.replace(radioGroupTag, "");
    }
  };

  const tagNumberChecker = (tag) => {
    if (!_.isEmpty(products)) {
      if (tag.includes(checkGroupTag)) {
        for (let p of products.filter(
          (p) => p.tags.includes("SMCHECKGROUP1") && !p.isLoadingProductInfo && !p.textGroups
        )) {
          productActionDispatchers.getAddonProductInfo(p.id);
        }
      }
      if (tag.includes(radioGroupTag)) {
        for (let p of products.filter(
          (p) => p.tags.includes("SMRADIOGROUP1") && !p.isLoadingProductInfo && !p.textGroups
        )) {
          productActionDispatchers.getAddonProductInfo(p.id);
        }
      }
    }
  };

  return (
    <>
      <Preloader />
      {_.isEmpty(products) ? (
        <FormPageContainer hideFooter>
          <Loader text={"Vent mens vi gjør ting klart for deg..."} />
        </FormPageContainer>
      ) : (
        <div>
          {!_.isEmpty(products) &&
            products.filter((p) => p.tags.includes("SMCHECKGROUP1")).every((p) => p.textGroups) && (
              <ProductGroupContainer groupId={tagNumberGetter(tag)} />
            )}
          {!_.isEmpty(products) &&
            products.filter((p) => p.tags.includes("SMRADIOGROUP1")).every((p) => p.textGroups) && (
              <ProductGroupContainer radioGroup={true} groupId={tagNumberGetter(tag)} />
            )}
          {tag === "NoAddonGroup" && (
            <FormPageContainer hideFooter>
              <h2>Dette tilleggsproduktet tilhører ikke en passende produktgruppe</h2>
            </FormPageContainer>
          )}
        </div>
      )}
    </>
  );
}
