import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { pages } from "../../Utils/CustomerFormHelper";
import SmsAcceptLandingPage from "./SmsAcceptLandingPage";
import ConfirmPage from "./ConfirmPage";
import Subscription from "./Subscription/Subscription";
import Receipt from "./Receipt/Receipt";

function SmsAcceptPage(props) {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      onbeforeunload = (e) =>
        `Endringene dine vil bli slettet. Er du sikker på at du vil forlate siden?`;
      return () => {
        onbeforeunload = null;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Switch>
      <Route exact path={pages.smsAcceptConfirm} component={ConfirmPage} />
      <Route exact path={pages.smsAcceptSubscription} component={Subscription} />
      <Route exact path={pages.smsAcceptReceipt} component={Receipt} />
      <Route exact path={pages.smsAcceptLandingPage} component={SmsAcceptLandingPage} />
    </Switch>
  );
}

export default SmsAcceptPage;
