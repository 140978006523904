import { call, put, takeLatest } from "redux-saga/effects";
import * as AxiosFunction from "../../Utils/AxiosFunctions";
import { submitTobbFormError, submitTobbFormSuccess } from "../Actions/TobbActions/TobbActions";
import { SUBMIT_TOBB_FORM } from "../Actions/TobbActions/TobbActionTypes";

function* tobbForm(action) {
  try {
    yield call(AxiosFunction.submitTobbForm, action.form, action.tobbId);
    yield put(submitTobbFormSuccess());
  } catch (e) {
    yield put(submitTobbFormError(e));
  }
}
export function* watchTobbForm() {
  yield takeLatest(SUBMIT_TOBB_FORM, tobbForm);
}
