import React, { useEffect } from "react";
import smsAcceptActionDispatchers from "../../ReduxModules/Actions/SmsAcceptActions/SmsAcceptActionDispatcher";
import ConfirmPage from "./ConfirmPage";

function SmsAcceptLandingPage(props) {
  useEffect(() => {
    const hashedCustomerId = props.match.params.hashedCustomerId;
    smsAcceptActionDispatchers.getSmsAcceptCustomerInfoAction(hashedCustomerId);
    smsAcceptActionDispatchers.updateHashedCustomerID(hashedCustomerId);
    //history.push(pages.smsAcceptConfirm);
  }, []);

  return <ConfirmPage />;
}

export default SmsAcceptLandingPage;
