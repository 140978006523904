import * as React from "react";
import { urlFor } from "../../../Utils/sanityClient";
import styles from "./AdditionalSales.module.scss";
import { PortableText } from "@portabletext/react";
import Loader from "../FKLoader/Loader";
import clsx from "clsx";
import { mobileProductLink } from "../../../Utils/ExternalLinks";
import { AdditionalSalesSanityData } from "../../../types";

function AdditionalSales({
  data,
  isLoading,
  error
}: {
  data?: AdditionalSalesSanityData[];
  isLoading: boolean;
  error: any;
}) {
  if (isLoading) {
    return <Loader text="Laster produkter" className="" />;
  }

  if (error || (data ?? []).length === 0) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={mobileProductLink}
        className={styles.additionalSalesOtherProductsLink}
      >
        Sjekk ut våre andre produkter!
      </a>
    );
  }

  return (
    <div className={styles.additionalSales}>
      <h2 className={styles.additionalSalesText}>
        Som kunde får du nå også ekstra gode priser på våre andre produkter!
      </h2>
      {(data ?? []).map((sale, index) => {
        const buttonPlacement = sale.buttonPlacement ?? "topLeft";
        return (
          <div
            key={`${sale._id}-${index}`}
            style={
              sale.image
                ? {
                    backgroundImage: `url(${urlFor(sale.image).url()}`
                  }
                : {}
            }
            className={clsx(styles.additionalSalesBox)}
          >
            <h3 className={styles.additionalSalesBoxTitle}>{sale.title}</h3>
            <div className={styles.additionalSalesBoxText}>
              <PortableText value={sale.ingress} />
            </div>
            <div
              className={clsx(styles.additionalSalesBoxButtons, {
                [styles.additionalSalesBoxButtonsTopLeft]: buttonPlacement === "topLeft",
                [styles.additionalSalesBoxButtonsTopRight]: buttonPlacement === "topRight",
                [styles.additionalSalesBoxButtonsBottomLeft]: buttonPlacement === "bottomLeft",
                [styles.additionalSalesBoxButtonsBottomRight]: buttonPlacement === "bottomRight",
                [styles.additionalSalesBoxButtonsTopCenter]: buttonPlacement === "topCenter",
                [styles.additionalSalesBoxButtonsBottomCenter]: buttonPlacement === "bottomCenter"
              })}
            >
              <a
                className={clsx("btnPrimary", styles.additionalSalesBoxLinkButton)}
                href={sale.ctaLink}
              >
                {sale.ctaText}
              </a>
              <a
                className={clsx("btnSecondary", styles.additionalSalesBoxLinkButton, {
                  hidden: !sale.readMoreLink || !sale.readMoreText
                })}
                href={sale.readMoreLink}
              >
                {sale.readMoreText}
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export { AdditionalSales };
