import * as update from "immutability-helper";
import {
  GET_BANK_ID_TOKEN_RESPONSE,
  GET_BANK_ID_CUSTOMER_INFO_ERROR,
  GET_BANK_ID_CUSTOMER_INFO_SUCCESS
} from "../Actions/SaleActions/SaleActionTypes";

const initialState = {
  isLoading: false,
  isBankIdComplete: false,
  error: null,
  bankIdInfo: { phone: null, ssn: null }
};

const bankIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANK_ID_TOKEN_RESPONSE:
      return update(state, {
        isLoading: { $set: true },
        error: { $set: null }
      });
    case GET_BANK_ID_CUSTOMER_INFO_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        isBankIdComplete: { $set: true },
        bankIdInfo: { $set: action.customerInfo }
      });
    case GET_BANK_ID_CUSTOMER_INFO_ERROR:
      return update(state, {
        isLoading: { $set: false },
        error: { $set: action.error }
      });
    default:
      return state;
  }
};

export default bankIdReducer;
