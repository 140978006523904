import React, { useState, useRef, useEffect } from "react";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import styles from "./Terms.module.scss";
import { connect } from "react-redux";
import { summarySelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import { IsFKTheme } from "../../../Utils/Themes";
import LaptopDesk from "../../../assets/Images/FK/TermsFramed.svg";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import MobileMascotBlushOpenEyes from "../../../assets/Images/FK/Mobil/MobileMascotBlushOpenEyes.svg";
import config from "../../../config";
import { powerRightOfWithdrawalLink } from "../../../Utils/ExternalLinks";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { Box, Popover } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { powerFormOnRightOfWithdrawalLink } from "../../../Utils/ExternalLinks";
import style from "./TermLinks.module.scss";
import { urlFor } from "../../../Utils/sanityClient";
import { getStep } from "../../../Utils/sanityClient";

// På path /vilkår
function Terms({ onBack, onConfirm, form, deliveryPoints, bonusCampaigns, isMobile }) {
  const baseUrl =
    config.env === "prod"
      ? "https://avtale.fjordkraft.no/api/term/"
      : "https://test-avtale.fjordkraft.no/api/term/";
  const link = baseUrl + form.producthubInfo.guid;
  const [acceptedPowerTerms, setAcceptedPowerTerms] = useState(false);
  const [openPowerTermsMessageDialog, setOpenPowerTermsMessageDialog] = useState(false);
  const powerTarget = useRef(null);
  const classes = useStyles();
  const [step, setStep] = useState([]);

  useEffect(() => {
    getStep("vilkar")
      .then((data) => setStep(data))
      .catch(console.error);
  }, []);

  const handleConfirm = () => {
    if (!isMobile) {
      const deliveryPointsList = deliveryPoints.map((dp) => {
        return { ...dp, ...dp.elhubData };
      });
      const bonusCampaignDtos =
        bonusCampaigns?.map((bc) => ({ id: bc.id, bonusTypeId: bc.bonusTypeId })) ?? null;

      saleActionDispatchers.submitFormAction({
        ...form,
        deliveryPoints: deliveryPointsList,
        bonusCampaigns: bonusCampaignDtos
      });
    }
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname);
    }
  };
  return (
    <FormPageContainer onBack={onBack} className={IsFKTheme && styles.containerFK}>
      {!isMobile ? (
        <h1 className={"fallIn-"}>{step?.lede} </h1>
      ) : (
        <h1 className={"fallIn-"}>Les og aksepter vilkårene for ditt nye mobilabonnement.</h1>
      )}

      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          {isMobile ? (
            <img
              className={"frameImage formPage-background fade-"}
              src={MobileMascotBlushOpenEyes}
              alt=""
            />
          ) : null}

          {!isMobile && step?.image && (
            <img
              className={"frameImage formPage-background fade-"}
              src={urlFor(step?.image).width(500).height(500).url()}
              alt="Laptop"
            />
          )}
        </div>
      )}
      {!isMobile ? (
        <div className={"slide"}>
          <div className={styles.checkBoxContainer}>
            <button
              className={styles.checkBoxButton}
              onClick={() => setAcceptedPowerTerms(!acceptedPowerTerms)}
              ref={powerTarget}
            >
              {acceptedPowerTerms ? (
                <CheckBox className={styles.checkBoxIcon} />
              ) : (
                <CheckBoxOutlineBlank className={styles.checkBoxIcon} />
              )}
            </button>
            <Popover
              classes={{ paper: classes.popoverCheckboxStyle }}
              open={openPowerTermsMessageDialog}
              anchorEl={powerTarget.current}
              onClose={() => setOpenPowerTermsMessageDialog(false)}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left"
              }}
            >
              <Box>
                <Box
                  sx={{
                    padding: 1,
                    paddingLeft: 0,
                    borderRadius: 3,
                    backgroundColor: "#628c2a",
                    color: "white",
                    borderColor: "black"
                  }}
                >
                  <ArrowLeftIcon fontSize={"large"} />
                  <WarningAmberIcon /> Husk å akseptere strømvilkårene
                </Box>
              </Box>
            </Popover>
            <div className={style.checkBoxTextTerm}>
              Jeg aksepterer{" "}
              <a
                className={IsFKTheme ? style.termLink : style.termLinkTK}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                vilkårene
              </a>{" "}
              for avtalen for strøm. Er kjent med{" "}
              <a
                className={IsFKTheme ? style.termLink : style.termLinkTK}
                href={powerRightOfWithdrawalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                angreretten
              </a>{" "}
              og{" "}
              <a
                className={IsFKTheme ? style.termLink : style.termLinkTK}
                href={powerFormOnRightOfWithdrawalLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                standard skjema for opplysninger om angrerett
              </a>
              . Jeg aksepterer at jeg må betale for de dagene jeg har fått strømleveransen dersom
              jeg har valgt oppstart før utløp av angrefristen.
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.text}>
            For å opprette er det fint om du leser og aksepterer vilkårene og gjøre deg kjent med
            angreretten.
          </div>
          <a className={styles.link} href={link} target="_blank" rel="noopener noreferrer">
            Les alle vilkårene her
          </a>
        </>
      )}
      <div className={"slide"}>
        <button
          className={`btnPrimary ${styles.button}`}
          onClick={handleConfirm}
          disabled={!isMobile && !acceptedPowerTerms}
        >
          Fullfør bestilling
        </button>
      </div>
    </FormPageContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  popoverCheckboxStyle: {
    marginLeft: "30px",
    backgroundColor: "transparent !important",
    border: 0,
    boxShadow: "none !important"
  }
}));

export default connect(summarySelector)(Terms);
