import { bindActionCreators } from "redux";
import store from "../../Store/Store";
import {
  getProductInfo,
  getProducts,
  getAddonProducts,
  getProductInfoBedrift,
  getAddonProductInfo,
  getPriceInfo,
  getExtraProductPriceInfo,
  getCampaignText,
  getCampaignLength
} from "./ProductActions";

const actions = {
  getProductInfo,
  getProducts,
  getAddonProducts,
  getProductInfoBedrift,
  getAddonProductInfo,
  getPriceInfo,
  getExtraProductPriceInfo,
  getCampaignText,
  getCampaignLength
};

const productActionDispatchers = bindActionCreators(actions, store.dispatch);

export default productActionDispatchers;
