import React, { useState } from "react";
import styles from "./CustomerForm.module.scss";
import Where from "../../assets/Images/TK/WhereIsMyMeterNumber.png";
import PowerMeterNumber from "../../assets/Images/FK/PowerMeterNumber.svg";
import Icon from "../Icon/Icon";
import { IsFKTheme } from "../../Utils/Themes";

function Modal() {
  const [show, setShow] = useState(false);
  const showModal = () => {
    setShow(true);
  };
  const closeModal = () => {
    setShow(false);
  };

  return (
    <div>
      <a onClick={showModal}>Hvor finner jeg målernummeret?</a>
      {show && (
        <div className={styles.overlay}>
          <div className={styles.modal}>
            <Icon
              className={styles.smallCloseButton}
              icon={IsFKTheme ? "clear" : "tkClear"}
              onClick={closeModal}
              type="button"
            />
            <h1>Her finner du målernummeret</h1>
            {IsFKTheme ? (
              <img className={styles.modalImage} src={PowerMeterNumber} alt="modalImage" />
            ) : (
              <img className={styles.modalImage} src={Where} alt="modalImage" />
            )}
            <p className={styles.modalDescription}>
              Som regel kan du finne den i sikringsskapet i boligen, men i enkelte leiligheter kan
              sikringsskapet være plassert utenfor leiligheten.
            </p>
            <p className={styles.modalButton} onClick={closeModal}>
              <b>Lukk</b>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Modal;
