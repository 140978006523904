import React, { useEffect, useState } from "react";
import SimpleInput from "../components/SimpleInput/SimpleInput";
import { encrypt } from "../Utils/UrlParser";
import styles from "./UrlEncoder.module.scss";
import AdditionalProductDropdownSelect from "./AdditionalProductDropdownSelect";
import ProductDropdownSelect from "./ProductDropdownSelect";
import * as _ from "lodash";
import AdditionalProductCampaignDropdownSelect from "./AdditionalProductCampaignSelector";
import SimpleCheckBox from "../components/CheckBox/SimpleCheckBox";
import { IsFKTheme } from "../Utils/Themes";
import FormPageContainer from "../components/CustomerForm/FormPageContainer/FormPageContainer";
import productActionDispatchers from "../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import {
  getAddonProductCategories,
  getMobileCampaignCategories,
  getProductCategories,
  pages,
  productCategories
} from "../Utils/CustomerFormHelper";
import config from "../config";
import PreviewAddonsButton from "../components/CustomerForm/PreviewAddon/PreviewAddonsButton";
import AuthWrapper from "./AuthWrapper";
import ProductCampaignSelector from "./ProductCampaignSelector";
import { TextField } from "@mui/material";
import AdditionalSalesSelect from "./AdditionalSalesSelect";
import { UrlEncoderAdditionalSalesPreview } from "./UrlEncoderAdditionalSalesPreview";

function UrlEncoder(props) {
  const [input, setInput] = useState("");
  const [product, setProduct] = useState(null);
  const [mainProductCampaign, setMainProductCampaign] = useState(null);
  const [additionalProducts, setAdditionalProducts] = useState([]);
  const [additionalSales, setAdditionalSales] = useState([]);

  const [isMobile, setIsMobile] = useState(
    new URLSearchParams(props.location.search).get("mobile")
  );
  const [isBedrift, setIsBedrift] = useState(
    new URLSearchParams(props.location.search).get("bedrift")
  );
  const [isMember, setIsMember] = useState(false);
  const [priceElement, setPriceElement] = useState(null);
  const [showPriceElementField, setShowPriceElementField] = useState(null);
  const [continueExistingAgreement, setContinueExistingAgreement] = useState(false);
  const [optionalProduct1, setOptionalProduct1] = useState(null);
  const [optionalProduct2, setOptionalProduct2] = useState(null);
  const [continuousChecked, setContinuousChecked] = useState(false);

  useEffect(() => {
    const storeId = config.env === "dev" ? 1399 : 935; // todo: get store id
    productActionDispatchers.getProducts(
      Object.values(productCategories),
      [storeId],
      isBedrift ? "B" : "P"
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (additionalProducts.some((p) => p.id == 734)) {
      setShowPriceElementField(true);
    }
  }, [additionalProducts]);

  const handleChange = (e) => {
    setInput(e);
  };

  // with validation
  const handlePriceElementChange = (e) => {
    let priceInput = Number(e.target.value);
    if (priceInput > 0 && priceInput <= 1000) {
      setPriceElement(priceInput);
    }
  };

  const handleContinueExistingAgreementChange = (e) => {
    setAdditionalProducts([]);
    setAdditionalSales([]);
    setProduct(null);
    setContinueExistingAgreement(e);
    if (continueExistingAgreement !== e) {
      setOptionalProduct1(null);
      setOptionalProduct2(null);
    }
    setContinuousChecked(!continuousChecked);
  };

  const selectExtraProduct = (products) => {
    if (products) {
      // TODO: fix this so we actually can remove extra products!!!
      document.getElementById("previewAddonHeading").style.display = "block";
      setAdditionalProducts(
        products
          ? products.map((p) => {
              const existingProductSelection = additionalProducts.find(
                (ap) => ap.id === p.value.id
              );
              return existingProductSelection || { ...p.value, optional: true };
            })
          : []
      );
    }
  };

  const selectAdditionalSale = (products) => setAdditionalSales(products ?? []);

  const selectExtraProductCampaigns = (extraProductId, campaigns) => {
    setAdditionalProducts(
      additionalProducts.map((p) => {
        if (p.id === extraProductId) {
          return { ...p, campaigns: campaigns ? campaigns.map((c) => c.value) : [] };
        }
        return p;
      })
    );
  };

  const selectMainProductCampaign = (campaign) => {
    console.log(campaign);
    setMainProductCampaign(campaign);
  };

  const selectProduct = (product) => {
    if (product && continueExistingAgreement) {
      setOptionalProduct1(product.value);
    } else if (product) {
      setProduct(product.value);
    }
  };

  const selectProduct2 = (product) => {
    if (product && continueExistingAgreement) {
      setOptionalProduct2(product.value);
    }
  };

  function encryptInput() {
    let queryParameters;
    if (input) {
      queryParameters = input;
    }
    if (continueExistingAgreement) {
      queryParameters = [queryParameters, "continueExistingAgreement=true"].join("&");

      if (optionalProduct1) {
        queryParameters = [queryParameters, "optionalProduct1=" + optionalProduct1.id].join("&");
      }
      if (optionalProduct2) {
        queryParameters = [queryParameters, "optionalProduct2=" + optionalProduct2.id].join("&");
      }
      if (queryParameters && !input) {
        queryParameters = "?" + queryParameters.substring(1, queryParameters.length);
      }
    } else {
      if (product) {
        queryParameters = [queryParameters, "avtaletype=" + product.id].join("&");
      }
      if (isMobile && isMember) {
        queryParameters = [queryParameters, "isMember=true"].join("&");
      }
      if (mainProductCampaign) {
        queryParameters = [queryParameters, "campaign=" + mainProductCampaign].join("&");
      }
      if (!_.isEmpty(additionalProducts)) {
        queryParameters =
          [
            queryParameters,
            "recommendedProducts=[" +
              additionalProducts
                .map(
                  (p) =>
                    `{id:${p.id};${p.id === 734 ? `priceElement:${priceElement};` : ""}${p.campaigns ? `campaigns:[${p.campaigns.join(",")}];` : ""}${p.optional !== undefined ? `optional:${p.optional};` : ""}}`
                )
                .join(",")
          ].join("&") + "]";
      }
      if (!_.isEmpty(additionalSales)) {
        queryParameters =
          [
            queryParameters,
            "additionalSales=[" + additionalSales.map((p) => `{id:${p.value};}`).join(",")
          ].join("&") + "]";
      }
      if (queryParameters && !input) {
        queryParameters = "?" + queryParameters.substring(1, queryParameters.length);
      }
    }
    return encrypt(queryParameters);
  }

  const handleExtraProductOptionalChange = (productId) => {
    setAdditionalProducts(
      additionalProducts.map((p) => {
        if (p.id === productId) {
          return { ...p, optional: !p.optional };
        }
        return p;
      })
    );
  };

  const handleSelect = (mobile = false) => {
    setAdditionalProducts([]);
    setAdditionalSales([]);
    setProduct(null);
    let newUrl = window.location.origin + window.location.pathname;
    if (mobile) {
      newUrl += "?mobile=true";
    }
    setIsMobile(mobile);
    setIsBedrift(false);
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const handleSelectBedrift = (bedrift = false) => {
    setAdditionalProducts([]);
    setAdditionalSales([]);
    setProduct(null);
    let newUrl = window.location.origin + window.location.pathname;
    if (bedrift) {
      newUrl += "?bedrift=true";
    }
    setIsBedrift(bedrift);
    setIsMobile(false);
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const encryptedInput = encryptInput();

  return (
    <AuthWrapper>
      <FormPageContainer hideFooter wide>
        <div className={`${styles.container} ${IsFKTheme && styles.containerFK}`}>
          <div className={styles.content}>
            <h1>Kryptering av Query Parametre</h1>
            {!continuousChecked && (
              <div className={styles.selectButtons}>
                <button
                  className={`${styles.selectButton} ${!isMobile && !isBedrift ? styles.selectedButton : styles.unselectedButton}`}
                  onClick={() => handleSelect(false)}
                >
                  Strøm
                </button>
                <button
                  className={`${styles.selectButton} ${isBedrift ? styles.selectedButton : styles.unselectedButton}`}
                  onClick={() => handleSelectBedrift(true)}
                >
                  Bedrift
                </button>
                <button
                  className={`${styles.selectButton} ${isMobile ? styles.selectedButton : styles.unselectedButton}`}
                  onClick={() => handleSelect(true)}
                >
                  Mobil
                </button>
              </div>
            )}
            <div className={styles.selectButtons}>
              <button
                className={`${styles.selectedButton} ${continueExistingAgreement ? styles.selectedButton : styles.unselectedButton}`}
                onClick={() => handleContinueExistingAgreementChange(true)}
              >
                Fortsett eksisterende avtale
              </button>
            </div>
            {isMobile && (
              <div className={styles.checkBoxMemberContainer}>
                <SimpleCheckBox checked={isMember} onClick={() => setIsMember(!isMember)} />
                Medlem
              </div>
            )}
            <div className={styles.dropDown}>
              <ProductDropdownSelect
                onChange={selectProduct}
                productCategories={getProductCategories(isMobile)}
                selected={product}
                isBedrift={isBedrift}
              />
              {continueExistingAgreement && (
                <ProductDropdownSelect
                  onChange={selectProduct2}
                  productCategories={getProductCategories(isMobile)}
                  selected={product}
                  isBedrift={isBedrift}
                />
              )}

              {isMobile && product && (
                <div style={{ display: "flex", marginLeft: "80px" }}>
                  <ProductCampaignSelector
                    onChange={selectMainProductCampaign}
                    productCategories={getMobileCampaignCategories()}
                    productId={product.id}
                    isMobile={true}
                  />
                </div>
              )}
              {!isMobile && product && (
                <div style={{ display: "flex", marginLeft: "80px" }}>
                  <ProductCampaignSelector
                    onChange={selectMainProductCampaign}
                    productCategories={[]}
                    productId={product.id}
                    isMobile={false}
                  />
                </div>
              )}

              <br />
              <AdditionalProductDropdownSelect
                onChange={selectExtraProduct}
                productCategories={getAddonProductCategories(isMobile)}
                selected={additionalProducts}
                isBedrift={isBedrift}
              />
              <h5
                id="previewAddonHeading"
                className={`${styles.text} ${styles.additionalProductHeader}`}
                title="Trykk på tillegg for å gå direkte til tilleggsproduktet uten å gå gjennom løpyen,
                            velg gruppe for de produktene som kan vises som gruppe."
                style={{ float: "right", display: "none" }}
              >
                Forhåndsvis
              </h5>
              {additionalProducts.map((ap, i) => (
                <div key={i}>
                  <h5 className={`${styles.text} ${styles.additionalProductHeader}`}>{ap.name}</h5>
                  <div style={{ display: "flex" }}>
                    <div className={`${styles.text} ${styles.checkBoxContainer}`}>
                      <div>Valgfritt:</div>
                      <SimpleCheckBox
                        checked={ap.optional}
                        onClick={() => handleExtraProductOptionalChange(ap.id)}
                      />
                    </div>
                    <AdditionalProductCampaignDropdownSelect
                      onChange={selectExtraProductCampaigns}
                      productId={ap.id}
                    />
                    <PreviewAddonsButton id={ap.id} tags={ap.tags} />
                  </div>
                </div>
              ))}
            </div>
            <div>
              {showPriceElementField && (
                <TextField
                  className={styles.priceElementInput}
                  id="filled-basic"
                  label="Fordelspoeng (max 1000)"
                  variant="filled"
                  value={priceElement}
                  onChange={handlePriceElementChange}
                />
              )}
            </div>
            <div className={styles.additionalSalesSelectContainer}>
              <AdditionalSalesSelect
                value={additionalSales}
                onChange={selectAdditionalSale}
                // we should update state variables to be actual booleans
                // but I don't want to handle backwards compatibility
                isBedrift={isBedrift !== null ? isBedrift : false}
                isMobile={isMobile !== null ? isMobile : false}
              />
            </div>
            <SimpleInput
              className={styles.input}
              label={"Url paremetre"}
              placeholder={"?phone=12345678&firstName=Ola&lastName=Normann"}
              value={input}
              onChange={handleChange}
              showWhiteBox
            />
            <h2>Url med kryptert query parametre:</h2>
            <a
              style={{ display: "block", wordBreak: "break-word" }}
              href={`${window.location.origin}${isMobile ? pages.mobile : isBedrift ? pages.bedrift : ""}?hash=${encryptedInput}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {window.location.origin}
              {isMobile ? pages.mobile : isBedrift ? pages.bedrift : ""}?hash={encryptedInput}
            </a>
          </div>
        </div>
        <UrlEncoderAdditionalSalesPreview additionalSales={additionalSales.map((p) => p.value)} />
      </FormPageContainer>
    </AuthWrapper>
  );
}

export default UrlEncoder;
