import {
  getPageFromProductId,
  getPageFromBonusTypeId,
  getTrumfProductId
} from "./AdditionalProductHelper";
import * as _ from "lodash";
import { IsFKTheme } from "./Themes";
import { matchPath } from "react-router-dom";
import { generatePath } from "react-router-dom";
import productActionDispatchers from "../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import store from "../ReduxModules/Store/Store";
import { getAgeFromSsn } from "./HelperFunctions";
import { getSmartPowerProductId } from "./PowerSmartUtils";

const { SMART_PRODUCT_POWER_ID, SMART_PRODUCT_POWER_PLUS_ID, PULSE_METER_ID } =
  getSmartPowerProductId();
const STEPS_SESSION_STORAGE_KEY = "STEPS_SESSION_STORAGE_KEY";

const CUSTOMER_INFO_PATH = "/KundeInfo";
const CUSTOMER_NAME_PATH = "/Navn";
const CUSTOMER_EMAIL_PATH = "/Epost";
const MOBILE_NUMBER_PATH = "/Mobilnummer";
const CUSTOMER_ADDRESS_PATH = "/Adresse";
const DELIVERY_POINTS_PATH = "/VelgLeveringsPunkt";
const DELIVERY_POINT_PATH_V2 = "/VelgLeveringsPunkt/:id";
const DELIVERY_POINT_PATH_V2_METER_NUMBER = "/Malernummer";
const DELIVERY_POINT_PATH_V2_METER_NUMBER_HELP = `${DELIVERY_POINT_PATH_V2_METER_NUMBER}/Hjelp`;
const DELIVERY_POINT_PATH_V2_METER_NUMBER_WHERE = `${DELIVERY_POINT_PATH_V2_METER_NUMBER}/Hvor`;
const DELIVERY_POINT_PATH_V2_METER_NUMBER_WHERE_FOUND = `${DELIVERY_POINT_PATH_V2_METER_NUMBER}/Funnet`;
const DELIVERY_POINT_PATH_V2_MOVING_DATE = "/VelgFlytteDato";
const DELIVERY_POINT_PATH_V2_CALENDAR = "/Kalender";
const DELIVERY_POINT_PATH = `${DELIVERY_POINTS_PATH}/LeggTilNy`;
const ADD_DELIVERY_POINT_METERNUMBER_PATH = `${DELIVERY_POINT_PATH}/Malernummer`;
const ADD_DELIVERY_POINT_METERNUMBER_HELP_PATH = `${DELIVERY_POINT_PATH}/Malernummer/Hjelp`;
const ADD_DELIVERY_POINT_METERNUMBER_WHERE_PATH = `${DELIVERY_POINT_PATH}/Malernummer/Hvor`;
const ADD_DELIVERY_POINT_METERNUMBER_FOUND_PATH = `${DELIVERY_POINT_PATH}/Malernummer/Funnet`;
const ADD_DELIVERY_POINT_MOVING_DATE_PATH = `${DELIVERY_POINT_PATH}/VelgFlytteDato`;
const ADD_DELIVERY_POINT_CALENDAR_PATH = `${DELIVERY_POINT_PATH}/Kalender`;
const ADDON_PRODUCT = "/Tilleggsprodukt/:id";
const ADDON_GROUP = "/Tilleggsproduktgruppe/:id";
const POWER_SMART_PATH = "/Stromsmart";
const POWER_SMART_DETAILS_PATH = "/Stromsmart/:id";
const NORWEGIAN_POWER_PATH = "/NorskVannkraft";
const TRIPLE_WARRANTY_PATH = "/TrippelGaranti";

const SUMMARY_PATH = "/Oppsummering";
const SUBSCRIPTION_PATH = "/Tilbud";
const TERMS_AND_CONDITIONS_PATH = "/Vilkar";
const RECEIPT_PATH = "/Kvittering";
const URL_ENCODER_PATH = "/UrlEncoder";
const TRUMF_PATH = "/Trumf";
const TRUMF_CALLBACK_PATH = `${TRUMF_PATH}/login`;
const TRUMF_ERROR_PATH = `${TRUMF_PATH}/Error`;
const SAS_EUROBONUS = `/Eurobonus`;
const METERNUMBER_PATH = "/malernummer/:orderId";
const MOBILE_PATH = "/mobil";
const BEDRIFT_PATH = "/bedrift";
const BEDRIFT_MOVING_DATE = "/oppstart";
const BEDRIFT_CALENDAR = "/calendar";
const BEDRIFT_CONTACT_PERSON = "/kontaktperson";
const ADD_TRUMF_PRODUCT_PATH = "/LeggTilTrumf";
const ADD_TRUMF_PRODUCT_ERROR_PATH = `${ADD_TRUMF_PRODUCT_PATH}/error`;
const BANK_ID_CALLBACK_PATH = "/bankid";
const BANK_ID_LOGIN_PATH = "/bankid/login";
const MANUAL_IDENTIFICATION_PATH = "/manuellidentifisering";
const SMS_ACCEPT_PAGE_PATH = "/smsaccept";
const SELECT_PRODUCT_PATH = "/produktvalg";
const CHANGE_PRODUCT_PAGE = "/produktvalg/endre";
const MOVE_IN_LANDING_PAGE = "/innflytting";
const SMS_ACCEPT_LANDING_PAGE_PATH = SMS_ACCEPT_PAGE_PATH + "/:hashedCustomerId";
const SMS_ACCEPT_CONFIRM_PATH = SMS_ACCEPT_PAGE_PATH + "/Bekreft";
const SMS_ACCEPT_SUBSCRIPTION_PATH = SMS_ACCEPT_PAGE_PATH + SUBSCRIPTION_PATH;
const SMS_ACCEPT_RECEIPT_PATH = SMS_ACCEPT_PAGE_PATH + RECEIPT_PATH;
const MISSING_METER_NUMBER_PATH = "/endreMalernummer/:hashId";
const INVOICE_MENU_PATH = "/velg-faktura";
const INVOICE_DETAILS_PATH = "/fakturadetaljer";
const MOBILE_CHOOSE_SUBSCRIPTION_RECEIVER = "/velg-mobilabonnement-eier";
const MOBILE_SELECT_NUMBER_PATH = "/velg-nummer";
const MOBILE_ID_SECURITY_PATH = "/id-sikring";
const MOBILE_DATA_SIM_PATH = "/data-sim";
const MOBILE_TWIN_SIM_PATH = "/tvilling-sim";
const MOBILE_MARKETING_EXCLUSION_PATH = "/reserver-mot-nummeropplysning";
const MOBILE_DATA_CONTROL_PATH = "/datakontroll";
const MOBILE_PARENT_CONTROL_PATH = "/foreldrekontroll";
const CONFIRM_ORDER_PATH = "/bekreft-bestilling";
const ADD_USER_MEMBER_PATH = "/leggTilAbonnement/:userId";
const BIRTHDATE_PATH = "/fodselsdato";
const SELECT_SIM_CARDS_PATH = "/ekstraSimKort";
const PRODUCT_CHECK_GROUP_PATH = "/VelgProdukt/:id";
const PRODUCT_RADIO_GROUP_PATH = "/VelgEnAv/:id";
const TOBB_PATH = "/tobb";
const TOBB_ID_PATH = "/tobb-kortnummer";
const PRICE_SUMMARY = "/Prisoversikt";
const PRICE_SUMMARY_MOBILE = "/PrisOversiktMobil";
const MIN_SIDE_TK_LOGIN_PATH = "/MinSide/MinSideLogInTK";
const MIN_SIDE_FK_LOGIN_PATH = "/MinSide/MinSideLogInFK";
const EXISTING_AGREEMENT_PATH = "/EksisterendeAvtale";
const CONFIRM_EXISTING_AGREEMENT_PATH = "/BekreftEksisterendeAvtale";

export const pages = {
  home: "/", // identification page (usually)
  manualIdentification: MANUAL_IDENTIFICATION_PATH,
  customerInfo: CUSTOMER_INFO_PATH,
  customerName: CUSTOMER_NAME_PATH,
  customerEmail: CUSTOMER_EMAIL_PATH,
  mobileNumber: MOBILE_NUMBER_PATH,
  customerAddress: CUSTOMER_ADDRESS_PATH,
  deliveryPoints: DELIVERY_POINTS_PATH,
  deliveryPoint: DELIVERY_POINT_PATH,
  deliveryPointMeterNumber: ADD_DELIVERY_POINT_METERNUMBER_PATH,
  deliveryPointMeterNumberHelp: ADD_DELIVERY_POINT_METERNUMBER_HELP_PATH,
  deliveryPointMeterNumberWhere: ADD_DELIVERY_POINT_METERNUMBER_WHERE_PATH,
  deliveryPointMeterNumberFound: ADD_DELIVERY_POINT_METERNUMBER_FOUND_PATH,
  deliveryPointSelectMovingDate: ADD_DELIVERY_POINT_MOVING_DATE_PATH,
  deliveryPointCalendar: ADD_DELIVERY_POINT_CALENDAR_PATH,
  addonProduct: ADDON_PRODUCT,
  norwegianPower: NORWEGIAN_POWER_PATH,
  tripleWarranty: TRIPLE_WARRANTY_PATH,
  summary: SUMMARY_PATH,
  termsAndConditions: TERMS_AND_CONDITIONS_PATH,
  subscription: SUBSCRIPTION_PATH,
  receipt: RECEIPT_PATH,
  urlEncoder: URL_ENCODER_PATH,
  trumf: TRUMF_PATH,
  trumfCallback: TRUMF_CALLBACK_PATH,
  trumfError: TRUMF_ERROR_PATH,
  sasEuroBonus: SAS_EUROBONUS,
  meterNumber: METERNUMBER_PATH,
  mobile: MOBILE_PATH,
  bedrift: BEDRIFT_PATH,
  bedriftMovingDate: BEDRIFT_MOVING_DATE,
  bedriftCalendar: BEDRIFT_CALENDAR,
  contactPerson: BEDRIFT_CONTACT_PERSON,
  addTrumfProduct: ADD_TRUMF_PRODUCT_PATH,
  addTrumfProductError: ADD_TRUMF_PRODUCT_ERROR_PATH,
  bankIdCallback: BANK_ID_CALLBACK_PATH,
  bankIdLogin: BANK_ID_LOGIN_PATH,
  selectProduct: SELECT_PRODUCT_PATH,
  changeProduct: CHANGE_PRODUCT_PAGE,
  moveInLandingPage: MOVE_IN_LANDING_PAGE,
  smsAcceptPage: SMS_ACCEPT_PAGE_PATH,
  smsAcceptLandingPage: SMS_ACCEPT_LANDING_PAGE_PATH,
  smsAcceptConfirm: SMS_ACCEPT_CONFIRM_PATH,
  smsAcceptReceipt: SMS_ACCEPT_RECEIPT_PATH,
  smsAcceptSubscription: SMS_ACCEPT_SUBSCRIPTION_PATH,
  missingMeterNumber: MISSING_METER_NUMBER_PATH,
  invoice: INVOICE_MENU_PATH,
  invoiceDetails: INVOICE_DETAILS_PATH,
  mobileSubscriptionReceiver: MOBILE_CHOOSE_SUBSCRIPTION_RECEIVER,
  mobileSelectNumber: MOBILE_SELECT_NUMBER_PATH,
  mobileIdSecurity: MOBILE_ID_SECURITY_PATH,
  mobileDataSim: MOBILE_DATA_SIM_PATH,
  mobileTwinSim: MOBILE_TWIN_SIM_PATH,
  mobileMarketingExclusion: MOBILE_MARKETING_EXCLUSION_PATH,
  mobileDataControl: MOBILE_DATA_CONTROL_PATH,
  mobileParentControl: MOBILE_PARENT_CONTROL_PATH,
  confirmOrder: CONFIRM_ORDER_PATH,
  addUser: ADD_USER_MEMBER_PATH,
  birthDate: BIRTHDATE_PATH,
  selectSimCards: SELECT_SIM_CARDS_PATH,
  deliveryPointV2: DELIVERY_POINT_PATH_V2,
  deliveryPointV2MeterNumber: DELIVERY_POINT_PATH_V2_METER_NUMBER,
  deliveryPointV2MeterNumberHelp: DELIVERY_POINT_PATH_V2_METER_NUMBER_HELP,
  deliveryPointV2MeterNumberWhere: DELIVERY_POINT_PATH_V2_METER_NUMBER_WHERE,
  deliveryPointV2MeterNumberFound: DELIVERY_POINT_PATH_V2_METER_NUMBER_WHERE_FOUND,
  deliveryPointV2MovingDate: DELIVERY_POINT_PATH_V2_MOVING_DATE,
  deliveryPointV2Calendar: DELIVERY_POINT_PATH_V2_CALENDAR,
  powerSmart: POWER_SMART_PATH,
  powerSmartDetails: POWER_SMART_DETAILS_PATH,
  productCheckGroup: PRODUCT_CHECK_GROUP_PATH,
  productRadioGroup: PRODUCT_RADIO_GROUP_PATH,
  priceSummary: PRICE_SUMMARY,
  priceSummaryMobile: PRICE_SUMMARY_MOBILE,
  minSideFKLogIn: MIN_SIDE_FK_LOGIN_PATH,
  minSideTKLogin: MIN_SIDE_TK_LOGIN_PATH,
  tobbId: TOBB_ID_PATH,
  tobb: TOBB_PATH,
  existingAgreement: EXISTING_AGREEMENT_PATH,
  confirmExistingAgreement: CONFIRM_EXISTING_AGREEMENT_PATH
};

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
let steps;

export function findAddtionalMobilePages(recommendedProducts, bonuses, isUser) {
  let additionalPages = [];
  // todo: bonus pages for mobile?
  additionalPages = [...addProductSteps(recommendedProducts, true, isUser)];
  let uniquePages = _.uniq(additionalPages);
  return uniquePages;
}
export function findAdditionalPages(recommendedProducts, bonuses) {
  let additionalPages = [];
  additionalPages = [...addProductSteps(recommendedProducts), ...addBonusSteps(bonuses)];
  let uniquePages = _.uniq(additionalPages);
  return uniquePages;
}

function addProductSteps(recommendedProducts, isMobile = false, isUser = false) {
  if (_.isEmpty(recommendedProducts)) {
    return [];
  }
  let productSteps = isMobile
    ? getMobileExtraProductSteps(recommendedProducts, isUser)
    : getPowerExtraProductSteps(recommendedProducts);
  return productSteps;
}

function getPowerExtraProductSteps(recommendedProducts) {
  let productSteps = [];

  // Ensure no duplicate steps for Strømsmart. Remove StrømsmartPlus if Strømsmart and StrømsmartPlus is present.

  // Insert new step to guide which recommended product step to go to
  // Ensure that the user doesnt organically navigate to recommended product step if they have not selected a product
  const powerSmart = recommendedProducts.find((rp) => rp.id === SMART_PRODUCT_POWER_ID.toString());
  const powerSmartPlus = recommendedProducts.find(
    (rp) => rp.id === SMART_PRODUCT_POWER_PLUS_ID.toString()
  );
  if (powerSmart || powerSmartPlus) {
    productSteps.push(pages.powerSmart);
  }
  //Remove Powersmart recommended products, to avoid creating addon product steps for them.
  //All powersmart steps are contained in PowerSmartStep.js
  const modifiedRecommendedProducts =
    powerSmart || powerSmartPlus
      ? recommendedProducts.filter(
          (rp) =>
            rp.id !== SMART_PRODUCT_POWER_PLUS_ID.toString() &&
            rp.id !== SMART_PRODUCT_POWER_ID.toString() &&
            rp.id !== PULSE_METER_ID.toString()
        )
      : recommendedProducts;
  for (let product of modifiedRecommendedProducts) {
    if (!_.isEmpty(product) && (product.optional || product.id === getTrumfProductId())) {
      if (product.id === getTrumfProductId()) {
        productSteps.push(pages.trumf);
      } else {
        let productPage = generatePath(pages.addonProduct, { id: product.id });
        if (productPage) {
          productSteps.push(productPage);
        }
      }
    }
  }
  return productSteps;
}
export const checkGroupTag = "SMCHECKGROUP";
export const radioGroupTag = "SMRADIOGROUP";
function getMobileExtraProductSteps(recommendedProducts, isUser = false) {
  if (_.isEmpty(recommendedProducts)) {
    return [];
  }
  let productSteps = [];
  let tagsToFilter = [];
  tagsToFilter.push(isUser ? "MobileOwnerOnly" : "MobileUserOnly");
  const filteredProducts = recommendedProducts.filter(
    (rp) => _.isEmpty(rp.tags) || !rp.tags.some((t) => tagsToFilter.includes(t))
  );

  if (_.isEmpty(filteredProducts)) {
    return [];
  }
  for (let product of filteredProducts) {
    if (!_.isEmpty(product) && product.optional) {
      if (product.id === getTrumfProductId()) {
        productSteps.push(pages.trumf);
      } else {
        let productPage = generatePath(pages.addonProduct, { id: product.id });
        if (!_.isEmpty(product.tags)) {
          // create check/radio group page if more than one product has the same tag.
          if (
            product.tags.some((t) => t.includes(checkGroupTag)) &&
            filteredProducts.filter((rp) => rp.tags.some((t) => t.includes(checkGroupTag))).length >
              1
          ) {
            const checkGroupId = product.tags
              .find((t) => t.includes(checkGroupTag))
              .replace(checkGroupTag, "");
            productPage = generatePath(pages.productCheckGroup, { id: checkGroupId });
          } else if (
            product.tags.some((t) => t.includes(radioGroupTag)) &&
            filteredProducts.filter((rp) => rp.tags.some((t) => t.includes(radioGroupTag))).length >
              1
          ) {
            const radioGroupId = product.tags
              .find((t) => t.includes(radioGroupTag))
              .replace(radioGroupTag, "");
            productPage = generatePath(pages.productRadioGroup, { id: radioGroupId });
          }
        }
        if (productSteps.includes(productPage)) {
          continue; // skip if we already added this page
        }
        if (productPage) {
          productSteps.push(productPage);
        }
      }
    }
  }
  if (recommendedProducts.some((rp) => rp.tags && rp.tags.includes("Simkort"))) {
    productSteps.push(pages.selectSimCards);
  }
  return productSteps.map((p) => pages.mobile + (isUser ? pages.addUser : "") + p);
}

function addBonusSteps(bonuses) {
  let bonusSteps = [];
  if (bonuses === undefined) return bonusSteps;
  bonusSteps = bonuses.map((b) => getPageFromBonusTypeId(b.bonusTypeId));
  return bonusSteps;
}

export function getAllPossiblePowerSteps(recommendedProducts) {
  let newSteps = [];
  newSteps.push(pages.home);
  newSteps.push(pages.manualIdentification);
  newSteps.push(pages.customerName);
  newSteps.push(pages.mobileNumber);
  newSteps.push(pages.manualIdentification);
  newSteps.push(pages.deliveryPoints);
  newSteps.push(...getPowerExtraProductSteps(recommendedProducts));
  newSteps.push(pages.sasEuroBonus);
  newSteps.push(pages.invoice);
  newSteps.push(pages.invoiceDetails);
  newSteps.push(pages.customerEmail);
  newSteps.push(pages.subscription);

  newSteps.push(pages.receipt);
  return newSteps;
}
export function getAllPossibleDeliveryPointSteps() {
  let deliveryPointSteps = [];
  deliveryPointSteps.push(pages.deliveryPointV2);
  deliveryPointSteps.push(pages.deliveryPointV2 + pages.deliveryPointV2MeterNumber);
  deliveryPointSteps.push(pages.deliveryPointV2 + pages.deliveryPointV2MeterNumberHelp);
  deliveryPointSteps.push(pages.deliveryPointV2 + pages.deliveryPointV2MeterNumberWhere);
  deliveryPointSteps.push(pages.deliveryPointV2 + pages.deliveryPointV2MeterNumberFound);
  deliveryPointSteps.push(pages.deliveryPointV2 + pages.deliveryPointV2MovingDate);
  deliveryPointSteps.push(pages.deliveryPointV2 + pages.deliveryPointV2Calendar);
  return deliveryPointSteps;
}

export function getAllPossibleBedriftDeliveryPointSteps() {
  let deliveryPointSteps = [];
  deliveryPointSteps.push(pages.bedrift + pages.deliveryPointV2);
  deliveryPointSteps.push(pages.bedrift + pages.deliveryPointV2 + pages.deliveryPointV2MeterNumber);
  deliveryPointSteps.push(
    pages.bedrift + pages.deliveryPointV2 + pages.deliveryPointV2MeterNumberHelp
  );
  deliveryPointSteps.push(
    pages.bedrift + pages.deliveryPointV2 + pages.deliveryPointV2MeterNumberWhere
  );
  deliveryPointSteps.push(
    pages.bedrift + pages.deliveryPointV2 + pages.deliveryPointV2MeterNumberFound
  );
  deliveryPointSteps.push(pages.bedrift + pages.deliveryPointV2 + pages.deliveryPointV2MovingDate);
  deliveryPointSteps.push(pages.bedrift + pages.deliveryPointV2 + pages.deliveryPointV2Calendar);
  return deliveryPointSteps;
}

export function isNextPowerStep(prevStep, nextStep, recommendedProducts) {
  if (!prevStep) {
    return true;
  }

  const powerSteps = getAllPossiblePowerSteps(recommendedProducts);
  const deliveryPointSteps = getAllPossibleDeliveryPointSteps(recommendedProducts);
  if (matchPath(nextStep, { path: pages.deliveryPointV2 })) {
    const nextDeliveryPointStep = deliveryPointSteps.findIndex((s) =>
      matchPath(nextStep, { path: s, exact: true })
    );
    if (matchPath(prevStep, { path: pages.deliveryPointV2 })) {
      return (
        nextDeliveryPointStep >
        deliveryPointSteps.findIndex((s) => matchPath(prevStep, { path: s, exact: true }))
      );
    } else {
      return true;
    }
  } else if (matchPath(prevStep, { path: pages.deliveryPointV2 })) {
    const prevDeliveryPointStepIndex = deliveryPointSteps.findIndex((s) =>
      matchPath(prevStep, { path: s, exact: true })
    );
    return prevDeliveryPointStepIndex !== 0;
  } else {
    return (
      powerSteps.findIndex((s) => matchPath(nextStep, { path: s, exact: true })) >
      powerSteps.findIndex((s) => matchPath(prevStep, { path: s, exact: true }))
    );
  }
}

export function isNextBedriftStep(prevStep, nextStep, recommendedProducts) {
  if (!prevStep) {
    return true;
  }

  const powerSteps = getAllPossibleBedriftSteps();
  const deliveryPointSteps = getAllPossibleBedriftDeliveryPointSteps();
  if (matchPath(nextStep, { path: pages.bedrift + pages.deliveryPointV2 })) {
    const nextDeliveryPointStep = deliveryPointSteps.findIndex((s) =>
      matchPath(nextStep, { path: s, exact: true })
    );
    if (matchPath(prevStep, { path: pages.bedrift + pages.deliveryPointV2 })) {
      return (
        nextDeliveryPointStep >
        deliveryPointSteps.findIndex((s) => matchPath(prevStep, { path: s, exact: true }))
      );
    } else {
      return true;
    }
  } else if (matchPath(prevStep, { path: pages.bedrift + pages.deliveryPointV2 })) {
    const prevDeliveryPointStepIndex = deliveryPointSteps.findIndex((s) =>
      matchPath(prevStep, { path: s, exact: true })
    );
    return prevDeliveryPointStepIndex !== 0;
  } else {
    return (
      powerSteps.findIndex((s) => matchPath(nextStep, { path: s, exact: true })) >
      powerSteps.findIndex((s) => matchPath(prevStep, { path: s, exact: true }))
    );
  }
}

const mobileUserPath = pages.mobile + pages.addUser;
// check to trigger correct transition animation when changing pages forwards and backwards
export function isNextMobileStep(prevStep, nextStep, recommendedProducts) {
  if (!prevStep) {
    return true;
  }
  const possibleUserStartSteps = [
    pages.mobile + pages.addUser,
    pages.mobile + pages.addUser + pages.customerName,
    pages.mobile + pages.addUser + pages.selectProduct,
    pages.mobile + pages.addUser + pages.mobileSelectNumber
  ];
  const mobileSteps = getAllPossibleMobileSteps(recommendedProducts);
  const addUserSteps = getAllPossibleMobileUserSteps(recommendedProducts);
  if (matchPath(nextStep, { path: mobileUserPath })) {
    const nextUserStep = addUserSteps.findIndex((s) =>
      matchPath(nextStep, { path: s, exact: true })
    );
    if (matchPath(prevStep, { path: mobileUserPath })) {
      return (
        nextUserStep > addUserSteps.findIndex((s) => matchPath(prevStep, { path: s, exact: true }))
      );
    } else {
      return possibleUserStartSteps.some((o) => matchPath(nextStep, { path: o, exact: true }));
    }
  } else if (matchPath(prevStep, { path: mobileUserPath })) {
    const prevUserStepIndex = addUserSteps.findIndex((s) =>
      matchPath(prevStep, { path: s, exact: true })
    );
    return !possibleUserStartSteps.some((o) => matchPath(prevStep, { path: o, exact: true }));
  } else {
    return (
      mobileSteps.findIndex((s) => matchPath(nextStep, { path: s, exact: true })) >
      mobileSteps.findIndex((s) => matchPath(prevStep, { path: s, exact: true }))
    );
  }
}
export function getAllPossibleMobileSteps(recommendedProducts) {
  let newSteps = [];
  newSteps.push(pages.mobile);
  newSteps.push(pages.mobile + pages.bankIdLogin);
  newSteps.push(pages.mobile + pages.manualIdentification);
  newSteps.push(pages.mobile + pages.customerName);
  newSteps.push(pages.mobile + pages.mobileSubscriptionReceiver);
  newSteps.push(pages.mobile + pages.mobileNumber);
  newSteps.push(pages.mobile + pages.customerEmail);
  newSteps.push(pages.mobile + pages.invoice);
  newSteps.push(pages.mobile + pages.invoiceDetails);
  newSteps.push(pages.mobile + pages.subscription);

  newSteps.push(pages.mobile + pages.confirmOrder);
  newSteps.push(pages.mobile + pages.receipt);
  return newSteps;
}

export function getAllPossibleBedriftSteps() {
  let newSteps = [];
  newSteps.push(pages.bedrift);
  newSteps.push(pages.bedrift + pages.contactPerson);
  newSteps.push(pages.bedrift + pages.deliveryPoints);
  newSteps.push(pages.bedrift + pages.invoiceDetails);
  newSteps.push(pages.bedrift + pages.bankIdLogin);
  newSteps.push(pages.bedrift + pages.manualIdentification);
  newSteps.push(pages.bedrift + pages.customerName);
  newSteps.push(pages.bedrift + pages.bedriftMovingDate);
  newSteps.push(pages.bedrift + pages.bedriftCalendar);
  newSteps.push(pages.bedrift + pages.mobileSubscriptionReceiver);
  newSteps.push(pages.bedrift + pages.invoice);
  newSteps.push(pages.bedrift + pages.termsAndConditions);
  newSteps.push(pages.bedrift + pages.confirmOrder);
  newSteps.push(pages.bedrift + pages.receipt);
  return newSteps;
}

const userInfoSteps = [
  pages.mobile + pages.addUser + pages.customerName,
  pages.mobile + pages.addUser + pages.birthDate
];
export function getMobileUserSteps(user, recommendedProducts, users, skipProductStep = false) {
  const initialized = users && users.some((f) => f.completed);
  const userPath = pages.mobile + pages.addUser;
  let userSteps = [];
  if (user?.isOwner === undefined) {
    userSteps.push(userPath); // only allowed to select type of user (owner/user) when created from summary.
  }
  if (!user?.isOwner) {
    userSteps.push(...userInfoSteps); // these steps are filled by form if user is owner
  }

  if (!skipProductStep) {
    userSteps.push(userPath + pages.selectProduct);
  }
  userSteps.push(userPath + pages.mobileSelectNumber);
  if (!user?.createNewNumber) {
    userSteps.push(userPath + pages.mobileNumber);
  }
  userSteps.push(...getMobileExtraProductSteps(recommendedProducts, true));
  if (initialized) {
    return userSteps;
  } else {
    const initialStepsToSkip = [
      pages.mobile + pages.addUser // the first time user selects owner
    ];
    return userSteps.filter((u) => !initialStepsToSkip.includes(u));
  }
}
export function getAllPossibleMobileUserSteps(recommendedProducts) {
  let newUserSteps = [];
  const mobileUserPath = pages.mobile + pages.addUser;
  newUserSteps.push(mobileUserPath);
  newUserSteps.push(mobileUserPath + pages.customerName);
  newUserSteps.push(mobileUserPath + pages.birthDate);
  newUserSteps.push(mobileUserPath + pages.customerEmail);
  newUserSteps.push(mobileUserPath + pages.selectProduct);
  newUserSteps.push(mobileUserPath + pages.mobileSelectNumber);
  newUserSteps.push(mobileUserPath + pages.mobileNumber);
  newUserSteps.push(...getMobileExtraProductSteps(recommendedProducts, true));
  return newUserSteps;
}

export const pageStepAnimationDuration = 1000; // time in milliseconds
export function getCurrentProgress(currentPage) {
  if (!steps) {
    return 100;
  }
  let page = currentPage.includes(pages.deliveryPoints) ? pages.deliveryPoints : currentPage;
  let stepsCount = steps.length - 2; // -2 for identification and receipt.
  let currentStep = steps.findIndex((s) => s.includes(page));
  return Math.round((100 / stepsCount) * currentStep);
}

export function insertNextStep(currentPage, nextPage) {
  const currentIndex = steps.findIndex((s) => s.includes(currentPage));
  const newSteps = [
    ...steps.slice(0, currentIndex + 1),
    nextPage,
    ...steps.slice(currentIndex + 1)
  ];
  steps = newSteps;
  saveStepsToSessionStorage(newSteps);
}

export function setFKMobileFormSteps() {
  steps = [];
  steps.push(pages.mobile + pages.home);
  steps.push(pages.mobile + pages.customerAddress);
  steps.push(pages.mobile + pages.customerEmail);
  saveStepsToSessionStorage(steps);
}

export function isValidIdentificationInput(ssn, phone) {
  return ssn.replace(/\s+/g, "").length === 11 && phone.replace(/\s+/g, "").length === 8;
}

export function isValidCustomerInfoInput(form) {
  return (
    form.firstName &&
    form.lastName &&
    form.address &&
    form.zipCode &&
    form.city &&
    form.email &&
    form.email.match(emailRegex)
  );
}

export function isValidNameInput(form) {
  return !!form.firstName && !!form.lastName;
}

export function isValidEmailInput(form) {
  return !!form.email && form.email.match(emailRegex);
}

export function isValidInvoiceAddress(form) {
  return !!form.address && !!form.zipCode && !!form.city;
}

function saveStepsToSessionStorage(steps) {
  sessionStorage.setItem(STEPS_SESSION_STORAGE_KEY, JSON.stringify(steps));
}

export function getProgress(steps, currentPage = null) {
  let current = currentPage || window.location.pathname;
  //let page = current.includes(pages.deliveryPoints) ? pages.deliveryPoints : current;
  let stepsCount = steps.length - 2; // -2 for identification and receipt.
  let currentStep = steps.findIndex((s) => matchPath(current, { path: s, exact: true }));
  return Math.round((100 / stepsCount) * currentStep);
}

export function getAllPossibleMobileExtraProductSteps(isUser = false, recommendedProducts) {
  const steps = [];
  steps.push(pages.addonProduct);
  steps.push(pages.mobileIdSecurity);
  steps.push(pages.selectSimCards);
  steps.push(pages.mobileMarketingExclusion);
  if (isUser) {
    steps.push(pages.mobileParentControl);
  } else {
    steps.push(pages.mobileDataControl);
  }
  return isUser
    ? steps.map((s) => pages.mobile + pages.addUser + s)
    : steps.map((s) => pages.mobile + s);
}

export const productCategories = {
  power: "POWER",
  agreement: "AGREEMENT",
  addon: "ADDON",
  productOption: "SPARKLE_PRODUCT",
  serviceOption: "SPARKLE_ADDON",
  campaignOption: "SPARKLE_CAMPAIGN"
};

export function getProductCategories(isMobile = false) {
  return isMobile
    ? [productCategories.productOption]
    : [productCategories.power, productCategories.agreement];
}

export function getAddonProductCategories(isMobile = false) {
  return isMobile ? [productCategories.serviceOption] : [productCategories.addon];
}

export function getMobileCampaignCategories() {
  return [productCategories.campaignOption];
}
