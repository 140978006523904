import { fork } from "redux-saga/effects";
import {
  watchGetAddonProduct,
  watchGetBisnodeData,
  watchGetElhubData,
  watchParseQueryString,
  watchSubmitForm,
  watchInitializeDeliveryPointDataAction,
  watchGetBankIdTokenResponse,
  watchSubmitMobileForm,
  watchSubmitBedriftForm
} from "../Sagas/SaleSaga";
import { watchMdmAddTrumfId } from "../Sagas/TrumfSaga";
import { watchTobbForm } from "../Sagas/TobbSaga";
import {
  watchGetSmsAcceptAddonProducts,
  watchGetSmsAcceptExtraProductText,
  watchSubmitSmsAcceptForm,
  watchGetSmsAcceptCustomerInfo,
  watchSubmitConsent,
  watchGetPriceSummary
} from "../Sagas/SmsAcceptSaga";
import {
  watchGetAddonProducts,
  watchGetProductInfo,
  watchGetProducts,
  watchGetProductInfoBedrift,
  watchGetAddonProductInfo,
  watchGetExtraProductPriceInfo,
  watchGetPriceInfo,
  watchGetCampaignText,
  watchGetCampaignLength
} from "../Sagas/ProductSaga";
import {
  watchGetCustomerByHashId,
  watchGetExistingCustomerData,
  watchSubmitMeterNumberUpdate
} from "../Sagas/CustomerSaga";
import { watchReportError } from "../Sagas/ErrorSaga";

function* root() {
  yield fork(watchGetBisnodeData);
  yield fork(watchGetElhubData);
  yield fork(watchSubmitForm);
  yield fork(watchGetAddonProduct);
  yield fork(watchParseQueryString);
  yield fork(watchInitializeDeliveryPointDataAction);
  yield fork(watchGetProductInfo);
  yield fork(watchGetPriceInfo);
  yield fork(watchGetExtraProductPriceInfo);
  yield fork(watchGetProductInfoBedrift);
  yield fork(watchMdmAddTrumfId);
  yield fork(watchGetBankIdTokenResponse);
  yield fork(watchGetSmsAcceptAddonProducts);
  yield fork(watchGetSmsAcceptExtraProductText);
  yield fork(watchSubmitSmsAcceptForm);
  yield fork(watchGetSmsAcceptCustomerInfo);
  yield fork(watchGetCustomerByHashId);
  yield fork(watchSubmitMeterNumberUpdate);
  yield fork(watchSubmitConsent);
  yield fork(watchGetProducts);
  yield fork(watchGetAddonProducts);
  yield fork(watchGetExistingCustomerData);
  yield fork(watchReportError);
  yield fork(watchGetAddonProductInfo);
  yield fork(watchSubmitMobileForm);
  yield fork(watchSubmitBedriftForm);
  yield fork(watchGetCampaignText);
  yield fork(watchGetPriceSummary);
  yield fork(watchTobbForm);
  yield fork(watchGetCampaignLength);
}

export default root;
