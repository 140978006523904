import React from "react";
import { Callback } from "react-oidc";
import { userManager } from "./TrumfStuff";
import saleActionDispatchers from "../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { history } from "../../App";
import { pages } from "../../Utils/CustomerFormHelper";
import Loader from "../CustomerForm/FKLoader/Loader";
import FormPageContainer from "../CustomerForm/FormPageContainer/FormPageContainer";
import trumfActionDispatchers from "../../ReduxModules/Actions/TrumfActions/TrumfActionDispatchers";

function TrumfCallback() {
  function onSuccess(user) {
    const trumfId = user.profile["http://id.trumf.no/claims/medlem"];
    if (user.state.addTrumfProduct) {
      trumfActionDispatchers.setTrumfId(trumfId);
      trumfActionDispatchers.mdmUpdateTrumfId(user.state.momentId, trumfId);
      history.push(pages.addTrumfProduct);
    } else {
      saleActionDispatchers.setStateAction(user.state);
      saleActionDispatchers.updateTrumfId(trumfId);
      history.push(pages.trumf);
    }
  }

  function onError(e) {
    // todo: handle expired data? handle different errors?
    const state = JSON.parse(window.sessionStorage.getItem("state"));
    if (state.addTrumfProduct) {
      trumfActionDispatchers.setError(e);
      history.push(pages.addTrumfProduct);
    } else {
      saleActionDispatchers.setStateAction(state);
      window.newrelic.noticeError(e, {
        errorMessage: e.message
      });
      history.push(pages.trumfError);
    }
  }

  return (
    <FormPageContainer hideFooter>
      <Callback
        userManager={userManager}
        onSuccess={(user) => onSuccess(user)}
        onError={(e) => onError(e)}
      />
      <Loader text={"Bekrefter Trumf innlogging..."} />
    </FormPageContainer>
  );
}

export default TrumfCallback;
