import * as React from "react";
import { AdditionalSalesByIds } from "../components/CustomerForm/AdditionalSales/AdditionalSalesByIds";
import styles from "./UrlEncoder.module.scss";

function UrlEncoderAdditionalSalesPreview({ additionalSales }: { additionalSales: string[] }) {
  const [additionalSalesPreview, setAdditionalSalesPreview] = React.useState(false);

  return additionalSales && additionalSales.length > 0 ? (
    <div className={styles.additionalSalesByIds}>
      <button className="btnSecondary" onClick={() => setAdditionalSalesPreview((prev) => !prev)}>
        {additionalSalesPreview
          ? "Skjul forhåndsvisning av mersalg"
          : "Vis forhåndsvisning av mersalg"}
      </button>
      {additionalSalesPreview ? (
        <>
          <h2>Visning av mersalg på kvitteringsside</h2>
          <p>
            Dersom du ønsker å endre rekkefølgen på mersalg, så må du endre rekkefølgen i Sanity.
          </p>
          <AdditionalSalesByIds ids={additionalSales} />
        </>
      ) : null}
    </div>
  ) : null;
}

export { UrlEncoderAdditionalSalesPreview };
