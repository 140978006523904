import React from "react";
import styles from "./FormPageContainer.module.scss";
import { IsFKTheme } from "../../../Utils/Themes";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { connect } from "react-redux";
import { CurrentProgressSelector } from "../../../ReduxModules/Selectors/PageStepSelectors";
import { useParams } from "react-router-dom";

function FormPageContainer({
  children,
  onBack,
  footerContent,
  footerSubContent,
  hideFooter,
  className,
  pageStepProgress,
  wide,
  currentStep
}) {
  const params = useParams();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      pageStepActionDispatchers.goToPreviousStep(window.location.pathname, params);
    }
  };
  return (
    <div className={"content-container-fixed " + className}>
      <div className={"content-fixed " + (wide && "content-fixed-wide")}>{children}</div>
      {!hideFooter && (
        <div className={"footer footer-fixed"}>
          {footerContent ? (
            footerContent
          ) : (
            <>
              <div className={"footerButtons"}>
                <div className={`${styles.fkFooter} ${!IsFKTheme && styles.tkFooter}`}>
                  <button className={styles.backButton} onClick={handleBack}>
                    Tilbake
                  </button>
                  <div className={styles.progressBar}>
                    <div className={styles.whiteBar} style={{ width: `${pageStepProgress}%` }} />
                    <div className={styles.darkBarContainer}>
                      <div className={styles.progress}>{pageStepProgress}%</div>
                      <div className={styles.darkBar} />
                    </div>
                  </div>
                </div>
              </div>
              {footerSubContent && footerSubContent}
            </>
          )}
        </div>
      )}
    </div>
  );
  /*return (
        <div className={`${styles.container} ${className}`}>
            <div className={'mainContent'}>
                <div className={'contentContainer'}>
                    {children}
                </div>
            </div>

            {!hideFooter &&
            <div className={'footer'}>
                {footerContent ? footerContent :
                    <div className={'footerButtons'}>
                        <div className={`${styles.fkFooter} ${!IsFKTheme && styles.tkFooter}`}>
                            <button className={styles.backButton} onClick={handleBack}>Tilbake</button>
                            <div className={styles.progressBar}>
                                <div className={styles.whiteBar}
                                     style={{width: `${pageStepProgress}%`}}/>
                                <div className={styles.darkBarContainer}>
                                    <div className={styles.progress}>{pageStepProgress}%
                                    </div>
                                    <div className={styles.darkBar}/>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>}
        </div>
    );*/
}

export default connect(CurrentProgressSelector)(FormPageContainer);
