import CheckBoxV2 from "../../CheckBox/CheckBoxV2";
import React, { useState } from "react";
import { checkGroupTag, pages } from "../../../Utils/CustomerFormHelper";
import { IsFKTheme } from "../../../Utils/Themes";
import MobileMascotSmile from "../../../assets/Images/FK/Mobil/MobileMascotSmile.svg";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { connect } from "react-redux";
import { formAndUserSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import styles from "./ProductGroup.module.scss";
import modalStyles from "../Mobile/ModalInfo.module.scss";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { useParams } from "react-router-dom";
import { productGroupSelector } from "../../../ReduxModules/Selectors/ProductSelectors";
import _ from "lodash";
import Icon from "../../Icon/Icon";
import ModalWindow from "../../Modal/Modal";
import MascotBlush from "../../../assets/Images/FK/Mobil/MascotBlush.svg";

function ProductCheckGroup({ groupId, onBack, onConfirm, userId, form, user, products }) {
  const extraProducts = (userId ? user.extraProducts : form.extraProducts) || [];
  const groupTag = checkGroupTag + groupId;
  const [no, setNo] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(
    extraProducts
      .filter((ep) => ep.tags.some((t) => t.includes(groupTag)))
      .map((ep) => parseInt(ep.id))
  );
  const [showProductModal, setShowProductModal] = useState(false);
  const params = useParams();
  const handleNoClick = () => {
    setSelectedProducts([]);
    setNo(!no);
  };
  const handleClick = (productId) => {
    setNo(false);
    if (selectedProducts.some((sp) => sp === productId)) {
      setSelectedProducts(selectedProducts.filter((sp) => sp !== productId));
    } else {
      setSelectedProducts(selectedProducts.concat([productId]));
    }
  };
  const handleConfirm = () => {
    let newExtraProducts = extraProducts
      .filter((ep) => !ep.tags.includes(groupTag))
      .concat(form.recommendedProducts.filter((rp) => selectedProducts.includes(parseInt(rp.id))));
    if (userId) {
      saleActionDispatchers.updateUser(userId, { extraProducts: newExtraProducts });
    } else {
      saleActionDispatchers.updateFormAction({ extraProducts: newExtraProducts });
    }
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
    }
  };
  function handleMoreInfoClick(e, product) {
    e.stopPropagation();
    setShowProductModal(product);
  }
  return (
    <FormPageContainer onBack={onBack} currentStep={userId ? pages.mobile + pages.summary : null}>
      <h1 className={"fallIn-"}>Knytt flere SIM-kort til ditt abonnement</h1>
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img
            className={"frameImage formPage-background fade-"}
            src={MobileMascotSmile}
            alt={"mobileMascot"}
          />
        </div>
      )}
      <div className={"slide"}>
        <ModalWindow open={showProductModal} onClose={() => setShowProductModal(false)}>
          <img className={modalStyles.modalImage} src={MascotBlush} alt={"mascot"} />
          {showProductModal && (
            <div dangerouslySetInnerHTML={{ __html: showProductModal.textGroups[1] }} />
          )}
        </ModalWindow>
        {products.map((product) => (
          <CheckBoxV2
            key={product.id}
            className={styles.checkBox}
            checked={selectedProducts.includes(product.id)}
            onClick={() => handleClick(product.id)}
          >
            <div
              className={"title"}
              dangerouslySetInnerHTML={{
                __html: product.data?.productName
                  .replaceAll("<p>", "<div>")
                  .replaceAll("</p>", "</div>")
              }}
            />
            {_.isNumber(product.data?.monthlyFeeIncVat) && (
              <div className={"priceTag"}>{product.data?.monthlyFeeIncVat} kr/mnd</div>
            )}
            <div className={"grayText"}>
              <div
                dangerouslySetInnerHTML={{
                  __html: product.data?.description
                    .replaceAll("<p>", "<div>")
                    .replaceAll("</p>", "</div>")
                }}
              />
              {_.isNumber(product.data?.initialFeeIncVat) && (
                <div>Etablering kr {product.data?.initialFeeIncVat}</div>
              )}
            </div>
            {product.textGroups[1] && (
              <div
                tabIndex={0}
                className={`btnUnderline ${modalStyles.blackMoreInfoButton}`}
                onClick={(e) => handleMoreInfoClick(e, product)}
              >
                <Icon className={modalStyles.warningIconBlack} icon={"WarningCircle"} />
                Mer informasjon
              </div>
            )}
          </CheckBoxV2>
        ))}
        <CheckBoxV2 className={styles.checkBox} checked={no} onClick={handleNoClick}>
          <div className={"title"}>Nei takk</div>
        </CheckBoxV2>
        <button
          className={`btnPrimary fadeDownButton ${styles.confirmButton}`}
          disabled={_.isEmpty(selectedProducts) && !no}
          onClick={handleConfirm}
        >
          Bekreft
        </button>
      </div>
    </FormPageContainer>
  );
}

export default connect(productGroupSelector)(connect(formAndUserSelector)(ProductCheckGroup));
