import React from "react";
import { connect } from "react-redux";
import { trumfSelector } from "../../ReduxModules/Selectors/SaleSelectors";
import TrumfRegistered from "./TrumfRegistered";
import TrumfLogin from "./TrumfLogin";

function Trumf({ trumfId, onNext, onBack, formPageAnimation }) {
  return trumfId ? (
    <TrumfRegistered onNext={onNext} onBack={onBack} formPageAnimation />
  ) : (
    <TrumfLogin onBack={onBack} formPageAnimation />
  );
}

export default connect(trumfSelector)(Trumf);
