import React, { useEffect, useMemo } from "react";
import config from "../config";
import { getIsProductExchange } from "./AxiosFunctions";
import { IsFKTheme } from "./Themes";
import { useLocation } from "react-router-dom";
import { pages } from "./CustomerFormHelper";
import { useSelector } from "react-redux";

const getImgSrc = (searchParams) => {
  return `${config.trackingPixelUrl}/pixel.png?${decodeURIComponent(searchParams.toString())}`;
};

const TrackingPixel = () => {
  const { pathname } = useLocation();
  const { saleState, deliveryPointState } = useSelector((state) => ({
    saleState: state.saleState,
    deliveryPointState: state.deliveryPointState
  }));

  const [src, setSrc] = React.useState(`${config.trackingPixelUrl}/pixel.png?location=${pathname}`);

  const brand = IsFKTheme ? "FKAS" : "TKAS";
  const meterPointId = (deliveryPointState.deliveryPoints ?? [])[0]?.meterIdentification;
  const ssn = saleState.form.ssn;
  const submittedOrders = saleState.form.submitOrderResponse ?? [];

  useEffect(() => {
    const searchParams = new URLSearchParams();
    searchParams.append("location", pathname);

    if (pathname !== pages.receipt) {
      setSrc(getImgSrc(searchParams));
      return;
    }

    if (!ssn || !meterPointId) {
      setSrc(getImgSrc(searchParams));
      return;
    }

    async function handleReceiptPage() {
      const response = await getIsProductExchange(ssn, brand, meterPointId);

      if (response) {
        searchParams.append("productExchange", response.data?.toString() ?? "error");
      }

      if (submittedOrders?.length > 0) {
        for (const order of submittedOrders) {
          searchParams.append("orderId", order?.id ?? "error");
        }
      }

      setSrc(getImgSrc(searchParams));
      return;
    }

    handleReceiptPage();
  }, [pathname, ssn, brand, meterPointId, submittedOrders]);

  return <img src={src} alt="" style={{ display: "none" }} />;
};

export { TrackingPixel };
