import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import styles from "../../../UrlEncoder/UrlEncoder.module.scss";
import React from "react";
import { checkGroupTag, radioGroupTag } from "../../../Utils/CustomerFormHelper";

export default function PreviewAddonsButton({ tags, id }) {
  const addonTagBuilder = (addonProd) => {
    if (addonProd) {
      return addonProd
        .filter((p) => p.includes(checkGroupTag) || p.includes(radioGroupTag))
        .toString();
    }
  };

  const thisTag = addonTagBuilder(tags);

  return (
    <div style={{ display: "flex", flexDirection: "column-reverse", margin: 3 }}>
      <Button
        title={"Forhåndsvis tilleggsprodukt"}
        variant="contained"
        component={Link}
        size="small"
        className={`${styles.previewAddonButton}`}
        sx={{ marginTop: 1 }}
        to={`/preview/Tilleggsprodukt/${id}`}
        target="_blank"
      >
        Tillegg
      </Button>

      {(thisTag.includes(checkGroupTag) || thisTag.includes(radioGroupTag)) && (
        <Button
          title={"Forhåndsvis tilleggsproduktgruppe"}
          variant="contained"
          component={Link}
          size="small"
          className={`${styles.previewAddonButton}`}
          to={`/preview/TilleggsproduktGruppe/${thisTag}`}
          target="_blank"
        >
          Gruppe
        </Button>
      )}
    </div>
  );
}
