import { createClient } from "@sanity/client";

import imageUrlBuilder from "@sanity/image-url";
import groq from "groq";

export const client = createClient({
  projectId: "fyoxk55p",
  dataset: "production",
  useCdn: true, // set to `false` to bypass the edge cache
  apiVersion: "2023-05-03" // use current date (YYYY-MM-DD) to target the latest API version
});

export async function getStep(slug) {
  const posts = await client.fetch(
    groq`*[_type == "step" && slug.current == $slug][0]{title, lede, "imageUrl": image.asset->url, ...}`,
    { slug: slug }
  );
  return posts;
}

export async function getConsentForm(slug) {
  const consentForm = await client.fetch(
    groq`*[_type == "consentForm" && slug.current == $slug  && !(_id in path('drafts.**'))][0]{title, slug, customerSegmentPicker, brandPicker, _key, _type, content[]{..., markDefs[]{..., _type == "fileAnnotation" => {"href": fileField.asset->url} , _type == "linkAnnotation" => {...}}}}`,
    { slug: slug }
  );
  return consentForm;
}

export async function getAllConsentForms() {
  const allConsentForms = await client.fetch(
    groq`*[_type == "consentForm"  && !(_id in path('drafts.**'))]{title, slug, customerSegmentPicker, brandPicker, _key, _type, content[]{..., markDefs[]{..., _type == "fileAnnotation" => {"href": fileField.asset->url} , _type == "linkAnnotation" => {...}}}}`
  );
  return allConsentForms;
}

export const additionalSales = {
  fkasQuery: groq`*[_type == "fkas.additionalSales" && !(_id in path('drafts.**'))] | order(orderRank)`,
  tkasQuery: groq`*[_type == "tkas.additionalSales" && !(_id in path('drafts.**'))] | order(orderRank)`,
  mobileQuery: groq`*[_type == "mobile.additionalSales" && !(_id in path('drafts.**'))] | order(orderRank)`,
  fkasBedriftQuery: groq`*[_type == "fkasBedrift.additionalSales" && !(_id in path('drafts.**'))] | order(orderRank)`,
  tkasBedriftQuery: groq`*[_type == "tkasBedrift.additionalSales" && !(_id in path('drafts.**'))] | order(orderRank)`
};

export const additionalSalesByIds = (ids) =>
  groq`*[_id in ["${(ids ?? []).join('","')}"]] | order(orderRank)`;

const builder = imageUrlBuilder(client);

export function urlFor(source) {
  return builder.image(source);
}
