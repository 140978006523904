export function GetOrderAddress(order) {
  return order.elhubData ? order.elhubData.address : order.powerSaleData.address;
}
export function GetOrderZipCode(order) {
  return order.elhubData ? order.elhubData.zipCode : order.powerSaleData.zipCode;
}
export function GetOrderCity(order) {
  return order.elhubData ? order.elhubData.city : order.powerSaleData.city;
}
export function GetOrderMeterNumber(order) {
  return order.elhubData ? order.elhubData.meterNumber : order.powerSaleData.meterNumber;
}

export function IsBedrift(salesTypeId) {
  if (!salesTypeId) {
    return false;
  }
  return salesTypeId === 2 || salesTypeId === 3;
}

export function IsAddonOrder(productTypeId) {
  return productTypeId === 3;
}

export function IsForutsigbarBetaling(productHubId) {
  return productHubId === 917 || productHubId === 918;
}
