import { getProgress, pages } from "../../Utils/CustomerFormHelper";
import { SALE_CONTEXT } from "../Sagas/SaleSaga";

export const CurrentProgressSelector = ({ pageStepState, saleState }, { currentStep }) => {
  const { steps } = pageStepState;
  const isBedrift = saleState.form.saleContext === SALE_CONTEXT.bedrift;

  if (isBedrift && currentStep) {
    currentStep = pages.bedrift + currentStep;
  }

  if (!steps) {
    return { pageStepProgress: 100 };
  }
  return {
    pageStepProgress: getProgress(steps, currentStep) // currentStep can be null
  };
};

export const StepsSelector = ({ pageStepState }) => {
  const { steps, userSteps, deliveryPointSteps } = pageStepState;
  return { steps };
};
