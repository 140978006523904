import {
  SUBMIT_TOBB_FORM,
  SUBMIT_TOBB_FORM_SUCCESS,
  SUBMIT_TOBB_FORM_ERROR,
  SET_FORM,
  SET_TOBB_ID
} from "./TobbActionTypes";
import { SET_TRUMF_ID } from "../TrumfActions/TrumfActionTypes";

export const submitTobbForm = (form, tobbId) => ({
  type: SUBMIT_TOBB_FORM,
  form,
  tobbId
});

export const submitTobbFormSuccess = () => ({
  type: SUBMIT_TOBB_FORM_SUCCESS
});

export const submitTobbFormError = (error) => ({
  type: SUBMIT_TOBB_FORM_ERROR,
  error
});

export const setTobbId = (tobbId) => ({
  type: SET_TOBB_ID,
  tobbId
});
