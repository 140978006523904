import React from "react";
import styles from "./SelectDeliveryPoints.module.scss";
import { IsFKTheme } from "../../../Utils/Themes";
import Icon from "../../Icon/Icon";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import * as _ from "lodash";
import WarningIcon from "@mui/icons-material/Warning";
import BlockOutlinedIcon from "@mui/icons-material/BlockOutlined";
import { productInfoSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import { connect } from "react-redux";
import CheckBoxV2 from "../../CheckBox/CheckBoxV2";
import moment from "moment";

function DeliveryPointOption({ deliveryPoint, priceAreaTags, productName, isBedrift }) {
  // TODO: fix opp i dette når det er tid
  const emptyStartDate =
    deliveryPoint?.moveInDate === undefined || deliveryPoint?.moveInDate === null;
  /*const handleCheck = (e) => {
        if(emptyStartDate) {
            handleEdit(e);
        } else {
            handleSelect();
        }
    }*/
  const handleSelect = () => {
    saleActionDispatchers.selectDeliveryPoint(deliveryPoint);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    /*if(e !== null && e !== undefined) {
            e.stopPropagation();
        }*/
    if (isBedrift) {
      saleActionDispatchers.startDeliveryPointStepsBedrift(deliveryPoint.id);
    } else {
      saleActionDispatchers.startDeliveryPointSteps(deliveryPoint.id);
    }
  };
  const zipCode =
    deliveryPoint.elhubData && !isBedrift
      ? deliveryPoint.elhubData.addressV1.zipCode
      : deliveryPoint.zipCode;
  const address =
    deliveryPoint.elhubData && !isBedrift
      ? `${deliveryPoint.elhubData.addressV1.streetName} ${deliveryPoint.elhubData.addressV1.buildingNumber}${deliveryPoint.elhubData.addressV1.buildingLetter || ""}`
      : deliveryPoint.address;
  const city =
    deliveryPoint.elhubData && !isBedrift
      ? deliveryPoint.elhubData.addressV1.city
      : deliveryPoint?.city?.toUpperCase();
  const meterNr = deliveryPoint.isSkipped
    ? "mangler"
    : (deliveryPoint.elhubData && deliveryPoint.elhubData.meterIdentification) ||
      deliveryPoint.meterIdentification ||
      "ikke funnet";
  const meterPointId =
    isBedrift && deliveryPoint.elhubData && deliveryPoint.elhubData?.meteringPointIdentification;
  const invalidProduct =
    !_.isEmpty(priceAreaTags) && !priceAreaTags.find((p) => p === deliveryPoint.priceArea);

  const content = (
    <div>
      {deliveryPoint.productName && (
        <div className={styles.productInfo}>
          <div className={styles.productChange}>
            <div className={styles.productName}>Aktiv avtale: {deliveryPoint.productName}</div>
          </div>
        </div>
      )}
      <hgroup className={`title ${styles.checkHeader}`}>
        <h4>{address}</h4>
        <h4>{`${zipCode} ${city}`}</h4>
      </hgroup>
      <div className={styles.grayDiv}>Målernr: {meterNr}</div>
      {isBedrift && <div className={styles.grayDiv}>MålepunktId: {meterPointId}</div>}
      {deliveryPoint.complete && (
        <div className={styles.grayDiv}>
          Startdato:{" "}
          {deliveryPoint.moveInDate
            ? moment(deliveryPoint.moveInDate).format("DD.MM.YYYY").toString()
            : "Om 14 dager"}
        </div>
      )}
      {deliveryPoint.productName && (
        <div className={styles.alreadyCustomerText}>
          <Icon icon={"check"} className={styles.checkIcon} />
          Du er allerede kunde på denne adressen
        </div>
      )}
    </div>
  );

  return (
    <div className={`${IsFKTheme && styles.containerFK}`}>
      {invalidProduct ? (
        <div className={`whiteBox ${styles.check} ${styles.warningBox}`}>
          <div className={styles.warningHeader}>
            <WarningIcon
              color={"primary"}
              className={`${styles.warning} ${styles.warningHeaderIcon}`}
            />
            <div className={styles.warningHeaderText}>
              Dette produktet kan ikke bestilles i dette området.
            </div>
          </div>
          <div className={styles.checkContent}>
            <div className={styles.warningBoxContent}>
              <div className={styles.checkBox}>
                <BlockOutlinedIcon color={"primary"} className={styles.blockIcon} />
              </div>
            </div>
            {content}
          </div>
        </div>
      ) : deliveryPoint.complete || isBedrift ? (
        <CheckBoxV2
          checked={deliveryPoint.selected}
          onClick={handleSelect}
          className={
            styles.checkBoxV2 +
            " " +
            (isBedrift &&
            !deliveryPoint.meterIdentification &&
            !deliveryPoint.elhubData?.meterIdentification &&
            !deliveryPoint.isSkipped
              ? styles.warningBox
              : "")
          }
        >
          <div className={styles.checkContent}>{content}</div>
          {!(isBedrift && deliveryPoint.isSkipped) &&
            !(
              isBedrift &&
              (deliveryPoint.meterIdentification || deliveryPoint.elhubData?.meterIdentification)
            ) && (
              <div tabIndex={0} className={`${styles.editButton}`} onClick={handleEdit}>
                <Icon className={styles.editIcon} icon={"pencil"} />
                {isBedrift && !deliveryPoint.meterIdentification && !deliveryPoint.isSkipped
                  ? "Legg til målernummer eller fortsett uten"
                  : "Endre"}
              </div>
            )}
        </CheckBoxV2>
      ) : (
        <div>
          <button className={`whiteBox ${styles.check}`} onClick={handleEdit}>
            <div className={styles.checkContent}>
              {content}
              <Icon className={IsFKTheme ? styles.chevron : styles.chevronTK} icon={"chevron"} />
            </div>
          </button>
        </div>
      )}
    </div>
  );
}

export default connect(productInfoSelector)(DeliveryPointOption);
