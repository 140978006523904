import React, { useEffect } from "react";
import styles from "../SelectDeliveryPoints.module.scss";
import saleActionDispatchers from "../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import { deliveryPointsSelector } from "../../../../ReduxModules/Selectors/SaleSelectors";
import AddIcon from "@mui/icons-material/Add";
import * as _ from "lodash";
import FormPageContainer from "../../FormPageContainer/FormPageContainer";
import { IsFKTheme } from "../../../../Utils/Themes";
import { pages, pageStepAnimationDuration } from "../../../../Utils/CustomerFormHelper";
import DeliveryPointOption from "../DeliveryPointOption";
import House from "../../../../assets/Images/FK/HouseFramed.svg";
import Finnmaler from "../../../../assets/Images/Bedrift/Finnmaler.svg";
import pageStepActionDispatchers from "../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import Loader from "../../FKLoader/Loader";
import { CSSTransition } from "react-transition-group";

function DeliveryPoints({
  deliveryPoints,
  isLoading,
  initializing,
  initialized,
  onConfirm,
  onBack,
  priceAreaTags,
  activeDeliveryPoints,
  isBedrift
}) {
  const hidePage =
    !isBedrift &&
    !initializing &&
    (_.isEmpty(deliveryPoints) ||
      (deliveryPoints.length === 1 && !deliveryPoints.some((dp) => dp.elhubData || dp.complete)));
  //const hideGåVidereWarning = !deliveryPoints.some(dp => dp.selected) || (isBedrift && deliveryPoints.some(dp => dp.selected && !dp.meterIdentification && !dp.isSkipped && !dp.elhubData?.meterIdentification)) || deliveryPoints.some(dp => dp.selected && priceAreaTags.length > 0 && !priceAreaTags.includes(dp.priceArea))
  const hideGåVidereWarning =
    !deliveryPoints.some((dp) => dp.selected) ||
    (isBedrift &&
      deliveryPoints.some(
        (dp) =>
          dp.selected &&
          !dp.meterIdentification &&
          !dp.isSkipped &&
          !dp.elhubData?.meterIdentification
      )) ||
    deliveryPoints.some(
      (dp) => dp.selected && priceAreaTags.length > 0 && !priceAreaTags.includes(dp.priceArea)
    );

  useEffect(() => {
    if (!initializing && !initialized) {
      saleActionDispatchers.initializeDeliveryPoints();
    } else if (hidePage) {
      handleEdit(!_.isEmpty(deliveryPoints) && deliveryPoints[0], true);
    }
  }, []);

  useEffect(() => {
    if (!initialized && !initializing) {
      saleActionDispatchers.initializeDeliveryPoints();
    }
    if (initialized && !initializing) {
      if (hidePage) {
        handleEdit(!_.isEmpty(deliveryPoints) && deliveryPoints[0], true);
      }
    }
  }, [initialized, initializing]);

  useEffect(() => {
    //Hack to fix issue where auto-selected delivery points can't be unselected because they have the wrong priceArea and are unclickable
    deliveryPoints.map((dp) => {
      if (priceAreaTags.length > 0 && !priceAreaTags.includes(dp.priceArea)) dp.selected = false;
    });
  }, [deliveryPoints]);

  const handleEdit = (deliveryPoint, replace = false) => {
    const id = deliveryPoint && deliveryPoint.id;
    saleActionDispatchers.startDeliveryPointSteps(id, replace);
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname);
    }
  };

  const loader = isLoading || initializing;

  return (
    <>
      <CSSTransition
        in={loader || hidePage}
        timeout={pageStepAnimationDuration}
        classNames={"formPage-"}
        mountOnEnter
        unmountOnExit
      >
        <FormPageContainer hideFooter className={"loaderContainer"}>
          <div className={"loader slide"}>
            <Loader text={"Finner adresse..."} />
          </div>
        </FormPageContainer>
      </CSSTransition>
      <CSSTransition
        in={!loader && !hidePage}
        timeout={pageStepAnimationDuration}
        classNames={"formPage-"}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <FormPageContainer onBack={onBack}>
          <h1 className={"fallIn-"}>
            {isBedrift
              ? "Velg hvor du vil ha levert strøm"
              : "Velg adressen du vil bestille strøm til"}
          </h1>
          {IsFKTheme && (
            <div className={"frameImageContainer"}>
              <img
                className={"frameImage formPage-background fade-"}
                src={isBedrift ? Finnmaler : House}
                alt="House"
              />
            </div>
          )}
          <div className={"slide"}>
            <div style={{ marginTop: "1px" }}>
              {deliveryPoints &&
                deliveryPoints
                  .filter((dp) => dp.address || dp.elhubData)
                  .map((deliveryPoint, i) => (
                    <DeliveryPointOption
                      deliveryPoint={deliveryPoint}
                      priceAreaTags={priceAreaTags}
                      key={i}
                    />
                  ))}
              <button className={`btnBorder ${styles.stretchButton}`} onClick={handleEdit}>
                Legg til ny adresse <AddIcon className={styles.alignRight} />
              </button>
            </div>
            {/*{
                            hideGåVidereWarning &&
                            deliveryPoints.some(dp => dp.selected) ?
                            'Du må fylle inn målernummer eller bekrefte tom måler for alle valgte adresser for å gå videre' : 'Du må velge minst en adresse for å gå videre'
                        }*/}
            {hideGåVidereWarning && "Du må velge minst en adresse for å gå videre"}

            <button
              className={`btnPrimary fadeDownButton ${styles.nextButton}`}
              onClick={handleConfirm}
              disabled={
                !deliveryPoints.some((dp) => dp.selected) ||
                (isBedrift &&
                  deliveryPoints.some(
                    (dp) =>
                      dp.selected &&
                      !dp.meterIdentification &&
                      !dp.isSkipped &&
                      !dp.elhubData?.meterIdentification
                  )) ||
                deliveryPoints.some(
                  (dp) =>
                    dp.selected && priceAreaTags.length > 0 && !priceAreaTags.includes(dp.priceArea)
                )
              }
            >
              Gå videre
            </button>
          </div>
        </FormPageContainer>
      </CSSTransition>
    </>
  );
}

export default connect(deliveryPointsSelector)(DeliveryPoints);
