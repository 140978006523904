import React from "react";
import styles from "./SlidingTabButtons.module.scss";

function SlidingTabButtons({ buttons, onClick, className }) {
  const buttonWidth = 100 / (buttons?.length || 1);
  const currentPos = buttons.findIndex((b) => b.active);
  return (
    <div className={`${styles.buttonsContainer} ${className}`}>
      <div className={styles.buttonsInnerContainer}>
        {buttons &&
          buttons.map((b, i) => (
            <button
              key={i}
              className={`${styles.tabButton} ${b.active && styles.tabButtonActive}`}
              style={{ width: `${buttonWidth}%` }}
              onClick={() => onClick(i)}
            >
              {b.text}
            </button>
          ))}
      </div>
      <div className={styles.buttonInnerContainerBackground}>
        <div
          className={styles.tabButtonBackground}
          style={{ transform: "translateX(" + currentPos * 100 + "%)", width: `${buttonWidth}%` }}
        />
      </div>
    </div>
  );
}

export default SlidingTabButtons;
