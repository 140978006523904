import { matchPath, Route, useLocation } from "react-router-dom";
import React from "react";

function TransitionRoute(props) {
  const location = useLocation();
  const isTransitioning = !matchPath(location.pathname, { path: props.path, exact: props.exact });
  const handleKeyDown = (e) => {
    if (e.keyCode === 9) {
      // prevents user from being focus trapped inside component while transitioning.
      return;
    }
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <div onKeyDown={isTransitioning ? handleKeyDown : null}>
      <Route {...props} />
    </div>
  );
}

export default TransitionRoute;
