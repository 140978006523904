import React, { useState } from "react";
import { loginRedirect } from "./TrumfStuff";
import { connect } from "react-redux";
import { stateSelector } from "../../ReduxModules/Selectors/SaleSelectors";
import styles from "./Trumf.module.scss";
import { IsFKTheme } from "../../Utils/Themes";
import Trumf from "../../assets/Images/FK/TrumfFramed.svg";
import Loader from "../CustomerForm/FKLoader/Loader";
import FormPageContainer from "../CustomerForm/FormPageContainer/FormPageContainer";
import pageStepActionDispatchers from "../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { getTrumfProductId } from "../../Utils/AdditionalProductHelper";
import saleActionDispatchers from "../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";

function TrumfLogin({ state, onBack, formPageAnimation }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isOptional = state.saleState.form.recommendedProducts.find(
    (rp) => rp.id === getTrumfProductId()
  ).optional;
  const trumfLogin = async () => {
    try {
      setLoading(true);
      onbeforeunload = null;
      await loginRedirect(state);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };
  const onSkip = () => {
    if (isOptional) {
      saleActionDispatchers.selectAddonProduct(getTrumfProductId(), true);
    }
    pageStepActionDispatchers.goToNextStep(window.location.pathname);
  };
  const onNo = () => {
    if (isOptional) {
      saleActionDispatchers.selectAddonProduct(getTrumfProductId(), false);
    }
    pageStepActionDispatchers.goToNextStep(window.location.pathname);
  };
  return (
    <FormPageContainer onBack={onBack} hideFooter={loading}>
      {loading ? (
        <Loader text={"Vent mens vi sender deg til trumf..."} />
      ) : (
        <>
          <div className={`${formPageAnimation && "fallIn-"}`}>
            {error ? (
              <div className={`${styles.error}`}>
                <h1>Noe gikk galt med innloggingen din.</h1>
                <h5>Prøv igjen eller kontakt kundeservice...</h5>
              </div>
            ) : (
              <div>
                <h1>Logg inn på Trumf for å registrere din bonus.</h1>
              </div>
            )}
          </div>
          {IsFKTheme && (
            <div className={"frameImageContainer"}>
              <img
                className={"frameImage formPage-background " + formPageAnimation && "fade-"}
                src={Trumf}
                alt={"trumf"}
              />
            </div>
          )}
          <div className={formPageAnimation ? "slide" : ""}>
            <div className={styles.text}>Få 1% Trumf-bonus på strømforbruket ditt hver måned.</div>
            <button className={"btnPrimary " + styles.button} onClick={trumfLogin}>
              Logg inn på trumf.no
            </button>
            <button className={`btnUnderline ${styles.skipButton}`} onClick={onSkip}>
              Hopp over og minn meg på det senere
            </button>
            {isOptional && !error && (
              <button className={`btnUnderline ${styles.skipButton}`} onClick={onNo}>
                Nei, takk
              </button>
            )}
          </div>
        </>
      )}
    </FormPageContainer>
  );
}

export default connect(stateSelector)(TrumfLogin);
