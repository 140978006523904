import React, { useState } from "react";
import styles from "./ManualIdentification.module.scss";
import SimpleInput from "../../SimpleInput/SimpleInput";
import Identifisering from "../../../assets/Images/Bedrift/Identifisering.svg";
import { IsFKTheme } from "../../../Utils/Themes";
import validateFirstPage from "../../CustomerForm/Validate/ValidateFirstPage";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import { formSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { pageStepAnimationDuration } from "../../../Utils/CustomerFormHelper";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";

function BedriftContactPerson({ onConfirm, form, isMobile }) {
  const { phone, firstName, lastName, email } = form;
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [fullName, setFullName] = useState(firstName && lastName ? firstName + " " + lastName : "");
  const validInput = fullName && fullName.length > 3;
  const isValidInput = phone.replace(/\s+/g, "").length === 8;

  const handleChange = (e) => {
    saleActionDispatchers.updateFormAction({ [e.target.name]: e.target.value });
  };

  const handleName = (e) => {
    setFullName(e.target.value);
    const split = e.target.value.split(" ");
    saleActionDispatchers.updateFormAction({ firstName: split.slice(0, -1).join(" ") });
    if (split.length > 1) {
      saleActionDispatchers.updateFormAction({ lastName: split[split.length - 1] });
    }
  };

  const handleEmail = (e) => {
    saleActionDispatchers.updateFormAction({ [e.target.name]: e.target.value });
    saleActionDispatchers.updateFormAction({ invoiceEmail: e.target.value });
  };

  const handleConfirm = () => {
    if (!validInput) {
      setValidationError("*Vennligst skriv inn et gyldig navn.");
      return;
    }
    const errorMessage = validateFirstPage(form, false, true);
    if (!errorMessage) {
      if (!onConfirm) {
        pageStepActionDispatchers.goToNextStep(window.location.pathname);
      } else {
        onConfirm();
      }
    } else {
      setError(errorMessage);
    }
  };

  return (
    <FormPageContainer className={IsFKTheme ? styles.containerFK : ""}>
      <div>
        <div className={styles.imgContainer}>
          <img
            className={styles.mascot}
            src={Identifisering}
            alt="mascot"
            style={{ marginLeft: "45px" }}
          />
        </div>
        <header className={`${styles.header} fallIn-`}>
          <h1>Hvem skal være kontaktperson?</h1>
        </header>
        <div className={"slide"}>
          <SimpleInput
            className={styles.field}
            type={"name"}
            name={"name"}
            value={fullName}
            label={"Navn til kontaktperson"}
            onChange={handleName}
            placeholder={"Kontaktperson"}
            showWhiteBox
            autofocus
            focusDelay={pageStepAnimationDuration}
            validationErrorMessage={validationError}
          />
          <SimpleInput
            className={styles.field}
            type={"phone"}
            name={"phone"}
            value={phone}
            label={"Telefonnummer"}
            onChange={handleChange}
            placeholder={"Ditt nummer"}
            showWhiteBox
          />
          <SimpleInput
            className={styles.field}
            type={"email"}
            name={"email"}
            value={email}
            label={"E-post"}
            onChange={handleEmail}
            placeholder={"Din e-postadresse"}
            showWhiteBox
          />
          <div className={styles.alert}>{error}</div>
          <button
            className={"btnPrimary " + styles.btn}
            disabled={!isValidInput}
            onClick={handleConfirm}
          >
            Bekreft
          </button>
        </div>
      </div>
    </FormPageContainer>
  );
}

export default connect(formSelector)(BedriftContactPerson);
