import React, { useEffect, useLayoutEffect, useState } from "react";
import { getQueryStrings } from "../../Utils/UrlParser";
import config from "../../config";
import { Route, Switch } from "react-router-dom";
import saleActionDispatchers from "../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { history } from "../../App";
import Preloader from "../Preloader/Preloader";
import { isNextPowerStep, pages, pageStepAnimationDuration } from "../../Utils/CustomerFormHelper";
import { connect } from "react-redux";
import { customerFormSelector } from "../../ReduxModules/Selectors/SaleSelectors";
import TrumfPage from "../Trumf/TrumfPage";
import ErrorPage from "../ErrorPage/ErrorPage";
import FormPageContainer from "./FormPageContainer/FormPageContainer";
import Name from "./Name/Name";
import Loader from "./FKLoader/Loader";
import Email from "./Email/Email";
import Summary from "./Summary/Summary";
import Terms from "./Terms/Terms";
import SubscriptionConsent from "./SubscriptionConsent/SubscriptionConsent";
import Receipt from "./Receipt/Receipt";
import SecretProductNameAndId from "./SecretProductNameAndId";
import BankIdLogin from "./Identification/BankId/BankIdLogin";
import ManualIdentification from "./Identification/ManualIdentification/ManualIdentification";
import Identification from "./Identification/Identification";
import Phone from "./Phone/Phone";
import InvoiceMenuPage from "./Invoice/InvoiceMenuPage";
import InvoiceDetails from "./Invoice/InvoiceDetails";
import SASBonus from "./SASBonus/SASBonus";
import Cookies from "js-cookie";
import { StepsSelector } from "../../ReduxModules/Selectors/PageStepSelectors";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import DeliveryPoints from "./DeliveryPoints/DeliveryPointsV2/DeliveryPoints";
import AddDeliveryPoint from "./DeliveryPoints/DeliveryPointsV2/AddDeliveryPoint";
import AddonProductContainer from "./AddonProduct/AddonProductContainer";
import productActionDispatchers from "../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import {IsFKTheme, IsGETheme} from "../../Utils/Themes";
import TransitionRoute from "../TransitionRoute/TransitionRoute";
import Tobb from "../TOBB/Tobb";
import PriceSummary from "./PriceSummary/PriceSummary";
import PriceSummaryMobile from "./PriceSummary/PriceSummaryMobile";
import PowerSmartStep from "../FormPages/PowerSmart/PowerSmartStep";

const cookeDomainFK = ".fjordkraft.no";
const cookeDomainTK = ".trondelagkraft.no";
function CustomerForm({
  location,
  initialized,
  submitSuccessful,
  criticalError,
  recommendedProducts
}) {
  const [prevLocation, setPrevLocation] = useState(null);
  const [next, setNext] = useState(true); // used for tracking next/back animations
  const [nextTimeout, setNextTimeout] = useState();
  const keyPath = location.pathname;
  useEffect(() => {
    let queryString = getQueryStrings(location.search);

    const coid = Cookies.get("coid", { domain: IsFKTheme ? cookeDomainFK : cookeDomainTK });
    const referralId = Cookies.get("referrer_id", {
      domain: IsFKTheme ? cookeDomainFK : cookeDomainTK
    });
    const utmSource = Cookies.get("utm_source", {
      domain: IsFKTheme ? cookeDomainFK : cookeDomainTK
    });
    const utmMedium = Cookies.get("utm_medium", {
      domain: IsFKTheme ? cookeDomainFK : cookeDomainTK
    });
    if (coid) {
      saleActionDispatchers.updateFormAction({ coid });
    }
    if (referralId) {
      let storeId = 871;
      saleActionDispatchers.updateFormAction({ referralId });
      saleActionDispatchers.updateFormAction({ storeId });
    }
    // add PriceElement for extra product if added from url encoder
    if (queryString?.recommendedProducts?.some((p) => p.priceElement)) {
      saleActionDispatchers.updateFormAction({
        extraProductPriceElement: queryString.recommendedProducts.find((p) => p.priceElement)
          .priceElement
      });
    }
    if (utmSource || utmMedium) {
      saleActionDispatchers.updateFormAction({ utmMedium, utmSource });
    }

    fetchData(queryString);
    if (process.env.NODE_ENV !== "development") {
      onbeforeunload = (e) =>
        `Endringene dine vil bli slettet. Er du sikker på at du vil forlate siden?`;
      return () => {
        onbeforeunload = null;
        if (nextTimeout) {
          clearTimeout(nextTimeout);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    for (let r of recommendedProducts) productActionDispatchers.getAddonProductInfo(r.id);
  }, [recommendedProducts]);

  async function fetchData(queryString) {
    if (!initialized) {
      saleActionDispatchers.parseQueryStringAction(queryString);
      saleActionDispatchers.getAddonProductAction(IsGETheme ? "GUEN" : config.theme + "AS", "P");
    }
  }

  useLayoutEffect(() => {
    if (nextTimeout) {
      clearTimeout(nextTimeout);
    }
    const isNext =
      prevLocation?.pathname === keyPath ||
      isNextPowerStep(prevLocation?.pathname, keyPath, recommendedProducts);
    setNext(isNext);
    if (!isNext) {
      setNextTimeout(setTimeout(() => setNext(true), pageStepAnimationDuration));
    }
    setPrevLocation(location);
  }, [location]);

  if (submitSuccessful && keyPath !== pages.receipt) {
    history.push(pages.receipt);
  }

  return (
    <>
      <Preloader />
      <SecretProductNameAndId />
      {!initialized ? (
        <FormPageContainer hideFooter className={"loaderContainer"}>
          <div className={"loader slide"}>
            <Loader text={"Vent mens vi gjør ting klart for deg..."} />
          </div>
        </FormPageContainer>
      ) : (
        <>
          {criticalError ? (
            <ErrorPage />
          ) : (
            <TransitionGroup className={next ? "formPage-next-" : "formPage-back-"}>
              <CSSTransition
                key={keyPath}
                timeout={pageStepAnimationDuration}
                classNames={"formPage-"}
                unmountOnExit
                mountOnEnter
              >
                <Switch location={location}>
                  <TransitionRoute exact path={pages.home} component={Identification} />
                  <TransitionRoute
                    exact
                    path={pages.manualIdentification}
                    component={ManualIdentification}
                  />
                  <TransitionRoute exact path={pages.customerName} component={Name} />
                  <TransitionRoute exact path={pages.bankIdLogin} component={BankIdLogin} />
                  <TransitionRoute
                    exact
                    path={pages.addonProduct}
                    component={AddonProductContainer}
                  />
                  <TransitionRoute exact path={pages.mobileNumber} component={Phone} />
                  <TransitionRoute exact path={pages.deliveryPoints} component={DeliveryPoints} />
                  <TransitionRoute path={pages.powerSmart} component={PowerSmartStep} />
                  <TransitionRoute exact path={pages.summary} component={Summary} />
                  <TransitionRoute exact path={pages.invoice} component={InvoiceMenuPage} />
                  <TransitionRoute exact path={pages.invoiceDetails} component={InvoiceDetails} />
                  <TransitionRoute exact path={pages.customerEmail} component={Email} />
                  <TransitionRoute exact path={pages.tobbId} component={Tobb} />
                  <TransitionRoute exact path={pages.priceSummary} component={PriceSummary} />

                  <TransitionRoute
                    exact
                    path={pages.subscription}
                    component={SubscriptionConsent}
                  />
                  <TransitionRoute exact path={pages.receipt} component={Receipt} />
                  <TransitionRoute path={pages.deliveryPointV2} component={AddDeliveryPoint} />
                  <TransitionRoute path={pages.trumf} component={TrumfPage} />
                  <TransitionRoute path={pages.sasEuroBonus} component={SASBonus} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        </>
      )}
    </>
  );
}

export default connect(StepsSelector)(connect(customerFormSelector)(CustomerForm));
