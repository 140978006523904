import React from "react";
import { Route, Switch } from "react-router-dom";
import Trumf from "./Trumf";
import { pages } from "../../Utils/CustomerFormHelper";
import TrumfError from "./TrumfError";
import TransitionRoute from "../TransitionRoute/TransitionRoute";
import pageStepActionDispatchers from "../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";

function TrumfPage({ onBack, onNext }) {
  function goNext() {
    if (onNext) {
      onNext();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname);
    }
  }

  function goBack() {
    if (onBack) {
      onBack();
    } else {
      pageStepActionDispatchers.goToPreviousStep(window.location.pathname);
    }
  }

  return (
    <Switch>
      <TransitionRoute
        exact
        path={pages.trumf}
        render={() => <Trumf onNext={goNext} onBack={goBack} formPageAnimation />}
      />
      <TransitionRoute
        exact
        path={pages.trumfError}
        render={() => <TrumfError onBack={goBack} />}
        formPageAnimation
      />
    </Switch>
  );
}

export default TrumfPage;
