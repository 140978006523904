import React from "react";
import { IsFKTheme } from "../../../Utils/Themes";
import styles from "./Receipt.module.scss";

const BonusReceiptBox = ({ preHeader, title, description, imageComponent, styleClasses }) => {
  return (
    <div
      className={`whiteBox ${styles.summary} ${styles.bonusContent} ${!IsFKTheme && styles.bonusContentTk}`}
    >
      {imageComponent}
      <div className={styles.grayText}>{preHeader}</div>
      <h2 className={styles.productName}>{title}</h2>
      <div className={styles.blackText}>{description}</div>
    </div>
  );
};

export default BonusReceiptBox;
