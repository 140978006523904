export default async function IframeHelper(orderId) {
  async function getFrameToken() {
    const responseOrder = orderId[0];
    buildAndSendToken(responseOrder);
  }

  function buildAndSendToken(order) {
    let result_status = "failed";
    let result_order = null;

    if (order != null) {
      result_status = "success";
      result_order = order.customer?.contactInfo?.email;
    }

    let message = { result: result_status, email: result_order };
    let message_string = JSON.stringify(message);

    window.parent.postMessage(JSON.parse(message_string), "*");
  }

  await getFrameToken();
}
