import React, { useEffect } from "react";
import { loginRedirect } from "../TrumfStuff";
import Loader from "../../CustomerForm/FKLoader/Loader";
import FormPageContainer from "../../CustomerForm/FormPageContainer/FormPageContainer";
import { queryStringSelector } from "../../../ReduxModules/Selectors/TrumfSelectors";
import { connect } from "react-redux";
import trumfActionDispatchers from "../../../ReduxModules/Actions/TrumfActions/TrumfActionDispatchers";

function AddTrumfProductLogin({ momentId, redirectUri, mdmId }) {
  useEffect(() => {
    trumfLogin();
  }, []);
  const trumfLogin = async () => {
    try {
      onbeforeunload = null;
      await loginRedirect({ addTrumfProduct: true, momentId, redirectUri, mdmId });
    } catch (e) {
      trumfActionDispatchers.setError(e);
    }
  };
  return (
    <FormPageContainer hideFooter={true}>
      <Loader text={"Vent mens vi sender deg til trumf..."} />
    </FormPageContainer>
  );
}

export default connect(queryStringSelector)(AddTrumfProductLogin);
