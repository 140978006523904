import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import * as AxiosFunction from "../../Utils/AxiosFunctions";
import {
  GET_SMS_ACCEPT_ADDON_PRODUCTS,
  GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT,
  SUBMIT_SMS_ACCEPT_FORM,
  GET_SMS_ACCEPT_CUSTOMER_INFO,
  SMS_ACCEPT_SUBMIT_CONSENT,
  GET_PRICE_SUMMARY,
  GET_PRICE_SUMMARY_SUCCESS,
  GET_PRICE_SUMMARY_ERROR
} from "../Actions/SmsAcceptActions/SmsAcceptActionTypes";
import {
  getSmsAcceptAddonProductsSuccess,
  getSmsAcceptAddonProductsError,
  getSmsAcceptExtraProductTextSuccess,
  getSmsAcceptExtraProductTextError,
  submitSmsAcceptFormSuccess,
  getSmsAcceptCustomerInfoSuccessAction,
  getSmsAcceptCustomerInfoErrorAction,
  submitSmsAcceptFormError,
  smsAcceptSubmitConsentError,
  smsAcceptSubmitConsentSuccess,
  getPriceSummarySuccess,
  getPriceSummaryError
} from "../Actions/SmsAcceptActions/SmsAcceptActions";

function* getPriceSummary(action) {
  try {
    const priceSummary = yield call(
      AxiosFunction.getPriceSummarys,
      action.productHubId,
      action.extraProducts,
      action.id
    );
    yield put(getPriceSummarySuccess(action.id, priceSummary));
  } catch (e) {
    yield put(getPriceSummaryError(action.id, e));
  }
}

export function* watchGetPriceSummary() {
  yield takeEvery(GET_PRICE_SUMMARY, getPriceSummary);
}

function* getSmsAcceptAddonProducts(action) {
  try {
    const addonProducts = yield call(
      AxiosFunction.getAddonProducts,
      action.brand,
      action.customerType
    );
    yield put(getSmsAcceptAddonProductsSuccess(addonProducts));
  } catch (e) {
    yield put(getSmsAcceptAddonProductsError(e));
  }
}

export function* watchGetSmsAcceptAddonProducts() {
  yield takeLatest(GET_SMS_ACCEPT_ADDON_PRODUCTS, getSmsAcceptAddonProducts);
}

function* getSmsAcceptExtraProductText(action) {
  try {
    const productText = yield call(AxiosFunction.getProductInfo, action.productId);
    yield put(getSmsAcceptExtraProductTextSuccess(productText));
  } catch (e) {
    yield put(getSmsAcceptExtraProductTextError(e));
  }
}

export function* watchGetSmsAcceptExtraProductText() {
  yield takeLatest(GET_SMS_ACCEPT_EXTRA_PRODUCT_TEXT, getSmsAcceptExtraProductText);
}

function* submitSmsAcceptForm(action) {
  try {
    const smsAcceptForm = yield call(
      AxiosFunction.submitAcceptOrders,
      action.hashedCustomerId,
      action.orderIds,
      action.subscriptionConsent
    );
    yield put(submitSmsAcceptFormSuccess(smsAcceptForm));
  } catch (e) {
    yield put(submitSmsAcceptFormError(e));
  }
}

export function* watchSubmitSmsAcceptForm() {
  yield takeLatest(SUBMIT_SMS_ACCEPT_FORM, submitSmsAcceptForm);
}

function* getSmsAcceptCustomerInfo(action) {
  try {
    const customer = yield call(AxiosFunction.getHashedCustomer, action.hashedCustomerId);
    yield put(getSmsAcceptCustomerInfoSuccessAction(customer));
  } catch (e) {
    yield put(getSmsAcceptCustomerInfoErrorAction(e));
  }
}

export function* watchGetSmsAcceptCustomerInfo() {
  yield takeLatest(GET_SMS_ACCEPT_CUSTOMER_INFO, getSmsAcceptCustomerInfo);
}

function* submitConsent(action) {
  try {
    const result = yield call(
      AxiosFunction.submitConsent,
      action.hashedCustomerId,
      action.subscriptionConsent
    );
    yield put(smsAcceptSubmitConsentSuccess());
    // Currently we do not actually care whether this succeeds or not. Let OrderModule handle any errors.
  } catch (e) {
    yield put(smsAcceptSubmitConsentError(e));
  }
}

export function* watchSubmitConsent() {
  yield takeLatest(SMS_ACCEPT_SUBMIT_CONSENT, submitConsent);
}
