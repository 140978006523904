import styles from "./DeliveryPointMovingDate.module.scss";
import React from "react";
import saleActionDispatchers from "../../../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import FormPageContainer from "../../../FormPageContainer/FormPageContainer";
import { deliveryPointV2Selector } from "../../../../../ReduxModules/Selectors/SaleSelectors";
import { history } from "../../../../../App";
import { pages } from "../../../../../Utils/CustomerFormHelper";
import { IsFKTheme } from "../../../../../Utils/Themes";
import WhiteButtonList from "../../../../ButtonList/WhiteButtonList";
import Calendar from "../../../../../assets/Images/FK/CalendarFramed.svg";
import pageStepActionDispatchers from "../../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { useParams } from "react-router-dom";

function DeliveryPointMovingDate(props) {
  const { deliveryPoint } = props;
  const params = useParams();

  const handleMovingDateChange = (moveInDate, rightOfWithdrawal = true) => {
    if (moveInDate) {
      moveInDate.setHours(moveInDate.getHours() + 2);
    } else if (moveInDate === null) {
      moveInDate = new Date();
      moveInDate.setDate(moveInDate.getDate() + 14);
    }

    saleActionDispatchers.updateDeliveryPointAction(deliveryPoint.id, {
      moveInDate,
      complete: true,
      selected: true,
      rightOfWithdrawal
    });
    history.push(pages.deliveryPoints);
  };

  const handleBack = () => {
    if (deliveryPoint.elhubData) {
      history.push(pages.deliveryPoints);
    } else {
      history.push(pages.deliveryPointMeterNumber);
    }
  };
  return (
    <FormPageContainer onBack={handleBack} currentStep={pages.deliveryPoints}>
      <h2 className={`${styles.header} fallIn-`}>Når skal strømavtalen gjelde fra?</h2>
      {IsFKTheme && (
        <div className={"frameImageContainer"}>
          <img className={"frameImage formPage-background fade-"} src={Calendar} alt="kalender" />
        </div>
      )}
      <div className={"slide"}>
        <div className={styles.text}>
          Du har 14 dagers angrefrist. Velger du oppstart snarest mulig, dvs før angrefristen
          utløper, betaler du iht. Angrerettloven for strømmen du bruker fram til du eventuelt
          benytter angreretten.
        </div>
        <div className={styles.btnContainer}>
          <WhiteButtonList
            buttons={[
              {
                content: <div className={"title"}>Oppstart om 14 dager</div>,
                onClick: () => handleMovingDateChange(null)
              },
              {
                content: (
                  <div>
                    <div className={"title"}>Oppstart snarest mulig!</div>
                  </div>
                ),
                onClick: () => handleMovingDateChange(new Date(), false)
              },
              {
                content: <div className={"title"}>Velg en senere dato</div>,
                icon: "calendar",
                onClick: () =>
                  pageStepActionDispatchers.goToNextStep(window.location.pathname, params)
              }
            ]}
          />
        </div>
      </div>
    </FormPageContainer>
  );
}

export default connect(deliveryPointV2Selector)(DeliveryPointMovingDate);
