import React from "react";
import styles from "./PowerMeter.module.scss";
import { IsFKTheme } from "../../../../../Utils/Themes";
import FormPageContainer from "../../../FormPageContainer/FormPageContainer";
import TKPowerMeter from "../../../../../assets/Images/TK/PowerMeter.svg";
import WhiteButtonList from "../../../../ButtonList/WhiteButtonList";
import PowerMeter from "../../../../../assets/Images/FK/ElecrticBoxFramed.svg";
import { pages } from "../../../../../Utils/CustomerFormHelper";

function WherePowerMeter({ chooseOne, chooseTwo, onBack }) {
  return (
    <FormPageContainer onBack={onBack} currentStep={pages.deliveryPoints}>
      <div className={(IsFKTheme && styles.containerFK) || ""}>
        <header className={`${styles.header} fallIn-`}>
          <h1>Her kan du finne måleren din.</h1>
        </header>
        {IsFKTheme ? (
          <div className={"frameImageContainer"}>
            <img
              className={"frameImage formPage-background fade-"}
              src={PowerMeter}
              alt="PowerMeter"
            />
          </div>
        ) : (
          <img src={TKPowerMeter} alt={"Strømmåler"} className={styles.powerMeterTK} />
        )}
        <div className={"slide"}>
          <div className={styles.text}>
            Som regel kan du finne den i sikringsskapet inne i boligen, men i enkelte leiligheter
            kan sikringsskapet være plassert utenfor leiligheten.
          </div>
          <div className={styles.btnContainer}>
            <WhiteButtonList
              buttons={[
                chooseOne && {
                  content: <div className={"title"}>Fant den!</div>,
                  onClick: chooseOne
                },
                chooseTwo && {
                  content: <div className={"title"}>Er ikke hjemme nå.</div>,
                  onClick: chooseTwo
                }
              ]}
            />
          </div>
        </div>
      </div>
    </FormPageContainer>
  );
}

export default WherePowerMeter;
