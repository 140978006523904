import {
  SET_IS_LOADING_REFERENCE,
  SET_REFERENCE,
  SET_INVOICE_SELECTED_INDEX,
  SET_REFERENCE_FOUND
} from "./InvoiceActionTypes";

export const setSelectedIndex = (index) => {
  return {
    type: SET_INVOICE_SELECTED_INDEX,
    payload: index
  };
};

export const setIsLoadingReference = (isLoading) => {
  return {
    type: SET_IS_LOADING_REFERENCE,
    payload: isLoading
  };
};

export const setEInvoiceReference = (reference) => {
  return {
    type: SET_REFERENCE,
    payload: reference
  };
};

export const setEInvoiceReferenceFound = (found) => {
  return {
    type: SET_REFERENCE_FOUND,
    payload: found
  };
};
