import React, { useEffect, useState } from "react";
import styles from "./Email.module.scss";
import SimpleInput from "../../SimpleInput/SimpleInput";
import { IsFKTheme } from "../../../Utils/Themes";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import { formAndUserSelector, formSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { sanitizeProps } from "../../../Utils/HelperFunctions";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import MobileMascotGlasses from "../../../assets/Images/FK/Mobil/MobileMascotGlasses.svg";
import { pages, pageStepAnimationDuration } from "../../../Utils/CustomerFormHelper";
import FkMascot from "../../../assets/Images/FK/FKMascotFramed.svg";
import { useParams } from "react-router-dom";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function Email({ onConfirm, onBack, form, isActiveCustomer, isMobile, userId, user }) {
  const [registeredEmail, setRegisteredEmail] = useState(null);
  const email = userId ? user.email : form.email;
  const params = useParams();
  useEffect(() => {
    setRegisteredEmail(email);
  }, []);
  const handleChange = (e) => {
    if (userId) {
      saleActionDispatchers.updateUser(userId, { [e.target.name]: e.target.value });
    } else {
      saleActionDispatchers.updateFormAction({ [e.target.name]: e.target.value });
    }
  };

  const handleConfirm = () => {
    if (!userId) {
      saleActionDispatchers.updateFormAction(sanitizeProps(form));
    }
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
    }
  };

  const validInput = email && email.match(emailRegex); // todo: Maybe use useEffect for efficiency?
  const handleUseOwnerEmail = () => {
    saleActionDispatchers.updateUser(userId, { email: form.email });
  };
  return (
    <FormPageContainer
      className={`${styles.container} ${IsFKTheme && styles.containerFK}`}
      onBack={onBack}
      currentStep={userId ? pages.mobile + pages.summary : null}
    >
      <header className={`${styles.header} fallIn-`}>
        <h1>
          {userId
            ? `Har ${user.firstName} en e-post vi kan ta kontakt på?`
            : isActiveCustomer && registeredEmail
              ? "Dette er e-posten vi har registrert på deg"
              : "Har du en e-post vi kan nå deg på?"}
        </h1>
      </header>
      {isMobile ? (
        <div className={"frameImageContainer"}>
          <img
            className={"frameImage formPage-background fade-"}
            src={MobileMascotGlasses}
            alt={"mobileMascot"}
          />
        </div>
      ) : (
        IsFKTheme && (
          <div className={"frameImageContainer"}>
            <img
              className={"frameImage formPage-background fade-"}
              src={FkMascot}
              alt={"mobileMascot"}
            />
          </div>
        )
      )}
      <div className={"slide"}>
        {form.onlyLegalOwner && !userId && (
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            Vi trenger dette siden du skal stå som eier av abonnementet.
          </div>
        )}
        <SimpleInput
          className={styles.field}
          type={"text"}
          name={"email"}
          value={email}
          label={"E-post"}
          onChange={handleChange}
          placeholder={`${userId ? "" : "Din "}E-post adresse`}
          showWhiteBox
          autofocus
          focusDelay={pageStepAnimationDuration}
        />
        {userId && (
          <button className={`btnUnderline ${styles.copyButton}`} onClick={handleUseOwnerEmail}>
            Kopier fra eier av abonnementet
          </button>
        )}
        <button
          className={"btnPrimary " + styles.btn}
          disabled={!validInput}
          onClick={handleConfirm}
        >
          Bekreft
        </button>
      </div>
    </FormPageContainer>
  );
}

export default connect(formAndUserSelector)(Email);
