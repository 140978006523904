import React from "react";
import styles from "./ConfirmDateModal.module.scss";
import Icon from "../../../../../Icon/Icon";
import { IsFKTheme } from "../../../../../../Utils/Themes";
import FormPageContainer from "../../../../FormPageContainer/FormPageContainer";

function ConfirmDateModal({ show, onConfirm, onCancel, onClose }) {
  return show ? (
    <div className={styles.overlay}>
      <FormPageContainer hideFooter>
        <div className={styles.modal}>
          <Icon
            className={styles.smallCloseButton}
            icon={IsFKTheme ? "clear" : "tkClear"}
            onClick={onClose}
            type="button"
          />
          <h5 className={styles.text}>
            Når du velger oppstart før angrefristen utløper, betaler du iht. Angrerettloven for
            strømmen du bruker, fram til du eventuelt benytter angreretten.
          </h5>
          <button className={`btnPrimary ${styles.btnConfirm}`} onClick={onConfirm}>
            Bekreft dato
          </button>
          <button
            className={`btnUnderline ${styles.btnCancel} ${IsFKTheme && styles.btnCancelFK}`}
            onClick={onCancel}
          >
            Velg en annen dato
          </button>
        </div>
      </FormPageContainer>
    </div>
  ) : (
    <></>
  );
}

export default ConfirmDateModal;
