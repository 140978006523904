import { connect, useDispatch } from "react-redux";
import { formAndUserSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import styles from "./PriceSummaryCSS.module.scss";
import { IsFKTheme } from "../../../Utils/Themes";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { sanitizeProps } from "../../../Utils/HelperFunctions";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { useParams } from "react-router-dom";
import store from "../../../ReduxModules/Store/Store";
import { Box, Grid, Paper } from "@mui/material";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import moment from "moment";

function PriceSummary({ onConfirm, onBack, userId, form }) {
  const params = useParams();
  const forms = store.getState().saleState.form;
  const productsState = store.getState().productState.products;
  const users = forms.users;
  const dispatch = useDispatch();

  const calculateTotalMonthly = (user) => {
    if (user.product !== null && user.product !== undefined) {
      let total = user.product.recPrice;
      if (user.extraProducts !== null && user.extraProducts.length > 0) {
        user.extraProducts.forEach((extraProduct) => {
          const product = productsState.find((p) => p.id === parseInt(extraProduct.id));
          if (product) {
            total += product.recPrice;
          }
        });
      }
      return total;
    } else {
      let total = ePInfo(user.avtaletype, true);
      if (
        user.extraProducts !== null &&
        user.extraProducts !== undefined &&
        user.extraProducts?.length > 0
      ) {
        user.extraProducts.forEach((extraProduct) => {
          const product = productsState.find((p) => p.id === parseInt(extraProduct.id));
          if (product) {
            total += product.recPrice;
          }
        });
      }
      return total;
    }
  };

  const ePInfo = (id, price) => {
    let info = productsState.filter((p) => p.id === parseInt(id));
    if (info === null || info === undefined || info.length === 0) {
      return null;
    }
    if (price) {
      return info[0].recPrice;
    } else {
      return info[0].name;
    }
  };

  const addToSummary = useCallback((users) => {
    users.map((user) => {
      const order = {
        product: user.isOwner
          ? forms.firstName + " " + forms.lastName
          : user.firstName + " " + user.lastName,
        priceMonthly: user.product !== undefined ? user.product.recPrice : 0,
        extraProducts:
          user.extraProducts !== null
            ? user.extraProducts.map((e) => ({
                name: ePInfo(e.id, false),
                price: ePInfo(e.id, true)
              }))
            : null,
        totalMonthly: calculateTotalMonthly(user),
        newNumber: user.createNewNumber ? "Nytt nummer 99 kr" : user.phone
      };

      dispatch({ type: "ADD_SUMMARY_MOBILE", summaryMobile: order });
    });
  }, []);

  const handleConfirm = () => {
    addToSummary(users);
    if (!userId) {
      saleActionDispatchers.updateFormAction(sanitizeProps(form));
    }
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
    }
  };
  return (
    <FormPageContainer
      className={`${styles.container} ${IsFKTheme && styles.containerFK}`}
      onBack={onBack}
      currentStep={null}
    >
      <Box className={"slide"}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between"
          }}
        >
          {users.map((user, index) => {
            const userTotalMonthly = calculateTotalMonthly(user);
            return (
              <Grid item xs={12} key={index} sx={{ mb: 3 }}>
                <Paper
                  elevation={8}
                  className={"whiteBox"}
                  sx={{
                    ml: -5,
                    mr: -5,
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sx={{ textAlign: "center", mb: 1 }}>
                      <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                        {user.isOwner
                          ? forms.firstName + " " + forms.lastName
                          : user.firstName + " " + user.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center", mb: 1 }}>
                      <Typography variant={"string"} sx={{ position: "left" }}>
                        {user.createNewNumber ? "Nytt nummer 99 kr" : user.phone}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={"string"} sx={{ textDecoration: "underline" }}>
                        {user.product ? user.product.name : ePInfo(user.avtaletype, false)}:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant={"string"} sx={{ position: "left" }}>
                        Månedspris:
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant={"string"}>
                        {user.product
                          ? user.product.recPrice + " kr/mnd"
                          : ePInfo(user.avtaletype, true) + " kr/mnd"}
                      </Typography>
                    </Grid>
                    {user.extraProducts !== null && user.extraProducts.length > 0 && (
                      <>
                        <Grid item xs={12} sx={{ mt: 1 }}>
                          <Typography variant="string" sx={{ textDecoration: "underline" }}>
                            Valgte tilleggstjenester
                          </Typography>
                        </Grid>
                        {user.extraProducts.map((extraProduct, index) => (
                          <Grid container key={index}>
                            <Grid item xs={8}>
                              <Typography variant="string">
                                {ePInfo(extraProduct.id, false)}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="string">
                                {ePInfo(extraProduct.id, true) + " kr/mnd"}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                    <Grid item xs={8} sx={{ mt: 2 }}>
                      <Typography variant={"string"} sx={{ position: "left", fontWeight: "bold" }}>
                        Total månedsbeløp:
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ mt: 2 }}>
                      <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                        {userTotalMonthly + " kr/mnd"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {forms.chosenDistributionMethod === 1 && (
        <Grid>
          <Box>
            <Paper elevation={8} className={"whiteBox"} sx={{ ml: -5, mr: -5 }}>
              <Grid container>
                <Grid item xs={8}>
                  <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                    Papirfaktura
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                    49 kr/mnd
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
      )}
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <div>
          <button className={"btnPrimary " + styles.btn} onClick={handleConfirm}>
            Bekreft
          </button>
        </div>
      </Box>
    </FormPageContainer>
  );
}
export default connect(formAndUserSelector)(PriceSummary);
