import * as _ from "lodash";
import { checkGroupTag, productCategories, radioGroupTag } from "../../Utils/CustomerFormHelper";

export const productSelector = ({ productState }, { productId }) => {
  return {
    product:
      productState &&
      productState.products &&
      productState.products.find((p) => p.id.toString() === productId.toString())
  };
};

export const productInfoSelector = ({ productState }, { productId }) => {
  const product =
    !_.isEmpty(productState.products) &&
    productState.products.find((p) => p.id.toString() === productId.toString());
  return {
    loading: product?.isLoading,
    productName: product?.productHubInfo?.name ?? "",
    productInfo: product?.productInfo,
    textGroups: product?.textGroups,
    error: product?.productInfoError,
    product
  };
};

export const productsSelector = ({ productState }) => {
  return {
    products: productState.products,
    isLoading: productState.isLoadingProducts,
    error: productState.getProductsError
  };
};

export const productsDropdownSelector = ({ productState }, { productCategories, isBedrift }) => {
  const { products, isLoadingProducts, getProductsError } = productState;
  let filteredProducts = _.isEmpty(productCategories)
    ? products
    : products.filter((p) => productCategories.includes(p.productCategory));
  filteredProducts = filteredProducts.filter((p) =>
    isBedrift ? p.customerType === "B" : p.customerType === "P"
  );
  return {
    options: filteredProducts.map((p) => ({ value: p, label: p.name })),
    isLoading: isLoadingProducts,
    error: getProductsError
  };
};

export const addonProductsSelector = ({ productState }) => {
  return {
    addonProducts: productState.addonProducts,
    isLoading: productState.isLoadingAddonProducts,
    error: productState.getAddonProductsError
  };
};

export const addonProductsDropdownSelector = ({ productState }) => {
  const { addonProducts, isLoadingAddonProducts, getAddonProductsError } = productState;
  return {
    options: addonProducts.map((p) => ({ value: p, label: p.name })),
    isLoading: isLoadingAddonProducts,
    error: getAddonProductsError
  };
};

export const productCampaignDropdownSelector = ({ productState }, { productId }) => {
  const { products } = productState;
  return {
    product: products && products.find((p) => p.id === productId),
    options: [],
    isLoading: false,
    error: null
  };
};

export const productSalesTypeSelector = ({ productState, smsAcceptState }, { productId }) => {
  return {
    customer: smsAcceptState?.customerInfo,
    product:
      productState &&
      productId &&
      productState.products &&
      productState.products.find((p) => p.id.toString() === productId.toString())
  };
};

export const addonProductInfoSelector = ({ productState }, { addonProductId }) => {
  const addonProduct = productState.products.find(
    (x) => parseInt(x.id) === parseInt(addonProductId)
  );
  return { addonProduct };
};

export const productInfoV2Selector = ({ productState }, { productId }) => {
  const product = productState.products.find((x) => parseInt(x.id) === parseInt(productId));
  return { product };
};

export const productGroupSelector = ({ productState }, { groupId, radioGroup }) => {
  const products = productState.products.filter(
    (p) =>
      p.productCategory === productCategories.serviceOption &&
      p.tags.includes((radioGroup ? radioGroupTag : checkGroupTag) + groupId)
  );
  return { products };
};

export const mainProductCampaignDropdownSelector = (
  { productState },
  { productId, productCategories, isMobile }
) => {
  const { products, isLoadingProducts, getProductsError } = productState;
  if (isMobile) {
    const filteredProducts = _.isEmpty(productCategories)
      ? products
      : products.filter((p) => productCategories.includes(p.productCategory));
    return {
      product: products && products.find((p) => p.id === productId),
      options: filteredProducts.map((p) => ({ value: p, label: p.name })),
      isLoading: isLoadingProducts,
      error: getProductsError
    };
  }
  return {
    product: products && products.find((p) => p.id === productId),
    options: [],
    isLoading: false,
    error: null
  };
};

export const bedriftProductInfoSelector = ({ productState }) => {
  return {
    product: productState && productState.products
  };
};
