import React, { useEffect, useState } from "react";
import styles from "./Identification.module.scss";
import Spotpris from "../../../assets/Images/FK/Spotpris.svg";
import MobileWelcome from "../../../assets/Images/FK/MobileWelcome.svg";
import BankIdLogo from "../../../assets/Images/BankIdLogo.svg";
import { IsFKTheme } from "../../../Utils/Themes";
import { connect } from "react-redux";
import { identificationSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import { pages } from "../../../Utils/CustomerFormHelper";
import Icon from "../../Icon/Icon";
import { history } from "../../../App";
import pageStepActionDispatchers from "../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import { SALE_CONTEXT } from "../../../ReduxModules/Sagas/SaleSaga";
import FKLogo from "../../../assets/Images/FK/FKLogo.png";
import TKLogos from "../../../assets/Images/TK/TKLogos.png";
import MinSideWrapper from "./MinSide/MinSideLogIn";
import { useAuth } from "react-oidc-context";
import * as AxiosFunction from "../../../Utils/AxiosFunctions";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import customerActionDispatchers from "../../../ReduxModules/Actions/CustomerActions/CustomerActionDispatchers";
import { useDispatch } from "react-redux";
import Loader from "../FKLoader/Loader";
import store from "../../../ReduxModules/Store/Store";
import { setAuthHeader } from "../../../Utils/axiosHeaders";
import config from "../../../config";

function Identification({ name, bankIdName, saleContext, hideFooter = true }) {
  const isMobile = saleContext === SALE_CONTEXT.mobile;
  const auth = useAuth();
  const dispatch = useDispatch();
  const producthubInfo = store.getState().saleState.form.producthubInfo;
  const isPrivate =
    producthubInfo !== null &&
    producthubInfo !== undefined &&
    producthubInfo.customerType !== undefined &&
    producthubInfo.customerType !== null &&
    producthubInfo.customerType === "P";

  useEffect(() => {
    if (
      auth.isAuthenticated &&
      auth.user !== null &&
      auth.user !== undefined &&
      auth.user.profile !== null &&
      auth.user.profile !== undefined &&
      isPrivate
    ) {
      if (auth.user.profile.customerId !== undefined && auth.user.profile.customerId !== null) {
        continueWithMinSide();
      }
    }
  }, [auth.isAuthenticated]);
  function clearAnyExtraIdentificationSteps() {
    if (isMobile) {
      pageStepActionDispatchers.removeSteps([
        pages.mobile + pages.manualIdentification,
        pages.mobile + pages.customerName
      ]);
    } else {
      pageStepActionDispatchers.removeSteps([
        pages.mobileNumber,
        pages.manualIdentification,
        pages.customerName
      ]);
    }
  }

  const selectManualIdentification = () => {
    clearAnyExtraIdentificationSteps();
    if (isMobile) {
      pageStepActionDispatchers.addAndGoToNextStep(
        pages.mobile,
        pages.mobile + pages.manualIdentification
      );
      pageStepActionDispatchers.addStep(
        pages.mobile + pages.manualIdentification,
        pages.mobile + pages.customerName
      );
    } else {
      pageStepActionDispatchers.addAndGoToNextStep(pages.home, pages.manualIdentification);
      pageStepActionDispatchers.addStep(pages.manualIdentification, pages.customerName);
    }
  };
  const selectBankId = () => {
    clearAnyExtraIdentificationSteps();
    history.push((isMobile ? pages.mobile : "") + pages.bankIdLogin);
  };
  const continueWithBankId = () => {
    pageStepActionDispatchers.goToNextStep(window.location.pathname);
  };

  const continueWithMinSide = () => {
    let brand = config.theme === "FK" ? "FKAS" : "TKAS";
    setAuthHeader(auth.user.access_token);
    AxiosFunction.getExistingCustomerSSNAndEmail(
      auth.user.profile.phone_number,
      auth.user.profile.customerId,
      brand
    )
      .then((info) => {
        const customerInfo = {
          firstName:
            auth.user !== null
              ? auth.user.profile["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"]
              : "",
          lastName:
            auth.user !== null
              ? auth.user.profile["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"]
              : "",
          email: auth.user !== null ? auth.user.profile.email : "",
          phone:
            auth.user !== null
              ? auth.user.profile.phone_number[0] === "+"
                ? auth.user.profile.phone_number.slice(3)
                : auth.user.profile.phone_number
              : "",
          customerId: auth.user !== null ? auth.user.profile.customerId : "",
          ssn: info.toString()
        };
        const personNumber = info.toString();
        saleActionDispatchers.updateFormAction({ personNumber });
        dispatch({
          type: "GET_MIN_SIDE_LOGIN_SUCCESS",
          payload: { customerInfo: { ...customerInfo } }
        });
        customerActionDispatchers.getExistingCustomerDataBySsn(info);
        saleActionDispatchers.getCustomerInfoAction(info, customerInfo.phone);
        pageStepActionDispatchers.goToNextStep(window.location.pathname);
      })
      .catch((error) => {
        console.error("Error: Failed to get SSN and Email for MinSide: ", error);
      });
  };

  const showButtons = (result) => {
    return !result;
  };
  const getLogInResult = (user) => {
    user !== false && isPrivate ? continueWithMinSide() : showButtons(false);
  };
  const [trigger, setTrigger] = useState(0);
  if (
    auth.isAuthenticated &&
    auth.user !== null &&
    auth.user !== undefined &&
    auth.user.profile !== null &&
    auth.user.profile !== undefined &&
    auth.user.profile.customerId !== undefined &&
    auth.user.profile.customerId !== null &&
    isPrivate
  ) {
    return (
      <FormPageContainer hideFooter className={"loaderContainer"}>
        <div className={"loader slide"}>
          <Loader text={"Vent mens vi gjør ting klart for deg..."} />
        </div>
      </FormPageContainer>
    );
  }
  return (
    <FormPageContainer hideFooter={hideFooter}>
      <MinSideWrapper getLogInResult={getLogInResult} trigger={trigger}></MinSideWrapper>
      {showButtons && (
        <div className={"slide"}>
          {IsFKTheme && (
            <div className={styles.imgContainer}>
              {isMobile ? (
                <img className={styles.mobileMascot} src={MobileWelcome} alt="mascot" />
              ) : (
                <img className={styles.mascot} src={Spotpris} alt="mascot" />
              )}
            </div>
          )}
          <header className={styles.header}>
            <h1>{`Bestill ${name}`}</h1>
            <h5 className={styles.introText}>
              For å komme i gang trenger vi litt informasjon om hvem du er.
            </h5>
          </header>
          <button className={`whiteBox ${styles.check}`} onClick={selectBankId}>
            <img className={styles.bankIdLogo} src={BankIdLogo} alt="BankIdLogo" />
            <div className={styles.bankIdContent}>
              <div className={styles.bankIdTitle}>BankID</div>
              <div className={styles.bankIdText}>Identifiser deg med BankID</div>
            </div>
            <Icon
              className={`${styles.chevron} ${!IsFKTheme && styles.chevronTk}`}
              icon={"chevron"}
            />
          </button>
          {bankIdName && (
            <button className={"btnUnderline " + styles.continueBtn} onClick={continueWithBankId}>
              Fortsett som {bankIdName}
            </button>
          )}
          {IsFKTheme ? (
            <button
              className={`whiteBox ${styles.check}`}
              onClick={() => {
                setTrigger((trigger) => trigger + 1);
              }}
            >
              <img className={styles.minSideLogo} src={FKLogo} alt="minSideLogo" />
              <div className={styles.minSideContent}>
                <div className={styles.minSideTitleFK}>Min Side</div>
                <div className={styles.minSideText}>Identifiser deg med Min Side</div>
              </div>

              <Icon
                className={`${styles.chevron} ${!IsFKTheme && styles.chevronTk}`}
                icon={"chevron"}
              />
            </button>
          ) : (
            <button
              className={`whiteBox ${styles.check}`}
              onClick={() => {
                setTrigger((trigger) => trigger + 1);
              }}
            >
              <img className={styles.minSideLogoTK} src={TKLogos} alt="minSideLogo" />
              <div className={styles.minSideContent}>
                <div className={styles.minSideTitleTK}>Min Side</div>
                <div className={styles.minSideText}>Identifiser deg med Min Side</div>
              </div>

              <Icon
                className={`${styles.chevron} ${!IsFKTheme && styles.chevronTk}`}
                icon={"chevron"}
              />
            </button>
          )}
          {bankIdName && (
            <button className={"btnUnderline " + styles.continueBtn} onClick={continueWithBankId}>
              Fortsett som {bankIdName}
            </button>
          )}
          <button
            className={"btnUnderline " + styles.manualBtn}
            onClick={selectManualIdentification}
          >
            Manuell identifisering
          </button>
        </div>
      )}
    </FormPageContainer>
  );
}

export default connect(identificationSelector)(Identification);
