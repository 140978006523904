import React, { useState, useEffect } from "react";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import { connect } from "react-redux";
import styles from "./SASBonus.module.scss";
import { formSelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import SimpleInput from "../../SimpleInput/SimpleInput";
import { Grid } from "@mui/material";
import PlaneFK from "../../../assets/Images/FK/Plane.svg";
import PlaneTK from "../../../assets/Images/TK/PlaneTK.svg";
import { IsFKTheme } from "../../../Utils/Themes";

function SASBonus({ form, onNext, onBack }) {
  const [ebNumber, setEbNumber] = useState("");
  const [showNextButton, setShowNextButton] = useState(false);
  let regExp = new RegExp(/.*\S.*/);

  useEffect(() => {
    setShowNextButton(ebNumber.length > 0);
  }, [ebNumber]);

  const handleConfirm = () => {
    saleActionDispatchers.updateFormAction({
      ebNumber
    });
    handleNext();
  };

  function handleNext() {
    onNext();
  }

  const handleBack = () => {
    onBack();
  };

  const handleChange = (event) => {
    setEbNumber(event.target.value);
  };

  return (
    <FormPageContainer onBack={handleBack}>
      <Grid container justify="center">
        <h1>Hva er ditt SAS EuroBonus-nummer?</h1>
      </Grid>
      <Grid container direction={"column"} justify="center" alignContent="center">
        <img className={styles.image} src={IsFKTheme ? PlaneFK : PlaneTK} alt="EuroBonus"></img>
        <SimpleInput
          name={"sas"}
          className={styles.ebNumberInput}
          type={"text"}
          value={ebNumber}
          showWhiteBox
          label={"SAS EuroBonus nummer"}
          placeholder={"EBG123456789"}
          onChange={handleChange}
        />

        <button
          className={`btnPrimary ${styles.btn}`}
          disabled={!showNextButton}
          onClick={handleConfirm}
        >
          Bekreft
        </button>
        <br />
        <div className={`${styles.container} ${IsFKTheme && styles.containerFK}`}>
          <button className={`btnBorder ${styles.btnSecondary}`} onClick={handleNext}>
            Registrer SAS EuroBonus-nummeret senere.
          </button>
        </div>
      </Grid>
    </FormPageContainer>
  );
}

export default connect(formSelector)(SASBonus);
