import { bindActionCreators } from "redux";
import store from "../../Store/Store";
import {
  updateSmsAcceptFormAction,
  getSmsAcceptAddonProducts,
  getSmsAcceptAddonProductsSuccess,
  getSmsAcceptAddonProductsError,
  getSmsAcceptExtraProductText,
  getSmsAcceptExtraProductTextSuccess,
  getSmsAcceptExtraProductTextError,
  submitSmsAcceptForm,
  getSmsAcceptCustomerInfoAction,
  smsAcceptSubmitConsent,
  updateHashedCustomerID,
  removeOrderAccept,
  addOrderAccept,
  getPriceSummary,
  getPriceSummaryError,
  getPriceSummarySuccess
} from "./SmsAcceptActions";

const actions = {
  updateSmsAcceptFormAction,
  getSmsAcceptAddonProducts,
  getSmsAcceptAddonProductsSuccess,
  getSmsAcceptAddonProductsError,
  getSmsAcceptExtraProductText,
  getSmsAcceptExtraProductTextSuccess,
  getSmsAcceptExtraProductTextError,
  submitSmsAcceptForm,
  getSmsAcceptCustomerInfoAction,
  smsAcceptSubmitConsent,
  updateHashedCustomerID,
  addOrderAccept,
  removeOrderAccept,
  getPriceSummaryError,
  getPriceSummarySuccess,
  getPriceSummary
};

const smsAcceptActionDispatchers = bindActionCreators(actions, store.dispatch);

export default smsAcceptActionDispatchers;
