import {
  MDM_UPDATE_TRUMF_ID,
  MDM_UPDATE_TRUMF_ID_ERROR,
  MDM_UPDATE_TRUMF_ID_SUCCESS,
  SET_ERROR,
  SET_QUERY_STRINGS,
  SET_TRUMF_ID
} from "./TrumfActionTypes";

export const setTrumfId = (trumfId) => ({
  type: SET_TRUMF_ID,
  trumfId
});

export const setQueryStrings = (redirectUri, momentId, mdmId) => ({
  type: SET_QUERY_STRINGS,
  redirectUri,
  momentId,
  mdmId
});

export const setError = (error) => ({
  type: SET_ERROR,
  error
});

export const mdmUpdateTrumfId = (momentId, trumfId) => ({
  type: MDM_UPDATE_TRUMF_ID,
  momentId,
  trumfId
});

export const mdmUpdateTrumfIdSuccess = () => ({
  type: MDM_UPDATE_TRUMF_ID_SUCCESS
});

export const mdmUpdateTrumfIdError = (error) => ({
  type: MDM_UPDATE_TRUMF_ID_ERROR,
  error
});
