import { call, put, takeLatest, takeEvery, all } from "redux-saga/effects";
import * as AxiosFunction from "../../Utils/AxiosFunctions";
import {
  getAddonProductsError,
  getAddonProductsSuccess,
  getProductInfoError,
  getProductInfoSuccess,
  getProductsError,
  getProductsSuccess,
  getProductInfoBedriftSuccess,
  getProductInfoBedriftError,
  getAddonProductInfoError,
  getAddonProductInfoSuccess,
  getPriceInfoError,
  getPriceInfoSuccess,
  getExtraProductPriceInfoError,
  getExtraProductPriceInfoSuccess,
  getCampaignTextSuccess,
  getCampaignTextError,
  getCampaignLengthSuccess,
  getCampaignLengthError
} from "../Actions/ProductActions/ProductActions";
import {
  GET_ADDON_PRODUCTS,
  GET_PRODUCT_INFO,
  GET_PRODUCTS,
  GET_PRODUCT_INFO_BEDRIFT,
  GET_ADDON_PRODUCT_INFO,
  GET_PRICE_INFO,
  GET_EXTRAPRODUCT_PRICE_INFO,
  GET_CAMPAIGN_TEXT,
  GET_CAMPAIGN_LENGTH
} from "../Actions/ProductActions/ProductActionTypes";
import config from "../../config";
import {IsGETheme} from "../../Utils/Themes";

function* getProductInfo(action) {
  try {
    const textGroups = yield all([
      call(AxiosFunction.getProductHubProductText, action.productId, 1),
      call(AxiosFunction.getProductHubProductText, action.productId, 2),
      call(AxiosFunction.getProductHubProductText, action.productId, 3, 7),
      call(AxiosFunction.getProductHubProductText, action.productId, 4),
      call(AxiosFunction.getProductHubProductText, action.productId, 5),
      call(AxiosFunction.getProductHubProductText, action.productId, 6)
    ]);
    const [productHubInfo, productInfo, campaignInfo] = yield all([
      call(AxiosFunction.GetProductHubProductWithBonuses, action.productId),
      call(AxiosFunction.getProductInfo, action.productId, action.isMobile),
      call(AxiosFunction.getCampaignInfo, action.campaignId)
    ]);
    yield put(
      getProductInfoSuccess(action.productId, productInfo, productHubInfo, campaignInfo, textGroups)
    );
  } catch (e) {
    yield put(getProductInfoError(e));
  }
}
function* getPriceInfo(action) {
  try {
    const priceInfo = yield call(AxiosFunction.getProductwithPrices, action.productId);
    yield put(getPriceInfoSuccess(priceInfo));
  } catch (e) {
    yield put(getPriceInfoError(e));
  }
}

function* getExtraProductPriceInfo(action) {
  const { productIdList } = action; // Destructure the productIdList from the action
  try {
    // Fetch extra product price information using the productIdList array
    const extraProductPriceInfo = yield call(
      AxiosFunction.getExtraProductWithPrices,
      productIdList
    );
    yield put(getExtraProductPriceInfoSuccess(extraProductPriceInfo));
  } catch (e) {
    yield put(getExtraProductPriceInfoError(e));
  }
}

export function* watchGetProductInfo() {
  yield takeEvery(GET_PRODUCT_INFO, getProductInfo);
}
export function* watchGetPriceInfo() {
  yield takeEvery(GET_PRICE_INFO, getPriceInfo);
}
export function* watchGetExtraProductPriceInfo() {
  yield takeEvery(GET_EXTRAPRODUCT_PRICE_INFO, getExtraProductPriceInfo);
}

function* getAddonProductInfo(action) {
  try {
    // todo: refactor and reference textGroups instead of different functions for each
    // 1, 2, 3, 6 are actually used
    const textGroups = yield all([
      call(AxiosFunction.getProductHubProductText, action.productId, 1),
      call(AxiosFunction.getProductHubProductText, action.productId, 2),
      call(AxiosFunction.getProductHubProductText, action.productId, 3),
      call(AxiosFunction.getProductHubProductText, action.productId, 4),
      call(AxiosFunction.getProductHubProductText, action.productId, 5),
      call(AxiosFunction.getProductHubProductText, action.productId, 6),
      call(AxiosFunction.getProductHubProductText, action.productId, 1, 7),
      call(AxiosFunction.getProductHubProductText, action.productId, 2, 7),
      call(AxiosFunction.getProductHubProductText, action.productId, 6, 7),
      call(AxiosFunction.getProductHubProductText, action.productId, 3, 7)
    ]);
    // Overskrift
    const addonProductInfo = yield call(AxiosFunction.getProductInformation, action.productId);
    // Brødtekst
    const addonProductExtendedInfo = yield call(
      AxiosFunction.getExtendedProductInformation,
      action.productId
    );
    // Prisinformasjon
    const productPriceInfo = yield call(AxiosFunction.getProductPriceInformation, action.productId);
    // Tekst til knapp
    const callToActionText = yield call(AxiosFunction.getProductCallToActionText, action.productId);

    yield put(
      getAddonProductInfoSuccess(
        action.productId,
        addonProductInfo,
        addonProductExtendedInfo,
        productPriceInfo,
        callToActionText,
        textGroups
      )
    );
  } catch (e) {
    yield put(getAddonProductInfoError(e));
  }
}

export function* watchGetAddonProductInfo() {
  yield takeEvery(GET_ADDON_PRODUCT_INFO, getAddonProductInfo);
}

function* getProductInfoBedrift(action) {
  try {
    const [
      productHubInfo,
      productInfo,
      productPriceList,
      campaignInfo,
      ingress,
      extendedProductInfo,
      image
    ] = yield all([
      call(AxiosFunction.GetProductHubProductWithBonuses, action.productId),
      call(AxiosFunction.getProductInfoBedrift, action.productId),
      call(AxiosFunction.GetProductHubProductPriceListById, action.productId),
      call(AxiosFunction.getCampaignInfo, action.campaignId),
      call(AxiosFunction.getIngressBedrift, action.productId),
      call(AxiosFunction.getExtendedInformationBedrift, action.productId),
      call(AxiosFunction.getImageBedrift, action.productId)
    ]);
    const [productTerms] = yield all([
      call(AxiosFunction.GetTermsAndConditionsGuidById, productHubInfo.termsAndConditionsId)
    ]);
    yield put(
      getProductInfoBedriftSuccess(
        action.productId,
        productInfo,
        productHubInfo,
        productPriceList,
        campaignInfo,
        ingress,
        extendedProductInfo,
        image,
        productTerms
      )
    );
  } catch (e) {
    yield put(getProductInfoBedriftError(e));
  }
}

export function* watchGetProductInfoBedrift() {
  yield takeEvery(GET_PRODUCT_INFO_BEDRIFT, getProductInfoBedrift);
}

function* getProducts(action) {
  try {
    const products = yield call(
      AxiosFunction.getProducts,
        IsGETheme ? "GUEN" : config.theme + "AS",
      "P",
      action.productCategories,
      action.storeIds
    );
    const products2 = yield call(
      AxiosFunction.getProducts,
        IsGETheme ? "GUEN" : config.theme + "AS",
      "B",
      action.productCategories,
      action.storeIds
    );
    yield put(getProductsSuccess([...products, ...products2]));
  } catch (e) {
    yield put(getProductsError(e));
  }
}

export function* watchGetProducts() {
  yield takeLatest(GET_PRODUCTS, getProducts);
}

function* getAddonProducts() {
  try {
    const addonProducts = yield call(AxiosFunction.getAddonProducts, IsGETheme ? "GUEN" : config.theme + "AS", "P");
    yield put(getAddonProductsSuccess(addonProducts));
  } catch (e) {
    yield put(getAddonProductsError(e));
  }
}

export function* watchGetAddonProducts() {
  yield takeLatest(GET_ADDON_PRODUCTS, getAddonProducts);
}

function* getCampaignText(action) {
  try {
    const campaignText = yield call(
      AxiosFunction.getCampaignText,
      action.campaignName,
      action.isMobile
    );
    yield put(getCampaignTextSuccess(campaignText));
  } catch (e) {
    yield put(getCampaignTextError(e));
  }
}

export function* watchGetCampaignText() {
  yield takeEvery(GET_CAMPAIGN_TEXT, getCampaignText);
}
function* getCampaignLength(action) {
  try {
    const campaignLength = yield call(
      AxiosFunction.getCampaignLength,
      action.id,
      action.campaignId
    );
    yield put(getCampaignLengthSuccess(campaignLength));
  } catch (e) {
    yield put(getCampaignLengthError(e));
  }
}
export function* watchGetCampaignLength() {
  yield takeEvery(GET_CAMPAIGN_LENGTH, getCampaignLength);
}
