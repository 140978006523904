import * as update from "immutability-helper";
import {
  ADD_AND_GO_TO_NEXT_STEP,
  ADD_STEP,
  ADD_STEPS,
  REMOVE_AND_GO_TO_NEXT_STEP,
  REMOVE_STEPS,
  SET_BEDRIFT_STEPS,
  SET_CONTINUE_AGREEMENT_STEPS,
  SET_CUSTOMER_FORM_STEPS,
  SET_MOBILE_SALE_STEPS,
  SET_MOBILE_USER_STEPS
} from "../Actions/PageStepActions/PageStepActionTypes";
import * as _ from "lodash";
import {
  findAdditionalPages,
  findAddtionalMobilePages,
  getAllPossibleBedriftDeliveryPointSteps,
  getAllPossibleDeliveryPointSteps,
  getMobileUserSteps,
  pages
} from "../../Utils/CustomerFormHelper";
import {
  SET_FORM_FROM_INTERNAL_MOVE_IN_INFO,
  SET_FORM_FROM_SMS_MOVE_IN_INFO,
  SET_STATE
} from "../Actions/SaleActions/SaleActionTypes";
import { GET_EXISTING_CUSTOMER_DATA_BY_SSN_SUCCESS } from "../Actions/CustomerActions/CustomerActionTypes";
import { matchPath } from "react-router-dom";

const initialState = {
  steps: [],
  userSteps: [],
  deliveryPointSteps: [],
  focusDelay: 0
};

const pageStepReducer = (state = initialState, action) => {
  let newSteps = [];
  let newUserSteps = [];
  let newDeliveryPointSteps = [];
  switch (action.type) {
    case SET_CUSTOMER_FORM_STEPS:
      newSteps.push(pages.home);
      //newSteps = newSteps.concat(findAdditionalPages(action.recommendedProducts, action.bonuses));
      newSteps.push(pages.deliveryPoints);
      newSteps = newSteps.concat(findAdditionalPages(action.recommendedProducts, action.bonuses));
      newSteps.push(pages.invoice);
      newSteps.push(pages.customerEmail);
      newSteps.push(pages.subscription);
      newSteps.push(pages.priceSummary);
      newSteps.push(pages.receipt);

      newDeliveryPointSteps = getAllPossibleDeliveryPointSteps();
      return update(state, {
        steps: { $set: newSteps },
        deliveryPointSteps: { $set: newDeliveryPointSteps }
      });

    case SET_FORM_FROM_SMS_MOVE_IN_INFO:
      newSteps.push(pages.changeProduct);
      newSteps.push(pages.selectProduct);
      newSteps.push(pages.manualIdentification);
      newSteps.push(pages.customerEmail);
      newSteps.push(pages.invoice);
      newSteps.push(pages.customerEmail);
      newSteps.push(pages.subscription);
      newSteps.push(pages.termsAndConditions);
      newSteps.push(pages.receipt);
      return update(state, {
        steps: { $set: newSteps }
      });

    case SET_FORM_FROM_INTERNAL_MOVE_IN_INFO:
      newSteps.push(pages.changeProduct);
      newSteps.push(pages.selectProduct);
      newSteps.push(pages.deliveryPoints);
      newSteps = newSteps.concat(findAdditionalPages(action.recommendedProducts, action.bonuses));
      newSteps.push(pages.invoice);
      newSteps.push(pages.customerEmail);
      newSteps.push(pages.subscription);
      newSteps.push(pages.termsAndConditions);
      newSteps.push(pages.receipt);
      return update(state, {
        steps: { $set: newSteps }
      });

    case SET_BEDRIFT_STEPS:
      newSteps.push(pages.bedrift);
      newSteps.push(pages.bedrift + pages.contactPerson);
      newSteps.push(pages.bedrift + pages.deliveryPoints);
      newSteps.push(pages.bedrift + pages.bedriftMovingDate);
      newSteps.push(pages.bedrift + pages.invoice);
      newSteps.push(pages.bedrift + pages.confirmOrder);
      newSteps.push(pages.bedrift + pages.receipt);

      newDeliveryPointSteps = getAllPossibleBedriftDeliveryPointSteps();
      return update(state, {
        steps: { $set: newSteps },
        deliveryPointSteps: { $set: newDeliveryPointSteps }
      });

    case SET_MOBILE_SALE_STEPS:
      newSteps.push(pages.mobile);
      newSteps.push(pages.mobile + pages.mobileSubscriptionReceiver);
      newSteps.push(pages.mobile + pages.customerEmail);
      newSteps.push(pages.mobile + pages.summary);
      newSteps.push(pages.mobile + pages.invoice);
      // newSteps.push(pages.mobile + pages.priceSummaryMobile);
      newSteps.push(pages.mobile + pages.subscription);
      newSteps.push(pages.mobile + pages.confirmOrder);
      newSteps.push(pages.mobile + pages.receipt);

      newUserSteps.push(pages.mobile + pages.addUser);
      newUserSteps.push(pages.mobile + pages.addUser + pages.customerName);
      newUserSteps.push(pages.mobile + pages.addUser + pages.birthDate);
      newUserSteps.push(pages.mobile + pages.addUser + pages.mobileSelectNumber);
      newUserSteps = newUserSteps.concat(
        findAddtionalMobilePages(action.recommendedProducts, action.bonuses, true)
      );
      return update(state, {
        steps: { $set: newSteps },
        userSteps: { $set: newUserSteps }
      });

    case SET_CONTINUE_AGREEMENT_STEPS:
      newSteps.push(pages.home);
      newSteps.push(pages.deliveryPoints);
      newSteps.push(pages.confirmExistingAgreement);
      newSteps.push(pages.deliveryPoints);
      //newSteps = newSteps.concat(findAdditionalPages(action.recommendedProducts, action.bonuses));
      newSteps.push(pages.invoice);
      newSteps.push(pages.customerEmail);
      newSteps.push(pages.subscription);
      newSteps.push(pages.priceSummary);
      newSteps.push(pages.receipt);

      newDeliveryPointSteps = getAllPossibleDeliveryPointSteps();
      return update(state, {
        steps: { $set: newSteps },
        deliveryPointSteps: { $set: newDeliveryPointSteps }
      });

    case SET_MOBILE_USER_STEPS:
      return update(state, {
        userSteps: {
          $set: getMobileUserSteps(
            action.user,
            action.recommendedProducts,
            action.users,
            action.skipProductStep
          )
        }
      });

    case REMOVE_AND_GO_TO_NEXT_STEP:
    case REMOVE_STEPS:
      newUserSteps = [...state.userSteps];
      newDeliveryPointSteps = [...state.deliveryPointSteps];
      newSteps = [...state.steps];
      if (!_.isEmpty(action.stepsToBeRemoved)) {
        if (matchPath(action.stepsToBeRemoved[0], pages.mobile + pages.addUser)) {
          newUserSteps = newUserSteps.filter(
            (s) => !action.stepsToBeRemoved.find((r) => matchPath(r, { path: s, exact: true }))
          );
        } else if (matchPath(action.stepsToBeRemoved[0], pages.deliveryPointV2)) {
          newDeliveryPointSteps = newDeliveryPointSteps.filter(
            (s) => !action.stepsToBeRemoved.find((r) => matchPath(r, { path: s, exact: true }))
          );
        } else {
          newSteps = newSteps.filter(
            (s) => !action.stepsToBeRemoved.find((r) => matchPath(r, { path: s, exact: true }))
          );
        }
      }
      return update(state, {
        userSteps: { $set: newUserSteps },
        deliveryPointSteps: { $set: newDeliveryPointSteps },
        steps: { $set: newSteps }
        //steps: { $set: state.steps.filter(s => !action.stepsToBeRemoved.map(s => action.params ? generatePath(s,action.params) : s).includes(s))}
      });

    case ADD_AND_GO_TO_NEXT_STEP:
    case ADD_STEP:
      newUserSteps = [...state.userSteps];
      newDeliveryPointSteps = [...state.deliveryPointSteps];
      newSteps = [...state.steps];
      if (matchPath(action.currentPage, pages.mobile + pages.addUser)) {
        const currentIndex = newUserSteps.findIndex((s) =>
          matchPath(action.currentPage, { path: s, exact: true })
        );
        newUserSteps.splice(currentIndex + 1, 0, action.nextPage);
      } else if (matchPath(action.currentPage, pages.deliveryPointV2)) {
        const currentIndex = newDeliveryPointSteps.findIndex((s) =>
          matchPath(action.currentPage, { path: s, exact: true })
        );
        newDeliveryPointSteps.splice(currentIndex + 1, 0, action.nextPage);
      } else {
        const currentIndex = newSteps.findIndex((s) =>
          matchPath(action.currentPage, { path: s, exact: true })
        );
        newSteps.splice(currentIndex + 1, 0, action.nextPage);
      }
      return update(state, {
        userSteps: { $set: _.uniq(newUserSteps) },
        deliveryPointSteps: { $set: _.uniq(newDeliveryPointSteps) },
        steps: { $set: _.uniq(newSteps) }
      });

    case ADD_STEPS:
      newUserSteps = [...state.userSteps];
      newDeliveryPointSteps = [...state.deliveryPointSteps];
      newSteps = [...state.steps];
      if (matchPath(action.currentPage, pages.addUser)) {
        const currentIndex = newUserSteps.findIndex((s) =>
          matchPath(action.currentPage, { path: s, exact: true })
        );
        newUserSteps.splice(currentIndex + 1, 0, ...action.pages);
      } else if (matchPath(action.currentPage, pages.deliveryPointV2)) {
        const currentIndex = newDeliveryPointSteps.findIndex((s) =>
          matchPath(action.currentPage, { path: s, exact: true })
        );
        newDeliveryPointSteps.splice(currentIndex + 1, 0, ...action.pages);
      } else {
        const currentIndex = newSteps.findIndex((s) =>
          matchPath(action.currentPage, { path: s, exact: true })
        );
        newSteps.splice(currentIndex + 1, 0, ...action.pages);
      }

      return update(state, {
        userSteps: { $set: newUserSteps },
        deliveryPointSteps: { $set: newDeliveryPointSteps },
        steps: { $set: newSteps }
      });

    case GET_EXISTING_CUSTOMER_DATA_BY_SSN_SUCCESS:
      let stepsToRemove = [];
      if (action.invoiceInfo && action.isActiveCustomer) {
        stepsToRemove.push(pages.invoice);
      } else {
        // add step
      }
      if (action.subscriptionConsent) {
        stepsToRemove.push(pages.subscription);
      } else {
        // add step
      }
      return update(state, {
        steps: { $set: state.steps.filter((s) => !stepsToRemove.includes(s)) }
      });

    case SET_STATE:
      return { ...state, ...action.state.pageStepState };

    default:
      return state;
  }
};

export default pageStepReducer;
