import React, { useEffect, useMemo, useState } from "react";
import { connect, useSelector } from "react-redux";
import { summarySelector } from "../../../ReduxModules/Selectors/SaleSelectors";
import styles from "./Receipt.module.scss";
import { IsFKTheme } from "../../../Utils/Themes";
import FormPageContainer from "../FormPageContainer/FormPageContainer";
import HouseWelcome from "../../../assets/Images/FK/HouseWelcome.svg";
import Trumf from "../../../assets/Images/FK/Trumf.svg";
import PlaneSmall from "../../../assets/Images/FK/PlaneSmall.svg";
import PlaneTKSmall from "../../../assets/Images/TK/PlaneTKSmall.svg";
import Identifisering from "../../../assets/Images/Bedrift/Identifisering.svg";
import WelcomeFlag from "../../../assets/Images/TK/VelkommenFlag.svg";
import saleActionDispatchers from "../../../ReduxModules/Actions/SaleActions/SaleActionDispatchers";
import Loader from "../FKLoader/Loader";
import ProductInfoModal from "../ProductInfoModal/ProductInfoModal";
import { powerLink } from "../../../Utils/ExternalLinks";
import config from "../../../config";
import BonusReceiptBox from "./BonusReceiptBox";
import { SasBonusType, TrumfBonusType } from "../../../Utils/AdditionalProductHelper";
import { toNorwegianNumberFormat } from "../../../Utils/HelperFunctions";
import MobileMascotCelebrate from "../../../assets/Images/FK/Mobil/MobileMascotCelebrate.svg";
import Icon from "../../Icon/Icon";
import * as _ from "lodash";
import { Box, Divider, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import productActionDispatchers from "../../../ReduxModules/Actions/ProductActions/ProductActionDispatchers";
import { AdditionalSalesByIds } from "../AdditionalSales/AdditionalSalesByIds";
import store from "../../../ReduxModules/Store/Store";
import { InvoicePrices } from "../../../Utils/Prices";

function Receipt({
  form,
  selectedAddonProducts,
  deliveryPoints,
  submitSuccessful,
  isLoading,
  bonusCampaigns,
  isMobile,
  isBedrift
}) {
  const { additionalSalesIds } = useSelector((state) => ({
    additionalSalesIds: state?.saleState?.form?.additionalSales ?? []
  }));

  const [showProductInfoModal, setShowProductInfoModal] = useState(false); // productId
  const [showModal, setShowModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const shouldRedirectBackToFlytting = useSelector(
    (state) => state.saleState.shouldRedirectBackToFlytting
  );
  const sasBonus = bonusCampaigns?.find((bc) => bc.bonusTypeId === SasBonusType) ?? null;
  const trumfBonus = bonusCampaigns?.find((bc) => bc.bonusTypeId === TrumfBonusType) ?? null;
  const trumfBonusAmount = 750;
  const isPartOfInternalMoveFlow = useSelector((state) => state.saleState.isPartOfInternalMoveFlow);
  const isPartOfSmsMoveFlow = useSelector((state) => state.saleState.isPartOfSmsMoveFlow);
  const isMoveIn = useMemo(
    () => isPartOfInternalMoveFlow || isPartOfSmsMoveFlow,
    [isPartOfSmsMoveFlow, isPartOfInternalMoveFlow]
  );
  const campaignText = useSelector((state) => state.productState.campaignText);

  const handleBackToFlytting = () => {
    onbeforeunload = null;
    window.location.replace(`${config.flytteUrl}/utflytting-redirect`);
  };

  const redirectToMainPage = () => {
    window.location = powerLink;
  };

  const handleTryAgain = () => {
    //TODO: Legg til bedrift her
    if (isMobile) {
      saleActionDispatchers.submitMobileFormAction(form);
    } else {
      saleActionDispatchers.submitFormAction(form);
    }
  };

  useEffect(() => {
    const fetchCampaignText = async () => {
      try {
        productActionDispatchers.getCampaignText(
          store.getState().saleState?.form?.campaign?.campaignName, isMobile
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchCampaignText();
  }, [store.getState().saleState?.form?.campaign]);

  /*const deprecatedTrumfBox = form.trumfId && form.storeId !== '851' && // don't show bonus if this is winback. Todo: Handle startbonus eligibility and report if customer will get bonus or not.
        <BonusReceiptBox
            preHeader={`Trumf`}
            title={`${toNorwegianNumberFormat(trumfBonus?.amount ?? trumfBonusAmount)} kr i Trumf-Bonus`}
            description={`Din velkomstbonus er på vei.\nI tillegg får du 2% bonus etter hver faktura.\n\nSjekk din Trumf-bonus saldo på trumf.no`}
            imageComponent={<img className={styles.trumf} src={Trumf} alt={'Trumf'}/>}
        />*/

  return isLoading ? (
    <FormPageContainer hideFooter className={"loaderContainer"}>
      <div className={"loader slide"}>
        <Loader text={"Sender bestilling..."} />
      </div>
    </FormPageContainer>
  ) : submitSuccessful ? (
    isMobile ? (
      <>
        {
          <ProductInfoModal
            show={showProductInfoModal}
            closeModal={() => setShowProductInfoModal(false)}
            productId={showProductInfoModal}
          />
        }
        <FormPageContainer hideFooter>
          <h1 className={"fallIn-"}>Abonnementene er bestilt og SIM-kortet er på vei!</h1>
          {IsFKTheme && (
            <div className={"frameImageContainer"}>
              <img
                className={"frameImage formPage-background fade-"}
                src={MobileMascotCelebrate}
                alt={"mobileMascot"}
              />
            </div>
          )}
          <div className={"slide " + styles.content} style={{ textAlign: "center" }}>
            <div>
              Du vil snart motta en e-post med bekreftelse på bestillingen din og du vil motta{" "}
              {form.users.length > 1 ? "SIM-kortene" : "SIM-kortet"} i løpet av 5-7 dager til
              folkeregistrert adresse.
            </div>
            <button
              className={"btnUnderline " + styles.expandSummaryButton}
              onClick={() => setShowDetails(!showDetails)}
            >
              Se min bestilling
              <Icon icon={"chevron"} className={showDetails ? styles.pointUp : styles.pointDown} />
            </button>
            {showDetails && (
              <>
                {!_.isEmpty(form.users) &&
                  form.users
                    .filter((f) => f.completed)
                    .map((f, i) => {
                      return (
                        <div
                          key={i}
                          className={`whiteBox ${styles.summary} ${styles.subSummary} ${IsFKTheme && styles.containerFK}`}
                        >
                          <div className={styles.grayText}>
                            {f.isOwner ? "Du har valgt" : "Familiemedlem"}
                          </div>
                          <button
                            className={styles.productName}
                            onClick={() => setShowProductInfoModal(f.product.id)}
                          >
                            {f.product.name}
                          </button>
                          <div>
                            <div className={styles.blackText}>
                              {f.isOwner
                                ? `${form.firstName} ${form.lastName}`
                                : `${f.firstName} ${f.lastName}`}
                            </div>
                            <div className={styles.grayText}>
                              {f.createNewNumber ? "Nytt nummer" : `Eksisterende nummer`}
                            </div>
                            {!f.createNewNumber && <div className={styles.grayText}>{f.phone}</div>}
                          </div>
                        </div>
                      );
                    })}
              </>
            )}
            <AdditionalSalesByIds ids={additionalSalesIds.map((x) => x.id)} />
            <button className={"btnPrimary " + styles.redirectButton} onClick={redirectToMainPage}>
              Tilbake til {IsFKTheme ? "Fjordkraft.no" : "Trondelagkraft.no"}
            </button>
          </div>
        </FormPageContainer>
      </>
    ) : (
      <FormPageContainer hideFooter className={IsFKTheme && styles.containerFK}>
        <ProductInfoModal
          show={showModal}
          closeModal={() => setShowModal(false)}
          productId={form.producthubInfo.id}
        />
        <h1 className={styles.title}>
          {isBedrift
            ? "Strømmen er bestilt til selskapet ditt!"
            : "Strømmen er bestilt og på vei hjem til deg!"}
        </h1>
        {isBedrift && (
          <div className={styles.imgContainer} style={{ marginLeft: "40px" }}>
            {<img className={styles.mascot} src={Identifisering} alt="mascot" />}
          </div>
        )}
        <h5>{"Du vil snart motta en e-post med\nbekreftelse på bestillingen din."}</h5>
        <div className={`whiteBox ${styles.summary}`}>
          {IsFKTheme && !isBedrift && (
            <img className={styles.house} src={HouseWelcome} alt={"HouseWelcome"} />
          )}
          {isBedrift && <br />}
          <div className={styles.grayText}>Din strømavtale</div>
          <button className={styles.productName} onClick={() => setShowModal(true)}>
            {form.producthubInfo.name}
          </button>
          {selectedAddonProducts &&
            selectedAddonProducts.map((item, i) => (
              <div key={i}>
                <h6 className={styles.addonProduct}>{item.name}</h6>
              </div>
            ))}
          {deliveryPoints &&
            deliveryPoints.map((deliveryPoint, i) => (
              <div key={i}>
                {isBedrift ? (
                  <>
                    <div className={styles.deliveryPoint}>
                      <div className={styles.blackText}>{deliveryPoint.address}</div>
                      <div className={styles.blackText}>
                        {deliveryPoint.zipCode} {deliveryPoint.city}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {deliveryPoint.elhubData ? (
                      <div className={styles.deliveryPoint}>
                        <div className={styles.blackText}>
                          {deliveryPoint.elhubData.addressV1.streetName}{" "}
                          {deliveryPoint.elhubData.addressV1.buildingNumber}
                          {deliveryPoint.elhubData.addressV1.buildingLetter}
                        </div>
                        <div className={styles.blackText}>
                          {deliveryPoint.elhubData.addressV1.zipCode}{" "}
                          {deliveryPoint.elhubData.addressV1.city}
                        </div>
                      </div>
                    ) : (
                      <div className={styles.deliveryPoint}>
                        <div className={styles.blackText}>{deliveryPoint.address}</div>
                        <div className={styles.blackText}>
                          {deliveryPoint.zipCode} {deliveryPoint.city}
                        </div>
                      </div>
                    )}
                  </>
                )}

                {isBedrift ? (
                  <>
                    <div className={styles.grayText}>
                      Målepunkt Id:{" "}
                      {deliveryPoint.elhubData
                        ? deliveryPoint.elhubData?.meterIdentification
                        : deliveryPoint.meterIdentification}
                    </div>
                    <div className={styles.grayText}>
                      Målepunkt Id: {deliveryPoint.elhubData?.meteringPointIdentification}
                    </div>
                  </>
                ) : (
                  <>
                    {isMoveIn ? (
                      <div className={styles.grayText}>
                        Målepunkt Id: {deliveryPoint.meteringPointIdentification || "Ikke funnet"}
                      </div>
                    ) : (
                      <div className={styles.grayText}>
                        Målernr:{" "}
                        {(deliveryPoint.elhubData && deliveryPoint.elhubData.meterIdentification) ||
                          deliveryPoint.meterIdentification ||
                          "Ikke funnet"}
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}

          {!IsFKTheme && (
            <img src={WelcomeFlag} alt={"VelkommenFlagg"} className={styles.welcomeFlag} />
          )}
        </div>
        {shouldRedirectBackToFlytting && (
          <div className={styles.backToMoveOutContainer}>
            <button className={"btnPrimary"} onClick={handleBackToFlytting}>
              {" "}
              Til utflytting
            </button>
          </div>
        )}

        {form.ebNumber && (
          <BonusReceiptBox
            preHeader={`SAS EuroBonus`}
            title={`${toNorwegianNumberFormat(sasBonus?.amount ?? 0)} poeng`}
            description={`Du har nå fått ${toNorwegianNumberFormat(sasBonus?.amount ?? 0)} poeng i velkomstgave! I tillegg får du 3 000 ekstrapoeng for hvert år du fortsetter med denne strømavtalen.`}
            imageComponent={
              <img
                className={styles.sasImage}
                src={IsFKTheme ? PlaneSmall : PlaneTKSmall}
                alt={"SAS EuroBonus"}
              />
            }
          />
        )}
        {!isBedrift && store.getState().saleState.summaryPower !== null && (
          <Box className={`whiteBox ${styles.summary} ${IsFKTheme && styles.containerFK}`}>
            <Grid container sx={{ textAlign: "left" }}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant={"string"} sx={{ color: "#FF5500" }}>
                  Oppsummering
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                <Divider sx={{ borderTop: "1px grey" }} />
              </Grid>
              {store.getState().saleState.summaryPower?.map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Grid container>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Typography variant={"string"}>
                        {item.address.adresse + " " + item.address.city + " " + item.address.zip}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                        {form.producthubInfo.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant={"string"}>Månedspris:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant={"string"}>{item.priceMonthly + " kr/mnd"}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant={"string"}>Påslag:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant={"string"}>{item.pricePaslag + " øre/kWh"}</Typography>
                    </Grid>
                    {item.extraProducts !== null && item.extraProducts.length > 0 && (
                      <>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                          <Typography variant={"string"} sx={{ textDecoration: "underline" }}>
                            Valgte tilleggstjenester
                          </Typography>
                        </Grid>
                        {item.extraProducts.map((extraProduct, i) => (
                          <>
                            <Grid item xs={7}>
                              <Typography variant={"string"}>{extraProduct.name}:</Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Typography variant={"string"}>
                                {extraProduct.price + " " + extraProduct.denomination}
                              </Typography>
                            </Grid>
                          </>
                        ))}
                      </>
                    )}
                    <Grid item xs={7} sx={{ mt: 2 }}>
                      <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                        Total månedspris:
                      </Typography>
                    </Grid>
                    <Grid item xs={5} sx={{ mt: 2 }}>
                      <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                        {item.totalMonthly + " kr/mnd"}
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                        {item.fixed ? "Total strømpris:" : "Total påslag:"}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant={"string"} sx={{ fontWeight: "bold" }}>
                        {item.totalPaslag + " øre/kWh"}
                      </Typography>
                    </Grid>
                    {item.extraProducts !== null &&
                      item.extraProducts.length > 0 &&
                      item.extraProducts.map(
                        (item) =>
                          item.campaignText !== null &&
                          item.campaignText.length > 0 &&
                          item.campaignText.map((item) => (
                            <Grid item xs={12} sx={{ mt: 1 }}>
                              <Typography variant={"string"} sx={{ fontStyle: "italic" }}>
                                {item}
                              </Typography>
                            </Grid>
                          ))
                      )}
                    {campaignText != null && (
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <Typography variant={"string"} sx={{ fontStyle: "italic" }}>
                          <div dangerouslySetInnerHTML={{ __html: campaignText }} />
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={12} sx={{ mt: 1, mb: 1 }}>
                      <Divider sx={{ borderTop: "2px dotted grey" }} />
                    </Grid>
                  </Grid>
                </Grid>
              ))}

              {form.chosenDistributionMethod === 1 && (
                <>
                  <Grid item xs={7} sx={{ mt: 2 }}>
                    <Typography variant={"string"}>
                      Papirfaktura:
                    </Typography>
                  </Grid>
                  <Grid item xs={5} sx={{ mt: 2 }}>
                    <Typography variant={"string"}>{isMobile ? InvoicePrices.MobilePaper : InvoicePrices.Paper} kr/stk</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        )}
        <AdditionalSalesByIds ids={additionalSalesIds.map((x) => x.id)} />
      </FormPageContainer>
    )
  ) : (
    <FormPageContainer hideFooter>
      <h1>Noe gikk galt med bestillingen din...</h1>
      <button className={`btnPrimary ${styles.failButton}`} onClick={handleTryAgain}>
        Prøv igjen
      </button>
    </FormPageContainer>
  );
}

export default connect(summarySelector)(Receipt);
