import React, { useEffect, useState } from "react";
import styles from "./ButtonList.module.scss";
import Icon from "../Icon/Icon";
import { IsFKTheme } from "../../Utils/Themes";
import "./ButtonContent.scss";
import { CircularProgress } from "@mui/material";

function WhiteButtonList({ buttons, disableAnimation, transitionDelay }) {
  const [check, setCheck] = useState(null);
  const [nextTimeout, setNextTimeout] = useState(0);

  useEffect(() => {
    // When component unmounts: clear timeout function call.
    return () => {
      setNextTimeout(null);
    };
  }, []);
  const handleClick = (onClick, index) => {
    if (!disableAnimation) {
      setCheck(index);
      clearTimeout(nextTimeout);
      setNextTimeout(setTimeout(onClick, transitionDelay || 300));
    } else {
      onClick();
    }
  };

  const transitionTime = transitionDelay || 500;
  return (
    <div className={`${styles.container} ${IsFKTheme && styles.containerFK}`}>
      {buttons.map((b, i) => {
        const checked = i === check;
        if (!b) {
          return null;
        }
        return (
          <button
            className={`btnWhite ${styles.btn} ${checked && `${styles.btnChecked} ${styles.btnGrow}`}`}
            onClick={() => handleClick(b.onClick, i)}
            key={i}
            style={{ transition: `all ${transitionTime}ms ease-in-out` }}
            disabled={b.isLoading}
          >
            <div className={`${styles.btnText} ${styles.btnContent}`}>{b.content}</div>
            <div className={styles.iconContainer}>
              <div
                className={
                  checked
                    ? `${styles.iconInnerContainer} ${styles.iconInnerContainerChecked}`
                    : styles.iconInnerContainer
                }
                style={{ transition: checked ? `all ${transitionTime}ms ease-in-out` : "" }}
              >
                <Icon icon={"check"} className={styles.icon} />
              </div>
            </div>
            <div className={styles.iconContainer}>
              <div
                className={
                  !checked
                    ? `${styles.iconInnerContainer} ${styles.iconInnerContainerChecked} ${styles.iconInnerContainerChevron}`
                    : `${styles.iconInnerContainer} ${styles.iconInnerContainerChevron}`
                }
              >
                {b.isLoading ? (
                  <CircularProgress thickness={5} size={15} className={styles.icon} />
                ) : (
                  <Icon icon={"chevron"} className={styles.icon} />
                )}
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
}

export default WhiteButtonList;
