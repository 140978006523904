import * as React from "react";
import { useAdditionalSalesIds } from "../../../hooks/additional-sales";
import { AdditionalSales } from "./AdditionalSales";

function AdditionalSalesByIds({ ids }: { ids: string[] }) {
  const { data, isLoading, error } = useAdditionalSalesIds(ids);

  return <AdditionalSales data={data} isLoading={isLoading} error={error} />;
}

export { AdditionalSalesByIds };
