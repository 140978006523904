import { SET_SESSION_ID } from "../Actions/SaleActions/SaleActionTypes";
import update from "immutability-helper";

const initialState = {
  sessionId: null
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION_ID:
      return update(state, {
        sessionId: { $set: action.sessionId }
      });
    default:
      return state;
  }
};

export default sessionReducer;
