import styles from "./SelectProductModal.module.scss";
import { Dialog } from "@mui/material";
import React from "react";
import SelectProduct from "./SelectProduct";

function SelectProductModal({ open, handleClose, userId, className }) {
  return (
    <Dialog
      open={open}
      PaperProps={{ className: styles.dialogBox }}
      onClose={handleClose}
      scroll={"body"}
    >
      <h1>Bytt Produkt</h1>
      <SelectProduct
        onConfirm={handleClose}
        userId={userId}
        whiteBackground
        className={className}
      />
    </Dialog>
  );
}

export default SelectProductModal;
