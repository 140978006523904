export const updateServiceWorker = (registration, reloadUri) => {
  const registrationWaiting = registration.waiting;

  if (registrationWaiting) {
    registrationWaiting.postMessage({ type: "SKIP_WAITING" });

    registrationWaiting.addEventListener("statechange", (e) => {
      if (e.target.state === "activated") {
        window.onbeforeunload = null; // redirect without asking for consent first.
        window.location.href = reloadUri;
      }
    });
  }
};
