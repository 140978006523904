import React from "react";
import styles from "./BonusDeal.module.scss";
import { IsFKTheme } from "../../../Utils/Themes";
import House from "../../../assets/Images/FK/HouseFramed.svg";
import Waterfall from "../../../assets/Images/FK/WaterfallFramed.svg";
import Trumf from "../../../assets/Images/FK/TrumfFramed.svg";
import {
  getNorwegianPowerId,
  getTripleWarrantyId,
  getTrumfProductId
} from "../../../Utils/AdditionalProductHelper";

function AddonProductPage({ onConfirm, addonProduct, campaign }) {
  function AddonProductImage({ productId }) {
    if (!IsFKTheme) return <div />;
    let src;
    switch (productId.toString()) {
      case getTripleWarrantyId():
        src = House;
        break;
      case getNorwegianPowerId():
        src = Waterfall;
        break;
      case getTrumfProductId():
        src = Trumf;
        break;
      default:
        src = House;
        break;
    }

    return (
      <div className={"frameImageContainer"}>
        <img className={"frameImage formPage-background fade-"} src={src} alt={"bilde"} />
      </div>
    );
  }

  return (
    <div className={`${styles.container} ${IsFKTheme && styles.containerFK}`}>
      <header className={`${styles.header} fallIn-`}>
        <h1>
          <div dangerouslySetInnerHTML={{ __html: addonProduct.addonProductInfo }} />
        </h1>
      </header>
      <AddonProductImage productId={addonProduct.id} />
      <div className={`${styles.content} slide`}>
        <div dangerouslySetInnerHTML={{ __html: addonProduct.addonProductExtendedInfo }} />
        <div
          dangerouslySetInnerHTML={{ __html: campaign?.campaignText ? campaign.campaignText : "" }}
        />
        <button className={`btnPrimary ${styles.btn}`} onClick={() => onConfirm(true)}>
          <div dangerouslySetInnerHTML={{ __html: addonProduct.callToActionText }} />
        </button>
        <a className={`btnSkip ${styles.btn}`} onClick={() => onConfirm(false)}>
          Nei takk
        </a>
      </div>
    </div>
  );
}

export default AddonProductPage;
