import React from "react";
import FormPageContainer from "../../FormPageContainer/FormPageContainer";
import pageStepActionDispatchers from "../../../../ReduxModules/Actions/PageStepActions/PageStepActionDispatchers";
import styles from "./SelectProduct.module.scss";
import { pages } from "../../../../Utils/CustomerFormHelper";
import { useParams } from "react-router-dom";
import SelectProduct from "./SelectProduct";

function SelectProductPage(props) {
  const { onConfirm, onBack, userId } = props;
  const params = useParams();

  function handleClick() {
    if (onConfirm) {
      onConfirm();
    } else {
      pageStepActionDispatchers.goToNextStep(window.location.pathname, params);
    }
  }
  return (
    <FormPageContainer
      className={`${styles.formPage}`}
      onBack={onBack}
      wide
      currentStep={userId ? pages.mobile + pages.summary : null}
    >
      <header>
        <h1 className={`fallIn- ${styles.header}`}>Velg abonnement</h1>
      </header>
      <div className={`slide`}>
        <SelectProduct userId={userId} onConfirm={handleClick} />
      </div>
    </FormPageContainer>
  );
}

export default SelectProductPage;
